import { z } from 'zod'

export const CRITERION_TYPE_ENUM = z.enum(['qualification', 'enrichment'])

export const CRITERION_SOURCE_TYPES = {
	WEB_SCRAPING: 'Web Scraping Agent',
	DATA_SET: 'Initial data set',
} as const

export const CRITERION_LOGIC_TYPES = {
	AND: 'AND',
	OR: 'OR',
} as const
