import { type z } from 'zod'
import { statsQueriesKeys } from '#src/api/icp/company/stats/queries'
import { client } from '#src/main'
import { companyKeys } from '#src/routes/company/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { valuePropositionEndpoints } from './endpoints'
import { valuePropositionQueriesKeys } from './queries'
import { type NewValuePropositionFormSchema, type ValuePropositionItemAPISchema } from './schemas'

const mutations = {
	create: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof NewValuePropositionFormSchema>,
	) => {
		await fetch(valuePropositionEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: valuePropositionQueriesKeys.all,
		})
	},
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof ValuePropositionItemAPISchema>['id'],
		data: z.infer<typeof NewValuePropositionFormSchema>,
	) => {
		await fetch(valuePropositionEndpoints.update(companyId, itemId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: valuePropositionQueriesKeys.all,
		})
	},
	delete: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof ValuePropositionItemAPISchema>['id'],
	) => {
		await fetch(valuePropositionEndpoints.delete(companyId, itemId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: valuePropositionQueriesKeys.all,
		})
	},
	publish: async (companyId: z.infer<typeof CompanySchema>['id']) => {
		await fetch(valuePropositionEndpoints.publish(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})

		await Promise.all([
			client.invalidateQueries({ queryKey: valuePropositionQueriesKeys.all }),
			client.invalidateQueries({ queryKey: companyKeys.all }),
			client.invalidateQueries({ queryKey: statsQueriesKeys.dashboard(companyId) }),
		])
	},
}

export { mutations as valuePropositionMutations }
