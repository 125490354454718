import { type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { conversationQuery } from '#src/routes/enable/roleplay/session/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type JoinRoleplayChatLoaderType = Awaited<ReturnType<typeof joinRoleplayChatLoader>>

export const joinRoleplayChatLoader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	return {
		conversationId: params.conversationId,
		conversationData: await client.fetchQuery(conversationQuery(company.id, params.conversationId)),
	}
}
