import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { updateAllVerticalsAnswers } from '#src/routes/calibrate/verticals/criteria/mutations'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { CriterionAllAssignedVerticalsAnswersPayload } from '#src/routes/calibrate/verticals/criteria/schema'
import {
	generateEditAnswersContext,
	transformAnswersSubmissionToPayload,
} from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'
import { type CriteriaEditAnswersSchema } from '../routes/editAnswers'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient, type: z.infer<typeof CriteriaEditAnswersSchema>['values']['type']) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])
		const formData = await request.formData()
		const { schema } = await generateEditAnswersContext(params.companyId, params.ecosystemId, type)
		const submission = parseFormData(formData, schema.transform(transformAndCleanToggleableFieldsSchema))
		const payload = transformAnswersSubmissionToPayload(submission.value, type)
		const parsed = CriterionAllAssignedVerticalsAnswersPayload.parse(payload)

		try {
			await updateAllVerticalsAnswers(params.companyId, params.ecosystemId, parsed)
			await Promise.all([
				queryClient.invalidateQueries({ queryKey: criteriaKeys.all }),
				queryClient.invalidateQueries({ queryKey: ecosystemKeys.all }),
				queryClient.invalidateQueries({ queryKey: verticalKeys.all }),
			])

			showToast({
				message: 'Criteria successfully saved',
				type: 'success',
				duration: 2000,
			})
		} catch (err) {
			captureException(err)
			console.error(err)
			showToast({
				message: 'Unexpected error while updating criteria answers',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.verticals.index({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}
