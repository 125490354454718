import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { valuePropositionMutations } from '#src/api/icp/company/value-proposition/mutations'
import { NewValuePropositionFormSchema } from '#src/api/icp/company/value-proposition/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const action =
	() =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId'])
		const formData = await request.formData()
		const submission = parseFormData(formData, NewValuePropositionFormSchema)

		try {
			params.pointId
				? await valuePropositionMutations.update(params.companyId, Number(params.pointId), submission.value)
				: await valuePropositionMutations.create(params.companyId, submission.value)

			showToast({
				message: `Successfully ${params.pointId ? 'updated' : 'added'}`,
				type: 'success',
			})
		} catch (err) {
			captureException(err)
			console.error(err)
			showToast({
				message: 'Unexpected error while saving value proposition point',
				type: 'error',
			})
		}

		return redirect(routes.calibrate.valueProposition.index({ companyId: params.companyId }))
	}
