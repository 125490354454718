import { type z } from 'zod'
import { SettingsCopilotAPISchema } from '#src/api/icp/company/settings/copilot/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { type ConversationSchema } from '#src/routes/enable/chat/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyCopilotConversationEndpoints } from './endpoints'

const keys = {
	all: ['icp', 'company', 'copilot', 'conversation'] as const,
	settings: (
		companyId: z.infer<typeof CompanySchema>['id'],
		conversationId: z.infer<typeof ConversationSchema>['id'],
	) => [...keys.all, 'settings', companyId, conversationId] as const,
}

const settingsQuery = (
	companyId: z.infer<typeof CompanySchema>['id'],
	conversationId: z.infer<typeof ConversationSchema>['id'],
) => ({
	queryKey: keys.settings(companyId, conversationId),
	queryFn: async () =>
		await safeFetch(
			SettingsCopilotAPISchema,
			companyCopilotConversationEndpoints.settings.get(companyId, conversationId),
			{
				method: 'GET',
			},
		),
})

const queries = {
	settings: settingsQuery,
}

export { queries as companyCopilotConversationQueries, keys as companyCopilotConversationQueriesKeys }
