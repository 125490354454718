import { Link, useLoaderData } from 'react-router-dom'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type CompanyDashboardLoaderRes } from '#src/routes/company/routes/dashboard'
import { ProductPillarCard, Resource } from '#src/routes/company/views/components'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		data: { stats, lraStats },
	} = useLoaderData() as CompanyDashboardLoaderRes
	const { user } = useAuth()
	const { company, companyId } = useCompany()
	const { dashboard } = company
	const msp = dashboard?.find(item => item.name === 'MSP')

	return (
		<PageLayout title="Home">
			<section className="grid w-full max-w-screen-2xl grid-cols-2 grid-rows-2 gap-6 xl:grid-cols-4 xl:grid-rows-1">
				<Link
					to={routes.calibrate.index({
						companyId: company.id,
					})}
					className="block"
				>
					<ProductPillarCard
						preffixIcon="machine-learning-model"
						title="Customer Intelligence OS"
						description="Calibration creates a unique customer intelligence dataset that is the foundation for all other Evergrowth agents. This dataset contains your Value Proposition, ICPs, Verticals, Key Accounts and IBPs. You must run Calibration before using other agents or copilots."
						titleColorClass="text-blue-60"
						bgColorClass="bg-blue-10"
						stats={[
							{
								label: 'Ecosystems',
								value: stats.calibrate.ecosystem_count,
							},
							{
								label: 'Verticals',
								value: stats.calibrate.vertical_count,
							},
							{
								label: 'Personas',
								value: stats.calibrate.persona_count,
							},
						]}
					/>
				</Link>

				<Link to={routes.organize.index({ companyId })} className="block">
					<ProductPillarCard
						preffixIcon="data-unreal"
						title="Organize"
						description="These criteria-based agents organize, categorize, and enrich lists of accounts and contacts, to validate their ICP based on your customer intelligence and waterfall enrichment vendor setup."
						titleColorClass="text-indigo-70"
						bgColorClass="bg-indigo-10"
						stats={
							checkIsUserSession(user.roles) || checkIsAdminSession(user.roles)
								? [
										{
											label: 'Lead research agent jobs',
											value: lraStats.inQueueCount + lraStats.completedCount + lraStats.inProgressCount,
										},
									]
								: null
						}
					/>
				</Link>

				<Link to={routes.prioritize.index({ companyId })} className="block">
					<ProductPillarCard
						preffixIcon="upstream"
						title="Prioritize"
						description="Prioritize uses intent-based agents to crawl the web, listening for buying signals and event triggers mapped to your prospects, and scores them accordingly. The agent also produces actionable insights for both prospecting and outreach."
						titleColorClass="text-purple-70"
						bgColorClass="bg-purple-10"
						stats={[
							{ label: 'Signals used', value: stats.prioritize.signals },
							{
								label: 'Personas mapped',
								value: stats.prioritize.personas,
							},
						]}
					/>
				</Link>

				<Link to={routes.enable.index({ companyId })} className="block">
					<ProductPillarCard
						preffixIcon="data-enrichment"
						title="Enable"
						description="Use AI copilots to generate ultra-personalized interactions with your prospects and customers across all stages of the sales funnel. Roleplay with simulated buyers based on LinkedIn profiles, or chat with personas for on-time training in a variety of sales scenarios."
						titleColorClass="text-green-80"
						bgColorClass="bg-green-10"
						stats={[
							{ label: 'Co-pilot chats', value: stats.enableNew.copilot },
							{ label: 'Roleplay scenarios', value: stats.enableNew.scenarios },
							{ label: 'Roleplay sessions', value: stats.enableNew.sessions },
							{ label: 'Industry-jargons', value: stats.enableNew.terms },
							{ label: 'Personas', value: stats.enableNew.personas },
							{ label: 'Plays', value: stats.enableNew.plays },
						]}
					/>
				</Link>
			</section>
			<div className="grid w-full max-w-screen-2xl grid-cols-2 grid-rows-1 gap-6">
				<DashboardCard icon="book" className="h-max self-start" title="Resources">
					<section className="mt-4 space-y-2">
						<Resource
							title="How to create your customer-centric Value Proposition"
							url="https://docs.evergrowth.com/platform/calibrate-guides/ai-customer-centric-value-proposition-generator"
						/>
						<Resource
							title="How to set up your Ecosystems"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-ecosystems"
						/>
						<Resource
							title="How to set up your Verticals"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-verticals"
						/>
						<Resource
							title="How to set up your Personas"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-personas"
						/>
					</section>
				</DashboardCard>

				<section className="flex flex-col gap-6">
					<DashboardCard
						icon="time-plot"
						className="h-max self-start"
						title={
							<div className="flex items-center gap-1">
								Time saved
								<ProductTip className="text-neutral-3-fg" content="Total hours saved with AI-enabled processes." />
							</div>
						}
					>
						<DashboardCardCount
							count={
								(stats?.time?.personas ?? 0) +
								(stats?.time?.signals ?? 0) +
								(stats?.time?.terms ?? 0) +
								(stats?.time?.verticals ?? 0)
							}
							countSuffix="Hours"
						/>
					</DashboardCard>

					<DashboardCard
						icon="portfolio"
						className="h-max self-start"
						title={
							<div className="flex w-full items-center justify-between">
								Mutual success plan
								<Button
									disabled={!msp?.url}
									asChild
									size="sm"
									variant="ghost"
									className="cursor-pointer gap-1 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
								>
									<a
										{...(msp?.url
											? {
													href: msp?.url,
													target: '__blank',
												}
											: { ['data-disabled']: true })}
									>
										View <Icon name="launch" size="sm" />
									</a>
								</Button>
							</div>
						}
					/>
				</section>
			</div>
		</PageLayout>
	)
}
