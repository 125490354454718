import { type FieldMetadata, getTextareaProps } from '@conform-to/react'
import { type z } from 'zod'
import { TextareaField } from '#src/components/forms'
import { CreateScenarioFormSchema } from '#src/routes/enable/roleplay/scenario/schema'

const PickedSchema = CreateScenarioFormSchema.pick({
	instructions: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

type InstructionsFormFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const InstructionsForm = ({ fields }: InstructionsFormFormProps) => (
	<>
		<TextareaField
			labelProps={{
				children: 'Instructions that will be displayed for user who is joining session',
			}}
			textareaProps={{
				...(fields.instructions ? getTextareaProps(fields.instructions) : { value: '' }),
				placeholder: 'Enter details',
				rows: 20,
			}}
			color="white"
			errors={fields.instructions?.errors ?? []}
		/>
	</>
)
