import { type z } from 'zod'
import { statsQueriesKeys } from '#src/api/icp/company/stats/queries'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { playsEndpoints } from './endpoints'
import { playsQueriesKeys } from './queries'
import { type PlaysFormSchema, type PlaysItemAPISchema } from './schemas'

const mutations = {
	create: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof PlaysFormSchema>) => {
		await fetch(playsEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: statsQueriesKeys.all,
		})
	},
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		playId: z.infer<typeof PlaysItemAPISchema>['id'],
		data: z.infer<typeof PlaysFormSchema>,
	) => {
		await fetch(playsEndpoints.update(companyId, playId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
	},
	delete: async (companyId: z.infer<typeof CompanySchema>['id'], playId: z.infer<typeof PlaysItemAPISchema>['id']) => {
		await fetch(playsEndpoints.delete(companyId, playId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: statsQueriesKeys.all,
		})
	},
}

export { mutations as playsMutations }
