import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'

export const View = () => {
	const { company } = useCompany()
	return (
		<PageLayout title="Report">
			<iframe
				className="aspect-video"
				src={`https://evergrowth.metabaseapp.com/public/dashboard/aa94caf7-4f45-43b8-83ac-81cc2799e174?tab=47-organize&ecosystem=&vertical=&qualification_week=&job=&persona_type=&persona_expertise=&intent_status=&copilot_type=&created_date=&last_interaction_date=&clientid=${company.id}`}
			/>
		</PageLayout>
	)
}
