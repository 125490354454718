import { z } from 'zod'
import {
	FILTERS,
	HackValues,
	IntentSource,
	IntentSourceValues,
	IntentType,
	IntentTypeValues,
	MappedPersonasValues,
	SortValues,
	WeightValues,
} from '#src/routes/prioritize/constants'
import { zString } from '#src/utils/validation'

export const IntentTypeEnumSchema = z.enum(IntentTypeValues)

export const IntentTypeSchema = IntentTypeEnumSchema.array().nullable().optional()

export const IntentSourceEnumSchema = z.enum(IntentSourceValues)

export const IntentSourceSchema = IntentSourceEnumSchema.array().nullable().optional()

export const HackEnumSchema = z.enum(HackValues)

export const HackSchema = HackEnumSchema.array().nullable().optional()

export const WeightEnumSchema = z.enum(WeightValues)

export const WeightSchema = WeightEnumSchema.array().nullable().optional()

export const MappedPersonasEnumSchema = z.enum(MappedPersonasValues)

export const MappedPersonasSchema = MappedPersonasEnumSchema.array().nullable().optional()

export const SortEnumSchema = z.enum(SortValues)

export const SortSchema = z
	.object({
		key: SortEnumSchema,
		direction: z.enum(['asc', 'desc']),
	})
	.nullable()
	.optional()

export const FilterFormSchema = z.object({
	intent: z.enum(['clear-all', 'clear', 'toggle', 'sort']),
	filter: z.enum([FILTERS.TYPE, FILTERS.SOURCE, FILTERS.HACK, FILTERS.WEIGHT, FILTERS.MAPPED_PERSONAS]).optional(),
	value: z
		.enum([...IntentTypeValues, ...IntentSourceValues, ...HackValues, ...WeightValues, ...MappedPersonasValues])
		.nullable()
		.optional(),
	sort: SortEnumSchema.optional(),
})

export const SignalTemplateAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	type: z.enum([
		IntentType.COLD_OUTBOUND_SIGNAL,
		IntentType.STATIC_HOOK,
		IntentType.INBOUND_SIGNAL,
		IntentType.WARM_OUTBOUND_SIGNAL,
	]),
	source: z.enum([
		IntentSource.ACCOUNT,
		IntentSource.ACCOUNT_PUBLIC_COMPANY,
		IntentSource.CONTACT,
		IntentSource.EXTERNAL,
	]),
	hack: z.boolean(),
	manualSteps: z.string(),
	context: z.string(),
	config: z.object({
		cost: z.number(),
		cadence: z.string(),
		weight: z.number(),
		manualInput: z.string().nullable(),
	}),
	manualRequired: z.boolean(),
	available: z.boolean(),
})

export const SignalsTemplatesAPISchema = SignalTemplateAPISchema.array()

export const AssignedSignalAPISchema = z.object({
	personaId: z.number(),
	signalId: z.number(),
	manualInput: z.string().nullable().optional(),
})

export const AssignedSignalsAPISchema = AssignedSignalAPISchema.array()

export const SignalConfigManualInputFormSchema = z.object({
	manualInput: zString(),
})

export const SignalConfigFormSchema = z.object({
	weight: z.number().optional(),
	manualInput: zString(z.string().optional()),
})

export const AssignedSignalsFormSchema = z.object({
	personas: z.record(
		z.string(),
		z.object({
			selected: z.enum(['on', 'off']),
			manualInput: z.string(),
		}),
	),
})

export const AssignedSignalsUpdateSchema = z.object({
	personas: z
		.object({
			id: z.number(),
			manualInput: z.string().nullable().optional(),
		})
		.array(),
})

export const SignalUsageExampleAPISchema = z.object({
	signal: z.number(),
	persona: z.number(),
	preview: z.string(),
})
