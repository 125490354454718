import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { PersonaDetailsCard } from '#src/components/shared/personas'
import { Button } from '#src/components/ui/button'
import useCompany from '#src/hooks/useCompany'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type HeadingSectionProps = {
	heading: string
	text?: string
}

const HeadingSection = ({ heading, text }: HeadingSectionProps) => (
	<div className="flex flex-col gap-1">
		<p className="text-body-sm font-semibold text-neutral-3-fg">{heading}</p>
		<p className="text-body-md text-neutral-1-fg">{text}</p>
	</div>
)

type PersonasDetailsHeaderProps = {
	persona: z.infer<typeof PersonaAPISchema>
}

export const PersonasDetailsHeader = ({ persona }: PersonasDetailsHeaderProps) => {
	const { verticalId } = useParsedRouteParams(['verticalId'])
	const { companyId } = useCompany()
	const { findEcosystemByVerticalId } = useEcosystem()

	const ecosystem = useMemo(
		() => findEcosystemByVerticalId(String(persona.vertical.id)),
		[findEcosystemByVerticalId, persona.vertical.id],
	)

	const headingSections = [
		{ heading: 'Ecosystem', text: ecosystem?.name },
		{ heading: 'Vertical', text: persona.vertical.name },
		{ heading: 'Job Experience', text: persona.jobExperience as string },
		{ heading: 'Job Seniority', text: persona.jobSeniority as string },
		{ heading: 'Job Titles', text: persona.jobTitles as string },
	]

	return (
		<>
			<section className="flex items-center justify-between p-10">
				<PersonaDetailsCard persona={persona} avatarSize="lg" />
				{ecosystem && (
					<Button type="button" variant="outline" size="md" asChild className="w-[120px]">
						<Link
							to={routes.enable.roleplay.new.session({ companyId })}
							state={{
								ecosystemId: ecosystem.id,
								verticalId: Number(verticalId),
								personaId: persona.id,
							}}
						>
							Roleplay
						</Link>
					</Button>
				)}
			</section>

			{ecosystem && (
				<section className="grid grid-cols-5 p-8">
					{headingSections.map(({ heading, text }, index) => (
						<HeadingSection key={index} heading={heading} text={text} />
					))}
				</section>
			)}
		</>
	)
}
