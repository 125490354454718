import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z, ZodObject } from 'zod'
import { CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import {
	criteriaListQuery,
	criteriaTemplatesQuery,
	externalCriteriaQuery,
} from '#src/routes/calibrate/verticals/criteria/queries'
import { validateRouteParams } from '#src/utils/misc'
import { CriteriaQuestionAPISchema } from '../schema'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
		ecosystemId: z.string(),
		type: CRITERION_TYPE_ENUM,
		schema: z.instanceof(ZodObject),
		defaultValues: z.record(z.union([z.string(), z.number(), z.boolean(), z.array(z.string())])),
	}),
	data: z.object({
		criteriaList: CriteriaQuestionAPISchema,
		externalCriteriaList: CriteriaQuestionAPISchema,
		criteriaTemplatesList: CriteriaQuestionAPISchema,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient, type: z.infer<typeof LoaderSchema>['values']['type']) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])

		const [criteriaList, externalCriteriaList, criteriaTemplatesList] = await Promise.all([
			queryClient
				.fetchQuery(criteriaListQuery(params.companyId))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
			queryClient
				.fetchQuery(externalCriteriaQuery(params.companyId))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
			queryClient
				.fetchQuery(criteriaTemplatesQuery(params.companyId))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
		])

		return {
			values: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				type,
			},
			data: {
				criteriaList,
				externalCriteriaList,
				criteriaTemplatesList,
			},
		}
	}
