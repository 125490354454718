import { z } from 'zod'

export enum SettingsCopilotLanguage {
	English = 'English',
	Spanish = 'Spanish',
	French = 'French',
	German = 'German',
	Chinese = 'Chinese',
	Japanese = 'Japanese',
	Portuguese = 'Portuguese',
	Dutch = 'Dutch',
}

export enum SettingsCopilotHumorLevel {
	Funny = 'Funny',
	Neutral = 'Neutral',
	Serious = 'Serious',
}

export enum SettingsCopilotFormalityLevel {
	Casual = 'Casual',
	Neutral = 'Neutral',
	Formal = 'Formal',
}

export enum SettingsCopilotRespectBalance {
	Irreverent = 'Irreverent',
	Neutral = 'Neutral',
	Respectful = 'Respectful',
}

export enum SettingsCopilotEnergyLevel {
	Enthusiastic = 'Irreverent',
	Neutral = 'Neutral',
	MatterOfFact = 'Matter-of-fact',
}

export const SettingsCopilotAPISchema = z.object({
	language: z.nativeEnum(SettingsCopilotLanguage),
	browsingAgent: z.boolean(),
	humorLevel: z.nativeEnum(SettingsCopilotHumorLevel),
	formalityLevel: z.nativeEnum(SettingsCopilotFormalityLevel),
	respectBalance: z.nativeEnum(SettingsCopilotRespectBalance),
	energyLevel: z.nativeEnum(SettingsCopilotEnergyLevel),
	dos: z.string().optional(),
	donts: z.string().optional(),
})

export const SettingsCopilotFormSchema = SettingsCopilotAPISchema

export const SettingsCopilotFormParserSchema = SettingsCopilotFormSchema.merge(
	z.object({
		browsingAgent: z.enum(['true', 'false']).transform(val => val === 'true'),
	}),
)
