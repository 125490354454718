import { type ReactNode } from 'react'
import { cn } from '#src/utils/misc'

export const WeightValue = ({ value, suffix }: { value: string | undefined; suffix?: ReactNode }) => {
	return (
		<div
			className={cn(
				'inline-flex h-5 items-center justify-center gap-0.5 rounded-xl px-2 text-label-sm transition-colors',
				suffix ? 'w-max min-w-8 pr-1.5' : 'w-8',
				value === '1' ? 'bg-green-10 text-green-40' : '',
				value === '2' ? 'bg-green-20 text-green-70' : '',
				value === '3' ? 'bg-green-30 text-green-70' : '',
				value === '4' ? 'bg-green-70 text-green-10' : '',
				value === '5' ? 'bg-green-100 text-green-10' : '',
			)}
		>
			{value}
			{suffix}
		</div>
	)
}
