import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Link, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { SettingsCopilotFormSchema } from '#src/api/icp/company/settings/copilot/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import CopilotSettingsFields from '#src/components/shared/copilot/CopilotSettingsFields'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Sidebar, SidebarHeader } from '#src/components/ui/sidebar'
import useCompany from '#src/hooks/useCompany'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from './loader'

const FORM_ID = 'copilot-conversation-settings-form'

export const View = () => {
	const {
		handle: { conversationId },
		data,
	} = useLoaderData() as LoaderRes

	const { company, companyId } = useCompany()

	const isPending = useIsPending({
		formAction: routes.enable.copilot.settings({
			companyId,
			conversationId,
		}),
		formMethod: 'PATCH',
	})

	const form = useForm<z.infer<typeof SettingsCopilotFormSchema>>({
		resolver: zodResolver(SettingsCopilotFormSchema),
		defaultValues: data.general,
		mode: 'onSubmit',
	})

	return (
		<div className="w-[28rem]">
			<Sidebar
				header={
					<SidebarHeader>
						<h1 className="flex flex-1 items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
							Chat settings
						</h1>
						<Button size="sm" variant="ghost" type="submit" form={FORM_ID} name="intent" value="reset">
							Reset to default
						</Button>
						<Link
							className="inline-flex"
							to={routes.enable.copilot.index({
								companyId: company.id,
								conversationId,
							})}
						>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<FormWrapper
						formId={FORM_ID}
						formProps={form}
						className="flex w-full max-w-lg flex-col gap-6"
						method="PATCH"
						action={routes.enable.copilot.settings({
							companyId,
							conversationId,
						})}
					>
						<CopilotSettingsFields />
					</FormWrapper>
				}
				footerProps={{
					isSubmitting: isPending,
					submitButton: {
						formId: FORM_ID,
					},
					cancelButton: {
						to: routes.enable.copilot.index({
							companyId: company.id,
							conversationId,
						}),
					},
				}}
			/>
		</div>
	)
}

export default View
