import { Form, useLoaderData } from 'react-router-dom'
import { FormFieldTypeChip } from '#src/components/shared'
import { FormSections } from '#src/routes/calibrate/verticals/criteria/views/components'
import { LabeledValue } from '#src/routes/lead-research-agent/views/components'
import { routes } from '#src/utils/routes'
import { type CriteriaSaveLoaderType } from '../routes/save'

export const View = () => {
	const {
		data: { criterion },
		values: { companyId, ecosystemId, type },
	} = useLoaderData() as CriteriaSaveLoaderType

	if (!criterion) return null

	return (
		<Form
			id="assignCriterionForm"
			method="PATCH"
			action={routes.calibrate.criteria.assign({
				companyId,
				ecosystemId,
				criterionType: type,
				criterionId: String(criterion.id),
			})}
		>
			<FormSections
				sections={[
					{
						heading: 'Details',
						content: (
							<>
								<LabeledValue label="Name">{criterion.name}</LabeledValue>
								<LabeledValue label="Source">{criterion.source}</LabeledValue>
								{criterion.instructions && <LabeledValue label="Instructions">{criterion.instructions}</LabeledValue>}
							</>
						),
					},
					{
						heading: 'Answers',
						content: (
							<>
								<LabeledValue label="Response type">
									<FormFieldTypeChip type={criterion.type} size="sm" />
								</LabeledValue>
								{!!criterion.options?.length && (
									<LabeledValue label="Possible answers">
										{criterion.options.map(item => (
											<ul key={item} className="ml-4 list-decimal">
												<li>{item}</li>
											</ul>
										))}
									</LabeledValue>
								)}
								{criterion.logic && <LabeledValue label="Logic">{criterion.logic}</LabeledValue>}
								{criterion.unit && <LabeledValue label="Logic">{criterion.unit}</LabeledValue>}
							</>
						),
					},
				]}
			/>
		</Form>
	)
}
