import { Icon } from './ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'

export default function AiGenerated() {
	return (
		<TooltipProvider>
			<Tooltip delayDuration={0}>
				<TooltipTrigger className="flex items-center justify-center" asChild>
					<span>
						<Icon name="magic-wand" size="sm" />
					</span>
				</TooltipTrigger>
				<TooltipContent side="top" align="center">
					AI generated
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
