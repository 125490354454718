import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type VoicesSchema } from '#src/routes/enable/roleplay/scenario/schema'

type UseVoiceLoaderType = z.infer<typeof VoicesSchema>

export const useVoice = () => {
	const { voices } = useLoaderData() as { voices: UseVoiceLoaderType }

	const voiceOptions = useMemo(() => {
		return voices.map(voice => ({
			value: voice.voiceId,
			label: voice.name,
		}))
	}, [voices])

	return { voiceOptions }
}
