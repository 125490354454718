import { getFormProps, getInputProps, getSelectProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useState } from 'react'
import {
	type ActionFunctionArgs,
	useFetcher,
	useLoaderData,
	useNavigate,
	type LoaderFunctionArgs,
	redirect,
} from 'react-router-dom'
import { ErrorList, Field, Select } from '#src/components/forms'
import { PersonaAvatar } from '#src/components/persona'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { createChat } from './mutations'
import { enableKeys } from './queries'
import { NewConversationFormSchema } from './schema'

export type NewChatLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const { readOnlySession } = await getUser()

	if (readOnlySession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return {
		conversationId: params.conversationId ?? null,
		ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
	}
}

export type NewChatActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: NewConversationFormSchema,
	})

	if (submission.status !== 'success') {
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Failed to submit form. Make sure all fields are filled out correctly.'],
			}),
		}
	}

	try {
		const response = await createChat(company.id, {
			personaId: submission.value.personaId,
			linkedinUrl: submission.value.linkedinUrl,
		})

		await client.invalidateQueries({
			queryKey: enableKeys.all,
		})

		return redirect(
			routes.enable.copilot.index({
				companyId,
				conversationId: response.conversationId,
			}),
		)
	} catch (error) {
		if (error instanceof Response) {
			if (error.status === 422) {
				return {
					ok: false,
					result: submission.reply({
						fieldErrors: {
							linkedinUrl: ['Unable to verify Linkedin profile'],
						},
					}),
				}
			}
		}
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Ooops! Something went wrong. Please try again later.'],
			}),
		}
	}
}

export default function NewChat() {
	const [open, setOpen] = useState(true)
	const navigate = useNavigate()
	const { ecosystems, conversationId } = useLoaderData() as NewChatLoaderResponse
	const { companyId } = useCompany()

	const fetcher = useFetcher<NewChatActionResponse>()

	const [form, fields] = useForm({
		id: 'new-chat-form',
		constraint: getZodConstraint(NewConversationFormSchema),
		defaultValue: {
			ecosystemId: null,
			verticalId: null,
			personaId: null,
			linkedinUrl: null,
		},
		lastResult: !(fetcher.data instanceof Response) ? fetcher.data?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: NewConversationFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	const ecosystemsOptions = ecosystems.map(({ id, name, verticals }) => ({
		value: id.toString(),
		label: `${name} (${verticals.length} verticals)`,
	}))
	const verticalsOptions =
		ecosystems
			.find(({ id }) => String(id) === fields.ecosystemId.value)
			?.verticals.map(({ id, name, personas }) => ({
				value: id.toString(),
				label: `${name} (${personas.length} personas)`,
			})) ?? []
	const personasOptions =
		ecosystems
			.find(({ id }) => String(id) === fields.ecosystemId.value)
			?.verticals.find(({ id }) => String(id) === fields.verticalId.value)
			?.personas.map(({ id, expertise, type, jobTitles }) => ({
				value: id.toString(),
				label: (
					<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
						<PersonaAvatar type={type} size="sm" className="row-span-2" />
						<h3 className="text-label-sm text-neutral-3-fg">{expertise}</h3>
						<p className="w-full truncate text-label-sm text-neutral-2-fg">{jobTitles}</p>
					</div>
				),
			})) ?? []

	return (
		<DialogRoot
			open={open}
			onOpenChange={isOpen => {
				if (!isOpen && fetcher.state === 'idle') {
					setOpen(false)
					navigate(-1)
				}
			}}
		>
			<DialogContent
				dialogHeading="Create Chat"
				className="w-full max-w-lg"
				closeClassName={fetcher.state !== 'idle' ? 'opacity-50 cursor-default' : ''}
			>
				<fetcher.Form
					{...getFormProps(form)}
					action={routes.enable.copilot.new({
						companyId,
						conversationId,
					})}
					method="POST"
					className="flex flex-col gap-4"
				>
					<Select
						labelProps={{
							children: 'Select Ecosystem',
						}}
						inputProps={{
							...getSelectProps(fields.ecosystemId),
							defaultValue: fields.ecosystemId.initialValue,
							value: fields.ecosystemId.value,
							placeholder: '-',
						}}
						options={ecosystemsOptions}
						errors={fields.ecosystemId.errors}
					/>
					<Select
						labelProps={{
							children: `Select Vertical`,
						}}
						inputProps={{
							...getSelectProps(fields.verticalId),
							defaultValue: fields.verticalId.initialValue,
							value: fields.verticalId.value,
							placeholder: '-',
							disabled: !verticalsOptions.length,
						}}
						options={verticalsOptions}
						errors={fields.verticalId.errors}
					/>
					<Select
						labelProps={{
							children: 'Assign Persona',
						}}
						inputProps={{
							contentProps: {
								position: 'popper',
								className: 'overflow-y-auto max-h-[20rem]',
							},
							className: 'h-12',
							...getSelectProps(fields.personaId),
							defaultValue: fields.personaId.initialValue,
							value: fields.personaId.value,
							placeholder: '-',
							disabled: !fields.ecosystemId.value || !fields.verticalId.value,
						}}
						options={personasOptions}
						errors={fields.personaId.errors}
					/>

					<Field
						labelProps={{ children: 'LinkedIn URL' }}
						inputProps={{
							...getInputProps(fields.linkedinUrl, { type: 'text' }),
							placeholder: 'Enter LinkedIn URL',
						}}
						errors={fields.linkedinUrl.errors}
					/>

					<ErrorList errors={form.errors} id={form.errorId} />

					<div className="flex justify-end">
						<StatusButton
							type="submit"
							status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
							disabled={fetcher.state !== 'idle'}
						>
							Submit
						</StatusButton>
					</div>
				</fetcher.Form>
			</DialogContent>
		</DialogRoot>
	)
}
