import { useQuery } from '@tanstack/react-query'
import { createSearchParams, Link, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Table } from '#src/components/ui/Table'
import useCompany from '#src/hooks/useCompany'
import { listQuery, statisticsQuery } from '#src/routes/lead-research-agent/queries'
import { canDownload, canStop } from '#src/routes/lead-research-agent/utils'
import { formatDateToReadableString } from '#src/utils/date'
import { formatNumberToPct } from '#src/utils/numbers'
import { routes } from '#src/utils/routes'
import { type LRAListLoaderRes } from '../routes/list'
import { JobDownloadModal, JobStopModal, ListStatisticsCard, StatusLabel } from './components'

export const View = () => {
	const {
		values: { sort, page },
		data,
	} = useLoaderData() as LRAListLoaderRes
	const { company, companyId } = useCompany()

	const { data: statistics } = useQuery({
		...statisticsQuery(company.id),
		initialData: data.statistics,
		refetchInterval: 10000,
	})

	const { data: jobs } = useQuery({
		...listQuery(company.id, page, sort),
		initialData: data.jobs,
		...(statistics.inProgressCount + statistics.inQueueCount > 0 && {
			refetchInterval: 10000,
		}),
	})

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	if (!jobs.meta.total) {
		return (
			<PageLayout title="Lead Research Agent Jobs">
				<EmptyStateCard
					icon="distribution-ledger"
					title="No Lead Research Agent jobs found"
					description="You don’t have any Lead Research Agent jobs yet."
					actions={[
						{
							title: 'Create job',
							to: {
								pathname: routes.leadResearchAgent.create({ companyId }),
								search: searchParams.toString(),
							},
						},
					]}
				/>
			</PageLayout>
		)
	}

	return (
		<PageLayout
			title="Lead Research Agent Jobs"
			actions={[
				{
					icon: 'add',
					title: 'Create job',
					to: {
						pathname: routes.leadResearchAgent.create({ companyId }),
						search: searchParams.toString(),
					},
					variant: 'default',
				},
			]}
		>
			<div className="grid w-full max-w-screen-2xl grid-cols-3 grid-rows-1 gap-6">
				<ListStatisticsCard name="In queue" count={statistics.inQueueCount} icon="circle-dash" />
				<ListStatisticsCard name="In progress" count={statistics.inProgressCount} icon="incomplete" />
				<ListStatisticsCard
					name="Completed"
					count={statistics.completedCount}
					icon="checkmark-filled"
					iconVariant="success"
				/>
			</div>
			<div className="flex w-full flex-col gap-6">
				<Table
					currentPage={jobs.meta.current_page}
					totalPages={jobs.meta.last_page}
					sort={sort}
					onPageChange={page =>
						navigate({
							pathname: routes.leadResearchAgent.index({
								companyId,
							}),
							search: createSearchParams({
								sort,
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onSortChange={data =>
						navigate({
							pathname: routes.leadResearchAgent.index({ companyId }),
							search: createSearchParams({
								sort: data,
							}).toString(),
						})
					}
					onItemClick={item =>
						navigate(
							routes.leadResearchAgent.view({
								companyId,
								jobId: item.id.toString(),
							}),
						)
					}
					columns={[
						{
							name: 'title',
							heading: 'Job name',
							render: item => (
								<Link
									to={routes.leadResearchAgent.view({
										companyId,
										jobId: item.id.toString(),
									})}
								>
									{item.title}
								</Link>
							),
						},
						{
							name: 'createdAt',
							heading: 'Date created',
							sortable: true,
							render: item => formatDateToReadableString(item.createdAt),
						},
						{
							name: 'icp',
							heading: 'ICP Ratio',
							render: item => `${formatNumberToPct(item.statistic.icpRatio)}%`,
						},
						{
							name: 'ibp',
							heading: 'IBP Ratio',
							render: item => `${formatNumberToPct(item.statistic.ibpRatio)}%`,
						},
						{
							name: 'status',
							heading: 'Status',
							render: item => <StatusLabel status={item.status} />,
						},
						{
							name: 'totalRows',
							heading: 'Items uploaded',
							render: item => <p className="text-right">{item.accountsContactsImported}</p>,
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div className="inline-flex flex-row" onClick={e => e.stopPropagation()}>
									{canStop(item.status) && (
										<JobStopModal jobId={item.id}>
											<Button variant="ghost">
												<Icon name="stop-filled-alt" size="font" className="text-status-danger-fg" />
											</Button>
										</JobStopModal>
									)}
									{canDownload(item.status) && (
										<JobDownloadModal jobId={item.id}>
											<Button variant="ghost">
												<Icon name="download" size="font" />
											</Button>
										</JobDownloadModal>
									)}
								</div>
							),
						},
					]}
					data={jobs.data}
				/>
			</div>
		</PageLayout>
	)
}
