import { useNavigate } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'
import { cn } from '#src/utils/misc'

type Step = {
	label: string
	value: string
	route?: string
	isFinished?: boolean
}

type WizardHeaderProps = {
	steps: Step[]
	title: string
	activeStep?: string
	exitTooltipText?: string
	onTabClick?: (value: string) => void
	onExitClick?: () => void
	exitRoute?: string
	disabled?: boolean
}

export const WizardHeader = ({
	steps,
	title,
	activeStep,
	onTabClick,
	onExitClick,
	exitRoute,
	exitTooltipText = 'Exit wizard',
	disabled,
}: WizardHeaderProps) => {
	const navigate = useNavigate()

	const handleTabClick = (step: Step) => {
		if (disabled) return

		if (step.route) {
			navigate(step.route)
		}

		if (onTabClick) onTabClick(step.value)
	}

	const handleExitRoute = () => {
		if (onExitClick) {
			onExitClick()
		} else if (exitRoute) {
			navigate(exitRoute)
		} else {
			navigate(-1)
		}
	}

	return (
		<section className="bg-white">
			<div className="m-auto flex max-w-screen-xl items-center justify-between px-20 py-4">
				<div className="flex">
					<h1 className="me-10 text-title-md font-semibold text-neutral-1-fg">{title}</h1>
					<div className="me-6 ml-6 flex items-center space-x-6 text-body-sm font-semibold">
						{steps.map((step, i) => (
							<div
								key={step.value}
								className={cn(
									'flex cursor-pointer items-center space-x-2',
									activeStep === step.value ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
									activeStep !== step.value && !step.isFinished && 'text-neutral-1-fg-disabled',
								)}
								onClick={() => handleTabClick(step)}
							>
								{!step.isFinished ? (
									<span
										className={cn(
											'flex h-7 w-7 items-center justify-center rounded-full border',
											activeStep === step.value
												? 'border-brand-1-bd text-brand-1-fg'
												: 'border-neutral-3-bd text-brand-1-fg',
											activeStep !== step.value && !step.isFinished && 'text-brand-1-fg-disabled',
										)}
									>
										{i + 1}
									</span>
								) : (
									<span className={cn('flex h-7 w-7 items-center justify-center rounded-full bg-status-success-bg')}>
										<Icon name="check" className="h-5 w-5 text-white" />
									</span>
								)}
								<span>{step.label}</span>
							</div>
						))}
					</div>
				</div>
				<TooltipProvider>
					<Tooltip delayDuration={100}>
						<TooltipTrigger asChild>
							<Button onClick={handleExitRoute} size="icon" variant="ghost" className="rounded-full">
								<Icon name="cross-1-filled" />
							</Button>
						</TooltipTrigger>
						<TooltipContent side="bottom" align="center">
							{exitTooltipText}
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
		</section>
	)
}
