import { type LoaderFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { signalUsageExampleMutation } from '../mutations'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['signalId', 'personaId'])
	try {
		return await signalUsageExampleMutation(company.id, params.personaId, params.signalId)
	} catch (e) {
		const res = (e as Response).clone()
		const body = (await res.json()) as { success: boolean; message?: string }
		showToast({
			message: body?.message ?? res?.statusText,
			type: 'error',
		})
		return { ok: false }
	}
}
