import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	await adminCompanyMutations.delete(params.companyId)
	throw redirect(routes.admin.index)
}
