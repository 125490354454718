import { client as queryClient } from '#src/main'

export const autofocusKeys = {
	field: ['autofocus', 'field'] as const,
	vertical: ['autofocus', 'vertical'] as const,
}

export const autofocusFieldQuery = () => ({
	queryKey: autofocusKeys.field,
	queryFn: (): string | undefined => {
		return queryClient.getQueryData(autofocusKeys.field)
	},
	initialData: () => {
		return ''
	},
})

export const autofocusFieldMutation = (field: string) => {
	return queryClient.setQueryData(autofocusKeys.field, field)
}

export const autofocusVerticalQuery = () => ({
	queryKey: autofocusKeys.vertical,
	queryFn: (): string | undefined => {
		return queryClient.getQueryData(autofocusKeys.vertical)
	},
	initialData: () => {
		return ''
	},
})

export const autofocusVerticalMutation = (vertical: string) => {
	return queryClient.setQueryData(autofocusKeys.vertical, vertical)
}
