import { queryOptions } from '@tanstack/react-query'
import { ScenarioTemplatesSchema } from '#src/routes/enable/roleplay/schema'
import { SessionDataSchema } from '#src/routes/enable/roleplay/session/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { AssignedUsersAPISchema, ScenarioAPISchema, ScenariosAPIResSchema, VoicesSchema } from './schema'

export const roleplayScenarioKeys = {
	all: ['enable', 'roleplay', 'scenario'] as const,
	list: (companyId: string, page?: number) => [...roleplayScenarioKeys.all, 'list', companyId, page ?? 1] as const,
	detail: (companyId: string, scenarioId: string) =>
		[...roleplayScenarioKeys.all, 'detail', companyId, scenarioId] as const,
	templates: (companyId: string) => [...roleplayScenarioKeys.all, 'templates', companyId] as const,
	voices: (companyId: string) => [...roleplayScenarioKeys.all, 'voices', companyId] as const,
	users: (companyId: string) => [...roleplayScenarioKeys.all, 'users', companyId] as const,
	assignedUsers: (companyId: string, scenarioId: string) =>
		[...roleplayScenarioKeys.all, 'assignedUsers', companyId, scenarioId] as const,
	managerAccessSessionDetails: (companyId: string, scenarioId: string, conversationId: string) =>
		[...roleplayScenarioKeys.all, 'assignedUsers', companyId, scenarioId, conversationId] as const,
}

export const scenarioTemplatesQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.templates(companyId),
		queryFn: async () =>
			await safeFetch(ScenarioTemplatesSchema, api.roleplay.scenario.templates(companyId), {
				method: 'GET',
			}),
	})

export const voicesQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.voices(companyId),
		queryFn: async () =>
			await safeFetch(VoicesSchema, api.roleplay.scenario.voices(companyId), {
				method: 'GET',
			}),
	})

export const roleplayScenariosQuery = (companyId: string, page?: number) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.list(companyId, page),
		queryFn: async () =>
			await safeFetchMeta(ScenariosAPIResSchema, api.roleplay.scenario.index(companyId, page), {
				method: 'GET',
			}),
	})

export const roleplayScenarioQuery = (companyId: string, scenarioId: string) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.detail(companyId, scenarioId),
		queryFn: async () =>
			await safeFetch(ScenarioAPISchema, api.roleplay.scenario.detail(companyId, scenarioId), {
				method: 'GET',
			}),
	})

export const assignedUsersQuery = (companyId: string, scenarioId: string) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.assignedUsers(companyId, scenarioId),
		queryFn: async () =>
			await safeFetch(AssignedUsersAPISchema, api.roleplay.scenario.assignedUsers(companyId, scenarioId), {
				method: 'GET',
			}),
	})

export const managerAccessSessionDetailsQuery = (companyId: string, scenarioId: string, conversationId: string) =>
	queryOptions({
		queryKey: roleplayScenarioKeys.managerAccessSessionDetails(companyId, scenarioId, conversationId),
		queryFn: async () =>
			await safeFetch(
				SessionDataSchema,
				api.roleplay.scenario.managerAccessSessionDetails(companyId, scenarioId, conversationId),
				{
					method: 'GET',
				},
			),
	})
