import { Link, useLoaderData } from 'react-router-dom'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import { PageLayout } from '#src/components/ui/PageLayout'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type OrganizeDashboardLoaderRes } from '#src/routes/organize/routes/dashboard'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { user } = useAuth()
	const {
		data: { lra },
	} = useLoaderData() as OrganizeDashboardLoaderRes
	const { companyId } = useCompany()

	return (
		<PageLayout title="Organize">
			<div className="grid grid-cols-3 grid-rows-1 gap-6">
				{(checkIsUserSession(user.roles) || checkIsAdminSession(user.roles)) && (
					<Link
						to={routes.leadResearchAgent.index({
							companyId,
						})}
						className="block"
					>
						<DashboardCard
							icon="chart-column"
							title={<div className="flex w-full items-center gap-1">Lead research agent jobs</div>}
							isHoverable
							className="min-h-[146px]"
						>
							<div className="flex gap-4">
								<DashboardCardCount count={lra.launched} countSuffix="Launched" />
								<DashboardCardCount count={lra.completed} countSuffix="Completed" />
							</div>
						</DashboardCard>
					</Link>
				)}
			</div>
		</PageLayout>
	)
}
