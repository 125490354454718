import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { valuePropositionMutations } from '#src/api/icp/company/value-proposition/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const action =
	() =>
	async ({ params }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'pointId'])

		try {
			await valuePropositionMutations.delete(params.companyId, Number(params.pointId))
		} catch (err) {
			captureException(err)
			console.error(err)
			showToast({
				message: 'Unexpected error while deleting value proposition point',
				type: 'error',
			})
		}

		return redirect(routes.calibrate.valueProposition.index({ companyId: params.companyId }))
	}
