import Markdown from '#src/components/markdown'
import { cn } from '#src/utils/misc'
import ContactAvatar, { type avatarVariantByPersonaType } from './avatar'
import { MessageError, MessageLoading, MessageResend } from './status'

type MessageAuthorType = {
	name: string | undefined
	avatarVariant: ReturnType<typeof avatarVariantByPersonaType>
	showAuthorName: boolean
}

export type MessageType = {
	id: string | number
	message: string
	author: MessageAuthorType
	status?: 'sending' | 'error' | 'generating' | undefined
	isReply: boolean
}

export default function Message({ message }: { message: MessageType }) {
	return (
		<div className={cn('flex flex-col gap-0.5', !message.isReply ? 'items-end' : 'items-start')}>
			<div className="flex max-w-[80%] gap-1">
				{message.isReply ? (
					<ContactAvatar
						className="hidden shrink-0 lg:inline-flex"
						variant={message.author.avatarVariant}
						size="sm"
						initial={message.author.name ?? ''}
					/>
				) : null}

				{!message.isReply && message.status === 'error' ? <MessageResend message={message.message} /> : null}

				<div
					className={cn(
						'flex flex-col gap-1 rounded px-3 py-2 text-body-lg',
						message.isReply ? 'bg-neutral-2-bg' : 'bg-brand-3-bg',
					)}
				>
					{message.author.showAuthorName && (
						<div
							className={cn(
								'text-body-sm font-semibold',
								message.isReply ? 'text-neutral-1-fg' : 'text-right text-white',
							)}
						>
							{message.author.name}
						</div>
					)}

					<Markdown className={cn(message.isReply ? 'text-neutral-1-fg' : 'text-white')}>{message.message}</Markdown>
				</div>
			</div>

			{message.status === 'generating' ? <MessageLoading message="Generating..." className="lg:pl-10" /> : null}

			{message.status === 'sending' ? <MessageLoading message="Sending..." /> : null}

			{message.isReply && message.status === 'error' ? (
				<MessageError className="lg:pl-10" message="Could not generate message" />
			) : null}

			{!message.isReply && message.status === 'error' ? <MessageError message="Message not sent" /> : null}
		</div>
	)
}
