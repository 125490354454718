import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { downloadJob } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'
import { LeadResearchAgentJobDownloadFormSchema } from '#src/routes/lead-research-agent/schema'
import { makeDownloadableFromRes } from '#src/utils/files'
import { validateRouteParams } from '#src/utils/misc'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['jobId'])

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: LeadResearchAgentJobDownloadFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Unsupported exporting type')
	}

	try {
		const res = await downloadJob(params.jobId, submission.value.type)
		await makeDownloadableFromRes(res)
		await client.invalidateQueries({
			queryKey: leadResearchAgentJobsKeys.all,
		})
		return {
			success: true,
		}
	} catch (e) {
		let message = 'Server failed to respond'
		if (e instanceof Response) {
			const res = e.clone()
			const body = (await res.json()) as { success: boolean; message?: string }
			message = body?.message ?? res?.statusText
		}
		showToast({
			message,
			type: 'error',
		})
		return null
	}
}
