import { type LoaderFunctionArgs } from 'react-router-dom'
import { getUserByToken } from '#src/routes/auth/queries'
import { checkSession, clearSession } from '#src/routes/auth/utils'
import { validateRouteParams } from '#src/utils/misc'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['token'])

	if (checkSession()) {
		clearSession()
	}

	const user = await getUserByToken(params.token)

	return {
		user,
	}
}
