import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { copyPersonas } from '#src/routes/calibrate/personas/mutations'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyPersonaFormSchema } from '#src/routes/calibrate/personas/schema'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { routes } from '#src/utils/routes'

export const copyPersonasAction =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: CopyPersonaFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		await copyPersonas(params.companyId, {
			personas: submission.value.personas,
			targetVerticals: submission.value.targetVerticals,
		})

		await queryClient.invalidateQueries({
			queryKey: verticalKeys.all,
		})

		await queryClient.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		await queryClient.invalidateQueries({
			queryKey: personaKeys.all,
		})

		showToast({
			message: 'Personas successfully copied',
			type: 'success',
		})

		return redirect(
			routes.calibrate.verticals.index({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}
