import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { cn } from '#src/utils/misc'

export const ProductPillarCard = ({
	preffixIcon,
	title,
	description,
	disabled = false,
	external = false,
	titleColorClass,
	bgColorClass,
	stats,
}: {
	preffixIcon: IconName
	title: string
	description: string
	disabled?: boolean
	external?: boolean
	titleColorClass: string
	bgColorClass: string
	stats?: { label: string; value: number }[] | null
}) => {
	return (
		<Surface
			className={cn(
				'flex h-full w-full flex-col gap-4 p-6',
				disabled
					? 'cursor-not-allowed'
					: 'cursor-pointer bg-neutral-1-bg transition-colors hover:bg-neutral-1-bg-hover',
			)}
		>
			<div
				className={cn(
					'grid grid-cols-[max-content,1fr] grid-rows-2 gap-2 text-title-md',
					disabled ? 'text-neutral-1-fg-disabled' : 'text-neutral-1-fg',
					titleColorClass,
				)}
			>
				<div className={cn('flex h-8 w-8 items-center justify-center rounded', bgColorClass)}>
					<Icon name={preffixIcon} size="font" className={cn('shrink-0')} />
				</div>
				{disabled ? (
					<p className="ml-auto flex flex-nowrap items-center gap-0.5 self-start whitespace-nowrap rounded-full bg-neutral-2-bg px-2 py-0.5 text-label-sm text-neutral-2-fg">
						Connect your CRM
					</p>
				) : external ? (
					<Icon name="launch" size="sm" className="self-center justify-self-end text-label-sm text-neutral-3-fg" />
				) : null}
				<div className="col-span-2 row-span-2">{title}</div>
			</div>
			<p className={cn('text-body-sm', disabled ? 'text-neutral-2-fg-disabled' : 'text-neutral-2-fg')}>{description}</p>
			<ul className="mt-auto">
				{stats &&
					stats.map((stat, i) => (
						<li
							key={i}
							className={cn(
								'flex w-full items-center justify-between border-b border-b-neutral-1-bd py-2 text-body-sm font-semibold last:border-none',
								disabled ? 'text-neutral-1-fg-disabled' : 'text-neutral-1-fg',
							)}
						>
							{stat.label}
							{disabled ? null : <div className={cn('font-normal', titleColorClass)}>{stat.value}</div>}
						</li>
					))}
			</ul>
		</Surface>
	)
}
