import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '#src/utils/misc'

// Define the base styles and variants for the divider
const dividerVariants = cva('border-0', {
	variants: {
		thickness: {
			thin: 'h-[1px]',
			thick: 'h-[2px]',
		},
		color: {
			neutral: 'bg-neutral-1-bd',
			primary: 'bg-blue-500',
			secondary: 'bg-gray-300',
		},
		marginY: {
			none: 'my-0',
			xs: 'my-2',
			sm: 'my-6',
			md: 'my-10',
			lg: 'my-14',
		},
	},
	defaultVariants: {
		thickness: 'thin',
		color: 'neutral',
		marginY: 'sm',
	},
})

type DividerProps = VariantProps<typeof dividerVariants> & {
	asChild?: boolean
	className?: string
}

export const Divider = ({
	className,
	thickness = 'thin',
	color = 'neutral',
	marginY = 'sm',
	asChild = false,
	...props
}: DividerProps) => {
	const Comp = asChild ? Slot : 'hr'
	return <Comp className={cn(dividerVariants({ thickness, color, marginY }), className)} {...props} />
}
