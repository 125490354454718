import { PersonaAIGeneratingItem } from './'

type MappingBodyProps = {
	items: {
		id: string
		heading: string
		value: string | null | undefined
		isFieldGenerating?: boolean
	}[]
	isGenerating?: boolean
	hasCrashed: boolean
}

export const PersonaAIGeneratingBody = ({ items, isGenerating = false, hasCrashed }: MappingBodyProps) => (
	<div className="space-y-6">
		{items.map(({ id, heading, value, isFieldGenerating }) => (
			<PersonaAIGeneratingItem
				key={id}
				id={id}
				heading={heading}
				text={value}
				isGenerating={isFieldGenerating ?? isGenerating}
				hasCrashed={hasCrashed}
			/>
		))}
	</div>
)
