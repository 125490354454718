import { z } from 'zod'
import { EcosystemAPIPersonaSchema } from '#src/routes/calibrate/ecosystem/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'

export const PersonasSchema = EcosystemAPIPersonaSchema.extend({
	verticalName: z.string(),
	ecosystemName: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
})

export const PersonasAPIResSchema = PaginatedAPIResSchema(PersonasSchema)

export type PersonasQueryPayload = {
	page?: number
	ecosystem_id?: string
	vertical_id?: string
	status?: string
	priority?: string
	type?: string
	expertise?: string
}

export type PersonasExpertiseQueryPayload = {
	ecosystem_id?: string
	vertical_id?: string
}

export const PersonasExpertiseAPIResSchema = z.array(z.string())
