import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { StatsAPISchema } from '#src/api/icp/company/stats/schemas'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { validateRouteParams } from '#src/utils/misc'

export const LoaderSchema = z.object({
	values: z.object({}),
	data: z.object({
		ecosystems: EcosystemListAPISchema,
		statistics: StatsAPISchema,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	() =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId'])
		const [statistics, ecosystems] = await Promise.all([
			client.fetchQuery(statsQueries.dashboard(params.companyId)),
			client.fetchQuery(ecosystemsQuery(params.companyId)),
		])

		return {
			data: {
				ecosystems,
				statistics,
			},
		}
	}
