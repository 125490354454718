import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { copyValidatePersonas } from '#src/routes/calibrate/personas/mutations'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyValidatePersonaFormSchema } from '#src/routes/calibrate/personas/schema'
import { routes } from '#src/utils/routes'

export const copyValidatePersonasAction =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: CopyValidatePersonaFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const personasIdsArray = JSON.parse(submission.value.personas) as string[]

		const res = await copyValidatePersonas(params.companyId, {
			personas: personasIdsArray,
		})

		queryClient.setQueryData(personaKeys.copyValidate(params.companyId), {
			data: res,
			personaIdsArray: personasIdsArray,
		})

		return redirect(
			routes.calibrate.verticals.copyPersonas({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}
