export const makeDownloadableFromRes = async (res: Response, name?: string) => {
	const blob = await res.blob()
	const file = window.URL.createObjectURL(blob)

	if (name) {
		const a = document.createElement('a')
		a.href = file
		a.download = name
		document.body.appendChild(a)
		a.click()
		a.remove()
	} else {
		window.location.assign(file)
	}
}
