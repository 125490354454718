import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { updateCompany } from '#src/routes/company/mutations'
import { companyKeys } from '#src/routes/company/queries'
import { EditCompanyFormSchema } from '#src/routes/company/schema'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	const formData = await request.formData()

	const submission = parseWithZod(formData, { schema: EditCompanyFormSchema })

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		const { id, ...companyForm } = submission.value

		if (company.id !== id) {
			throw Error('Company id doesnt match')
		}

		const req = await updateCompany(id, companyForm)

		if (!req) {
			throw Error('Could not update company')
		}

		await client.refetchQueries({
			queryKey: companyKeys.detail(req.id),
		})

		void client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})
		void client.invalidateQueries({
			queryKey: verticalKeys.all,
		})
		void client.invalidateQueries({
			queryKey: personaKeys.all,
		})
		void client.invalidateQueries({
			queryKey: personaKeys.all,
		})
		return {
			ok: true,
		}
	} catch (error) {
		let errorMessage = 'Something went wrong with updating company details. Try again later.'

		if (error instanceof Response) {
			const errorBody = (await error.json()) as { message?: string }

			if (errorBody?.message) {
				errorMessage = errorBody.message
			}
		}

		return {
			ok: false,
			result: submission.reply({
				formErrors: [errorMessage],
			}),
		}
	}
}
