import { Link, useLoaderData } from 'react-router-dom'
import ContactAvatar from '#src/components/chat/avatar'
import { Button } from '#src/components/ui/button'
import { Divider } from '#src/components/ui/Divider'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import useCompany from '#src/hooks/useCompany'
import { type JoinRoleplayChatLoaderType } from '#src/routes/enable/roleplay/session/loaders/joinRoleplayChatLoader'
import { formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const JoinRoleplayChat = () => {
	const { conversationId, conversationData } = useLoaderData() as JoinRoleplayChatLoaderType
	const { companyId } = useCompany()
	const { conversation } = conversationData

	return (
		<Surface className="mx-auto my-auto flex max-h-[600px] min-h-[600px] w-1/2 max-w-[550px] flex-col justify-between bg-white p-10">
			<section className="mb-9 flex flex-col items-center justify-center gap-3.5 text-brand-1-fg">
				<Icon name="voice-activate" size="lg" />
				<h1 className="text-title-lg">{conversation.title}</h1>
			</section>
			<section>
				<div className="flex items-center gap-6">
					<div className="relative">
						<ContactAvatar
							variant="gray"
							size="xl"
							initial={formatAvatarAbbr(conversation.name ?? conversation.user)}
						/>
					</div>
					<div>
						<h3 className="mb-2.5 text-title-md text-neutral-2-fg">{conversation.name ?? conversation.user}</h3>
						{conversation.scenario?.personaMapping ? (
							<div className="flex flex-col gap-1.5 text-body-sm">
								<p className="text-gray-500 flex">
									<span className="font-semibold">Ecosystem:</span>
									<span className="ml-1 text-neutral-2-fg">{conversation.scenario?.personaMapping?.ecosystem}</span>
								</p>
								<p className="text-gray-500 flex">
									<span className="font-semibold">Vertical:</span>
									<span className="ml-1 text-neutral-2-fg">{conversation.scenario?.personaMapping?.vertical}</span>
								</p>
							</div>
						) : (
							<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
						)}
					</div>
				</div>
				<Divider />
			</section>
			<section className="mb-6 flex flex-col gap-2">
				<p className="text-body-sm font-semibold text-neutral-3-fg">Agenda</p>
				<p className="text-body-md">{conversation.scenarioTitle}</p>
			</section>
			<section className="flex flex-col gap-2">
				<p className="text-body-sm font-semibold text-neutral-3-fg">Instructions</p>
				<p className="text-body-md">{conversation.scenario?.instructions}</p>
			</section>
			<section className="mt-8 flex flex-col items-center gap-4">
				<Button asChild size="md">
					<Link
						to={routes.enable.roleplay.session.index({
							companyId,
							conversationId,
						})}
					>
						Join Session
					</Link>
				</Button>
				<Button asChild variant="ghost" size="md">
					<Link
						to={routes.enable.roleplay.index({
							companyId,
						})}
					>
						Back to Home
					</Link>
				</Button>
			</section>
		</Surface>
	)
}
