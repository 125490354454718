import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type PlaysItemAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/plays`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	create: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	item: (companyId: z.infer<typeof CompanySchema>['id'], playId: z.infer<typeof PlaysItemAPISchema>['id']) =>
		`${base(companyId)}/${playId}`,
	update: (companyId: z.infer<typeof CompanySchema>['id'], playId: z.infer<typeof PlaysItemAPISchema>['id']) =>
		`${base(companyId)}/${playId}`,
	delete: (companyId: z.infer<typeof CompanySchema>['id'], playId: z.infer<typeof PlaysItemAPISchema>['id']) =>
		`${base(companyId)}/${playId}`,
}

export { endpoints as playsEndpoints }
