import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { type APIFieldType } from '#src/schemas/global'
import { getAvailableFieldsChips, getAvailableLogicOptions, getAvailableUnits } from '#src/utils/fieldOptions'
import { type CriterionQuestionAPISchema } from '../../schema'
import { type FormData } from '../save'

type Option = string | string[] | undefined

type AnswerFieldsProps = {
	fieldType: APIFieldType
	fieldsDisabled: boolean
	criterion?: z.infer<typeof CriterionQuestionAPISchema>
}

export const getOptionsForArrayField = (
	criterion?: z.infer<typeof CriterionQuestionAPISchema>,
	type?: z.infer<typeof CriterionQuestionAPISchema>['type'],
) => {
	const isRangeType = type === 'range'

	if (criterion?.type === type && criterion?.options?.length) {
		return criterion.options.map(item => (isRangeType ? item.split(',').map(val => val.trim()) : item))
	}

	return isRangeType ? [[]] : [undefined]
}

export const FormAnswersSection = ({ fieldType, fieldsDisabled, criterion }: AnswerFieldsProps) => {
	const { watch, unregister, control } = useFormContext<FormData>()
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'options', // Field name for the array
	})

	const options = watch('options', []) || []
	const checkboxUnit = watch('checkboxUnit')
	const logic = watch('logic')

	useEffect(() => {
		if (!fieldType) return

		const fieldsToUnregister: Partial<Record<keyof FormData, boolean>> = {
			options: !['select', 'multiselect', 'range'].includes(fieldType) || fieldType === 'boolean',
			logic: fieldType !== 'multiselect',
			checkboxUnit: fieldType !== 'number' && fieldType !== 'range',
			unit: !checkboxUnit?.length,
			otherUnit: !checkboxUnit?.length,
		}

		Object.entries(fieldsToUnregister).forEach(([field, shouldUnregister]) => {
			if (shouldUnregister) {
				unregister(field as keyof FormData)
			}
		})
	}, [fieldType, checkboxUnit, logic, unregister])

	const isOptionUsed = (value: unknown): boolean => {
		if (!criterion?.usedOptions) return false

		if (Array.isArray(value)) {
			const valueString = value.join(',')
			return criterion.usedOptions.includes(valueString)
		}

		return criterion.usedOptions.includes(value as string)
	}

	const handleAddOption = () => {
		const newValue = fieldType === 'range' ? [undefined] : ''

		// @ts-expect-error - mismatch
		append(newValue)
	}

	const handleRemoveOption = (index: number) => {
		remove(index)
	}

	const renderOptionField = (fieldType: APIFieldType, option: Option, i: number) => {
		const isUsed = isOptionUsed(option)

		switch (fieldType) {
			case 'multiselect':
			case 'select':
				return (
					<FormField
						fieldType="text"
						name={`options.${i}`}
						wrapperClassName="flex-1"
						placeholder="Enter answer"
						disableField={fieldsDisabled && isUsed}
						disableLabel
					/>
				)
			case 'range':
				return (
					<FormField
						fieldType="range"
						name={`options.${i}`}
						wrapperClassName="flex-1"
						disableField={fieldsDisabled && isUsed}
						{...(fieldType === 'range' && {
							fromInputProps: {
								iconLeft: 'min',
								className: 'pl-10',
							},
							toInputProps: {
								iconLeft: 'max',
								className: 'pl-10',
							},
						})}
						disableLabel
					/>
				)
			default:
				return null
		}
	}

	return (
		<div className="flex flex-col gap-4">
			<FormField
				fieldType="select"
				name="type"
				label="Response type"
				options={getAvailableFieldsChips()}
				disableField={fieldsDisabled}
			/>
			{fieldType === 'multiselect' && (
				<FormField
					fieldType="radio"
					name="logic"
					options={getAvailableLogicOptions()}
					selectFirstByDefault
					spacing="sm"
					wrapperClassName="bg-neutral-2-bg p-3 rounded -mt-3"
					disableField={fieldsDisabled}
				/>
			)}
			<div className="flex flex-col gap-4">
				{(fieldType === 'number' || fieldType === 'range') && (
					<>
						<FormField
							fieldType="checkbox"
							name="checkboxUnit"
							disableLabel
							spacing="sm"
							options={[
								{
									value: 'true',
									label: 'Unit (Optional)',
									content: (
										<FormField
											fieldType="radio"
											name="unit"
											options={[
												...getAvailableUnits(),
												{
													value: 'other',
													label: 'Other',
													content: (
														<FormField
															fieldType="text"
															name="otherUnit"
															disableLabel
															disableField={fieldsDisabled}
															className="bg-white"
														/>
													),
												},
											]}
											selectFirstByDefault
											spacing="sm"
											disableField={fieldsDisabled}
											disableLabel
										/>
									),
								},
							]}
							wrapperClassName="bg-neutral-2-bg p-3 rounded -mt-3"
							disableField={fieldsDisabled}
						/>
					</>
				)}
			</div>
			{['multiselect', 'select', 'range'].includes(fieldType) && (
				<div className="flex flex-col gap-1">
					<span className="text-body-sm">Possible answers</span>
					{!fields.length && <div className="flex gap-1">{renderOptionField(fieldType, '', 0)}</div>}
					{fields.map((field, i) => (
						<div key={field.id} className="flex gap-1">
							{renderOptionField(fieldType, options[i], i)}
							{fields.length > 1 && !isOptionUsed(options[i]) && (
								<Button onClick={() => handleRemoveOption(i)} type="button" variant="secondary">
									<Icon name="trash" size="sm" color="red" />
								</Button>
							)}
						</div>
					))}
					<Button
						onClick={handleAddOption}
						variant="secondary"
						size="sm"
						className="mt-1 gap-0.5 self-start"
						type="button"
					>
						<Icon name="add" size="sm" /> Add
					</Button>
				</div>
			)}
		</div>
	)
}
