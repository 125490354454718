import { QueryClient } from '@tanstack/react-query'

export const createClientStore = () =>
	new QueryClient({
		defaultOptions: {
			queries: {
				// 3 mins~
				staleTime: 1000 * 60 * 3,
			},
		},
	})
