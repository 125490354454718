import { type z } from 'zod'
import { client } from '#src/main'
import { companyKeys } from '#src/routes/company/queries'
import { CompanySchema } from '#src/routes/company/schema'
import { fetch, safeFetch } from '#src/utils/safeFetch'
import { adminCompanyEndpoints } from './endpoints'
import { type AdminCompanyDashboardFormSchema, type AdminCompanyFormSchema } from './schemas'

const mutations = {
	create: async (data: z.infer<typeof AdminCompanyFormSchema>) => {
		const res = await safeFetch(CompanySchema, adminCompanyEndpoints.create(), {
			method: 'POST',
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: companyKeys.all,
		})

		return res
	},
	delete: async (companyId: z.infer<typeof CompanySchema>['id']) => {
		await fetch(adminCompanyEndpoints.delete(companyId), { method: 'DELETE' })
		client.removeQueries({
			queryKey: companyKeys.detail(companyId),
		})
		await client.invalidateQueries({
			queryKey: companyKeys.list(),
		})
	},
	updateDashboard: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof AdminCompanyDashboardFormSchema>,
	) => {
		await fetch(adminCompanyEndpoints.updateDashboard(companyId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.refetchQueries({
			queryKey: companyKeys.detail(companyId),
		})
	},
}

export { mutations as adminCompanyMutations }
