import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/settings/copilot`

const endpoints = {
	get: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	update: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
}

export { endpoints as settingsCopilotEndpoints }
