import { useFetcher, useLoaderData } from 'react-router-dom'
import { CardSwitch } from '#src/components/ui/CardSwitch'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type ActionRes as SettingsCopilotCommandEditActionRes } from '../../actions/command-edit'
import { type LoaderRes as SettingsCopilotLoader } from '../../loaders/index'

const CommandsForm = () => {
	const { data } = useLoaderData() as SettingsCopilotLoader
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<SettingsCopilotCommandEditActionRes>()
	return (
		<div className="flex w-full max-w-lg flex-col gap-6">
			<p className="text-title-md">Default chat commands</p>
			<div className="flex flex-col gap-4">
				{data.commands.map(command => (
					<CardSwitch
						key={`command-${command.id}`}
						heading={command.title}
						checked={command.enabled}
						onCheckedChange={checked =>
							actionFetcher.submit(
								{
									enabled: checked,
								},
								{
									method: 'PATCH',
									action: routes.settings.copilot.defaultCommands.edit({
										companyId,
										commandId: String(command.id),
									}),
								},
							)
						}
						disabled={actionFetcher.state !== 'idle'}
					/>
				))}
			</div>
		</div>
	)
}

export default CommandsForm
