import { type ReactNode } from 'react'
import { Link, type To } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

type Props = {
	className?: ClassName
	icon?: IconName
	title: ReactNode
	children?: ReactNode
	description?: string
	actions?: ({
		title: string
		to?: To
		onClick?: () => void
		noIcon?: boolean
	} & ({ to: To } | { onClick: () => void }))[]
}

export const EmptyStateCard = (props: Props) => {
	const { className, icon, title, description, actions, children } = props

	return (
		<Surface
			className={cn(
				'flex min-h-[360px] w-full flex-col items-center justify-center gap-6 border-none bg-neutral-2-bg',
				className,
			)}
		>
			<div className="flex flex-col items-center justify-center gap-4">
				{!!icon && <Icon name={icon} className="h-16 w-16 text-brand-1-fg" />}
				<div className="flex flex-col items-center justify-center">
					{title && <h1 className="text-center text-title-sm text-neutral-1-fg">{title}</h1>}
					{!!description && <p className="text-center text-body-md text-neutral-2-fg">{description}</p>}
				</div>
			</div>
			{children}
			{!!actions?.length && (
				<div className="flex items-center gap-2">
					{actions.map(action => (
						<Button
							key={`action-${action.title}`}
							asChild={!!action.to}
							className="flex flex-nowrap items-center gap-2"
							onClick={() => action.onClick?.()}
						>
							{action.to ? (
								<Link to={action.to}>
									{!action.noIcon && <Icon name="add" />} {action.title}
								</Link>
							) : (
								<>
									{!action.noIcon && <Icon name="add" />} {action.title}
								</>
							)}
						</Button>
					))}
				</div>
			)}
		</Surface>
	)
}
