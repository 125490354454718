import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { assignUserToScenario } from '#src/routes/enable/roleplay/scenario/mutations'
import { roleplayScenarioKeys } from '#src/routes/enable/roleplay/scenario/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const inviteParticipantsAction = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])
	const formData = await request.formData()
	const selectedMembers = formData.getAll('members')

	const assignRequests = selectedMembers.map(async item => {
		return await assignUserToScenario(company.id, params.scenarioId, item as string)
	})

	await client.invalidateQueries({
		queryKey: roleplayScenarioKeys.detail(company.id, params.scenarioId),
	})
	await client.invalidateQueries({
		queryKey: roleplayScenarioKeys.assignedUsers(company.id, params.scenarioId),
	})

	try {
		await Promise.all(assignRequests)
		showToast({
			message: 'Invitations were sent',
			type: 'success',
		})
	} catch (error) {
		captureException(error)
	}

	return redirect(
		routes.enable.roleplay.scenario.index({
			companyId,
			scenarioId: params.scenarioId,
		}),
	)
}
