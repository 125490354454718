import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/stats`

const endpoints = {
	dashboard: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/dashboard`,
	enable: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/enable`,
	valueProposition: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/value-proposition`,
}

export { endpoints as statsEndpoints }
