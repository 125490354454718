import Millis, { AgentState } from '@millisai/web-sdk'
import * as Sentry from '@sentry/react'
import { captureException } from '@sentry/react'
import { useEffect, useRef, useState } from 'react'

const CUSTOM_LLM_WEBSCOCKET = import.meta.env.VITE_MILLIS_AI_CUSTOM_LLM_WEBSOCKET // prettier-ignore

const client = Millis.createClient({
	publicKey: 'PaFhkIECE_TCDOpN39ISjjxC6G4Dpl2eDbem2hVVf9Q',
	endPoint: 'https://api-eu-west.millis.ai/millis',
	// endPoint: 'https://api-west.millis.ai/millis',
})

export enum CallState {
	IDLE,
	CONNECTING,
	READY,
}

export default function useVoiceChat(metadata: Record<string, unknown>, voiceId?: string) {
	if (!CUSTOM_LLM_WEBSCOCKET) {
		throw new Error('Must have websocket endpoint')
	}

	const [callState, setCallState] = useState(CallState.IDLE)
	const [agentState, setAgentState] = useState(AgentState.IDLE)
	const [transcript, setTranscript] = useState('')
	const [error, setError] = useState('')
	const latency = useRef<number>(0)
	const [analyzer, setAnalyzer] = useState<AnalyserNode | null>(null)

	useEffect(() => {
		client.on('onopen', () => {
			setError('')
			// console.log('millis.open')
		})

		client.on('onready', () => {
			setCallState(CallState.READY)
			// console.log('millis.ready')
		})

		client.on('onaudio', () => {
			// console.log('millis.audio')
		})

		client.on('onresponsetext', text => {
			setTranscript(text)
			// console.log('millis.response_text')
		})

		client.on('onclose', event => {
			if (event?.reason) {
				captureException(new Error('MillisAI SDK connection closed'), scope => {
					scope.setContext('Message', {
						messages: event.reason ? JSON.stringify(event.reason) : 'No reason message',
					})

					return scope
				})
			}
			// console.log('MillisSDK', 'event:onclose', event?.reason)
			setCallState(CallState.IDLE)
			setTranscript('')
			setError(event?.reason ? 'Voice chat connection closed.' : '')
			// console.log('millis.close')
		})

		client.on('onerror', error => {
			captureException(new Error('MillisAI SDK connection error'), scope => {
				scope.setContext('Message', {
					messages: error ? JSON.stringify(error) : 'No error message',
				})

				return scope
			})
			// console.error('An error occurred:', error)
			setCallState(CallState.IDLE)
			setTranscript('')
			setError('Voice chat is not available at the moment. Please try again later.')
			// console.log('millis.error')
		})

		client.on('ontranscript', () => {
			// NOTE: do not show transcript text when user speaks
			setTranscript('')
			// console.log('millis.user_text')
		})

		/**
		 * AgentState.IDLE: doing nothing while listening
		 * AgentState.PREPARE_ANSWER : agent detects end of user sentence and starts asking for custom llm for response
		 * AgentState.ANSWER : client receives audio response and starts the playback
		 */
		client.on('onagentstate', state => {
			switch (state) {
				case AgentState.ANSWER:
					if (latency.current === 0) break

					Sentry.metrics.distribution('millisai_response_time', Date.now() - latency.current, {
						tags: { type: 'important' },
						unit: 'millisecond',
					})

					latency.current = 0

					break
				case AgentState.PREPARE_ANSWER:
					latency.current = Date.now()
					break
				default:
					break
			}
			setAgentState(state)
		})

		client.on('useraudioready', ({ analyser }) => {
			setAnalyzer(analyser)
		})

		return () => {
			void client.stop()
		}
	}, [])

	const toggleConversation = async () => {
		if (callState === CallState.READY) {
			await client.stop()
		} else if (callState === CallState.IDLE) {
			client
				.start(
					{
						prompt: '',
						voice: {
							provider: 'cartesia',

							// British Customer Support Lady
							voice_id: voiceId ?? 'a01c369f-6d2d-4185-bc20-b32c225eab70',
						},
						tools: [],
						custom_llm_websocket: CUSTOM_LLM_WEBSCOCKET,
						language: 'en-US',
						privacy_settings: {
							opt_out_data_collection: true,
						},
						// NOTE: TBD, but for now interruption should be enabled
						flow: {
							user_start_first: true,
							response_delay: 300,
							// interruption: {
							// 	allowed: false,
							// 	keep_interruption_message: false,
							// },
						},
						debug: true,
					},
					metadata,
				)
				.catch(console.error)
			setCallState(CallState.CONNECTING)
		}
	}

	return {
		toggleConversation,
		transcript,
		callState,
		agentState,
		error,
		analyzer,
	}
}
