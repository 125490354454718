import { z } from 'zod'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { MessageStatus, PersonalitySchema, StatusSchema } from '../schema'

export const ConversationStatusSchema = z.object({
	status: StatusSchema,
})

export const ConversationSchema = z.object({
	id: z.string(),
	name: z.string(),
	user: z.string(),
	createdAt: z.string(),
	canDelete: z.boolean().optional(),
	type: z.string(),
	status: StatusSchema,
	lastMessage: z.string().nullable(),
	evaluation: z
		.object({
			status: z.string(),
			value: z.string().nullable(),
		})
		.nullable(),
	personality: PersonalitySchema,
	title: z.string().optional().nullable(),
	scenarioTitle: z.string().optional().nullable(),
	scenario: z
		.object({
			id: z.string(),
			personaMapping: z
				.object({
					ecosystem: z.string(),
					vertical: z.string(),
					personaType: z.string(),
					expertise: z.string(),
				})
				.nullable(),
			title: z.string(),
			instructions: z.string(),
		})
		.nullable(),
})

export const SessionDataSchema = z.object({
	conversation: ConversationSchema,
	participant: z
		.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		})
		.nullable(),
	writable: z.boolean(),
})

export const SessionMessageSchema = z.object({
	id: z.number().or(z.string()),
	author: z.string().optional(),
	isAi: z.boolean(),
	createdAt: z.string().optional(),
	status: z.enum([MessageStatus.Sending, MessageStatus.Error, MessageStatus.Generating]).optional(),
	message: z.string(),
})

export const SessionMessagesSchema = SessionMessageSchema.array()

export const SessionSchema = z.object({
	conversation: ConversationSchema,
	participant: z
		.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		})
		.nullable(),
})

export const SessionsAPIResSchema = PaginatedAPIResSchema(SessionSchema)

export const CreateSessionAPIResSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})

export const CreateSessionFormSchema = z.object({
	title: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string().url().optional(),
	personality: PersonalitySchema,
	evaluation: z.string().optional().nullable(),
	scenario: z.string().optional().nullable(),
	scenarioTitle: z.string(),
	templateId: z.number(),
})

export type CreateSessionFormSchemaType = z.infer<typeof CreateSessionFormSchema>

export const getActiveNewSessionWizardSchema = (activeStep: string) => {
	switch (activeStep) {
		case 'session':
			return CreateSessionFormSchema.pick({
				title: true,
				scenario: true,
				scenarioTitle: true,
			})
		case 'persona-mapping':
			return CreateSessionFormSchema.pick({
				ecosystemId: true,
				verticalId: true,
				personaId: true,
				linkedinUrl: true,
			})
		case 'personality':
			return CreateSessionFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return CreateSessionFormSchema.pick({ evaluation: true })
		default:
			return CreateSessionFormSchema
	}
}

export const SessionFormPayloadSchema = CreateSessionFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
}).merge(z.object({ scenario: z.string().optional().nullable() }))
