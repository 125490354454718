import { type RouteObject } from 'react-router-dom'
import { action as inviteAction } from '#src/routes/settings/team/actions/invite'
import { loader as teamLoader } from '#src/routes/settings/team/loaders'
import { View as TeamView } from '#src/routes/settings/team/views'

export default {
	path: 'team',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Team members',
				icon: 'user-multiple',
			},
		],
	},
	children: [
		{
			index: true,
			element: <TeamView />,
			loader: teamLoader,
		},
		{
			path: 'invite',
			action: inviteAction,
		},
	],
} satisfies RouteObject
