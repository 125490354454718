import { clsx } from 'clsx'
import { type ReactNode } from 'react'
import { Icon } from '#src/components/ui/icon'
import Paginator from '#src/components/ui/Paginator'
import { cn } from '#src/utils/misc'
import { getObjectValueByKey } from '#src/utils/objects'

type Props<T> = {
	data: T[]
	sort?: string[]
	columns: {
		hidden?: boolean
		name: string
		heading: string
		sortable?: boolean
		render: (data: T) => ReactNode
	}[]
	currentPage: number
	totalPages: number
	onItemClick?: (data: T) => void
	onPageChange: (data: number) => void
	onSortChange?: (data: string[]) => void
	uniqueId?: string
}

enum SORT {
	Asc = 'asc',
	Desc = 'desc',
}

export const Table = <T extends Record<string, unknown>>(props: Props<T>) => {
	const {
		columns,
		data,
		currentPage,
		totalPages,
		sort,
		onPageChange,
		onItemClick,
		onSortChange,
		uniqueId = 'id',
	} = props

	const clickable = typeof onItemClick !== 'undefined'

	const handleSortChange = (value: string) => {
		if (!sort) {
			return
		}
		const existing = sort.find(item => item === value || item === `-${value}`)
		const index = existing ? sort.indexOf(existing) : undefined
		let res = [...sort]
		if (index !== undefined) res.splice(index, 1)
		res = [!existing || existing.startsWith('-') ? value : `-${value}`, ...res]
		onSortChange?.(res)
	}

	const getSortType = (columnName: string): SORT | undefined => {
		if (!sort) {
			return
		}
		const sortKey = sort.find(i => i === columnName || i === `-${columnName}`)
		return typeof sortKey === 'string' ? (sortKey === columnName ? SORT.Asc : SORT.Desc) : undefined
	}

	return (
		<>
			<div className="w-full overflow-auto">
				<table className="w-full">
					<thead className="text-left">
						<tr>
							{columns
								.filter(column => !column.hidden)
								.map(column => {
									const sortType = getSortType(column.name)

									return (
										<th key={`th-${column.name}`} className="px-3 py-3.5 text-label-md text-neutral-2-fg">
											<div className="inline-flex">
												{column.heading}
												{column.sortable && (
													<>
														{sortType ? (
															<div
																className="relative inline-flex h-4 w-4 cursor-pointer items-center justify-center"
																onClick={() => handleSortChange(column.name)}
															>
																<Icon
																	name="chevron-sort-asc"
																	size="sm"
																	className={cn(
																		'absolute left-0 top-0',
																		sortType === SORT.Asc ? 'opacity-100' : 'opacity-50',
																	)}
																/>
																<Icon
																	name="chevron-sort-desc"
																	size="sm"
																	className={cn(
																		'absolute left-0 top-0',
																		sortType === SORT.Desc ? 'opacity-100' : 'opacity-50',
																	)}
																	onClick={() => handleSortChange(column.name)}
																/>
															</div>
														) : (
															<Icon
																name="chevron-sort"
																size="sm"
																className="cursor-pointer"
																onClick={() => handleSortChange(column.name)}
															/>
														)}
													</>
												)}
											</div>
										</th>
									)
								})}
						</tr>
					</thead>

					<tbody className="text-left">
						{data.map(item => (
							<tr
								key={`tr-${getObjectValueByKey(item, uniqueId) as string}`}
								className={clsx(
									'border-none bg-transparent transition-colors hover:bg-neutral-1-bg-hover',
									clickable && 'cursor-pointer',
								)}
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									onItemClick?.(item)
								}}
							>
								{columns
									.filter(column => !column.hidden)
									.map(column => (
										<td
											key={`td-${getObjectValueByKey(item, uniqueId) as string}-${column.name}`}
											className="px-3 py-3.5"
										>
											<div
												className={clsx(
													'truncate text-body-md text-neutral-1-fg',
													column.name === 'actions' && 'text-right',
												)}
											>
												{column.render(item)}
											</div>
										</td>
									))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Paginator
				className="mb-0 mt-0"
				currentPage={currentPage}
				totalPages={totalPages}
				setCurrentPage={onPageChange}
				paginatorPosition="center"
			/>
		</>
	)
}
