import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { VerticalSavePayloadSchema } from '#src/routes/calibrate/verticals/schema'
import {
	generateSaveVerticalContext,
	transformVerticalSaveSubmissionToPayload,
} from '#src/routes/calibrate/verticals/utils'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'
import { createVertical, updateVertical } from './mutations'
import { verticalKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])
		const formData = await request.formData()
		const intent = formData.get('intent')
		const { schema } = await generateSaveVerticalContext(params.companyId, params.ecosystemId)
		const submission = parseFormData(formData, schema.transform(transformAndCleanToggleableFieldsSchema))
		const payload = transformVerticalSaveSubmissionToPayload(submission.value, params.ecosystemId)
		const parsed = VerticalSavePayloadSchema.parse(payload)

		if (intent === 'vertical') {
			if (!params.verticalId) {
				await createVertical(params.companyId, Number(params.ecosystemId), parsed)
			} else {
				await updateVertical(params.companyId, Number(params.verticalId), parsed)
			}

			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: ecosystemKeys.list(params.companyId),
				}),
				queryClient.invalidateQueries({
					queryKey: verticalKeys.all,
				}),
				queryClient.invalidateQueries({
					queryKey: criteriaKeys.all,
				}),
			])

			return redirect(
				routes.calibrate.verticals.index({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}
	}
