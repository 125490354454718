import { type ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import PageLoader from '#src/components/loader'
import Panel, { PanelProvider } from '#src/components/panel'
import useLayoutProps from '#src/hooks/layouts/useLayoutProps'
import Sidebar from '#src/layouts/Sidebar'
import { cn } from '#src/utils/misc'
import Header from './header/Header'

type LayoutProps = {
	enableHeader?: boolean
	fullWidth?: boolean
	sidePanelDefaultExpanded?: boolean
	disableSidebar?: boolean
	sidePanelHeader?: ReactNode
	sidePanelContent?: ReactNode
	noPadding?: boolean
}

const Layout = (props: LayoutProps) => {
	const layout = useLayoutProps(props)
	return (
		<div className="relative flex h-screen w-screen overflow-hidden bg-neutral-1-bg text-neutral-1-fg">
			<PanelProvider>
				<PageLoader />
				{!layout.disableSidebar && <Sidebar />}
				{layout.sidePanelContent && (
					<Panel header={layout.sidePanelHeader as ReactNode} defaultExpanded={layout.sidePanelDefaultExpanded}>
						{layout.sidePanelContent}
					</Panel>
				)}
				<div id="scrollable" className="relative flex w-full flex-col overflow-auto">
					{layout.enableHeader && (
						<Header panelToggle={!!layout.sidePanelContent} className="border-b border-neutral-1-bd" />
					)}

					<div
						className={cn(
							'm-auto flex w-full flex-1 flex-col',
							!layout.fullWidth && 'max-w-screen-2xl',
							!layout.noPadding && 'px-20 py-10',
						)}
					>
						<Outlet />
					</div>
				</div>
			</PanelProvider>
		</div>
	)
}

export default Layout
