import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'
import { type IconName } from '@/icon-name'

const textVariants = {
	default: 'An error occurred.',
	output: 'Failed to generate output.',
} as const

const errorVariants = cva('flex items-center', {
	variants: {
		iconSize: {
			sm: 'h-4 w-4',
			md: 'h-5 w-5',
			lg: 'h-6 w-6',
		},
		textSize: {
			sm: 'text-body-sm',
			md: 'text-body-md',
			lg: 'text-body-lg',
		},
	},
	defaultVariants: {
		iconSize: 'sm',
		textSize: 'sm',
	},
})

type InlineErrorProps = VariantProps<typeof errorVariants> & {
	message?: string
	iconName?: IconName
	asChild?: boolean
	className?: string
	size?: 'sm' | 'md' | 'lg'
	textVariant?: keyof typeof textVariants
}

export const InlineError = forwardRef<HTMLDivElement, InlineErrorProps>(
	(
		{ className, size = 'sm', textVariant = 'default', message = '', iconName = 'error', asChild = false, ...props },
		ref,
	) => {
		const Comp = asChild ? Slot : 'div'
		const errorText = message || textVariants[textVariant]

		return (
			<Comp className={cn('flex items-center gap-2 text-status-danger-fg', className)} ref={ref} {...props}>
				<Icon name={iconName} className={cn(errorVariants({ iconSize: size, textSize: null }))} />
				<span className={cn(errorVariants({ textSize: size, iconSize: null }))}>{errorText}</span>
			</Comp>
		)
	},
)

InlineError.displayName = 'InlineError'
