import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { type PlaysItemLoaderRes } from '#src/routes/enable/plays/routes/item'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const {
		data: { play },
	} = useRouteLoaderData('plays-item') as PlaysItemLoaderRes

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && navigate(routes.enable.plays.index({ companyId }))}
			action={routes.enable.plays.delete({
				companyId,
				playId: String(play.id),
			})}
			method="DELETE"
			actionTitle="Delete"
			title="Delete play?"
			description={
				<>
					Are you sure you want to remove the <b>{play.name}</b> Play?
				</>
			}
		/>
	)
}
