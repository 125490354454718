import { type PersonasExpertiseQueryPayload, type PersonasQueryPayload } from '#src/routes/enable/personas/schema'
import { api } from '#src/utils/endpoints'
import { buildQueryParams } from '#src/utils/misc'
import { safeFetch } from '#src/utils/safeFetch'
import { PersonaAPISchema } from './schema'

export const personaKeys = {
	all: ['persona'] as const,
	details: () => [...personaKeys.all, 'details'] as const,
	detail: (companyId: string, personaId: string) => [...personaKeys.details(), companyId, personaId] as const,
	copyValidate: (companyId: string) => [...personaKeys.details(), companyId] as const,
	list: (companyId: string, queryParams: PersonasQueryPayload) =>
		[...personaKeys.all, 'list', companyId, buildQueryParams(queryParams)] as const,
	expertiseList: (companyId: string, queryParams: PersonasExpertiseQueryPayload) =>
		[...personaKeys.all, 'expertiseList', companyId, buildQueryParams(queryParams)] as const,
}

export const personaQuery = (companyId: string, personaId: string) => ({
	queryKey: personaKeys.detail(companyId, personaId),
	queryFn: async () => {
		return await safeFetch(PersonaAPISchema, api.persona.details(companyId, personaId), {
			method: 'GET',
		})
	},
})
