import { type ReactNode } from 'react'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

type Props = {
	children: ReactNode
	signalId: string
}

export const UnmapAllPersonasModal = (props: Props) => {
	const { children, signalId } = props
	const { companyId } = useCompany()

	if (!signalId) {
		return children
	}

	return (
		<DeleteConfirm
			trigger={children}
			action={routes.prioritize.signal.assign({
				companyId,
				signalId,
			})}
			method="PATCH"
			intent="unmap-all"
			actionTitle="Unmap all"
			title="Unmap all personas"
			description="Are you sure you want to unmap all personas from this signal?"
		/>
	)
}
