import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { type PlaysItemLoaderRes } from '#src/routes/enable/plays/routes/item'
import { routes } from '#src/utils/routes'
import PlayFormFields from './components/PlayFormFields'

const FORM_ID = (id: number) => `edit-play-form-${id}`

export const View = () => {
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const {
		data: { play },
	} = useRouteLoaderData('plays-item') as PlaysItemLoaderRes

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: play.name,
			instructions: play.instructions,
			inCopilot: play.inCopilot,
			inCrm: play.inCrm,
			personaTypes: play.personaTypes,
			expertise: play.expertise,
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && navigate(routes.enable.plays.index({ companyId }))}
			dialogHeading="Edit play"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(play.id), label: 'Save' },
			]}
		>
			<FormWrapper formId={FORM_ID(play.id)} formProps={form} className="flex flex-col gap-6">
				<PlayFormFields />
			</FormWrapper>
		</Dialog>
	)
}
