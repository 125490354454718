import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { userEndpoints } from './endpoints'
import { userQueriesKeys } from './queries'
import { type InviteFormSchema } from './schemas'

const mutations = {
	invite: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof InviteFormSchema>) => {
		await fetch(userEndpoints.invite(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.list(companyId),
		})
	},
}

export { mutations as userMutations }
