import { z } from 'zod'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { PersonalitySchema, ScenarioTemplateSchema, StatusSchema } from '../schema'

export const VoiceSchema = z.object({
	voiceId: z.string(),
	name: z.string(),
})

export const VoicesSchema = VoiceSchema.array()

export const Participant = z.object({
	id: z.string(),
	status: StatusSchema,
	username: z.string(),
	createdAt: z.string(),
	completedAt: z.string().nullable(),
})

export type ParticipantType = z.infer<typeof Participant>

export const AssignedUserSchema = z.object({
	id: z.number(),
	username: z.string(),
	assigned: z.boolean(),
})

export const AssignedUsersAPISchema = AssignedUserSchema.array()

export const ScenarioAPISchema = z.object({
	id: z.string(),
	// NOTE: this is user's custom name for scenario
	name: z.string(),
	// NOTE: this is scenario name from dropdown list
	title: z.string(),
	// NOTE: this is scenario tuning
	scenario: z.string().optional().nullable(),
	personaId: z.number(),
	templateId: z.number().nullable(),
	personaMapping: z
		.object({
			ecosystem: z.string(),
			vertical: z.string(),
			personaType: z.string(),
			expertise: z.string(),
		})
		.nullable(),
	linkedinUrl: z.string().optional().nullable(),
	contact: z.string().optional().nullable(),
	personality: PersonalitySchema,
	evaluation: z.string().optional().nullable(),
	instructions: z.string(),
	sessions: Participant.array(),
	createdBy: z.string(),
	createdAt: z.string(),
})

export const ScenariosAPIResSchema = PaginatedAPIResSchema(ScenarioAPISchema)

export const ScenarioFormSchema = z.object({
	name: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string().url().optional(),
	contactId: z.string().optional(),
	personality: PersonalitySchema,
	template: ScenarioTemplateSchema,
	evaluation: z.string().optional(),
	instructions: z.string(),
	scenario: z.string().optional().nullable(),
})

export const EditScenarioFormSchema = ScenarioFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
	template: true,
}).merge(
	z.object({
		templateId: z.number(),
	}),
)

export const CreateScenarioFormSchema = ScenarioFormSchema.omit({
	template: true,
}).merge(
	z.object({
		templateId: z.number(),
		scenario: z.string().optional().nullable(),
		linkedinUrl: z.string().url().optional(),
		contactId: z.string().optional(),
	}),
)

export type CreateScenarioFormSchemaType = z.infer<typeof CreateScenarioFormSchema>

export const getActiveNewScenarioWizardSchema = (activeStep: string) => {
	switch (activeStep) {
		case 'scenario':
			return CreateScenarioFormSchema.pick({
				name: true,
				scenario: true,
				templateId: true,
			})
		case 'persona-mapping':
			return CreateScenarioFormSchema.pick({
				ecosystemId: true,
				verticalId: true,
				personaId: true,
				linkedinUrl: true,
			})
		case 'personality':
			return CreateScenarioFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return CreateScenarioFormSchema.pick({ evaluation: true })
		case 'instructions':
			return CreateScenarioFormSchema.pick({ instructions: true })
		default:
			return CreateScenarioFormSchema
	}
}

export const getActiveScenarioTabSchema = (activeTab: string) => {
	switch (activeTab) {
		case 'scenario':
			return EditScenarioFormSchema.pick({
				name: true,
				scenario: true,
				templateId: true,
			})
		case 'persona-mapping':
			return EditScenarioFormSchema.pick({ personaId: true, linkedinUrl: true })
		case 'personality':
			return EditScenarioFormSchema.pick({
				personality: true,
			})
		case 'evaluation':
			return EditScenarioFormSchema.pick({ evaluation: true })
		case 'instructions':
			return EditScenarioFormSchema.pick({ instructions: true })
		default:
			return EditScenarioFormSchema
	}
}

export const ScenarioFormPayloadSchema = ScenarioFormSchema.omit({
	ecosystemId: true,
	verticalId: true,
	template: true,
}).merge(
	z.object({
		scenario: z.string().optional().nullable(),
		templateId: z.number(),
	}),
)
