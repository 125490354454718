import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes.js'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { restartConversationChat } from './mutations'
import { enableKeys } from './queries'

export const action = async ({ params }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	const { adminSession } = await getUser()
	validateRouteParams(params, ['conversationId'])

	if (!adminSession)
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})

	const response = await restartConversationChat(company.id, params.conversationId)

	await Promise.all([
		void client.invalidateQueries({
			queryKey: enableKeys.chats(company.id),
		}),
		void client.invalidateQueries({
			queryKey: enableKeys.chat(company.id, params.conversationId),
		}),
		void client.invalidateQueries({
			queryKey: enableKeys.conversation(company.id, params.conversationId),
		}),
	])

	return redirect(
		routes.enable.copilot.index({
			companyId,
			conversationId: response.conversation.id,
		}),
	)
}
