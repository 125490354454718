import { Icon } from '#src/components/ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'

export function SignalStatus({ enabled }: { enabled: boolean }) {
	if (enabled)
		return (
			<div className="flex items-center text-label-md text-green-70">
				<Icon name="circle-fill" size="sm" className="flex flex-nowrap items-center text-green-70 transition-colors" />
				Available
			</div>
		)

	return (
		<div className="flex flex-nowrap items-center text-label-md text-neutral-3-fg">
			<Icon name="circle-fill" size="sm" className="flex flex-nowrap items-center text-orange-70 transition-colors" />
			Coming soon&nbsp;
			<TooltipProvider>
				<Tooltip delayDuration={0}>
					<TooltipTrigger>
						<Icon name="information" size="sm" className="flex flex-nowrap items-center transition-colors" />
					</TooltipTrigger>
					<TooltipContent side="bottom" align="center" className="max-w-60">
						Currently, these signals are still under development, but you can map personas so we can prioritize them as
						soon as they become available
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</div>
	)
}
