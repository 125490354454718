import { type z } from 'zod'
import { Auth0AuthAPISchema, type ResetPasswordAPISchema } from '#src/routes/auth/schema'
import { WEB_AUTH_CLIENTID, WEB_AUTH_DOMAIN, WEB_AUTH_REDIRECT_URI } from '#src/routes/auth/utils'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetchMeta } from '#src/utils/safeFetch'

export const resetPassword = async (data: z.infer<typeof ResetPasswordAPISchema>) => {
	return await fetch(api.user.password, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

export const refreshSessionReq = async (refreshToken: string) =>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	await safeFetchMeta(Auth0AuthAPISchema, `${WEB_AUTH_DOMAIN}/oauth/token`, {
		method: 'POST',
		body: JSON.stringify({
			grant_type: 'refresh_token',
			client_id: WEB_AUTH_CLIENTID,
			redirect_uri: WEB_AUTH_REDIRECT_URI,
			refresh_token: refreshToken,
		}),
	})

export const authorizeSessionReq = async (code: string) =>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	await safeFetchMeta(Auth0AuthAPISchema, `${WEB_AUTH_DOMAIN}/oauth/token`, {
		method: 'POST',
		body: JSON.stringify({
			grant_type: 'authorization_code',
			client_id: WEB_AUTH_CLIENTID,
			redirect_uri: WEB_AUTH_REDIRECT_URI,
			code,
		}),
	})
