import {
	// SettingsCopilotEnergyLevel,
	// SettingsCopilotFormalityLevel,
	// SettingsCopilotHumorLevel,
	SettingsCopilotLanguage,
	// SettingsCopilotRespectBalance,
} from '#src/api/icp/company/settings/copilot/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
// import { Icon } from '#src/components/ui/icon'

const CopilotSettingsFields = () => {
	return (
		<>
			<p className="text-title-md">General settings</p>
			<FormField
				fieldType="select"
				name="language"
				label="Language"
				options={Object.values(SettingsCopilotLanguage).map(val => ({
					value: val,
					label: val,
				}))}
			/>
			{/*<p className="text-title-md">Tone of voice</p>*/}
			{/*<FormField*/}
			{/*	fieldType="radio"*/}
			{/*	name="humorLevel"*/}
			{/*	label="Humor level"*/}
			{/*	options={Object.values(SettingsCopilotHumorLevel).map(val => ({*/}
			{/*		value: val,*/}
			{/*		label: val,*/}
			{/*	}))}*/}
			{/*/>*/}
			{/*<FormField*/}
			{/*	fieldType="radio"*/}
			{/*	name="formalityLevel"*/}
			{/*	label="Formality level"*/}
			{/*	options={Object.values(SettingsCopilotFormalityLevel).map(val => ({*/}
			{/*		value: val,*/}
			{/*		label: val,*/}
			{/*	}))}*/}
			{/*/>*/}
			{/*<FormField*/}
			{/*	fieldType="radio"*/}
			{/*	name="respectBalance"*/}
			{/*	label="Respect balance"*/}
			{/*	options={Object.values(SettingsCopilotRespectBalance).map(val => ({*/}
			{/*		value: val,*/}
			{/*		label: val,*/}
			{/*	}))}*/}
			{/*/>*/}
			{/*<FormField*/}
			{/*	fieldType="radio"*/}
			{/*	name="energyLevel"*/}
			{/*	label="Energy level"*/}
			{/*	options={Object.values(SettingsCopilotEnergyLevel).map(val => ({*/}
			{/*		value: val,*/}
			{/*		label: val,*/}
			{/*	}))}*/}
			{/*/>*/}
			{/*<p className="text-title-md">Instructions</p>*/}
			{/*<FormField*/}
			{/*	fieldType="textarea"*/}
			{/*	placeholder="Instructions"*/}
			{/*	name="dos"*/}
			{/*	label={*/}
			{/*		<div className="mb-2 flex flex-row items-center gap-2">*/}
			{/*			<div className="flex h-8 w-8 items-center justify-center rounded bg-green-10">*/}
			{/*				<Icon name="thumbs-up" className="h-4 w-4 text-green-80" />*/}
			{/*			</div>*/}
			{/*			<div>*/}
			{/*				<p className="text-title-sm text-green-80">Do’s</p>*/}
			{/*				<p className="text-body-sm text-neutral-2-fg">Formulations or sentences that are encouraged</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	}*/}
			{/*/>*/}
			{/*<FormField*/}
			{/*	fieldType="textarea"*/}
			{/*	placeholder="Instructions"*/}
			{/*	name="donts"*/}
			{/*	label={*/}
			{/*		<div className="mb-2 flex flex-row items-center gap-2">*/}
			{/*			<div className="flex h-8 w-8 items-center justify-center rounded bg-red-10">*/}
			{/*				<Icon name="thumbs-down" className="h-4 w-4 text-red-60" />*/}
			{/*			</div>*/}
			{/*			<div>*/}
			{/*				<p className="text-title-sm text-red-60">Dont’s</p>*/}
			{/*				<p className="text-body-sm text-neutral-2-fg">Formulations or sentences that are discouraged</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	}*/}
			{/*/>*/}
		</>
	)
}

export default CopilotSettingsFields
