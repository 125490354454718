import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { playsEndpoints } from './endpoints'
import { PlaysItemAPISchema, PlaysListItemAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'plays'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'list', companyId] as const,
	item: (companyId: z.infer<typeof CompanySchema>['id'], playId: z.infer<typeof PlaysItemAPISchema>['id']) =>
		[...keys.all, 'item', companyId, playId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.list(companyId),
	queryFn: async () =>
		await safeFetch(PlaysListItemAPISchema, playsEndpoints.list(companyId), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema>['id'],
	playId: z.infer<typeof PlaysItemAPISchema>['id'],
) => ({
	queryKey: keys.item(companyId, playId),
	queryFn: async () =>
		await safeFetch(PlaysItemAPISchema, playsEndpoints.item(companyId, playId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as playsQueries, keys as playsQueriesKeys }
