import { zodResolver } from '@hookform/resolvers/zod'
import { type QueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { type LoaderFunctionArgs, useLoaderData, Link } from 'react-router-dom'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { generateCriterionFieldsForVertical, generateSaveVerticalContext } from '#src/routes/calibrate/verticals/utils'
import { getAvailablePriorities, getAvailableStatuses } from '#src/utils/fieldOptions'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { verticalQuery } from './queries'

export type VerticalEditLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const url = new URL(request.url)
		const duplicate = url.searchParams.get('duplicate')
		const verticalId = params.verticalId || duplicate

		let vertical

		if (verticalId) {
			vertical = await queryClient.fetchQuery(verticalQuery(params.companyId, params.ecosystemId, verticalId))
		}

		if (duplicate && vertical) {
			vertical = duplicate ? { ...vertical, id: undefined, name: vertical.name + ' (Copy)' } : undefined
		}

		const { criteria, enrichmentCriteria, schema, defaultValues } = await generateSaveVerticalContext(
			params.companyId,
			params.ecosystemId,

			// @ts-expect-error: TODO: How duplicating (above) was done is nonsense,  fix in the future
			vertical,
		)

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			vertical,
			criteria,
			enrichmentCriteria,
			schema,
			defaultValues,
		}
	}

export default function VerticalEdit() {
	const { companyId, ecosystemId, vertical, criteria, enrichmentCriteria, schema, defaultValues } =
		useLoaderData() as VerticalEditLoaderResponse

	const action = vertical?.id
		? routes.calibrate.verticals.update({
				companyId: companyId,
				ecosystemId: ecosystemId,
				verticalId: vertical.id.toString(),
			})
		: routes.calibrate.verticals.save({
				companyId: companyId,
				ecosystemId: ecosystemId,
			})
	const method = vertical?.id ? 'PATCH' : 'POST'
	// NOTE: criteria management happens now without taking user out of main flow
	const formId = 'verticalSaveForm'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="sticky right-0 w-[26rem] shrink-0">
			<Sidebar
				header={
					<SidebarHeader
						heading={vertical?.id ? 'Edit Vertical' : 'Create Vertical'}
						closeTo={routes.calibrate.verticals.index({
							companyId: companyId,
							ecosystemId: ecosystemId,
						})}
					/>
				}
				main={
					<VerticalForm
						key={formId}
						formId={formId}
						action={action}
						method={method}
						criteria={criteria}
						enrichmentCriteria={enrichmentCriteria}
						schema={schema}
						defaultValues={defaultValues}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.verticals.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							type="submit"
							size="sm"
							name="intent"
							value="vertical"
							form={formId}
							disabled={isPending}
						>
							{vertical?.id ? 'Update' : 'Create'}
						</StatusButton>
					</SidebarFooter>
				}
				className="px-0"
			/>
		</div>
	)
}

function VerticalForm({
	formId,
	action,
	method,
	criteria,
	enrichmentCriteria,
	schema,
	defaultValues,
}: {
	formId: string
	action: string
	method: 'PATCH' | 'POST'
	criteria: VerticalEditLoaderResponse['criteria']
	enrichmentCriteria: VerticalEditLoaderResponse['enrichmentCriteria']
	schema: VerticalEditLoaderResponse['schema']
	defaultValues: VerticalEditLoaderResponse['defaultValues']
}) {
	const formProps = useForm({
		resolver: zodResolver(schema),
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})

	const { control } = formProps

	const { fields, append, remove } = useFieldArray({
		control,

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		name: 'otherNotes',
	})

	const renderCriteriaFields = useMemo(() => generateCriterionFieldsForVertical(criteria), [criteria])
	const renderEnrichmentCriteriaFields = useMemo(
		() => generateCriterionFieldsForVertical(enrichmentCriteria),
		[enrichmentCriteria],
	)

	return (
		<FormWrapper formProps={formProps} formId={formId} method={method} action={action} className="flex flex-col gap-6">
			<section className="flex flex-col gap-3.5 px-4">
				<FormField fieldType="text" name="name" label="Vertical Name" placeholder="Enter here" autoFocus />
				<FormField fieldType="select" name="status" options={getAvailableStatuses()} selectFirstByDefault />
				<FormField
					fieldType="select"
					name="priority"
					label="Priority"
					options={getAvailablePriorities()}
					selectFirstByDefault
				/>
			</section>
			<section>
				<h3 className="mb-2.5 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">Business Description</h3>
				<div className="flex flex-col gap-3.5 px-4">
					<FormField
						fieldType="textarea"
						name="bizDescriptionOverride"
						label="Manual Description (optional)"
						placeholder="Enter here"
						rows={4}
					/>
					<FormField
						fieldType="number"
						name="bizModelLen"
						label="Generated Description Length (words)"
						placeholder="Enter here"
						step={1}
					/>
				</div>
			</section>
			{!!criteria.length && (
				<section>
					<h3 className="mb-2.5 flex items-center gap-1 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">
						Qualification Criteria
						<span className="text-body-sm text-neutral-3-fg">{criteria.length}</span>
					</h3>
					<div className="flex flex-col gap-3.5 px-4">{renderCriteriaFields}</div>
				</section>
			)}
			{!!enrichmentCriteria.length && (
				<section>
					<h3 className="mb-2.5 flex items-center gap-1 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">
						Enrichment Criteria
						<span className="text-body-sm text-neutral-3-fg">{enrichmentCriteria.length}</span>
					</h3>
					<div className="flex flex-col gap-3.5 px-4">{renderEnrichmentCriteriaFields}</div>
				</section>
			)}
			<section>
				<h3 className="mb-2.5 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">Notes</h3>
				<div className="flex flex-col gap-3.5 px-4">
					<FormField fieldType="textarea" name="listBuildingNotes" rows={3} />
					{fields.map((note, index) => (
						<div key={note.id} className="mb-4 flex items-start gap-4">
							<FormField
								fieldType="textarea"
								name={`otherNotes.${index}`}
								label={`Note #${index + 1}`}
								placeholder="Enter note here"
								rows={3}
								wrapperClassName="flex-1"
								autoFocus
							/>
							<Button variant="secondary" type="button" onClick={() => remove(index)} className="mt-6 self-start">
								<Icon name="trash" size="sm" color="red" />
							</Button>
						</div>
					))}
					<Button type="button" onClick={() => append('')} variant="secondary" size="sm" className="self-start">
						<Icon name="add" size="sm" className="me-2" /> Add Note
					</Button>
				</div>
			</section>
		</FormWrapper>
	)
}
