import { queryOptions } from '@tanstack/react-query'
import { api } from '#src/utils/endpoints'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { ConversationStatusSchema, SessionDataSchema, SessionMessagesSchema, SessionsAPIResSchema } from './schema'

export const roleplaySessionKeys = {
	all: ['enable', 'roleplay', 'session'] as const,
	status: (companyId: string, contactId: string) =>
		[...roleplaySessionKeys.all, 'status', companyId, contactId] as const,
	conversation: (companyId: string, conversationId: string) =>
		[...roleplaySessionKeys.all, 'chat', companyId, conversationId] as const,
	list: (companyId: string, page?: number) => [...roleplaySessionKeys.all, 'list', companyId, page ?? 1] as const,
	detail: (companyId: string, conversationId: string) =>
		[...roleplaySessionKeys.all, 'detail', companyId, conversationId] as const,
}

export const conversationQuery = (companyId: string, conversationId: string) => ({
	queryKey: roleplaySessionKeys.conversation(companyId, conversationId),
	queryFn: async () =>
		await safeFetch(SessionDataSchema, api.roleplay.session.index(companyId, conversationId), {
			method: 'GET',
		}),
})

export const roleplaySessionsQuery = (companyId: string, page?: number) => ({
	queryKey: roleplaySessionKeys.list(companyId, page),
	queryFn: async () => {
		return await safeFetchMeta(SessionsAPIResSchema, api.roleplay.session.list(companyId, page), {
			method: 'GET',
		})
	},
})

export const roleplaySessionMessagesQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: roleplaySessionKeys.detail(companyId, conversationId),
		queryFn: async () => {
			return await safeFetch(SessionMessagesSchema, api.roleplay.session.messages(companyId, conversationId), {
				method: 'GET',
			})
		},
	})

export const statusQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: roleplaySessionKeys.status(companyId, conversationId),
		queryFn: async () =>
			await safeFetch(ConversationStatusSchema, api.roleplay.session.status(companyId, conversationId), {
				method: 'GET',
			}),
	})
