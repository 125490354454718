import { Slot } from '@radix-ui/react-slot'

import { forwardRef, type HTMLAttributes } from 'react'
import { cn } from '#src/utils/misc'

export interface SurfaceProps extends HTMLAttributes<HTMLDivElement> {
	as?: string
	asChild?: boolean
}

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
	({ className, asChild = false, as = 'div', ...props }, ref) => {
		const Comp = asChild ? Slot : as
		return (
			<Comp
				className={cn('rounded-lg border border-neutral-1-bd bg-neutral-1-bg p-10', className)}
				ref={ref}
				{...props}
			/>
		)
	},
)
Surface.displayName = 'Surface'
