import { type z } from 'zod'
import { PagePaginator } from '#src/components/ui/PagePaginator'
import { type PersonasAPIResSchema } from '#src/routes/enable/personas/schema'
import { PersonaCard } from './'

export type PersonasCardsProps = {
	personas: z.infer<typeof PersonasAPIResSchema>
	isLoading: boolean
	currentPage: number
	setCurrentPage: (currentPage: number) => void
}

export const PersonasCards = ({ personas, isLoading, currentPage, setCurrentPage }: PersonasCardsProps) => {
	return (
		<PagePaginator
			isLoading={isLoading}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={personas.meta.last_page}
			paginatorPosition="center"
		>
			<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4">
				{personas?.data.map(item => <PersonaCard persona={item} key={item.id} />)}
			</div>
		</PagePaginator>
	)
}
