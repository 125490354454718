type ListOfErrors = string[] | undefined

export const ErrorList = ({ id, errors }: { errors?: ListOfErrors; id?: string }) => {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null

	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-body-sm text-status-danger-fg">
					{e}
				</li>
			))}
		</ul>
	)
}

type FieldErrorProps = {
	errorMsg: string | string[]
	errorId?: string
}

export const FieldError = ({ errorMsg, errorId }: FieldErrorProps) => {
	const errorsArray = Array.isArray(errorMsg) ? errorMsg : [errorMsg]

	return (
		<div className="min-h-[32px] pb-3">
			<ErrorList id={errorId} errors={errorsArray} />
		</div>
	)
}
