import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { LoginFormSchema } from '#src/routes/auth/schema'
import { isAuth0Error, login } from '#src/routes/auth/utils'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	const submission = parseWithZod(formData, { schema: LoginFormSchema })

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	const { username, password } = submission.value

	try {
		await login(username, password)

		return { ok: true, result: submission.reply() }
	} catch (error) {
		if (isAuth0Error(error)) {
			return {
				ok: false,
				result: submission.reply({
					formErrors: [error.error_description ?? error.errorDescription ?? 'Authentication service error'],
				}),
			}
		}
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Something went wrong. Try again later.'],
			}),
		}
	}
}
