export const REGIONS = [
	{ value: 'united_states', label: 'United States' },
	{ value: 'european_union', label: 'European Union' },
	{ value: 'european_economic_area', label: 'European Economic Area' },
	{ value: 'france_and_associated', label: 'France and Associated Territories' },
	{ value: 'latin_america', label: 'Latin America' },
	{ value: 'middle_east', label: 'Middle East' },
	{ value: 'emea', label: 'EMEA' },
	{ value: 'apac', label: 'APAC' },
	{ value: 'apj', label: 'APJ' },
	{ value: 'anz', label: 'ANZ' },
	{ value: 'namer', label: 'NAMER' },
	{ value: 'dach', label: 'DACH' },
	{ value: 'baltics', label: 'Baltics' },
	{ value: 'benelux', label: 'Benelux' },
	{ value: 'nordics', label: 'Nordics' },
	{ value: 'central_africa', label: 'Central Africa' },
	{ value: 'eastern_africa', label: 'Eastern Africa' },
	{ value: 'east_asia', label: 'East Asia' },
	{ value: 'south_asia', label: 'South Asia' },
	{ value: 'southeast_asia', label: 'Southeast Asia' },
	{ value: 'oceania', label: 'Oceania' },
	{ value: 'southern_africa', label: 'Southern Africa' },
	{ value: 'sub_saharan_africa', label: 'Sub-Saharan Africa' },
	{ value: 'western_africa', label: 'Western Africa' },
	{ value: 'northern_africa', label: 'Northern Africa' },
]

export const COUNTRIES = [
	{ code: 'us', label: 'United States' },
	{ code: 'af', label: 'Afghanistan' },
	{ code: 'ax', label: 'Aland Islands' },
	{ code: 'al', label: 'Albania' },
	{ code: 'dz', label: 'Algeria' },
	{ code: 'as', label: 'American Samoa' },
	{ code: 'ad', label: 'Andorra' },
	{ code: 'ao', label: 'Angola' },
	{ code: 'ai', label: 'Anguilla' },
	{ code: 'aq', label: 'Antarctica' },
	{ code: 'ag', label: 'Antigua and Barbuda' },
	{ code: 'ar', label: 'Argentina' },
	{ code: 'am', label: 'Armenia' },
	{ code: 'aw', label: 'Aruba' },
	{ code: 'au', label: 'Australia' },
	{ code: 'at', label: 'Austria' },
	{ code: 'az', label: 'Azerbaijan' },
	{ code: 'bs', label: 'Bahamas' },
	{ code: 'bh', label: 'Bahrain' },
	{ code: 'bd', label: 'Bangladesh' },
	{ code: 'bb', label: 'Barbados' },
	{ code: 'by', label: 'Belarus' },
	{ code: 'be', label: 'Belgium' },
	{ code: 'bz', label: 'Belize' },
	{ code: 'bj', label: 'Benin' },
	{ code: 'bm', label: 'Bermuda' },
	{ code: 'bt', label: 'Bhutan' },
	{ code: 'bo', label: 'Bolivia' },
	{ code: 'ba', label: 'Bosnia and Herzegovina' },
	{ code: 'bw', label: 'Botswana' },
	{ code: 'br', label: 'Brazil' },
	{ code: 'io', label: 'British Indian Ocean Territory' },
	{ code: 'bn', label: 'Brunei Darussalam' },
	{ code: 'bg', label: 'Bulgaria' },
	{ code: 'bf', label: 'Burkina Faso' },
	{ code: 'bi', label: 'Burundi' },
	{ code: 'kh', label: 'Cambodia' },
	{ code: 'cm', label: 'Cameroon' },
	{ code: 'ca', label: 'Canada' },
	{ code: 'cv', label: 'Cape Verde' },
	{ code: 'cb', label: 'Caribbean Nations' },
	{ code: 'ky', label: 'Cayman Islands' },
	{ code: 'cf', label: 'Central African Republic' },
	{ code: 'td', label: 'Chad' },
	{ code: 'cl', label: 'Chile' },
	{ code: 'cn', label: 'China' },
	{ code: 'cx', label: 'Christmas Island' },
	{ code: 'cc', label: 'Cocos (Keeling) Islands' },
	{ code: 'co', label: 'Colombia' },
	{ code: 'km', label: 'Comoros' },
	{ code: 'cg', label: 'Congo' },
	{ code: 'ck', label: 'Cook Islands' },
	{ code: 'cr', label: 'Costa Rica' },
	{ code: 'ci', label: 'Cote D’Ivoire (Ivory Coast)' },
	{ code: 'hr', label: 'Croatia' },
	{ code: 'cu', label: 'Cuba' },
	{ code: 'cy', label: 'Cyprus' },
	{ code: 'cz', label: 'Czech Republic' },
	{ code: 'cd', label: 'Democratic Republic of the Congo' },
	{ code: 'dk', label: 'Denmark' },
	{ code: 'dj', label: 'Djibouti' },
	{ code: 'dm', label: 'Dominica' },
	{ code: 'do', label: 'Dominican Republic' },
	{ code: 'tp', label: 'East Timor' },
	{ code: 'ec', label: 'Ecuador' },
	{ code: 'eg', label: 'Egypt' },
	{ code: 'sv', label: 'El Salvador' },
	{ code: 'gq', label: 'Equatorial Guinea' },
	{ code: 'er', label: 'Eritrea' },
	{ code: 'ee', label: 'Estonia' },
	{ code: 'et', label: 'Ethiopia' },
	{ code: 'fk', label: 'Falkland Islands (Malvinas)' },
	{ code: 'fo', label: 'Faroe Islands' },
	{ code: 'fm', label: 'Federated States of Micronesia' },
	{ code: 'fj', label: 'Fiji' },
	{ code: 'fi', label: 'Finland' },
	{ code: 'fr', label: 'France' },
	{ code: 'gf', label: 'French Guiana' },
	{ code: 'pf', label: 'French Polynesia' },
	{ code: 'tf', label: 'French Southern Territories' },
	{ code: 'ga', label: 'Gabon' },
	{ code: 'gm', label: 'Gambia' },
	{ code: 'ge', label: 'Georgia' },
	{ code: 'de', label: 'Germany' },
	{ code: 'gh', label: 'Ghana' },
	{ code: 'gi', label: 'Gibraltar' },
	{ code: 'gr', label: 'Greece' },
	{ code: 'gl', label: 'Greenland' },
	{ code: 'gd', label: 'Grenada' },
	{ code: 'gp', label: 'Guadeloupe' },
	{ code: 'gu', label: 'Guam' },
	{ code: 'gt', label: 'Guatemala' },
	{ code: 'gg', label: 'Guernsey' },
	{ code: 'gn', label: 'Guinea' },
	{ code: 'gw', label: 'Guinea-Bissau' },
	{ code: 'gy', label: 'Guyana' },
	{ code: 'ht', label: 'Haiti' },
	{ code: 'hn', label: 'Honduras' },
	{ code: 'hk', label: 'Hong Kong' },
	{ code: 'hu', label: 'Hungary' },
	{ code: 'is', label: 'Iceland' },
	{ code: 'in', label: 'India' },
	{ code: 'id', label: 'Indonesia' },
	{ code: 'ir', label: 'Iran' },
	{ code: 'iq', label: 'Iraq' },
	{ code: 'ie', label: 'Ireland' },
	{ code: 'im', label: 'Isle of Man' },
	{ code: 'il', label: 'Israel' },
	{ code: 'it', label: 'Italy' },
	{ code: 'jm', label: 'Jamaica' },
	{ code: 'jp', label: 'Japan' },
	{ code: 'je', label: 'Jersey' },
	{ code: 'jo', label: 'Jordan' },
	{ code: 'kz', label: 'Kazakhstan' },
	{ code: 'ke', label: 'Kenya' },
	{ code: 'ki', label: 'Kiribati' },
	{ code: 'kr', label: 'Korea' },
	{ code: 'kp', label: 'Korea (North)' },
	{ code: 'kw', label: 'Kuwait' },
	{ code: 'kg', label: 'Kyrgyzstan' },
	{ code: 'la', label: 'Laos' },
	{ code: 'lv', label: 'Latvia' },
	{ code: 'lb', label: 'Lebanon' },
	{ code: 'ls', label: 'Lesotho' },
	{ code: 'lr', label: 'Liberia' },
	{ code: 'ly', label: 'Libya' },
	{ code: 'li', label: 'Liechtenstein' },
	{ code: 'lt', label: 'Lithuania' },
	{ code: 'lu', label: 'Luxembourg' },
	{ code: 'mo', label: 'Macao' },
	{ code: 'mk', label: 'Macedonia' },
	{ code: 'mg', label: 'Madagascar' },
	{ code: 'mw', label: 'Malawi' },
	{ code: 'my', label: 'Malaysia' },
	{ code: 'mv', label: 'Maldives' },
	{ code: 'ml', label: 'Mali' },
	{ code: 'mt', label: 'Malta' },
	{ code: 'mh', label: 'Marshall Islands' },
	{ code: 'mq', label: 'Martinique' },
	{ code: 'mr', label: 'Mauritania' },
	{ code: 'mu', label: 'Mauritius' },
	{ code: 'yt', label: 'Mayotte' },
	{ code: 'mx', label: 'Mexico' },
	{ code: 'md', label: 'Moldova' },
	{ code: 'mc', label: 'Monaco' },
	{ code: 'mn', label: 'Mongolia' },
	{ code: 'me', label: 'Montenegro' },
	{ code: 'ms', label: 'Montserrat' },
	{ code: 'ma', label: 'Morocco' },
	{ code: 'mz', label: 'Mozambique' },
	{ code: 'mm', label: 'Myanmar' },
	{ code: 'na', label: 'Namibia' },
	{ code: 'nr', label: 'Nauru' },
	{ code: 'np', label: 'Nepal' },
	{ code: 'nl', label: 'Netherlands' },
	{ code: 'an', label: 'Netherlands Antilles' },
	{ code: 'nc', label: 'New Caledonia' },
	{ code: 'nz', label: 'New Zealand' },
	{ code: 'ni', label: 'Nicaragua' },
	{ code: 'ne', label: 'Niger' },
	{ code: 'ng', label: 'Nigeria' },
	{ code: 'nu', label: 'Niue' },
	{ code: 'nf', label: 'Norfolk Island' },
	{ code: 'mp', label: 'Northern Mariana Islands' },
	{ code: 'no', label: 'Norway' },
	{ code: 'om', label: 'Oman' },
	{ code: 'pk', label: 'Pakistan' },
	{ code: 'pw', label: 'Palau' },
	{ code: 'ps', label: 'Palestinian Territory' },
	{ code: 'pa', label: 'Panama' },
	{ code: 'pg', label: 'Papua New Guinea' },
	{ code: 'py', label: 'Paraguay' },
	{ code: 'pe', label: 'Peru' },
	{ code: 'ph', label: 'Philippines' },
	{ code: 'pn', label: 'Pitcairn' },
	{ code: 'pl', label: 'Poland' },
	{ code: 'pt', label: 'Portugal' },
	{ code: 'pr', label: 'Puerto Rico' },
	{ code: 'qa', label: 'Qatar' },
	{ code: 're', label: 'Reunion' },
	{ code: 'ro', label: 'Romania' },
	{ code: 'ru', label: 'Russian Federation' },
	{ code: 'rw', label: 'Rwanda' },
	{ code: 'sh', label: 'Saint Helena' },
	{ code: 'kn', label: 'Saint Kitts and Nevis' },
	{ code: 'lc', label: 'Saint Lucia' },
	{ code: 'pm', label: 'Saint Pierre and Miquelon' },
	{ code: 'vc', label: 'Saint Vincent and the Grenadines' },
	{ code: 'ws', label: 'Samoa' },
	{ code: 'sm', label: 'San Marino' },
	{ code: 'st', label: 'Sao Tome and Principe' },
	{ code: 'sa', label: 'Saudi Arabia' },
	{ code: 'sn', label: 'Senegal' },
	{ code: 'rs', label: 'Serbia' },
	{ code: 'sc', label: 'Seychelles' },
	{ code: 'sl', label: 'Sierra Leone' },
	{ code: 'sg', label: 'Singapore' },
	{ code: 'sk', label: 'Slovak Republic' },
	{ code: 'si', label: 'Slovenia' },
	{ code: 'sb', label: 'Solomon Islands' },
	{ code: 'so', label: 'Somalia' },
	{ code: 'za', label: 'South Africa' },
	{ code: 'es', label: 'Spain' },
	{ code: 'lk', label: 'Sri Lanka' },
	{ code: 'sd', label: 'Sudan' },
	{ code: 'sr', label: 'Suriname' },
	{ code: 'sj', label: 'Svalbard and Jan Mayen' },
	{ code: 'sz', label: 'Swaziland' },
	{ code: 'se', label: 'Sweden' },
	{ code: 'ch', label: 'Switzerland' },
	{ code: 'sy', label: 'Syria' },
	{ code: 'tw', label: 'Taiwan' },
	{ code: 'tj', label: 'Tajikistan' },
	{ code: 'tz', label: 'Tanzania' },
	{ code: 'th', label: 'Thailand' },
	{ code: 'tl', label: 'Timor-Leste' },
	{ code: 'tg', label: 'Togo' },
	{ code: 'tk', label: 'Tokelau' },
	{ code: 'to', label: 'Tonga' },
	{ code: 'tt', label: 'Trinidad and Tobago' },
	{ code: 'tn', label: 'Tunisia' },
	{ code: 'tr', label: 'Turkey' },
	{ code: 'tm', label: 'Turkmenistan' },
	{ code: 'tc', label: 'Turks and Caicos Islands' },
	{ code: 'tv', label: 'Tuvalu' },
	{ code: 'ug', label: 'Uganda' },
	{ code: 'ua', label: 'Ukraine' },
	{ code: 'ae', label: 'United Arab Emirates' },
	{ code: 'uk', label: 'United Kingdom' },
	{ code: 'uy', label: 'Uruguay' },
	{ code: 'uz', label: 'Uzbekistan' },
	{ code: 'vu', label: 'Vanuatu' },
	{ code: 'va', label: 'Vatican City State (Holy See)' },
	{ code: 've', label: 'Venezuela' },
	{ code: 'vn', label: 'Vietnam' },
	{ code: 'vg', label: 'Virgin Islands (British)' },
	{ code: 'vi', label: 'Virgin Islands (U.S.)' },
	{ code: 'wf', label: 'Wallis and Futuna' },
	{ code: 'eh', label: 'Western Sahara' },
	{ code: 'ye', label: 'Yemen' },
	{ code: 'zm', label: 'Zambia' },
	{ code: 'zw', label: 'Zimbabwe' },
]
