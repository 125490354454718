import { useNavigation } from 'react-router-dom'
import { cn } from '#src/utils/misc'

export default function PageLoader({ status, className }: { status?: 'submitting'; className?: string }) {
	const { state } = useNavigation()

	return (
		<div
			className={cn(
				'fixed left-0 right-0 top-0 z-[9999] h-[3px] w-full bg-white transition-opacity',
				'bg-loading-gradient',
				"after:absolute after:left-0 after:right-0 after:block after:h-full after:animate-loading after:bg-loading-mask after:content-['']",
				state === 'loading' || status === 'submitting' ? 'opacity-100 after:opacity-100' : 'opacity-0 after:opacity-0',
				className,
			)}
		/>
	)
}
