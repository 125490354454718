import { type z } from 'zod'
import { LRA_JOB_STATUS } from '#src/routes/lead-research-agent/constants'
import { type LeadResearchAgentItemAPISchema } from '#src/routes/lead-research-agent/schema'

export const canStop = (status: z.infer<typeof LeadResearchAgentItemAPISchema>['status']) =>
	[LRA_JOB_STATUS.InProgress, LRA_JOB_STATUS.Launching].includes(status)

export const canDownload = (status: z.infer<typeof LeadResearchAgentItemAPISchema>['status']) =>
	[
		LRA_JOB_STATUS.Completed,
		LRA_JOB_STATUS.CompletedWithQuestions,
		LRA_JOB_STATUS.InProgress,
		LRA_JOB_STATUS.Cancelled,
		LRA_JOB_STATUS.Error,
	].includes(status)
