import { type z } from 'zod'

export const getValidSortKeys = (data: string[], schema: z.SomeZodObject): string[] => {
	const keys = Object.keys(schema.shape)
	const ascDescKeys: string[] = []
	keys.forEach(key => {
		ascDescKeys.push(key)
		ascDescKeys.push(`-${key}`)
	})

	return data.filter(item => ascDescKeys.includes(item))
}
