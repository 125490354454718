import { Link } from 'react-router-dom'
import { type z } from 'zod'
import { type PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import Markdown from '#src/components/markdown'
import { AccordionItem, AccordionTrigger } from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { LabeledValue } from '#src/routes/lead-research-agent/views/components'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	play: z.infer<typeof PlaysItemAPISchema>
}

const PlayAccordion = (props: Props) => {
	const { play } = props
	const { companyId } = useCompany()

	return (
		<AccordionItem
			value={`item-${play.id}`}
			className="p-6"
			trigger={
				<div className="flex flex-row items-center justify-between gap-6">
					<div className="flex-1">
						<AccordionTrigger index={play.id} dataLength={1} className="text-title-sm">
							{play.name}
						</AccordionTrigger>
					</div>
					<div className="flex flex-row gap-4">
						<div
							className={cn(
								'inline-flex items-center gap-1 text-body-md',
								play.inCrm ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
							)}
						>
							<Icon
								name={play.inCrm ? 'checkmark-filled' : 'checkmark-outline'}
								size="sm"
								className={cn(play.inCrm && 'text-green-70')}
							/>
							LRA
						</div>
						<div
							className={cn(
								'inline-flex items-center gap-1 text-body-md',
								play.inCopilot ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
							)}
						>
							<Icon
								name={play.inCopilot ? 'checkmark-filled' : 'checkmark-outline'}
								size="sm"
								className={cn(play.inCopilot && 'text-green-70')}
							/>
							Copilot
						</div>
					</div>
					<div className="flex flex-row gap-4">
						<Link
							to={routes.enable.plays.edit({ companyId, playId: String(play.id) })}
							className="inline-flex h-full items-center gap-1 whitespace-nowrap"
						>
							<Icon name="edit" size="sm" />
						</Link>
						<Link
							to={routes.enable.plays.delete({ companyId, playId: String(play.id) })}
							className="inline-flex h-full items-center gap-1 whitespace-nowrap"
						>
							<Icon name="trash" size="sm" className="text-status-danger-fg" />
						</Link>
					</div>
				</div>
			}
			contentProps={{
				className:
					'overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
			}}
		>
			<div className="flex w-full max-w-2xl flex-col gap-6">
				<Markdown className="text-body-md text-neutral-2-fg">{play.instructions}</Markdown>
				{!!play.personaTypes?.length && (
					<LabeledValue label="Persona type">
						<div className="flex flex-row flex-wrap gap-1">
							{play.personaTypes.map(item => (
								<div
									key={`personaType-${item}`}
									className="rounded bg-brand-1-bg px-2 py-0.5 text-label-sm text-brand-1-fg"
								>
									{item}
								</div>
							))}
						</div>
					</LabeledValue>
				)}
				{!!play.expertise?.length && (
					<LabeledValue label="Expertise">
						<div className="flex flex-row flex-wrap gap-1">
							{play.expertise.map(item => (
								<div
									key={`expertise-${item}`}
									className="rounded bg-indigo-10 px-2 py-0.5 text-label-sm text-indigo-70"
								>
									{item}
								</div>
							))}
						</div>
					</LabeledValue>
				)}
			</div>
		</AccordionItem>
	)
}

export default PlayAccordion
