import { z } from 'zod'

export const GlossaryTermAPISchema = z.object({
	id: z.number(),
	ecosystem: z.object({
		id: z.number(),
		name: z.string(),
	}),
	term: z.string(),
	manualInput: z.string().nullable().optional(),
	definition: z.string().nullable().optional(),
})

export const GlossaryTermListAPISchema = GlossaryTermAPISchema.array()

export const EditTermFormSchema = z.object({
	ecosystemId: z.string(),
	term: z.string(),
	manualInput: z.string().nullable().optional(),
})
