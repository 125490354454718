import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, useActionData } from 'react-router-dom'
import { AdminCompanyFormSchema } from '#src/api/icp/admin/company/schemas'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import { useIsPending } from '#src/utils/misc'
import { type ActionRes } from '../actions/new'

export default function New() {
	const actionData = useActionData() as ActionRes
	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'company-form',
		constraint: getZodConstraint(AdminCompanyFormSchema),
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: AdminCompanyFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Surface className="mx-auto flex w-full max-w-screen-md flex-col items-center gap-6 px-24 py-10">
			<h1 className="text-center text-title-md">Enter your company name</h1>

			<Form method="POST" {...getFormProps(form)} className="flex w-full flex-col gap-6">
				<Field
					labelProps={{ children: 'Name' }}
					inputProps={{
						...getInputProps(fields.name, { type: 'text' }),
						autoFocus: true,
						placeholder: 'Enter company name',
					}}
					errors={fields.name.errors}
				/>

				<ErrorList errors={form.errors} id={form.errorId} />

				<div className="flex w-full justify-center">
					<StatusButton
						status={isPending ? 'pending' : (form.status ?? 'idle')}
						type="submit"
						disabled={isPending}
						size="md"
						className="min-w-40"
					>
						Continue
					</StatusButton>
				</div>
			</Form>
		</Surface>
	)
}
