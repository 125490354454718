import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type ActionFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { editRoleplayScenario } from '#src/routes/enable/roleplay/scenario/mutations'
import { roleplayScenarioKeys } from '#src/routes/enable/roleplay/scenario/queries'
import { type EditScenarioFormSchema, getActiveScenarioTabSchema } from '#src/routes/enable/roleplay/scenario/schema'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type EditRoleplayScenarioActionResponse = Awaited<ReturnType<typeof editRoleplayScenarioAction>>

export const editRoleplayScenarioAction = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const formData = await request.formData()
	const activeTab = formData.get('activeTab')
	const TabSchema = getActiveScenarioTabSchema(activeTab as string)

	const submission = parseWithZod(formData, {
		schema: TabSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		const fullData = JSON.parse(submission.payload.fullData as string) as z.infer<typeof EditScenarioFormSchema>

		delete submission.payload.activeTab
		delete submission.payload.fullData

		let contactId: string | undefined = fullData.contactId

		const activeTab = formData.get('activeTab') as string

		if (activeTab === 'persona-mapping') {
			if ('linkedinUrl' in submission.value && 'personaId' in submission.value && submission.value.linkedinUrl) {
				const linkedinPayload = {
					linkedinUrl: submission.value.linkedinUrl,
					personaId: submission.value.personaId,
				}
				const res = await validateLinkedinUrl(company.id, linkedinPayload)
				contactId = res.uuid
			} else {
				delete submission.payload.linkedinUrl
				delete fullData.linkedinUrl
				delete submission.payload.contactId
				delete fullData.contactId
				contactId = ''
			}
		}

		const payload = {
			...fullData,
			...submission.payload,
			...(contactId && { contactId }),
		}

		try {
			await editRoleplayScenario(company.id, params.scenarioId, payload)

			await client.invalidateQueries({
				queryKey: roleplayScenarioKeys.all,
			})

			showToast({
				message: 'Saved',
				type: 'success',
			})

			return {
				ok: true,
				result: {
					...submission.reply(),
				},
			}
		} catch (err) {
			captureException(err)
		}
	} catch (err) {
		console.error(err)

		return {
			ok: false,
			result: submission.reply({
				fieldErrors: {
					linkedinUrl: ['Unable to verify LinkedIn profile'],
				},
			}),
		}
	}
}
