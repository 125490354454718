import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { playsMutations } from '#src/api/icp/company/plays/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method !== 'DELETE') {
		throw new Response('Wrong action method', {
			status: 400,
			statusText: 'Bad Request',
		})
	}
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['playId'])

	try {
		await playsMutations.delete(company.id, Number(params.playId))
		showToast({
			message: 'Play deleted',
			type: 'success',
		})
	} catch (e) {
		if (e instanceof Response) {
			const response = (await e.json()) as { message: string; errors: Record<string, string[]> }
			showToast({
				message: response.message,
				type: 'error',
			})
			return {
				ok: false,
				// ...response,
			}
		}
		showToast({
			message: 'Failed api response. Please contact support',
			type: 'error',
		})
		return {
			ok: false,
			// message: 'Failed api response. Please contact support'
		}
	}

	throw redirect(routes.enable.plays.index({ companyId }))
}
