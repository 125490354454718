import { type RouteObject } from 'react-router-dom'
import Roleplay, { loader as roleplayLoader } from '#src/routes/enable/roleplay/index'
import ScenarioDetails, {
	loader as scenarioDetailsLoader,
	newTestSessionAction,
	type LoaderRes as RoleplayScenarioLoaderResponse,
} from '#src/routes/enable/roleplay/scenario'
import { editRoleplayScenarioAction } from '#src/routes/enable/roleplay/scenario/actions/editRoleplayScenarioAction'
import { inviteParticipantsAction } from '#src/routes/enable/roleplay/scenario/actions/inviteParticipantsAction'
import { newRoleplayScenarioWizardAction } from '#src/routes/enable/roleplay/scenario/actions/newRoleplayScenarioWizardAction'
import { InviteParticipantsModal } from '#src/routes/enable/roleplay/scenario/components/InviteParticipantsModal'
import { NewRoleplayScenarioWizard } from '#src/routes/enable/roleplay/scenario/components/NewRoleplayScenarioWizard'
import { inviteParticipantsLoader } from '#src/routes/enable/roleplay/scenario/loaders/inviteParticipantsLoader'
import { newRoleplayScenarioWizardLoader } from '#src/routes/enable/roleplay/scenario/loaders/newRoleplayScenarioWizardLoader'
import RoleplaySession, {
	loader as roleplaySessionLoader,
	type RoleplaySessionLoaderResponse,
} from '#src/routes/enable/roleplay/session'
import { joinRoleplayChatAction } from '#src/routes/enable/roleplay/session/actions/joinRoleplayChatAction'
import { newRoleplaySessionWizardAction } from '#src/routes/enable/roleplay/session/actions/newRoleplaySessionWizardAction'
import { JoinRoleplayChat } from '#src/routes/enable/roleplay/session/components/JoinRoleplayChat'
import { NewRoleplaySessionWizard } from '#src/routes/enable/roleplay/session/components/NewRoleplaySessionWizard'
import DeleteRoleplayChat, { action as deleteRoleplayAction } from '#src/routes/enable/roleplay/session/delete'
import EndRoleplayChat, { action as chatEndRoleplayAction } from '#src/routes/enable/roleplay/session/end'
import { joinRoleplayChatLoader } from '#src/routes/enable/roleplay/session/loaders/joinRoleplayChatLoader'
import { action as restartRoleplayAction } from '#src/routes/enable/roleplay/session/restart'
import RoleplayReview, {
	loader as roleplayReviewLoader,
	type RoleplayReviewLoaderResponse,
} from '#src/routes/enable/roleplay/session/review'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default {
	path: 'roleplay',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Roleplay training',
				icon: 'voice-activate',
			},
		],
	},
	children: [
		{
			index: true,
			element: <Roleplay />,
			errorElement: <RouteBoundary />,
			loader: roleplayLoader,
		},
		{
			path: 'scenario/:scenarioId',
			element: <ScenarioDetails />,
			loader: scenarioDetailsLoader,
			action: editRoleplayScenarioAction,
			handle: {
				crumb: (data: RoleplayScenarioLoaderResponse) => [
					{
						label: data.handle.scenario,
						icon: 'user-service-desk',
					},
				],
			},
			children: [
				{
					path: 'invite',
					element: <InviteParticipantsModal />,
					loader: inviteParticipantsLoader,
					action: inviteParticipantsAction,
				},
				{
					path: 'new-test-session',
					action: newTestSessionAction,
				},
			],
		},
		{
			path: 'session/:conversationId',
			element: <RoleplaySession />,
			loader: roleplaySessionLoader,
			handle: {
				crumb: () => [
					{
						label: 'Voice chat',
						icon: 'user-service-desk',
					},
				],
				action: (data: RoleplaySessionLoaderResponse) => [
					...(data.handle.canRestartChat
						? [
								{
									id: 'copilot-restart',
									action: routes.enable.roleplay.session.restart({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									method: 'PUT',
									label: 'Restart Roleplay',
									icon: 'reset',
								},
							]
						: []),
					...(data.handle.canDeleteChat
						? [
								{
									to: routes.enable.roleplay.session.delete({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									icon: 'trash',
									className: 'text-status-danger-fg',
								},
							]
						: []),
				],
			},
			children: [
				{
					path: 'restart',
					action: restartRoleplayAction,
				},
				{
					path: 'end-session',
					element: <EndRoleplayChat />,
					action: chatEndRoleplayAction,
				},
				{
					path: 'delete',
					element: <DeleteRoleplayChat />,
					action: deleteRoleplayAction,
				},
			],
		},
		{
			path: 'session/:conversationId/join',
			element: <JoinRoleplayChat />,
			loader: joinRoleplayChatLoader,
			action: joinRoleplayChatAction,
			handle: {
				layout: {
					enableHeader: false,
					disableSidebar: true,
				},
			},
		},
		{
			id: 'roleplay-review',
			path: 'review/:conversationId',
			element: <RoleplayReview />,
			loader: roleplayReviewLoader,
			handle: {
				crumb: () => [
					{
						label: 'Review',
						icon: 'user-service-desk',
					},
				],
				action: (data: RoleplayReviewLoaderResponse) =>
					data.handle.canDeleteChat
						? [
								{
									to: routes.enable.roleplay.session.deleteReviewed({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									icon: 'trash',
									className: 'text-status-danger-fg',
								},
							]
						: [],
			},
			children: [
				{
					path: 'delete',
					element: <DeleteRoleplayChat />,
					action: deleteRoleplayAction,
				},
			],
		},
		{
			path: 'scenario',
			children: [
				{
					path: 'new',
					element: <NewRoleplayScenarioWizard />,
					loader: newRoleplayScenarioWizardLoader,
					action: newRoleplayScenarioWizardAction,
					handle: {
						layout: {
							disableSidebar: true,
							noPadding: true,
							fullWidth: true,
							enableHeader: false,
						},
					},
				},
			],
		},
		{
			path: 'session',
			children: [
				{
					path: 'new',
					element: <NewRoleplaySessionWizard />,
					loader: newRoleplayScenarioWizardLoader,
					action: newRoleplaySessionWizardAction,
					handle: {
						layout: {
							disableSidebar: true,
							noPadding: true,
							fullWidth: true,
							enableHeader: false,
						},
					},
				},
			],
		},
	],
} satisfies RouteObject
