import { getFormProps, getInputProps, getSelectProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, Link, useLoaderData, useParams, type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { Field, Select, TextareaField } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { glossaryQuery } from './queries'
import { EditTermFormSchema, type GlossaryTermAPISchema } from './schema'

type EditTermLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id))

	const terms = params.termId ? await client.fetchQuery(glossaryQuery(company.id)) : null

	const term = terms?.find(term => term.id === Number(params.termId)) ?? null

	return {
		handle: {
			termId: params.termId ?? null,
		},
		ecosystems,
		term,
	}
}

export default function TermEdit() {
	const params = useParams()
	const {
		ecosystems,
		term,
		handle: { termId },
	} = useLoaderData() as EditTermLoaderResponse
	const { companyId } = useCompany()

	const action = routes.enable.glossary.save({
		companyId,
		termId,
	})
	const method = params.termId ? 'PATCH' : 'POST'
	const formId = 'term-form-' + (params.termId ?? 'new')

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const cancelTo = routes.enable.glossary.index({ companyId })

	return (
		<div className="w-[24rem]">
			<Sidebar
				header={<SidebarHeader heading="New Term" closeTo={cancelTo} />}
				main={
					<EditTermForm
						formId={formId}
						action={action}
						method={method}
						ecosystems={ecosystems.map(({ id, name }) => ({ id, name }))}
						term={term}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link to={cancelTo}>Cancel</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							form={formId}
							disabled={isPending}
						>
							Create
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function EditTermForm({
	formId,
	action,
	method,
	ecosystems,
	term,
}: {
	formId: string
	action: string
	method: 'PATCH' | 'POST'
	ecosystems: Array<{ id: number; name: string }>
	term?: z.infer<typeof GlossaryTermAPISchema> | null
}) {
	const defaultValue = {
		ecosystemId: term?.ecosystem?.id ?? '',
		term: term?.term ?? '',
		manualInput: term?.manualInput ?? '',
	}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(EditTermFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: EditTermFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Form method={method} {...getFormProps(form)} action={action} className="-mx-4 flex flex-col gap-4 px-4">
			<Select
				labelProps={{ children: 'Ecosystem' }}
				inputProps={{
					...getSelectProps(fields.ecosystemId),
					defaultValue: undefined,
					value: String(fields.ecosystemId.value) || undefined,
				}}
				options={ecosystems.map(({ id, name }) => ({
					value: String(id),
					label: name,
				}))}
				errors={fields.ecosystemId.errors}
			/>

			<Field
				labelProps={{ children: 'Term' }}
				inputProps={{
					...getInputProps(fields.term, { type: 'text' }),
				}}
				errors={fields.term.errors}
			/>

			<TextareaField
				labelProps={{
					children: 'Manual Input',
				}}
				textareaProps={{
					...getTextareaProps(fields.manualInput),
				}}
				errors={fields.manualInput.errors}
			/>
		</Form>
	)
}
