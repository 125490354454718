import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { SettingsCopilotFormSchema } from '#src/api/icp/company/settings/copilot/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import CopilotSettingsFields from '#src/components/shared/copilot/CopilotSettingsFields'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { type ActionRes as SettingsCopilotGeneralEditActionRes } from '#src/routes/settings/copilot/actions/general-edit'
import { type LoaderRes as SettingsCopilotLoader } from '#src/routes/settings/copilot/loaders'
import { routes } from '#src/utils/routes'

const FORM_ID = 'settings-copilot-general-form'

const GeneralSettingsForm = () => {
	const { data } = useLoaderData() as SettingsCopilotLoader
	const { companyId } = useCompany()
	const form = useForm<z.infer<typeof SettingsCopilotFormSchema>>({
		resolver: zodResolver(SettingsCopilotFormSchema),
		defaultValues: data.general,
		mode: 'onSubmit',
	})
	const actionFetcher = useFetcher<SettingsCopilotGeneralEditActionRes>()

	return (
		<FormWrapper
			formId={FORM_ID}
			formProps={form}
			className="flex w-full max-w-lg flex-col gap-6"
			onSubmit={formData =>
				actionFetcher.submit(formData, {
					method: 'PATCH',
					action: routes.settings.copilot.generalSettings.edit({
						companyId,
					}),
				})
			}
		>
			<CopilotSettingsFields />
			<StatusButton
				status={actionFetcher.state === 'idle' ? 'idle' : 'pending'}
				type="submit"
				disabled={actionFetcher.state !== 'idle'}
				size="md"
				className="min-w-40 self-start"
			>
				Save
			</StatusButton>
		</FormWrapper>
	)
}

export default GeneralSettingsForm
