import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { type z } from 'zod'
import { DialogFooterWarning } from '#src/components/DialogFooterWarning'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { routes } from '#src/utils/routes'
import { action as criterionAssignAction } from './actions/assign'
import { action as criterionUnassignAction } from './actions/unassign'
import { ModalLayout, type ModalHandle } from './layouts'
import { loader as modalLayoutLoader } from './loaders/modalLayout'
import { CriteriaDetailsView } from './routes/details'
import { criteriaEditAnswersAction, criteriaEditAnswersLoader, CriteriaEditView } from './routes/editAnswers'
import { criteriaListLoader, CriteriaListView } from './routes/list'
import { criteriaSaveAction, criteriaSaveLoader, type CriteriaSaveLoaderType, CriteriaSaveView } from './routes/save'

type RouteObjectWithHandle = RouteObject & {
	handle?: ModalHandle
}

function createCriteriaRoutes(
	client: QueryClient,
	criterionType: z.infer<typeof CRITERION_TYPE_ENUM>,
): RouteObjectWithHandle[] {
	return [
		{
			path: 'answers',
			loader: criteriaEditAnswersLoader(client, criterionType),
			action: criteriaEditAnswersAction(client, criterionType),
			element: <CriteriaEditView key={criterionType} />,
		},
		{
			loader: modalLayoutLoader(client, criterionType),
			element: <ModalLayout />,
			children: [
				{
					path: 'list',
					loader: criteriaListLoader(client, criterionType),
					element: <CriteriaListView />,
					handle: {
						getDialogConfig: () => ({
							footer: null,
							contentProps: { disableScrollbar: true, className: 'h-[90vh]' },
						}),
					},
				},
				{
					path: 'new',
					loader: criteriaSaveLoader(client, criterionType),
					action: criteriaSaveAction(client, criterionType),
					element: <CriteriaSaveView />,
					handle: {
						getDialogConfig: (data: CriteriaSaveLoaderType) => ({
							actions: [
								{
									type: 'cancel',
									label: 'Go Back',
									to: routes.calibrate.criteria.list({
										companyId: data.values.companyId,
										ecosystemId: data.values.ecosystemId,
										criterionType: data.values.type,
									}),
								},
								{ type: 'submit', label: 'Use Criterion', formId: 'saveCriterionForm' },
							],
						}),
					},
				},
				{
					path: ':criterionId',
					children: [
						{
							path: 'new',
							loader: criteriaSaveLoader(client, criterionType),
							action: criteriaSaveAction(client, criterionType),
							element: <CriteriaSaveView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'cancel',
											label: 'Go Back',
											to: routes.calibrate.criteria.list({
												companyId: data.values.companyId,
												ecosystemId: data.values.ecosystemId,
												criterionType: data.values.type,
											}),
										},
										{ type: 'submit', label: 'Use Criterion', formId: 'saveCriterionForm' },
									],
								}),
							},
						},
						{
							path: 'edit',
							loader: criteriaSaveLoader(client, criterionType),
							action: criteriaSaveAction(client, criterionType),
							element: <CriteriaSaveView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'cancel',
											label: 'Go Back',
											to: routes.calibrate.criteria.list({
												companyId: data.values.companyId,
												ecosystemId: data.values.ecosystemId,
												criterionType: data.values.type,
											}),
										},
										{ type: 'submit', label: 'Update', formId: 'saveCriterionForm' },
									],
									footerInfo: data.data.criterion?.ecosystems.length && (
										<DialogFooterWarning
											count={data.data.criterion.ecosystems.length}
											entityName="Ecosystem"
											tooltipLabel={data.data.criterion.ecosystems.join(', ')}
											message="will be affected by changes"
										/>
									),
								}),
							},
						},
						{
							path: 'assign',
							action: criterionAssignAction(client, criterionType),
						},
						{
							path: 'unassign',
							action: criterionUnassignAction(client, criterionType),
						},
						{
							path: 'details',
							loader: criteriaSaveLoader(client, criterionType),
							element: <CriteriaDetailsView />,
							handle: {
								getDialogConfig: (data: CriteriaSaveLoaderType) => ({
									actions: [
										{
											type: 'cancel',
											label: 'Go Back',
											to: routes.calibrate.criteria.list({
												companyId: data.values.companyId,
												ecosystemId: data.values.ecosystemId,
												criterionType: data.values.type,
											}),
										},
										{ type: 'submit', label: 'Use Criterion', formId: 'assignCriterionForm' },
									],
								}),
							},
						},
					],
				},
			],
		},
	]
}

export default (client: QueryClient) =>
	({
		path: 'criteria',
		children: [
			{
				path: 'qualification',
				children: createCriteriaRoutes(client, 'qualification'),
			},
			{
				path: 'enrichment',
				children: createCriteriaRoutes(client, 'enrichment'),
			},
		],
	}) satisfies RouteObjectWithHandle
