import { type RouteObject } from 'react-router-dom'
import {
	indexLoader,
	IndexView,
	publishLoader,
	valuePropositionDeleteAction,
	valuePropositionPublishAction,
	valuePropositionSaveAction,
} from './routes/index'
import { NewPointModal, PublishStatsModal, PublishWarnModal } from './views/components'

export default () =>
	({
		id: 'value-proposition',
		path: 'value-proposition',
		handle: {
			layout: {
				enableHeader: true,
			},
			crumb: () => [
				{
					label: 'Value Proposition',
					icon: 'white-paper',
				},
			],
		},
		children: [
			{
				path: '',
				element: <IndexView />,
				loader: indexLoader(),
				children: [
					{
						path: 'new',
						action: valuePropositionSaveAction(),
						element: <NewPointModal />,
					},
					{
						path: 'publish',
						element: <PublishStatsModal />,
						loader: publishLoader(),
						action: valuePropositionPublishAction(),
					},
					{
						path: 'publish-warn',
						element: <PublishWarnModal />,
						loader: publishLoader(),
						action: valuePropositionPublishAction(),
					},
					{
						path: ':pointId',
						children: [
							{
								path: 'delete',
								action: valuePropositionDeleteAction(),
							},
							{
								path: 'save',
								action: valuePropositionSaveAction(),
							},
						],
					},
				],
			},
			{
				path: 'published',
				element: <IndexView isReadOnly />,
				loader: indexLoader('published'),
			},
		],
	}) satisfies RouteObject
