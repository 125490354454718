import { Link, useLoaderData } from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/companies'
import DeleteCompanyForm from './components/DeleteCompanyForm'

export default function Companies() {
	const {
		data: { companies },
	} = useLoaderData() as LoaderRes

	return (
		<PageLayout
			title="Companies"
			actions={[
				{
					variant: 'default',
					icon: 'add',
					to: routes.admin.company.create,
					title: 'Create new',
				},
			]}
		>
			<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				{companies.map(company => (
					<Surface
						key={company.id}
						className="flex w-full items-center gap-2 p-2 transition-all hover:translate-x-1 hover:shadow-lg"
					>
						<Link to={routes.company.index({ companyId: company.id })} className="h-full w-full py-4 pl-4">
							{company.name}
						</Link>
						<div className="inline-flex h-full items-center gap-2 py-4 pr-4">
							<Link
								to={routes.admin.company.edit({ companyId: company.id })}
								className="inline-flex h-full items-center gap-1 whitespace-nowrap"
							>
								<Icon name="edit" size="sm" />
							</Link>
							<DeleteCompanyForm companyId={company.id} />
						</div>
					</Surface>
				))}
			</div>
		</PageLayout>
	)
}
