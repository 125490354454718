import { z } from 'zod'
import { DashboardSchema } from '#src/routes/company/schema'
import { zString } from '#src/utils/validation'

export const AdminCompanyFormSchema = z.object({
	name: zString(),
})

export const AdminCompanyDashboardFormSchema = z.object({
	dashboard: DashboardSchema.omit({ name: true })
		.transform(v => ({ ...v, url: v.url ? v.url : null }))
		.array(),
})
