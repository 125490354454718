import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'

export type CalibrateModuleLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(params.companyId))

		if (!ecosystems)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			ecosystems,
		}
	}
