import { type ReactNode } from 'react'
import ProductTip from '#src/components/product-tip'

type Props = {
	label: string
	tooltip?: string
	children: ReactNode
}

export const LabeledValue = (props: Props) => {
	const { label, tooltip, children } = props
	return (
		<div className="flex w-full flex-col gap-1">
			<h4 className="flex items-center gap-1 text-label-md text-neutral-3-fg">
				{label} {!!tooltip && <ProductTip content={tooltip} />}
			</h4>
			<div className="text-body-md text-neutral-1-fg">{children}</div>
		</div>
	)
}
