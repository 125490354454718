import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { AdminCompanyFormSchema } from '#src/api/icp/admin/company/schemas'
import { routes } from '#src/utils/routes'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	const submission = parseWithZod(formData, { schema: AdminCompanyFormSchema })

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		const company = await adminCompanyMutations.create(submission.value)

		return redirect(routes.calibrate.valueProposition.index({ companyId: company.id }))
	} catch (error) {
		let errorResponse = 'There was error submitting the form'

		if (error instanceof Error) {
			errorResponse = error.message
		} else if (error instanceof Response) {
			const response = (await error.json()) as { message: string }

			if ('message' in response) errorResponse = response.message
		}

		return {
			ok: false,
			result: submission.reply({
				formErrors: [errorResponse],
			}),
		}
	}
}
