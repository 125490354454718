import { useMemo } from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { ExpandableTree } from '#src/components/ExpandableTree'
import useCompany from '#src/hooks/useCompany'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { usePersona } from '#src/hooks/usePersona'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { type PersonasIndexLoaderResType } from '#src/routes/enable/personas/routes'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type IconName } from '@/icon-name'

export const getTraversedVerticalsTreeForPersonas = (
	companyId: string | null,
	ecosystems: z.infer<typeof EcosystemAPISchema>[],
) => {
	return ecosystems
		.filter(ecosystem => ecosystem.verticals.length)
		.map(ecosystem => {
			const totalPersonasCount = ecosystem.verticals.reduce((total, vertical) => total + vertical.personas.length, 0)

			return {
				id: String(ecosystem.id),
				label: ecosystem.name,
				childrenCount: totalPersonasCount,
				children: ecosystem.verticals.map(vertical => ({
					id: String(vertical.id),
					label: vertical.name,
					childrenCount: vertical.personas.length,
					icon: 'category' as IconName,
					to: routes.enable.personas.index({
						companyId: companyId,
						verticalId: String(vertical.id),
					}),
				})),
			}
		})
}

export const PersonasSidebar = () => {
	const { ecosystems } = useRouteLoaderData('personas-loader') as PersonasIndexLoaderResType
	const { getPersonasCountPerCompany } = usePersona()
	const { verticalId } = useParsedRouteParams(['verticalId'])
	const { companyId } = useCompany()
	const { findEcosystemByVerticalId } = useEcosystem()

	const traversedData = useMemo(
		() => getTraversedVerticalsTreeForPersonas(companyId, ecosystems),
		[companyId, ecosystems],
	)

	const selectedEcosystem = useMemo(
		() => findEcosystemByVerticalId(verticalId, ecosystems),
		[ecosystems, findEcosystemByVerticalId, verticalId],
	)

	const personasCount = useMemo(() => getPersonasCountPerCompany(ecosystems), [ecosystems, getPersonasCountPerCompany])

	return (
		<>
			<div className="relative mx-2 flex rounded-sm bg-neutral-3-bg px-3 py-2 font-semibold">
				<span className="text-body-md">All Personas</span>
				<span className="absolute right-[10px] top-1/2 -translate-y-1/2 transform text-body-sm">{personasCount}</span>
			</div>
			{selectedEcosystem && (
				<ExpandableTree
					data={traversedData}
					defaultExpandedItems={[String(selectedEcosystem.id)]}
					defaultSelectedLeafItemId={verticalId}
					size="md"
				/>
			)}
		</>
	)
}
