import * as Portal from '@radix-ui/react-portal'
import { type HTMLAttributes, type MouseEvent, type ReactNode } from 'react'
import { Link, type To } from 'react-router-dom'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

type SidebarProps = {
	header?: ReactNode
	headerProps?: SidebarHeaderProps
	footer?: ReactNode
	footerProps?: SidebarFooterProps
	main: ReactNode
	className?: ClassName
} & HTMLAttributes<HTMLDivElement>

//TODO: Rename into Drawer
export const Sidebar = ({ header, headerProps = {}, main, footer, footerProps = {}, className }: SidebarProps) => {
	const defaultCancelBtnProps = {
		...footerProps.cancelButton,
		to: footerProps.cancelButton?.onClick ? undefined : (footerProps.cancelButton?.to ?? headerProps.closeTo),
	}

	return (
		<Portal.Root>
			<div className="fixed right-0 top-[var(--builder-header-height)] z-10 h-screen w-[28rem] animate-in fade-in slide-in-from-right-10">
				<div className="h-[calc(100vh-var(--builder-header-height))] overflow-y-auto border-l border-neutral-1-bd bg-neutral-1-bg">
					<aside className="flex min-h-full grow flex-col">
						{header ?? <SidebarHeader {...headerProps} />}
						<main className={cn('grow p-4', className)}>{main}</main>
						{footer ?? <SidebarFooter {...footerProps} cancelButton={defaultCancelBtnProps} />}
					</aside>
				</div>
			</div>
		</Portal.Root>
	)
}

type SidebarHeaderProps = {
	children?: ReactNode
	className?: string
	heading?: ReactNode
	onClose?: () => void
	closeTo?: To
	actions?: {
		title?: ReactNode
		icon?: IconName
		onClick?: (e: MouseEvent<HTMLButtonElement>) => void
		to?: To
		className?: string
		variant?: ButtonProps['variant']
	}[]
}

export const SidebarHeader = ({ children, className, heading, onClose, closeTo, actions }: SidebarHeaderProps) => {
	const renderCloseIcon = <Icon name="cross-1" size="sm" />

	return (
		<header
			className={cn(
				'sticky top-0 z-10 flex h-[var(--builder-header-height)] w-full items-center justify-between border-b border-t-0 border-b-neutral-1-bd bg-neutral-1-bg px-4',
				className,
			)}
		>
			<div className="flex w-full gap-2">
				{heading && <h1 className="text-body-md font-semibold">{heading}</h1>}
				{children}
			</div>
			<section className="flex space-x-2">
				{actions?.length && (
					<div className="flex">
						{actions.map((item, index) => (
							<Button
								key={index}
								variant={item.variant ?? 'secondary'}
								size="xs"
								className={cn('flex gap-1', item.className)}
								onClick={item.onClick}
								asChild={!!item.to}
							>
								{item.to ? (
									<Link to={item.to}>
										{item.icon && <Icon name={item.icon} size="sm" />}
										{item.title}
									</Link>
								) : (
									<>
										{item.icon && <Icon name={item.icon} size="sm" />}
										{item.title}
									</>
								)}
							</Button>
						))}
					</div>
				)}
				{(onClose || closeTo) && (
					<button onClick={onClose}>{closeTo ? <Link to={closeTo}>{renderCloseIcon}</Link> : renderCloseIcon}</button>
				)}
			</section>
		</header>
	)
}

type SidebarFooterButtonProps = {
	label?: string
	to?: To
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void
	formId?: string
}

type SidebarFooterProps = {
	children?: ReactNode
	className?: string
	isSubmitting?: boolean
	cancelButton?: SidebarFooterButtonProps
	submitButton?: SidebarFooterButtonProps
}

export const SidebarFooter = ({
	children,
	className,
	isSubmitting = false,
	cancelButton = {},
	submitButton = {},
}: SidebarFooterProps) => (
	<footer
		className={cn(
			'sticky bottom-0 z-50 mt-auto flex w-full justify-end gap-2 border-t border-t-neutral-1-bd bg-neutral-1-bg px-4 py-3',
			className,
		)}
	>
		{children ?? (
			<>
				<Button
					variant="outline"
					size="sm"
					asChild={!!cancelButton.to}
					onClick={!cancelButton.to ? cancelButton.onClick : undefined}
				>
					{cancelButton.to ? (
						<Link to={cancelButton.to}>{cancelButton.label || 'Cancel'}</Link>
					) : (
						<span>{cancelButton.label || 'Cancel'}</span>
					)}
				</Button>
				<StatusButton
					status={isSubmitting ? 'pending' : 'idle'}
					size="sm"
					type={!submitButton.formId ? 'button' : 'submit'}
					form={submitButton.formId}
					onClick={submitButton.onClick}
					disabled={isSubmitting}
				>
					{submitButton.to ? (
						<Link to={submitButton.to}>{submitButton.label || 'Save'}</Link>
					) : (
						<span>{submitButton.label || 'Save'}</span>
					)}
				</StatusButton>
			</>
		)}
	</footer>
)
