import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type ActionFunctionArgs } from 'react-router-dom'
import { userMutations } from '#src/api/icp/company/user/mutations'
import { InviteFormSchema } from '#src/api/icp/company/user/schemas'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: InviteFormSchema,
	})

	if (submission.status !== 'success') {
		return {
			ok: false,
			token: null,
			result: submission.reply({
				formErrors: ['Failed to submit form. Make sure all fields are filled out correctly.'],
			}),
		}
	}

	try {
		await userMutations.invite(company.id, {
			email: submission.value.email,
			role: submission.value.role,
		})

		return {
			ok: true,
			result: null,
			email: submission.value.email,
		}
	} catch (error) {
		captureException(error)

		if (error instanceof Response && error.status === 422) {
			const { message } = (await error.json()) as { message: string }

			if (message) {
				return {
					ok: false,
					result: submission.reply({
						formErrors: [message],
					}),
					email: submission.value.email,
				}
			}
		}
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Ooops! Something went wrong. Please try again later.'],
			}),
			email: submission.value.email,
		}
	}
}
