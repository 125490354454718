import { clsx } from 'clsx'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'

type IconVariant = 'success'

type Props = {
	icon: IconName
	iconVariant?: IconVariant
	name: string
	count: number | string
}

const ICON_COLOR_VARIANTS: Record<IconVariant, string> = {
	success: 'text-green-70',
} as const

export const ListStatisticsCard = (props: Props) => {
	const { icon, iconVariant, name, count } = props
	return (
		<Surface className="flex h-full w-full flex-col gap-6 p-6">
			<div className="flex flex-col gap-2">
				<Icon
					name={icon}
					size="md"
					className={clsx('self-start text-neutral-3-fg', iconVariant && ICON_COLOR_VARIANTS[iconVariant])}
				/>
				<h2 className="text-label-lg text-neutral-2-fg">{name}</h2>
			</div>
			<h1 className="text-heading-md">{count}</h1>
		</Surface>
	)
}
