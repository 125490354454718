import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import DeleteConfirm from '#src/components/delete-confirm'
import { type CalibrateNavPersona } from '#src/routes/calibrate/_layout/panel'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export type DeletePersonaAsyncFormProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	ecosystemId: string
	verticalName: string
	children: ReactNode
	personas: CalibrateNavPersona[]
	setIsDropdownOpen: (state: { id: string; open: boolean }) => void
}

export const DeletePersonasAsyncForm = forwardRef<HTMLButtonElement, DeletePersonaAsyncFormProps>(
	({ ecosystemId, verticalName, personas, children, className, setIsDropdownOpen, ...props }, ref) => {
		const params = useParsedRouteParams(['companyId'])
		const action = routes.calibrate.verticals.batchDeletePersonas({
			companyId: params.companyId,
			ecosystemId: ecosystemId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button {...props} ref={ref} type="button" className={cn('flex items-center justify-center', className)}>
						{children}
					</button>
				}
				action={action}
				title="Delete all personas?"
				description={
					<>
						Are you sure you want to archive all personas for <b>{verticalName}</b> vertical?
					</>
				}
				input={<input type="hidden" name="personas" value={JSON.stringify(personas.map(item => item.id))} />}
				handleModalClose={() => setIsDropdownOpen({ id: '', open: false })}
			/>
		)
	},
)

DeletePersonasAsyncForm.displayName = 'DeletePersonasAsyncForm'
