import { useLoaderData } from 'react-router-dom'
import { Button } from '#src/components/ui/button.js'
import { type AuthResetPasswordLoaderRes } from '#src/routes/auth/routes/reset-password'
import { ResetPasswordForm } from '#src/routes/auth/views/components'
import GuestPageLayout from '#src/routes/auth/views/components/GuestPageLayout'

export const View = () => {
	const { user } = useLoaderData() as AuthResetPasswordLoaderRes

	if (user.accepted) {
		return (
			<GuestPageLayout
				title="Invitation already accepted"
				description="If you think this is a mistake or if you have trouble logging into the workspace, please contact the workspace admins or support."
			>
				<div className="text-center">
					<Button asChild className="px-8">
						<a href="/src/routes/auth/views/login">Go to Login</a>
					</Button>
				</div>
			</GuestPageLayout>
		)
	}

	return <ResetPasswordForm />
}
