import { Icon, type IconName } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

export function TrainingAgenda({
	title,
	icon,
	titleClassName,
}: {
	title: string
	icon: IconName
	titleClassName?: string
}) {
	return (
		<div className="flex items-center gap-2">
			<div className="flex h-8 w-8 items-center justify-center rounded border border-neutral-1-bd bg-neutral-3-bg text-brand-1-fg">
				<Icon name={icon} size="sm" />
			</div>
			<p className={cn('text-body-md', titleClassName)}>{title}</p>
		</div>
	)
}
