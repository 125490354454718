import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { playsMutations } from '#src/api/icp/company/plays/mutations'
import { PlaysFormParserSchema } from '#src/api/icp/company/plays/schemas'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['playId'])

	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: PlaysFormParserSchema,
	})

	if (submission.status !== 'success') {
		showToast({
			message: 'Failed to parse form data',
			type: 'error',
		})
		return {
			ok: false,
		}
	}

	try {
		await playsMutations.update(company.id, Number(params.playId), submission.value)
		showToast({
			message: 'Play edited',
			type: 'success',
		})
	} catch (e) {
		if (e instanceof Response) {
			const response = (await e.json()) as { message: string; errors: Record<string, string[]> }
			showToast({
				message: response.message,
				type: 'error',
			})
			return {
				ok: false,
				// ...response,
			}
		}
		showToast({
			message: 'Failed api response. Please contact support',
			type: 'error',
		})
		return {
			ok: false,
			// message: 'Failed api response. Please contact support'
		}
	}

	throw redirect(routes.enable.plays.index({ companyId }))
}
