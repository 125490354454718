import { useMemo } from 'react'
import { type z } from 'zod'
import { AccordionTrigger } from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { type AssignedSignalsAPISchema } from '#src/routes/prioritize/schema'

type Props = {
	ecosystem: z.infer<typeof EcosystemAPISchema>
	assignedSignals: z.infer<typeof AssignedSignalsAPISchema>
}

export const EcosystemAccordionTrigger = (props: Props) => {
	const { ecosystem, assignedSignals } = props

	const totalPersonas = useMemo(
		() =>
			ecosystem.verticals.reduce(
				(totalPersonaCount, vertical) => totalPersonaCount + (vertical.personas?.length ? vertical.personas.length : 0),
				0,
			),
		[ecosystem],
	)

	const totalAssignedPersonas = useMemo(() => {
		const personasIds = ecosystem.verticals
			.map(vertical => {
				if (!vertical.personas) {
					return []
				}
				return vertical.personas.map(persona => persona.id)
			})
			.flat(3)
		const assignedPersonasIds = assignedSignals.map(signal => signal.personaId)
		return personasIds.filter(id => assignedPersonasIds.includes(id)).length
	}, [ecosystem, assignedSignals])

	return (
		<div className="flex flex-row items-center justify-between gap-4">
			<div className="flex-1">
				<AccordionTrigger index={0} dataLength={1}>
					{ecosystem.name}
				</AccordionTrigger>
			</div>
			<div className="flex flex-row gap-4">
				<div className="text-label-sm font-normal text-neutral-3-fg">
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger>
								<div className="flex flex-row gap-1">
									<Icon name="category" size="sm" />
									<span className="text-label-sm font-normal text-neutral-3-fg">{ecosystem.verticals.length ?? 0}</span>
								</div>
							</TooltipTrigger>
							<TooltipContent side="top" align="center">
								Verticals
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
				<div className="text-label-sm font-normal text-neutral-3-fg">
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger>
								<div className="flex flex-row gap-1">
									<Icon name="user" size="sm" />
									<span className="text-label-sm font-normal text-neutral-3-fg">
										{totalAssignedPersonas}/{totalPersonas}
									</span>
								</div>
							</TooltipTrigger>
							<TooltipContent side="top" align="center">
								Personas (Mapped/Total)
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>
		</div>
	)
}
