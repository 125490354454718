import { useParams } from 'react-router-dom'
import { z } from 'zod'

export const routeParamSchema = z.object({
	companyId: z.string(),
	ecosystemId: z.string(),
	verticalId: z.string(),
	personaId: z.string(),
	signalId: z.string(),
	conversationId: z.string(),
	scenarioId: z.string(),
	newRoleplayType: z.enum(['scenario', 'session']),
})

type RouteParams = z.infer<typeof routeParamSchema>

export const useParsedRouteParams = <T extends keyof RouteParams>(params: T[]) => {
	const pickedParams = Object.fromEntries(params.map(param => [param, true]))

	const parsedParams = routeParamSchema
		.pick({
			companyId: pickedParams.companyId || undefined,
			ecosystemId: pickedParams.ecosystemId || undefined,
			verticalId: pickedParams.verticalId || undefined,
			personaId: pickedParams.personaId || undefined,
			signalId: pickedParams.signalId || undefined,
			conversationId: pickedParams.conversationId || undefined,
			newRoleplayType: pickedParams.newRoleplayType || undefined,
			scenarioId: pickedParams.scenarioId || undefined,
		})
		.parse(useParams())

	return parsedParams as Pick<RouteParams, T>
}
