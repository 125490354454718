import { Root, Viewport, Scrollbar, Thumb, Corner } from '@radix-ui/react-scroll-area'
import { type CSSProperties, type ReactNode } from 'react'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

export type ScrollAreaProps = {
	children: ReactNode
	rootClassName?: ClassName
	viewPortClassName?: ClassName
	scrollbarClassName?: ClassName
	maxHeight?: CSSProperties['maxHeight']
}

export const ScrollArea = ({
	children,
	rootClassName,
	viewPortClassName,
	maxHeight = '50vh',
	scrollbarClassName,
}: ScrollAreaProps) => {
	return (
		<Root className={rootClassName}>
			<Viewport className={cn(`overflow-y-auto pe-3`, viewPortClassName)} style={{ maxHeight }}>
				{children}
			</Viewport>
			<Scrollbar orientation="vertical" className={cn('w-1.5', scrollbarClassName)}>
				<Thumb className="rounded-full bg-neutral-2-bd" />
			</Scrollbar>
			<Corner />
		</Root>
	)
}
