import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'

type WizardFooterProps = {
	activeStep: number
	totalSteps: number
	onNext?: () => void
	onPrevious: () => void
	isLoading?: boolean
	disabled?: boolean
	finishBtnText?: string
}

export const WizardFooter = ({
	activeStep,
	totalSteps,
	onNext,
	onPrevious,
	isLoading,
	disabled,
	finishBtnText,
}: WizardFooterProps) => {
	return (
		<section className="bg-white">
			<div className="m-auto flex max-w-screen-xl justify-between px-20 py-4">
				<Button
					onClick={onPrevious}
					disabled={activeStep === 0 || disabled}
					variant="ghost"
					size="sm"
					className="gap-1"
					type="button"
					id="back-button"
				>
					<Icon name="arrow-left" />
					Back
				</Button>
				<StatusButton
					onClick={onNext}
					size="sm"
					type="submit"
					id="continue-button"
					disabled={disabled}
					status={isLoading ? 'pending' : 'idle'}
				>
					{activeStep === totalSteps - 1 ? finishBtnText : 'Continue'}
				</StatusButton>
			</div>
		</section>
	)
}
