import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { settingsCommandsEndpoints } from './endpoints'
import { settingsCommandsQueriesKeys } from './queries'
import { type SettingsCommandsFormSchema, type SettingsCommandsItemAPISchema } from './schemas'

const mutations = {
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		commandId: z.infer<typeof SettingsCommandsItemAPISchema>['id'],
		data: z.infer<typeof SettingsCommandsFormSchema>,
	) => {
		await fetch(settingsCommandsEndpoints.update(companyId, commandId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: settingsCommandsQueriesKeys.all,
		})
	},
}

export { mutations as settingsCommandsMutations }
