import { type ReactNode, useMemo, useState } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { type ClassName } from '#src/types/styles'
import { calculatePct, cn } from '#src/utils/misc'

type BarLineItem = {
	id: string
	icon: IconName
	iconColor: ClassName
	color: ClassName
	name: string
	suffix?: ReactNode
	value: number
}

type Props = {
	name: string
	items: BarLineItem[]
}

export const BarLineStatistics = (props: Props) => {
	const { name, items } = props

	const [selected, setSelected] = useState<string | null>(null)

	const total = useMemo(() => items.map(i => i.value).reduce((partialSum, a) => partialSum + a, 0), [items])

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-2">
				<div className="flex flex-row items-center gap-1">
					<h4 className="flex-1 text-body-lg">{name}</h4>
					<span className="text-body-sm text-neutral-2-fg">{total}</span>
				</div>
				<div className="flex h-2 w-full flex-row flex-nowrap overflow-hidden rounded-sm bg-neutral-1-bd">
					{items.map(item => (
						<CustomTooltip
							key={`bar-line-${item.id}`}
							rootProps={{
								open: selected === item.id,
							}}
							label={`${item.name} ${item.value} (${calculatePct(item.value, total, 1)}%)`}
							noChildrenWrapper
						>
							<div
								className={cn('h-full', item.color, !!(selected && selected !== item.id) && 'opacity-25')}
								style={{ width: `${calculatePct(item.value, total, 1)}%` }}
								onMouseEnter={() => setSelected(item.id)}
								onMouseLeave={() => setSelected(null)}
							/>
						</CustomTooltip>
					))}
				</div>
			</div>
			<div className="flex flex-col">
				{items.map(item => (
					<div
						key={item.id}
						className={cn(
							'flex flex-row items-center justify-between gap-1 py-1.5',
							!!(selected && selected !== item.id) && 'opacity-25',
						)}
						onMouseEnter={() => setSelected(item.id)}
						onMouseLeave={() => setSelected(null)}
					>
						<div className="flex flex-row items-center justify-between gap-1">
							<Icon name={item.icon} size="sm" className={item.iconColor} />
							<div className="text-body-sm text-neutral-2-fg">{item.name}</div>
							{item.suffix}
						</div>
						<div className="text-body-sm">
							{item.value} ({calculatePct(item.value, total, 1)}%)
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
