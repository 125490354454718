import * as Sentry from '@sentry/react'
import { WebAuth, type Auth0Error } from 'auth0-js'
import { client } from '#src/main'
import { authorizeSessionReq, refreshSessionReq } from '#src/routes/auth/mutations'

export const WEB_AUTH_REALM = import.meta.env.VITE_WEB_AUTH_REALM
export const WEB_AUTH_DOMAIN = import.meta.env.VITE_WEB_AUTH_DOMAIN
export const WEB_AUTH_CLIENTID = import.meta.env.VITE_WEB_AUTH_CLIENTID
export const WEB_AUTH_AUDIENCE = import.meta.env.VITE_WEB_AUTH_AUDIENCE
export const WEB_AUTH_REDIRECT_URI = import.meta.env.VITE_WEB_AUTH_REDIRECT_URI

export const STORAGE_KEY_AT = 'eg:auth:at'
export const STORAGE_KEY_RT = 'eg:auth:rt'
export const AUTH_REDIRECT_KEY = 'eg:redirect'

/**
 * Tips'n'tricks
 *
 * https://dev.to/kleeut/skipping-auth0-consent-prompt-for-local-development-524k
 * https://github.com/tarunnsingh/auth0-embedded-react/tree/master
 */
export const webAuth = new WebAuth({
	domain: WEB_AUTH_DOMAIN,
	clientID: WEB_AUTH_CLIENTID,
	audience: WEB_AUTH_AUDIENCE,
	redirectUri: WEB_AUTH_REDIRECT_URI,
	responseType: 'code',
	scope: 'openid profile email offline_access',
})

export const login = async (username: string, password: string) =>
	new Promise((resolve, reject) =>
		webAuth.login(
			{
				username,
				password,
				realm: WEB_AUTH_REALM,
				scope: 'openid profile email offline_access',
			},
			error => reject(error),
		),
	)

export const checkIsAuth0Callback = () => {
	return Boolean(getAuthResponseCode())
}

export const getAuthResponseCode = () => {
	return new URLSearchParams(location.search).get('code')
}
/**
 * NOTE: session is taken from window.location.hash by default in auth0-js
 * @returns
 */
export const authorizeSession = async () => {
	const code = getAuthResponseCode()

	if (!code) {
		return
	}

	const session = await authorizeSessionReq(code)

	setSession(session.accessToken, session.refreshToken)
}

export const refreshSession = async () => {
	const refreshToken = getRefreshToken()

	if (!refreshToken) {
		return
	}

	const session = await refreshSessionReq(refreshToken)

	setSession(session.accessToken, session.refreshToken)
}

export const checkSession = () => {
	return Boolean(getAccessToken()) && Boolean(getRefreshToken())
}

export const clearSession = () => {
	localStorage.removeItem(STORAGE_KEY_AT)
	localStorage.removeItem(STORAGE_KEY_RT)
}

export const setSession = (accessToken: string, refreshToken: string) => {
	localStorage.setItem(STORAGE_KEY_AT, accessToken)
	localStorage.setItem(STORAGE_KEY_RT, refreshToken)
}

export const getSession = () => {
	return {
		accessToken: getAccessToken(),
		refreshToken: getRefreshToken(),
	}
}

export const getAccessToken = () => {
	return localStorage.getItem(STORAGE_KEY_AT)
}

export const getRefreshToken = () => {
	return localStorage.getItem(STORAGE_KEY_RT)
}

export const logout = () => {
	const session = Boolean(getAccessToken())

	Sentry.setUser(null)

	if (!session) {
		window.location.replace(`${window.location.origin}/login`)

		return
	}

	clearSession()

	webAuth.logout({
		clientID: WEB_AUTH_CLIENTID,
		returnTo: WEB_AUTH_REDIRECT_URI,
	})

	// when user logs out we need to flush all in-memory data to avoid accessing other users data
	client.clear()

	window.location.replace(`${window.location.origin}/login`)

	return
}

export const isAuth0Error = (error: unknown): error is Auth0Error => {
	if (typeof error !== 'object' || error == null) return false

	return 'error' in error && ('error_description' in error || 'errorDescription' in error)
}
