import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { statsEndpoints } from './endpoints'
import { StatsAPISchema, StatsEnableAPISchema, StatsValuePropositionSchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'stats'] as const,
	dashboard: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'dashboard', companyId] as const,
	enable: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'enable', companyId] as const,
}

const dashboardQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.dashboard(companyId),
	queryFn: async () =>
		await safeFetch(StatsAPISchema, statsEndpoints.dashboard(companyId), {
			method: 'GET',
		}),
})

const enableQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.enable(companyId),
	queryFn: async () =>
		await safeFetch(StatsEnableAPISchema, statsEndpoints.enable(companyId), {
			method: 'GET',
		}),
})

const valuePropositionQuery = async (companyId: z.infer<typeof CompanySchema>['id']) =>
	safeFetch(StatsValuePropositionSchema, statsEndpoints.valueProposition(companyId), { method: 'GET' })

const queries = {
	dashboard: dashboardQuery,
	enable: enableQuery,
	valueProposition: valuePropositionQuery,
}

export { queries as statsQueries, keys as statsQueriesKeys }
