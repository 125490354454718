import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, type HTMLAttributes } from 'react'
import { cn } from '#src/utils/misc'

export const chipVariants = cva('inline-flex flex-nowrap flex-shrink-0 items-center gap-0.5 rounded-full py-1 px-2.5', {
	variants: {
		variant: {
			green: 'bg-green-10 text-green-70',
			blue: 'bg-blue-10 text-blue-60',
			blueFilled: 'bg-brand-3-bg text-white',
			orange: 'bg-orange-10 text-orange-70',
			indigo: 'bg-indigo-10 text-indigo-70',
			purple: 'bg-purple-10 text-purple-70',
			red: 'bg-red-10 text-red-60',
			gray: 'bg-neutral-3-bg text-neutral-2-fg',
			black: 'bg-neutral-5-bg text-white',
		},
		size: {
			sm: 'text-label-sm',
			md: 'text-label-md',
			lg: 'text-label-lg',
		},
		shape: {
			default: '',
			circle: '',
		},
	},
	compoundVariants: [
		{
			shape: 'circle',
			size: 'sm',
			className: 'w-6 h-6 justify-center p-0',
		},
		{
			shape: 'circle',
			size: 'md',
			className: 'w-7 h-7 justify-center p-0',
		},
		{
			shape: 'circle',
			size: 'lg',
			className: 'w-8 h-8 justify-center p-0',
		},
	],
	defaultVariants: {
		variant: 'green',
		size: 'sm',
		shape: 'default',
	},
})

export type ChipProps = HTMLAttributes<HTMLDivElement> &
	VariantProps<typeof chipVariants> & {
		asChild?: boolean
	}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
	({ className, variant, size, shape, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'div'
		return <Comp className={cn(chipVariants({ variant, size, shape, className }))} ref={ref} {...props} />
	},
)

Chip.displayName = 'Chip'
