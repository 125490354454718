import { type ActionFunctionArgs } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { deleteGlossaryTerm } from './mutations'
import { glossaryKeys } from './queries'

export type DeleteGlossaryTermActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['termId'])

	await deleteGlossaryTerm(company.id, params.termId)

	await client.invalidateQueries({
		queryKey: glossaryKeys.all,
	})

	return null
}

export function DeleteTermAsyncForm({ termId }: { termId: number }) {
	const { companyId } = useCompany()
	const action = routes.enable.glossary.delete({
		companyId,
		termId: termId.toString(),
	})

	return (
		<DeleteConfirm
			trigger={
				<button className="inline-flex items-center justify-center">
					<Icon name="trash" size="font" />
				</button>
			}
			action={action}
			title="Delete term?"
			description="This action cannot be undone. This will permanently delete the term."
		/>
	)
}
