import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { SignalConfigManualInputFormSchema, type SignalTemplateAPISchema } from '#src/routes/prioritize/schema'
import { type ActionRes as SettingsCopilotGeneralEditActionRes } from '#src/routes/settings/copilot/actions/general-edit'
import { routes } from '#src/utils/routes'

type Props = {
	signalTemplate: z.infer<typeof SignalTemplateAPISchema>
}

const DefaultManualInputForm = (props: Props) => {
	const { signalTemplate } = props

	const { companyId } = useCompany()
	const form = useForm<z.infer<typeof SignalConfigManualInputFormSchema>>({
		resolver: zodResolver(SignalConfigManualInputFormSchema),
		defaultValues: {
			manualInput: signalTemplate.config.manualInput ?? '',
		},
		mode: 'onBlur',
	})

	const actionFetcher = useFetcher<SettingsCopilotGeneralEditActionRes>()

	return (
		<FormWrapper
			formId={`default-manual-input-${signalTemplate.id}`}
			formProps={form}
			className="flex w-full flex-col gap-6"
			onSubmit={formData =>
				actionFetcher.submit(formData, {
					method: 'PATCH',
					action: routes.prioritize.signal.save({
						companyId,
						signalId: signalTemplate.id.toString(),
					}),
				})
			}
		>
			<FormField fieldType="textarea" name="manualInput" disableLabel rows={7} />
			<StatusButton
				status={actionFetcher.state === 'idle' ? 'idle' : 'pending'}
				type="submit"
				disabled={!form.formState.isDirty || actionFetcher.state !== 'idle'}
				size="md"
				className="min-w-40 self-start"
			>
				Save changes
			</StatusButton>
		</FormWrapper>
	)
}

export default DefaultManualInputForm
