import { useEffect, useRef, useState } from 'react'
import { Icon } from '#src/components/ui/icon'
import { Textarea } from '#src/components/ui/textarea'

type Props = {
	value: string
	onValueChange: (value: string) => void
	onChangeAll: (value: string) => void
}

export const AssignedPersonaManualInput = (props: Props) => {
	const { value, onValueChange, onChangeAll } = props
	const [open, setOpen] = useState(!!value)
	const [focused, setFocused] = useState(false)
	const btnRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		if (value) {
			setOpen(true)
		}
	}, [value])

	if (!open && !value)
		return (
			<button
				onClick={() => setOpen(true)}
				type="button"
				className="inline-flex items-center gap-1 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
			>
				<Icon name="add" size="sm" />
				Add
			</button>
		)

	return (
		<div className="flex flex-col gap-2">
			<Textarea
				value={value}
				className="text-body-sm"
				onChange={e => onValueChange(e.currentTarget.value)}
				onFocus={() => setFocused(true)}
				onBlur={e => {
					if (btnRef.current && e.relatedTarget === btnRef.current) {
						btnRef.current.click()
					}
					setFocused(false)
				}}
				rows={2}
			/>
			{!!(focused && value) && (
				<button
					onClick={() => onChangeAll(value)}
					ref={btnRef}
					type="button"
					className="inline-flex gap-1 text-left text-button-sm text-link hover:text-link-hover active:text-link-pressed"
				>
					<Icon name="copy" size="sm" />
					Copy to all in this vertical
				</button>
			)}
		</div>
	)
}
