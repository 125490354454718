import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { type HTMLAttributes, forwardRef } from 'react'
import { cn } from '#src/utils/misc'

export const progressBarVariants = cva('rounded-full max-w-full', {
	variants: {
		color: {
			danger: 'bg-status-danger-bg',
			warning: 'bg-status-warning-bg',
			success: 'bg-status-success-bg',
			primary: 'bg-brand-3-bg',
		},
		size: {
			xs: 'h-1',
			sm: 'h-2',
			md: 'h-4',
			lg: 'h-6',
		},
		status: {
			base: 'bg-status-success-bg',
		},
	},
	defaultVariants: {
		color: 'success',
		size: 'sm',
	},
})

export type ProgressBarProps = Omit<HTMLAttributes<HTMLDivElement>, 'color' | 'size'> &
	VariantProps<typeof progressBarVariants> & {
		percent: number
		variant?: 'status' | 'danger' | 'warning' | 'success' | 'primary'
		size?: 'sm' | 'md' | 'lg' | 'xs'
		asChild?: boolean
		className?: string
	}

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
	({ percent, variant = 'status', size = 'sm', asChild = false, className }, ref) => {
		const getColorVariant = (): 'danger' | 'warning' | 'success' => {
			if (percent <= 30) return 'danger'
			if (percent <= 70) return 'warning'
			return 'success'
		}

		const colorVariant = variant === 'status' ? getColorVariant() : variant
		const Comp = asChild ? Slot : 'div'

		return (
			<Comp className={cn('w-full rounded bg-neutral-2-bg', className)} ref={ref}>
				<div className={progressBarVariants({ color: colorVariant, size })} style={{ width: `${percent}%` }}></div>
			</Comp>
		)
	},
)

export default ProgressBar

ProgressBar.displayName = 'ProgressBar'
