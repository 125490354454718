import * as SwitchPrimitive from '@radix-ui/react-switch'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef, type ReactNode } from 'react'
import { selectionControlDisabledVariants, defaultLabelSizeVariants } from '#src/theme'
import { cn } from '#src/utils/misc'

const switchVariants = cva('inline-flex items-center', {
	variants: {
		size: {
			sm: 'h-5 w-9',
			md: 'h-6 w-11',
			lg: 'h-8 w-14',
		},
		thumbSize: {
			sm: 'h-4 w-8',
			md: 'h-3 w-5',
			lg: 'h-8 w-14',
		},
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

const thumbVariants = cva('block transform rounded-full bg-white transition-transform', {
	variants: {
		size: {
			sm: 'h-[14px] w-[14px] translate-x-[4px] radix-state-checked:translate-x-[18px]',
			md: 'h-[18px] w-[18px] translate-x-[4px] radix-state-checked:translate-x-[22px]',
			lg: 'h-[24px] w-[24px] translate-x-[5px] radix-state-checked:translate-x-[29px]',
		},
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

const labelSizeVariants = cva('ml-1', {
	variants: {
		size: defaultLabelSizeVariants,
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

export const SwitchRoot = SwitchPrimitive.Root

export const SwitchThumb = forwardRef<
	ElementRef<typeof SwitchPrimitive.Thumb>,
	ComponentPropsWithoutRef<typeof SwitchPrimitive.Thumb> & VariantProps<typeof switchVariants>
>(({ className, size, ...props }, forwardedRef) => (
	<SwitchPrimitive.Thumb className={cn(thumbVariants({ size }), className)} {...props} ref={forwardedRef} />
))
SwitchThumb.displayName = 'SwitchThumb'

export type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & {
	labelText?: ReactNode
	size?: VariantProps<typeof switchVariants>['size']
	disabled?: boolean
}

export const Switch = forwardRef<ElementRef<typeof SwitchPrimitive.Root>, SwitchProps>(
	({ className, labelText, size, disabled, ...props }, forwardedRef) => (
		<label htmlFor={props.name} className="flex items-center gap-1.5">
			<SwitchRoot
				className={cn(
					switchVariants({ size, disabled }),
					'relative inline-flex items-center rounded-full',
					'bg-neutral-1-bg-disabled radix-state-checked:bg-status-success-fg',
					'transition-colors',
					className,
				)}
				disabled={disabled}
				{...props}
				ref={forwardedRef}
			>
				<SwitchThumb size={size} />
			</SwitchRoot>
			{labelText && <span className={labelSizeVariants({ size, disabled })}>{labelText}</span>}
		</label>
	),
)
Switch.displayName = 'Switch'
