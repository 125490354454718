import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const joinRoleplayChatAction = async ({ params }: ActionFunctionArgs) => {
	const { companyId } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	return redirect(
		routes.enable.roleplay.session.index({
			companyId,
			conversationId: params.conversationId,
		}),
	)
}
