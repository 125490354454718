import { useEffect, useMemo } from 'react'
import { useFetcher } from 'react-router-dom'
import { FormField } from '#src/components/forms/v2/FormField'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type LoaderRes as PlayFormLoaderRes } from '../../loaders/create'

const PlayFormFields = () => {
	const fetcher = useFetcher<PlayFormLoaderRes>()
	const { companyId } = useCompany()

	useEffect(() => {
		if (fetcher.state === 'idle' && !fetcher.data) {
			fetcher.load(routes.enable.plays.create({ companyId }))
		}
	}, [fetcher, companyId])

	const personaTypesOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.personaTypes.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const expertiseOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.expertises.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	return (
		<>
			<FormField fieldType="text" name="name" label="Play name" />
			<FormField fieldType="textarea" name="instructions" label="Play instructions" rows={8} />
			<div className="flex flex-col gap-2">
				<FormField fieldType="switch" name="inCrm" disableLabel labelText="Use this play in Lead Research Agent jobs" />
				<FormField fieldType="switch" name="inCopilot" disableLabel labelText="Use this play in Copilot" />
			</div>
			<FormField fieldType="multiselect" name="personaTypes" label="Persona types" options={personaTypesOptions} />
			<FormField fieldType="multiselect" name="expertise" label="Expertise" options={expertiseOptions} />
		</>
	)
}

export default PlayFormFields
