import { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { cn } from '#src/utils/misc'

export const PersonaMappingNavigator = ({ items }: { items: { id: string; heading: string }[] }) => {
	const location = useLocation()
	const lastHash = useRef<string | null>(null)

	useEffect(() => {
		if (location.hash) {
			lastHash.current = location.hash.slice(1)
		}

		const targetElement = lastHash.current ? document.getElementById(lastHash.current) : null
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
			lastHash.current = null
			history.replaceState(null, document.title, location.pathname + window.location.search)
		}
	}, [location])

	return (
		<aside>
			<ul className="sticky top-[calc(var(--builder-header-height)+1rem)]">
				{items.map(({ id, heading }) => (
					<li
						key={id}
						className={cn(
							'border-l-2 border-neutral-1-bd px-4 py-1.5 transition-all hover:border-brand-1-bd-hover',
							location.hash === `#${id}` ? 'border-bd-brand-1 text-brand-1-fg' : 'text-neutral-2-fg',
						)}
					>
						<Link to={`#${id}`} className={cn('text-body-md font-medium')}>
							{heading}
						</Link>
					</li>
				))}
			</ul>
		</aside>
	)
}
