import { PageLayout } from '#src/components/ui/PageLayout'
import { EcosystemsList, Cards } from '#src/routes/calibrate/dashboard/views/components'

export const View = () => {
	return (
		<PageLayout
			title="Customer Intelligence OS"
			description="This unique dataset is a foundation for all Evergrowth agents and features. Calibrating your Value Proposition, Verticals, ICP's, and IBP's will affect the outcome of Evergrowth platform."
		>
			<Cards />
			<EcosystemsList />
		</PageLayout>
	)
}
