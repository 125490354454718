import { getFormProps, getInputProps, getSelectProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { Form, Link, type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, Select, SelectTextInput, TextareaField } from '#src/components/forms'
import Priority from '#src/components/priority'
import ProductTip from '#src/components/product-tip'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { PERSONA_TYPES, PERSONA_TYPE_OPTIONS, PRIORITY_OPTIONS, STATUS_OPTIONS } from '#src/utils/enumerations'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { personaQuery } from './queries'
import { type PersonaAPISchema, PersonaSetupFormSchema } from './schema'

export type PersonaSetupLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.verticalId || !params.personaId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(params.companyId))
		const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))
		const verticals = ecosystem?.verticals
		if (!verticals?.length)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const vertical = verticals.find(v => v.id.toString() === params.verticalId)
		if (!vertical)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const personas = vertical?.personas

		const persona = await queryClient.fetchQuery(personaQuery(params.companyId, params.personaId))

		if (!persona)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: params.personaId,
			personas,
			persona,
		}
	}

export default function PersonaSetup() {
	const { companyId, ecosystemId, verticalId, personaId, personas, persona } =
		useLoaderData() as PersonaSetupLoaderResponse

	const action = routes.calibrate.persona.save({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: persona.id!.toString(),
	})
	const method = 'PATCH'
	const formId = 'persona-setup-form-' + persona.id

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="w-[26rem]">
			<Sidebar
				header={
					<SidebarHeader
						heading="Setup info"
						closeTo={routes.calibrate.persona.index({
							companyId: companyId,
							ecosystemId: ecosystemId,
							verticalId: verticalId,
							personaId: personaId,
						})}
					/>
				}
				main={
					<PersonaSetupForm
						formId={formId}
						action={action}
						persona={persona}
						personas={
							personas
								?.filter(p => p.id !== persona.id)
								.map(p => ({
									value: String(p.id ?? ''),
									label: String(p.jobTitles ?? p.type ?? ''),
								})) ?? []
						}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.persona.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
									verticalId: verticalId,
									personaId: personaId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							name="intent"
							value="persona"
							form={formId}
							disabled={isPending}
						>
							Save
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function PersonaSetupForm({
	formId,
	action,
	persona,
	personas,
}: {
	formId: string
	action: string
	persona: z.infer<typeof PersonaAPISchema>
	personas: {
		value: string
		label: string
	}[]
}) {
	const method = 'PATCH'

	const defaultValue = {
		id: persona.id,
		reportsTo: persona.reportsTo?.id ?? null,
		reportsOverride: persona.reportsOverride,
		status: persona.status,
		priority: persona.priority,
		type: persona.type,
		expertise: persona.expertise,
		jobTitles: persona.jobTitles,
		jobSeniority: persona.jobSeniority,
		jobExperience: persona.jobExperience,
		typeOfTask: persona.typeOfTask,
		pain: persona.pain,
		painLength: persona.painLength,
		painOverride: persona.painOverride,
	}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(PersonaSetupFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: PersonaSetupFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Form method={method} {...getFormProps(form)} action={action} className="-mx-4">
			<input {...getInputProps(fields.id, { type: 'hidden' })} defaultValue={fields.id.value} />

			<section className="flex flex-col gap-4 px-4">
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Type
								<ProductTip content="Type: Specify the role of the persona (e.g., Decision Maker)." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.type),
						defaultValue: fields.type.initialValue,
						value: fields.type.value,
						onValueChange: value => {
							form.update({
								name: fields.jobSeniority.name,
								value:
									value === PERSONA_TYPES.decisionmaker
										? 'VP, Director or Head'
										: value === PERSONA_TYPES.influencer
											? 'Head, Manager'
											: value === PERSONA_TYPES.champion
												? 'Associate, coordinator, assistant'
												: '',
							})
							form.update({
								name: fields.jobExperience.name,
								value:
									value === PERSONA_TYPES.decisionmaker
										? '+7 years'
										: value === PERSONA_TYPES.influencer
											? '+5 years'
											: value === PERSONA_TYPES.champion
												? '+3 years'
												: '',
							})
						},
					}}
					options={PERSONA_TYPE_OPTIONS}
					errors={fields.status.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Status
								<ProductTip content="Status: Indicate whether the persona setup is ongoing or completed." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.status),
						defaultValue: fields.status.initialValue,
						value: fields.status.value,
					}}
					options={STATUS_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Status status={label} />,
					}))}
					errors={fields.status.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Priority
								<ProductTip content="Priority: Set the priority level (High, Medium, Low) for resource allocation and focus. " />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.priority),
						defaultValue: fields.priority.initialValue,
						value: fields.priority.value,
					}}
					options={PRIORITY_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Priority priority={label} />,
					}))}
					errors={fields.priority.errors}
				/>
			</section>

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Edit setup info
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Expertise
								<ProductTip content="Expertise: Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.expertise, { type: 'text' }),
						onBlur: e => {
							if (fields.jobTitles.value) return

							const jobSeniority = fields.jobSeniority.value ?? fields.jobSeniority.initialValue
							if (e.target.value && jobSeniority) {
								form.update({
									name: fields.jobTitles.name,
									value: `${jobSeniority} of ${e.target.value}`,
								})
							}
						},
					}}
					errors={fields.expertise.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Seniority
								<ProductTip content="Job Seniority: Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobSeniority, { type: 'text' }),
						onBlur: e => {
							if (fields.jobTitles.value) return

							const expertise = fields.expertise.value ?? fields.expertise.initialValue
							if (e.target.value && expertise) {
								form.update({
									name: fields.jobTitles.name,
									value: `${e.target.value} of ${expertise}`,
								})
							}
						},
					}}
					errors={fields.jobSeniority.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Experience
								<ProductTip content="Job Experience: Indicate the required years of experience (e.g., +7 years)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobExperience, { type: 'text' }),
					}}
					errors={fields.jobExperience.errors}
				/>

				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Title examples
								<ProductTip content="Job Title Examples: Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobTitles, { type: 'text' }),
					}}
					errors={fields.jobTitles.errors}
				/>

				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Types of Tasks
								<ProductTip content="Types of Tasks: Describe key responsibilities and tasks undertaken by the persona (e.g., Implementing account-based methodologies)." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.typeOfTask),
						rows: fields.typeOfTask.initialValue ? 8 : 3,
					}}
					errors={fields.typeOfTask.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Reports To
								<ProductTip content="Reports To: Specify the reporting structure (e.g., CEO)." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.reportsTo),
						className: 'h-auto min-h-10',
						defaultValue: undefined,
						value: fields.reportsOverride.value
							? 'other'
							: fields.reportsTo.value
								? String(fields.reportsTo.value)
								: '-',
						onValueChange: value => {
							if (value === 'other') {
								form.update({
									name: fields.reportsOverride.name,
									value: fields.reportsOverride.initialValue ?? '',
								})
							} else if (value == '-') {
								form.update({
									name: fields.reportsTo.name,
									value: '',
								})
								form.update({
									name: fields.reportsOverride.name,
									value: '',
								})
							} else {
								form.update({
									name: fields.reportsOverride.name,
									value: '',
								})
							}
						},
					}}
					options={[{ value: '-', label: '-' }].concat(...personas).concat([{ value: 'other', label: 'Other' }])}
					errors={fields.reportsTo.errors}
				/>
				<Field
					labelProps={{ children: 'Reports To (manual)' }}
					inputProps={{
						...getInputProps(fields.reportsOverride, { type: 'text' }),
						disabled: fields.reportsTo.value !== 'other' && !fields.reportsOverride.value,
					}}
					errors={fields.reportsOverride.errors}
				/>
				<SelectTextInput
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Pain Length (# of words)
								<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.painLength, { type: 'number' }),
						placeholder: 'Enter # of words',
					}}
					options={[
						{ value: '15', label: '15' },
						{ value: '30', label: '30' },
						{ value: '50', label: '50' },
						{ value: '100', label: '100' },
						{ value: '150', label: '150' },
					]}
					errors={fields.painLength.errors}
				/>

				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Fine tune pain
								<ProductTip content="Fine Tune Pain: Provide detailed pain points to guide AI prompts (e.g., Focus on new business revenue and account expansion, not retention)." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.painOverride),
						rows: fields.painOverride.initialValue ? 8 : 3,
					}}
					errors={fields.painOverride.errors}
				/>
				<ErrorList errors={form.errors} id={form.errorId} />
			</section>
		</Form>
	)
}
