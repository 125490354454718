import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type ReactNode } from 'react'
import { Icon } from '#src/components/ui/icon'

type AccordionItem = {
	id: string
	header: ReactNode
	content: ReactNode
}

type FullPageAccordionProps = {
	sections: AccordionItem[]
	defaultExpandedSections?: string[]
}

export const FullPageAccordion = ({ sections, defaultExpandedSections }: FullPageAccordionProps) => {
	return (
		<AccordionPrimitive.Root type="multiple" defaultValue={defaultExpandedSections} className="w-full">
			{sections.map(section => (
				<AccordionPrimitive.Item value={section.id} key={section.id}>
					<AccordionPrimitive.Header>
						<AccordionPrimitive.Trigger
							className={`group flex w-full items-center gap-2 border-t border-neutral-1-bd bg-neutral-1-bg px-2 py-2.5 text-body-md font-semibold outline-none transition-all data-[state=closed]:rounded-b-lg data-[state=open]:border-transparent data-[state=open]:bg-neutral-2-bg`}
						>
							<Icon
								name="chevron-down"
								className="transform transition-transform group-data-[state=open]:rotate-180"
								aria-hidden
								size="sm"
							/>
							{section.header}
						</AccordionPrimitive.Trigger>
					</AccordionPrimitive.Header>

					<AccordionPrimitive.Content
						className={`rounded-b-lg data-[state=closed]:animate-[acc-slide-up_150ms_ease-out] data-[state=closed]:overflow-hidden`}
					>
						<section className="rounded-b-lg bg-neutral-1-bg p-8">{section.content}</section>
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}
