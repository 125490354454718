import { Icon } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

export const ExpertGtpInfo = ({ className }: { className?: ClassName }) => {
	return (
		<p className={cn('w-full rounded-sm bg-brand-1-bg p-2 text-center text-body-sm', className)}>
			Use our free{' '}
			<a
				href="https://chatgpt.com/g/g-5eVvHz6wz-value-prop-expert-v16"
				target="__blank"
				title="Value Prop Sage GPT"
				className="text-link transition-colors hover:text-link-hover active:text-link-pressed"
			>
				<Icon name="magic-wand" asSuffix>
					Value Proposition Expert
				</Icon>
			</a>{' '}
			AI helper. Paste your Website URL, upload files and answer few questions to get your company&apos;s value
			proposition fast!
		</p>
	)
}
