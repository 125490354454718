import * as SelectPrimitive from '@radix-ui/react-select'
import { useFetcher } from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import {
	SelectContent,
	SelectIcon,
	SelectItemText,
	SelectRoot,
	SelectTrigger,
	SelectValue,
} from '#src/components/ui/select'
import useCompany from '#src/hooks/useCompany'
import { type PrioritizeItemSaveActionRes } from '#src/routes/prioritize/routes/save'
import { WeightValue } from '#src/routes/prioritize/views/components/WeightValue'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const SignalWeightForm = ({
	signalId,
	weight,
	showResult,
}: {
	signalId: number
	weight?: number
	showResult?: boolean
}) => {
	const currentValue = String(weight ?? 1)
	const fetcher = useFetcher<PrioritizeItemSaveActionRes>()
	const { companyId } = useCompany()

	return (
		<div className="flex flex-row gap-3">
			<WeightSelect
				name="weight"
				value={String(weight ?? 1)}
				onValueChange={v => {
					if (v && v !== currentValue) {
						fetcher.submit(
							{ weight: Number(v) },
							{
								method: 'PATCH',
								action: routes.prioritize.signal.save({
									companyId,
									signalId: signalId.toString(),
								}),
							},
						)
					}
				}}
			/>
			{showResult && (
				<div className="flex items-center gap-2 text-label-sm">
					{['submitting', 'loading'].includes(fetcher.state) ? (
						<Icon name="update" className="animate-spin" size="sm" />
					) : null}
					{['submitting', 'loading'].includes(fetcher.state)
						? 'Saving...'
						: fetcher.data?.result.status === 'success'
							? 'Success!'
							: null}
				</div>
			)}
		</div>
	)
}

const WEIGHT_OPTIONS = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
]

const WeightSelect = ({
	id,
	name,
	value,
	onValueChange,
}: {
	id?: string
	name: string
	value: string | undefined
	onValueChange: (value: string) => void
}) => {
	return (
		<SelectRoot name={name} value={value} onValueChange={onValueChange}>
			<SelectTrigger className="group flex items-center outline-none" id={id}>
				<SelectValue>
					<WeightValue
						value={value}
						suffix={
							<SelectIcon>
								<Icon name="chevron-down" size="sm" className="group-data-[state='open']:rotate-180" />
							</SelectIcon>
						}
					/>
				</SelectValue>
			</SelectTrigger>

			<SelectContent className="px-2" position="popper" side="bottom" align="start" sideOffset={4}>
				{WEIGHT_OPTIONS.map(({ value }) => (
					<SelectPrimitive.Item
						key={value}
						value={value}
						className={cn(
							'flex cursor-pointer select-none rounded-sm px-2 py-1.5 outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
						)}
					>
						<SelectItemText>{<WeightValue value={value} />}</SelectItemText>
					</SelectPrimitive.Item>
				))}
			</SelectContent>
		</SelectRoot>
	)
}
