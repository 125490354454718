import { type ReactNode } from 'react'
import { type RoleDataType, type UserDataType } from '#src/routes/auth/schema'
import { canSee } from '#src/utils/misc'

type RoleProps = {
	children: ReactNode
	user: UserDataType
	allow?: RoleDataType
	hideIfReadOnly?: boolean
	exact?: boolean
}

/**
 * The `Role` component renders its children if the user has the appropriate roles
 * and satisfies the conditions specified by the `allow` and `hideIfReadOnly` props.

 * Role hierarchy:
 *
 * Base Roles:
 *   - Admin: Full access, including admin actions.
 *   - User: Full system access, except admin actions.
 *
 * Independent Roles:
 *   - Enable-only: Access to the "Enable" section only.
 *   - Data-trainer: Access to the "Organize" section only.
 *   - Read-only: Access to everything, but limited to GET (read-only) actions.
 */
const Role = ({ user, allow = ['read-only'], children, hideIfReadOnly = false, exact = false }: RoleProps) => {
	const allowedRoles = Array.isArray(allow) ? allow : [allow]
	const userRoles = user.roles || []

	if (exact) {
		// Will be needed in future when roles are represented as array
		// const exactMatch =
		// 	userRoles.length === allowedRoles.length &&
		// 	allowedRoles.every(role => userRoles.includes(role))
		// 	allowedRoles.some(role => userRoles.includes(role))

		const exactMatch = allowedRoles.some(role => userRoles.includes(role))

		if (exactMatch) {
			return <>{children}</>
		}
	} else {
		if (canSee(allowedRoles, user, hideIfReadOnly)) {
			return <>{children}</>
		}
	}

	return null
}

export default Role
