import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'

export const Resource = ({ title, url }: { title: string; url: string }) => {
	return (
		<div className="flex items-center justify-between gap-4 rounded bg-neutral-2-bg px-3 py-2 text-body-md font-medium">
			{title}
			<Button asChild size="sm" variant="ghost" className="cursor-pointer gap-1">
				<a href={url} target="__blank">
					View <Icon name="launch" size="sm" />
				</a>
			</Button>
		</div>
	)
}
