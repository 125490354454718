// This file is generated by npm run build:icons

import { type IconName } from './name'

export const iconNames = [
	'add-alt',
	'add-filled',
	'add',
	'ai-settings',
	'arrow-down',
	'arrow-left',
	'arrow-right',
	'arrow-up-right',
	'arrow-up',
	'book',
	'boolean',
	'bullhorn',
	'calendar-add',
	'carret-down',
	'category-new',
	'category',
	'character-whole-number',
	'chart-column',
	'chat',
	'check',
	'checkbox-checked-filled',
	'checkbox-checked',
	'checkbox-indeterminate-filled',
	'checkbox-indeterminate',
	'checkbox-unchecked',
	'checkmark-filled-bordered',
	'checkmark-filled-warning',
	'checkmark-filled',
	'checkmark-outline-warning',
	'checkmark-outline',
	'chevron-down',
	'chevron-right',
	'chevron-sort-asc',
	'chevron-sort-desc',
	'chevron-sort',
	'circle-dash',
	'circle-fill',
	'close-filled',
	'continue',
	'conversation',
	'copy-file',
	'copy',
	'cross-1-filled',
	'cross-1',
	'cross-outline',
	'data-collection',
	'data-enrichment',
	'data-unreal',
	'data-unstructured',
	'decision-tree-multiple',
	'decision-tree',
	'distribution-ledger',
	'document',
	'dollar',
	'download',
	'drag-vertical',
	'edit',
	'edited',
	'education',
	'email',
	'enterprise',
	'error-filled',
	'error',
	'filter',
	'group',
	'hearing',
	'help-filled',
	'home',
	'ibm-cloud-pak',
	'incomplete',
	'information-filled',
	'information',
	'launch',
	'list-checked',
	'loading-lg',
	'loading-sm',
	'location',
	'logout',
	'machine-learning-model',
	'magic-wand',
	'menu',
	'microphone-filled',
	'minus',
	'notebook-reference',
	'or-operation',
	'overflow-menu-horizontal',
	'pan-horizontal',
	'pause-filled',
	'pencil-1',
	'play-filled-alt',
	'portfolio',
	'query',
	'report-data',
	'request-quote',
	'reset',
	'restart',
	'rotate',
	'search-dialogue',
	'search',
	'send',
	'settings-adjust',
	'settings-services',
	'settings',
	'side-panel-close',
	'side-panel-open',
	'spinner-lg',
	'stop-filled-alt',
	'stop-sign-filled',
	'stop-sign-outline',
	'subtract-alt',
	'subtract-filled',
	'text-creation',
	'time-plot',
	'trash',
	'trophy',
	'update',
	'upload-thin',
	'upload',
	'upstream',
	'user-avatar',
	'user-certification',
	'user-check',
	'user-follow',
	'user-multiple',
	'user-remove',
	'user-service-desk',
	'user-settings',
	'user',
	'voice-activate',
	'warning-alt-filled',
	'warning-alt',
	'white-paper',
] satisfies IconName[]
