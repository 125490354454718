import { useCallback, useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'

type UseVerticalLoaderType = {
	ecosystems: z.infer<typeof EcosystemAPISchema>[]
}

export const useVertical = () => {
	const loaderData = useLoaderData() as UseVerticalLoaderType | undefined
	const ecosystems = useMemo(() => loaderData?.ecosystems || [], [loaderData])

	const getVerticalsOptions = useCallback(
		(ecosystemId: string) => {
			const ecosystem = ecosystems.find(item => String(item.id) === ecosystemId)

			if (!ecosystem) return []

			return (
				ecosystem.verticals.map(({ id, name, personas }) => ({
					value: id.toString(),
					label: `${name} (${personas.length} personas)`,
				})) ?? []
			)
		},
		[ecosystems],
	)

	return { getVerticalsOptions }
}
