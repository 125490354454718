import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { type ConversationSchema } from '#src/routes/enable/chat/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/copilot/conversation`

const endpoints = {
	settings: {
		get: (companyId: z.infer<typeof CompanySchema>['id'], conversationId: z.infer<typeof ConversationSchema>['id']) =>
			`${base(companyId)}/${conversationId}/settings`,
		update: (
			companyId: z.infer<typeof CompanySchema>['id'],
			conversationId: z.infer<typeof ConversationSchema>['id'],
		) => `${base(companyId)}/${conversationId}/settings`,
	},
}

export { endpoints as companyCopilotConversationEndpoints }
