import { useFetcher } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const NoFilterResults = () => {
	const { companyId } = useParsedRouteParams(['companyId'])
	const filtersFetcher = useFetcher()
	return (
		<div className="mx-20 w-full">
			<EmptyStateCard
				icon="search"
				title="No results match"
				actions={[
					{
						title: 'Clear all filters',
						noIcon: true,
						onClick: () =>
							filtersFetcher.submit(
								{ intent: 'clear-all' },
								{
									method: 'POST',
									action: routes.prioritize.filters({ companyId }),
								},
							),
					},
				]}
			/>
		</div>
	)
}
