import { type z } from 'zod'
import { type SettingsCopilotFormSchema } from '#src/api/icp/company/settings/copilot/schemas'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { type ConversationSchema } from '#src/routes/enable/chat/schema'
import { fetch } from '#src/utils/safeFetch'
import { companyCopilotConversationEndpoints } from './endpoints'
import { companyCopilotConversationQueriesKeys } from './queries'

const mutations = {
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		conversationId: z.infer<typeof ConversationSchema>['id'],
		data: z.infer<typeof SettingsCopilotFormSchema>,
	) => {
		await fetch(companyCopilotConversationEndpoints.settings.update(companyId, conversationId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: companyCopilotConversationQueriesKeys.settings(companyId, conversationId),
		})
	},
}

export { mutations as companyCopilotConversationMutations }
