import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type ActionFunctionArgs, redirect, useFetcher, useNavigate } from 'react-router-dom'
import { Button } from '#src/components/ui/button.js'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { StatusButton } from '#src/components/ui/status-button.js'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { useIsPending, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { endRoleplaySession } from './mutations'
import { roleplaySessionKeys } from './queries'

export type EndRoleplayActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	await endRoleplaySession(company.id, params.conversationId)

	await client.invalidateQueries({
		queryKey: roleplaySessionKeys.all,
	})

	return redirect(
		routes.enable.roleplay.session.review({
			companyId,
			conversationId: params.conversationId,
		}),
	)
}

export default function EndRoleplayChat() {
	const fetcher = useFetcher()
	const { companyId } = useCompany()
	const params = useParsedRouteParams(['conversationId'])
	const navigate = useNavigate()
	const action = routes.enable.roleplay.session.end({
		companyId,
		conversationId: params.conversationId,
	})
	const isPending = useIsPending({
		formAction: action,
		formMethod: 'PUT',
	})

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen => {
				if (!isOpen && !isPending) {
					navigate(
						routes.enable.roleplay.session.index({
							companyId,
							conversationId: params.conversationId,
						}),
					)
				}
			}}
		>
			<DialogContent className="w-full max-w-lg" closeClassName={isPending ? 'opacity-50 cursor-default' : ''}>
				<div className="flex w-full max-w-xl flex-col gap-4">
					<h1 className="text-title-lg text-neutral-1-fg">End session</h1>
					<p className="text-body-lg text-neutral-2-fg">Are you sure you want to end this session?</p>
				</div>
				<div className="flex w-full items-center justify-end gap-2 pt-8">
					<DialogPrimitive.Close asChild>
						<Button type="button" variant="outline" className="min-w-24">
							Cancel
						</Button>
					</DialogPrimitive.Close>
					<fetcher.Form action={action} method="DELETE">
						<StatusButton
							status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
							type="submit"
							disabled={fetcher.state !== 'idle'}
							className="min-w-24"
						>
							Yes, end
						</StatusButton>
					</fetcher.Form>
				</div>
			</DialogContent>
		</DialogRoot>
	)
}
