import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { type CriteriaEditAnswersSchema } from '#src/routes/calibrate/verticals/criteria/routes/editAnswers'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { unassignCriterion } from '../mutations'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient, type: z.infer<typeof CriteriaEditAnswersSchema>['values']['type']) =>
	async ({ params }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId', 'criterionId'])

		try {
			await unassignCriterion(params.companyId, params.ecosystemId, params.criterionId)

			await Promise.all([
				queryClient.invalidateQueries({ queryKey: criteriaKeys.all }),
				queryClient.invalidateQueries({ queryKey: ecosystemKeys.all }),
				queryClient.invalidateQueries({ queryKey: verticalKeys.all }),
			])
		} catch (e) {
			captureException(e)

			showToast({
				message: 'Unexpected error while unassigning criterion',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.criteria.editAnswers({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				criterionType: type,
			}),
		)
	}
