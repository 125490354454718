import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import Markdown from '#src/components/markdown'
import { FullPageAccordion } from '#src/components/ui/FullPageAccordion'
import { InlineError } from '#src/components/ui/InlineError'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { TabSwitcher } from '#src/components/ui/tabs'
import { PERSONA_DETAILS_REFRESH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'
import { renderCriterionAnswerValue } from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalQuery } from '#src/routes/calibrate/verticals/queries'
import { type PersonasDetailsLoaderResType } from '../../../routes/details'
import { PersonaCriteriaAside, PersonaAIGeneratingBody, PersonaMappingNavigator, PersonaSalesCoachSection } from './'

type PersonasDetailsBodyProps = {
	persona: z.infer<typeof PersonaAPISchema>
}

export const PersonasDetailsBody = ({ persona }: PersonasDetailsBodyProps) => {
	const { ecosystemId, verticalId } = useLoaderData() as PersonasDetailsLoaderResType
	const { company } = useCompany()

	const { data: vertical } = useQuery({
		...verticalQuery(company.id, String(ecosystemId), verticalId),
		refetchInterval: ({ state: { data } }) =>
			data?.prompt?.status && ['Pending', 'In Progress'].includes(data.prompt.status)
				? PERSONA_DETAILS_REFRESH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
		throwOnError: true,
	})

	const isVerticalPromptGenerating = vertical?.prompt?.status !== 'Completed' && vertical?.prompt?.status !== 'Crashed'
	const hasVerticalPromptCrashed = vertical?.prompt?.status === 'Crashed'

	const isPersonaPromptGenerating = persona?.prompt?.status !== 'Completed' && persona?.prompt?.status !== 'Crashed'
	const hasPersonaPromptCrashed = persona?.prompt?.status === 'Crashed'

	const criteriaList = useMemo(() => {
		const filtered = vertical?.answers?.filter(item => !item.isEnrichment)

		return (
			filtered?.map(item => ({
				value: renderCriterionAnswerValue(item),
				label: <div className="flex items-center gap-1">{item.question}</div>,
			})) || []
		)
	}, [vertical?.answers])

	const enrichmentCriteriaList = useMemo(() => {
		const filtered = vertical?.answers?.filter(item => item.isEnrichment)

		return (
			filtered?.map(item => ({
				value: renderCriterionAnswerValue(item),
				label: <div className="flex items-center gap-1">{item.question}</div>,
			})) || []
		)
	}, [vertical?.answers])

	const personaMappingItems = useMemo(
		() => [
			{
				id: 'responsibilities',
				heading: 'Responsibilities',
				value: persona.personaMapping?.responsibilities,
			},
			{ id: 'kpis', heading: 'KPIs', value: persona.personaMapping?.kpis },
			{
				id: 'frustrations',
				heading: 'Frustrations',
				value: persona.personaMapping?.frustrations,
			},
			{
				id: 'value-props',
				heading: 'Value Propositions',
				value: persona.personaMapping?.valuePropositions,
			},
			{
				id: 'objections-concerns',
				heading: 'Objections/Concerns',
				value: persona.personaMapping?.objectionsConcerns,
			},
			{
				id: 'objection-addressing',
				heading: 'Objection Addressing',
				value: persona.personaMapping?.objectionAddressing,
			},
			{
				id: 'buyer-journey-info',
				heading: 'Buyer Journey Info',
				value: persona.personaMapping?.buyerJourneyInfo,
			},
			{
				id: 'buyer-journey-use-cases',
				heading: 'Buyer Journey Use Cases',
				value: persona.personaMapping?.buyerJourneyUseCases,
			},
			{
				id: 'gain-information-from',
				heading: 'Gain Information From',
				value: persona.personaMapping?.gainInformationFrom,
			},
		],
		[persona.personaMapping],
	)

	const renderCompanyProfileContent = () => (
		<section className="grid grid-cols-3 gap-8">
			<div className="col-span-2 flex flex-col gap-6">
				<h3 className="text-title-md">Business model description</h3>
				{hasVerticalPromptCrashed ? (
					<InlineError textVariant="output" size="md" />
				) : isVerticalPromptGenerating ? (
					<InlineSpinner />
				) : (
					<Markdown className="text-neutral-2-fg">{vertical?.bizDescription}</Markdown>
				)}
			</div>
			{(!!criteriaList.length || !!enrichmentCriteriaList.length) && (
				<PersonaCriteriaAside
					sections={[
						...(criteriaList.length ? [{ title: 'Qualification Criteria', items: criteriaList }] : []),
						...(enrichmentCriteriaList.length ? [{ title: 'Enrichment Criteria', items: enrichmentCriteriaList }] : []),
					]}
				/>
			)}
		</section>
	)

	const renderPersonaInfoTabs = () => (
		<TabSwitcher
			tabs={
				[
					{
						label: 'Summary',
						value: 'persona-summary',
						content: (
							<section className="mt-4">
								{isPersonaPromptGenerating ? (
									<InlineSpinner textVariant="output" />
								) : persona?.summary ? (
									<Markdown>{persona.summary}</Markdown>
								) : (
									''
								)}
							</section>
						),
					},
					{
						label: 'Persona mapping',
						value: 'persona-mapping',
						content: (
							<section className="mt-4 grid grid-cols-3 gap-8">
								<div className="col-span-2 flex flex-col gap-6">
									<PersonaAIGeneratingBody
										isGenerating={isPersonaPromptGenerating}
										hasCrashed={hasPersonaPromptCrashed}
										items={personaMappingItems}
									/>
								</div>
								<PersonaMappingNavigator items={personaMappingItems} />
							</section>
						),
					},
					{
						label: 'Pain points',
						value: 'persona-pain-points',
						content: (
							<section className="mt-4">
								{!isVerticalPromptGenerating && persona?.pain ? (
									<Markdown>{persona.pain}</Markdown>
								) : (
									<InlineSpinner textVariant="output" />
								)}
							</section>
						),
					},
					{
						label: 'Sales coach',
						value: 'persona-sales-coach',
						content: (
							<section className="mt-4">
								<PersonaSalesCoachSection persona={persona} />
							</section>
						),
					},
					{
						label: 'Boolean eg. for LinkedIn Sales Nav.',
						value: 'persona-mapping.boolean-linkedin',
						content: (
							<section className="mt-4">
								{!isPersonaPromptGenerating ? (
									<Markdown>{persona.personaMapping?.boolLinkedin}</Markdown>
								) : (
									<InlineSpinner textVariant="output" />
								)}
							</section>
						),
					},
				] as const
			}
		/>
	)

	return (
		<FullPageAccordion
			defaultExpandedSections={['2']}
			sections={[
				{
					id: '1',
					header: 'Company profile',
					content: renderCompanyProfileContent(),
				},
				{
					id: '2',
					header: 'Persona info',
					content: renderPersonaInfoTabs(),
				},
			]}
		/>
	)
}
