// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import { Icon } from '#src/components/ui/icon'
import { Status } from '#src/routes/enable/roleplay/schema'
import { cn } from '#src/utils/misc'

// TODO: This component was created by copying current reused logic and needs improvements in the future.

type StatusDisplayProps = {
	status: string
	evaluationStatus?: string | null
}

const StatusDisplay = ({ status, evaluationStatus }: StatusDisplayProps) => {
	const displayStatus = ['Pending', 'Evaluating'].includes(evaluationStatus ?? '')
		? 'Evaluating'
		: status === Status.Closed
			? 'Completed'
			: status === Status.Ready
				? 'Ready'
				: 'Initializing'

	const statusColorClass = ['Pending', 'Evaluating'].includes(evaluationStatus ?? '')
		? 'text-yellow-70'
		: status === Status.Closed
			? 'text-green-70'
			: status === Status.Ready
				? 'text-orange-70'
				: 'text-blue-60'

	return (
		<span
			className={cn(
				'flex flex-nowrap items-center gap-1 py-0.5 pl-0.5 pr-2 text-label-md transition-colors',
				statusColorClass,
			)}
		>
			<Icon name="circle-fill" size="sm" />
			{displayStatus}
		</span>
	)
}

export default StatusDisplay
