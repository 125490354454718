import { type SVGProps } from 'react'
import { cn } from '#src/utils/misc'

const sizeClassName = {
	font: 'h-[1em]',
	xs: 'h-6',
	sm: 'h-8',
	md: 'h-12',
	lg: 'h-16',
	xl: 'h-20',
} as const

type Size = keyof typeof sizeClassName

export function Logo({
	className = '',
	type = 'full',
	size = 'font',
}: SVGProps<SVGSVGElement> & {
	className?: string
	type?: 'full' | 'symbol'
	size?: Size
}) {
	if (type === 'full')
		return (
			<svg
				viewBox="0 0 146 25"
				xmlns="http://www.w3.org/2000/svg"
				className={cn(sizeClassName[size], 'inline self-center', className)}
			>
				<path
					d="M63.9203 7.55924C63.0973 7.09166 62.1846 6.84229 61.1821 6.84229C60.1497 6.84229 59.2071 7.09166 58.3542 7.59042C57.5013 8.08917 56.828 8.79054 56.3342 9.67894C55.8405 10.5673 55.6011 11.5804 55.6011 12.687C55.6011 13.8092 55.8554 14.8379 56.3342 15.7263C56.828 16.6147 57.5013 17.3161 58.3542 17.8149C59.2071 18.3136 60.1497 18.563 61.1821 18.563C62.0649 18.563 62.843 18.4071 63.5163 18.111C64.1896 17.8149 64.7582 17.394 65.2071 16.8797C65.6559 16.3654 66.015 15.7887 66.2395 15.1341H63.9203C63.6659 15.5861 63.2918 15.9445 62.828 16.2251C62.3642 16.5056 61.7956 16.6459 61.1672 16.6459C60.5986 16.6459 60.0599 16.5212 59.5811 16.2718C59.1023 16.0225 58.6834 15.6484 58.3692 15.1808C58.0549 14.7132 57.8604 14.1677 57.8006 13.5755V13.5599H66.434C66.4639 13.4663 66.4938 13.3105 66.5088 13.1079C66.5238 12.8897 66.5387 12.6403 66.5387 12.3753C66.5387 11.3311 66.2993 10.3803 65.8355 9.55425C65.3866 8.69702 64.7432 8.04241 63.9203 7.55924ZM57.8305 11.8298C57.9053 11.2375 58.0998 10.7076 58.3991 10.2556C58.6983 9.78804 59.0874 9.42957 59.5662 9.1646C60.045 8.89964 60.5836 8.77495 61.1821 8.77495C61.7657 8.77495 62.2894 8.89964 62.7532 9.1646C63.2021 9.42957 63.5911 9.80363 63.8754 10.2712C64.1597 10.7388 64.3243 11.2687 64.3692 11.8298V11.8454H57.8305V11.8298Z"
					fill="currentColor"
				/>
				<path
					d="M71.7007 7.34103C71.3865 7.51248 71.1022 7.77744 70.8778 8.10475H70.7581L70.5336 7.07607H68.6184V18.3292H70.8329V11.5025C70.8329 10.7388 71.0423 10.1465 71.4463 9.74128C71.8503 9.33605 72.4339 9.13343 73.182 9.13343H74.9775V7.06049H72.9875C72.4339 7.07607 72.0149 7.16959 71.7007 7.34103Z"
					fill="currentColor"
				/>
				<path
					d="M92.4238 7.34103C92.1096 7.51248 91.8253 7.77744 91.6009 8.10475H91.4812L91.2568 7.07607H89.3416V18.3292H91.556V11.5025C91.556 10.7388 91.7655 10.1465 92.1695 9.74128C92.5735 9.33605 93.157 9.13343 93.9051 9.13343H95.7007V7.06049H93.7106C93.172 7.07607 92.7381 7.16959 92.4238 7.34103Z"
					fill="currentColor"
				/>
				<path
					d="M84.2993 8.38528C83.9103 7.9177 83.4165 7.52805 82.818 7.26309C82.2195 6.98254 81.5462 6.85785 80.813 6.85785C79.8554 6.85785 78.9577 7.09164 78.1646 7.54364C77.3716 7.99563 76.7432 8.65025 76.2793 9.46072C75.8155 10.2868 75.5911 11.2219 75.5911 12.2818C75.5911 13.3261 75.8305 14.2768 76.2793 15.1029C76.7432 15.9289 77.3716 16.568 78.1646 17.0199C78.9577 17.4719 79.8404 17.7057 80.813 17.7057C81.5312 17.7057 82.1746 17.581 82.7582 17.3317C83.3417 17.0823 83.8205 16.755 84.1796 16.3497H84.3292V18.0486C84.3292 18.9059 84.0599 19.5916 83.5063 20.0904C82.9676 20.5891 82.2345 20.8385 81.3517 20.8385C80.7382 20.8385 80.2295 20.745 79.8105 20.5736C79.3916 20.4021 79.0773 20.1527 78.8529 19.8722C78.6285 19.5916 78.4938 19.2799 78.434 18.9682H76.01C76.0399 19.6072 76.2644 20.2307 76.6684 20.8385C77.0724 21.4464 77.6709 21.9607 78.4489 22.366C79.227 22.7712 80.1995 22.9738 81.3367 22.9738C82.4439 22.9738 83.4165 22.7712 84.2245 22.366C85.0325 21.9607 85.6609 21.384 86.0798 20.6515C86.5138 19.9189 86.7232 19.0617 86.7232 18.0798V7.07606H84.6584L84.434 8.38528H84.2993ZM83.9103 13.9807C83.6409 14.4794 83.2519 14.8691 82.7731 15.1496C82.2943 15.4302 81.7407 15.5704 81.1422 15.5704C80.5586 15.5704 80.02 15.4302 79.5412 15.1496C79.0624 14.8691 78.6734 14.4794 78.3891 13.9807C78.1048 13.4819 77.9701 12.9052 77.9701 12.2662C77.9701 11.6272 78.1197 11.0505 78.3891 10.5517C78.6734 10.053 79.0624 9.66334 79.5412 9.38279C80.02 9.10224 80.5586 8.96196 81.1422 8.96196C81.7556 8.96196 82.2943 9.10224 82.7731 9.38279C83.2519 9.66334 83.626 10.053 83.9103 10.5517C84.1796 11.0505 84.3292 11.6272 84.3292 12.2662C84.3292 12.9052 84.1796 13.4819 83.9103 13.9807Z"
					fill="currentColor"
				/>
				<path
					d="M40.7133 7.55924C39.8904 7.09166 38.9777 6.84229 37.9752 6.84229C36.9427 6.84229 36.0001 7.09166 35.1472 7.59042C34.2944 8.08917 33.621 8.79054 33.1273 9.67894C32.6335 10.5673 32.3792 11.5804 32.3792 12.687C32.3792 13.8092 32.6335 14.8379 33.1273 15.7263C33.621 16.6147 34.2944 17.3161 35.1472 17.8149C36.0001 18.3136 36.9427 18.563 37.9752 18.563C38.858 18.563 39.636 18.4071 40.3093 18.111C40.9826 17.8149 41.5512 17.394 42.0001 16.8797C42.449 16.3654 42.8081 15.7887 43.0325 15.1341H40.7133C40.4589 15.5861 40.0849 15.9445 39.621 16.2251C39.1572 16.5056 38.5886 16.6459 37.9602 16.6459C37.3916 16.6459 36.853 16.5212 36.3742 16.2718C35.8954 16.0225 35.4764 15.6484 35.1622 15.1808C34.848 14.7132 34.6535 14.1677 34.5936 13.5755V13.5599H43.227C43.257 13.4663 43.2869 13.3105 43.3018 13.1079C43.3168 12.8897 43.3318 12.6403 43.3318 12.3753C43.3318 11.3311 43.0924 10.3803 42.6285 9.55425C42.1796 8.69702 41.5363 8.04241 40.7133 7.55924ZM34.6235 11.8298C34.6984 11.2375 34.8929 10.7076 35.1921 10.2556C35.4914 9.78804 35.8804 9.42957 36.3592 9.1646C36.838 8.89964 37.3767 8.77495 37.9752 8.77495C38.5587 8.77495 39.0824 8.89964 39.5462 9.1646C39.9951 9.42957 40.3841 9.80363 40.6684 10.2712C40.9527 10.7388 41.1173 11.2687 41.1622 11.8298V11.8454H34.6235V11.8298Z"
					fill="currentColor"
				/>
				<path
					d="M49.2419 15.8042L46.1896 7.07605H43.8704L47.8504 18.3292H51.1123L55.0923 7.07605H52.7432L49.7058 15.8042H49.2419Z"
					fill="currentColor"
				/>
				<path
					d="M130.968 3.19513L128.738 4.90959V7.07605H126.808V9.13341H128.738V16.0224C128.738 16.7394 128.963 17.3005 129.382 17.7057C129.816 18.111 130.414 18.3136 131.177 18.3136H133.556V16.2874H131.671C131.432 16.2874 131.252 16.2251 131.132 16.1004C131.013 15.9757 130.968 15.8042 130.968 15.5549V9.13341H133.556V7.06047H130.968V3.19513Z"
					fill="currentColor"
				/>
				<path
					d="M120.419 15.5237V15.5081L118.204 7.07605H115.287L113.087 15.5237H112.623V15.5081L110.424 7.07605H108.105L111.082 18.3292H114.404L116.529 10.1309H116.993L119.102 18.3292H122.424L124.863 9.14899L125.387 7.07605H123.067L120.883 15.5237H120.419Z"
					fill="currentColor"
				/>
				<path
					d="M104.783 7.59042C103.93 7.09166 102.972 6.84229 101.925 6.84229C100.878 6.84229 99.9201 7.09166 99.0673 7.59042C98.2144 8.08917 97.5411 8.79054 97.0623 9.67894C96.5835 10.5673 96.3291 11.5804 96.3291 12.687C96.3291 13.8092 96.5685 14.8379 97.0623 15.7263C97.5411 16.6147 98.2144 17.3161 99.0673 17.8149C99.9201 18.3136 100.878 18.563 101.925 18.563C102.972 18.563 103.93 18.3136 104.783 17.8149C105.636 17.3161 106.309 16.6147 106.788 15.7263C107.267 14.8379 107.521 13.8248 107.521 12.687C107.521 11.5804 107.282 10.5673 106.788 9.67894C106.309 8.79054 105.636 8.08917 104.783 7.59042ZM104.843 14.6821C104.544 15.2587 104.14 15.7107 103.631 16.0225C103.122 16.3342 102.554 16.49 101.94 16.49C101.327 16.49 100.758 16.3342 100.249 16.0225C99.7406 15.7107 99.3366 15.2587 99.0373 14.6821C98.7381 14.1054 98.5885 13.4352 98.5885 12.7026C98.5885 11.9701 98.7381 11.2999 99.0373 10.7232C99.3366 10.1465 99.7406 9.69453 100.249 9.38281C100.758 9.07109 101.327 8.91523 101.94 8.91523C102.554 8.91523 103.122 9.07109 103.631 9.38281C104.14 9.69453 104.544 10.1309 104.843 10.7232C105.142 11.2999 105.292 11.9701 105.292 12.7026C105.292 13.4352 105.142 14.1054 104.843 14.6821Z"
					fill="currentColor"
				/>
				<path
					d="M143.476 7.43452C142.803 7.04487 142.025 6.84225 141.172 6.84225C140.439 6.84225 139.781 6.98252 139.212 7.26307C138.643 7.54362 138.21 7.85534 137.925 8.19823H137.806V2.04175L135.591 3.74062V18.3292H137.806V11.7518C137.806 11.2375 137.94 10.7699 138.195 10.3491C138.449 9.92828 138.808 9.58539 139.242 9.32043C139.676 9.07105 140.185 8.93078 140.723 8.93078C141.232 8.93078 141.696 9.05546 142.115 9.30484C142.519 9.55422 142.863 9.89711 143.102 10.3179C143.342 10.7388 143.461 11.2219 143.461 11.7518V18.3292H145.691V11.5648C145.691 10.6452 145.496 9.81918 145.107 9.10222C144.688 8.40085 144.15 7.83975 143.476 7.43452Z"
					fill="currentColor"
				/>
				<path
					d="M12 0C5.38653 0 0 5.61097 0 12.5C0 19.389 5.38653 25 12 25C14.409 25 16.7282 24.2675 18.7032 22.8647C19.6608 22.1945 20.5137 21.384 21.2618 20.4489C21.995 19.5137 22.6085 18.4695 23.0574 17.3628L21.8604 16.116L18.0599 12.1571L16.9077 13.3572L13.3017 9.601V4.6914H12.5536V5.09664H9.27681L10.5187 6.39027L9.29177 7.66833H12.5686V9.53866L9.14214 13.1079L5.8803 9.7101L1.66085 14.1054V14.0898C1.58603 13.5599 1.55611 13.0299 1.55611 12.5C1.55611 6.49938 6.25437 1.60536 12.015 1.60536C17.7756 1.60536 22.4738 6.49938 22.4738 12.5C22.4738 13.0299 22.4289 13.5599 22.3541 14.0898L23.6708 15.4613C23.8953 14.495 24.015 13.4975 24.015 12.5C24 5.61097 18.6284 0 12 0ZM18.0748 14.4327L21.202 17.6901C19.3616 21.2126 15.8454 23.3946 12.015 23.3946C11.2519 23.3946 10.4888 23.3011 9.74065 23.1297H9.72569L18.0748 14.4327ZM12.8978 11.4402L15.8304 14.495L8.06484 22.5842C6.74813 22.0231 5.56609 21.197 4.56359 20.1372L12.8978 11.4402ZM5.86534 11.9701L8.03491 14.2301L3.5611 18.8903C2.91771 17.9863 2.42394 16.9888 2.09476 15.9133L5.86534 11.9701Z"
					fill="currentColor"
				/>
			</svg>
		)

	return (
		<svg
			fill="none"
			viewBox="0 0 97 96"
			xmlns="http://www.w3.org/2000/svg"
			className={cn(sizeClassName[size], 'inline self-center', className)}
		>
			<path
				fill="currentColor"
				d="M48.5 0C22.0461 0 0.5 21.5461 0.5 48C0.5 74.4539 22.0461 96 48.5 96C58.1359 96 67.4127 93.187 75.313 87.8005C79.1434 85.2269 82.5549 82.1147 85.5474 78.5237C88.4801 74.9327 90.9339 70.9227 92.7294 66.6733L87.9414 61.8853L72.7394 46.6833L68.1309 51.2918L53.707 36.8678V18.015H50.7145V19.5711H37.6072L42.5748 24.5387L37.6671 29.4464H50.7743V36.6284L37.0686 50.3342L24.0212 37.2868L7.14339 54.1646V54.1047C6.84414 52.0698 6.72444 50.0349 6.72444 48C6.72444 24.9576 25.5175 6.16459 48.5599 6.16459C71.6023 6.16459 90.3953 24.9576 90.3953 48C90.3953 50.0349 90.2157 52.0698 89.9165 54.1047L95.1833 59.3716C96.0811 55.6609 96.5599 51.8304 96.5599 48C96.5 21.5461 75.0137 0 48.5 0ZM72.7993 55.4215L85.308 67.9302C77.9464 81.4564 63.8816 89.8354 48.5599 89.8354C45.5075 89.8354 42.4551 89.4763 39.4626 88.818H39.4027L72.7993 55.4215ZM52.091 43.9302L63.8217 55.6609L32.7594 86.7232C27.4925 84.5686 22.7643 81.3965 18.7544 77.3267L52.091 43.9302ZM23.9613 45.9651L32.6397 54.6434L14.7444 72.5387C12.1708 69.0673 10.1958 65.2369 8.87905 61.1072L23.9613 45.9651Z"
			/>
		</svg>
	)
}
