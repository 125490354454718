import { useState, useMemo, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export type UsePaginatorType<T> = {
	currentPage: number
	setCurrentPage: (page: number) => void
	pagedData: T[]
	totalPages: number
}

type UsePaginatorOptions<T> = {
	data?: T[] | null
	itemsPerPage?: number
	totalItems?: number
	includeQueryParam?: boolean
}

/**
 * A hook that handles pagination for both client-side and server-side data.
 * Provides the current page, a method to set the current page, and the paged data.
 *
 * For client-side pagination, pass the data array and optionally specify the number of items per page.
 * For server-side pagination, provide `totalItems` to control the total number of pages.
 *
 * @param {UsePaginatorOptions<T>} options - Optional parameters to configure pagination.
 * @param {T[]} [options.data] - The data array for client-side pagination.
 * @param {number} [options.itemsPerPage=10] - Number of items to display per page.
 * @param {number} [options.totalItems] - Total number of items (used for server-side pagination).
 * @param {number} [options.totalItems] - Includes ?page=1 query parameter.
 *
 * @returns {UsePaginatorType<T>} An object containing current page, a function to set the current page, paginated data, and total pages.
 */
const usePaginator = <T,>(options: UsePaginatorOptions<T> = {}): UsePaginatorType<T> => {
	const { data = [], itemsPerPage = 10, totalItems, includeQueryParam = true } = options
	const [searchParams, setSearchParams] = useSearchParams()
	const initialPage = Number(searchParams.get('page')) || 0
	const [currentPage, setCurrentPageState] = useState<number>(initialPage)

	useEffect(() => {
		if (includeQueryParam) {
			const pageFromUrl = searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0

			if (currentPage !== pageFromUrl) {
				setCurrentPageState(pageFromUrl)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, includeQueryParam])

	const setCurrentPage = useCallback(
		(page: number): void => {
			setCurrentPageState(page)

			if (includeQueryParam) {
				setSearchParams({ page: (page + 1).toString() })
			}
		},
		[setSearchParams, includeQueryParam],
	)

	const totalPages = useMemo((): number => {
		if (!data && totalPages) {
			return totalPages
		}

		if (!data) return 0

		const itemsCount = totalItems ?? data.length
		return Math.ceil(itemsCount / itemsPerPage)
	}, [data, itemsPerPage, totalItems])

	const pagedData = useMemo((): T[] => {
		if (!data) return []

		const startIndex = currentPage * itemsPerPage
		return data.slice(startIndex, startIndex + itemsPerPage)
	}, [currentPage, data, itemsPerPage])

	return {
		currentPage: currentPage + 1,
		setCurrentPage,
		pagedData,
		totalPages,
	}
}

export default usePaginator
