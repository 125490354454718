import { type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { assignedUsersQuery } from '#src/routes/enable/roleplay/scenario/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type InviteParticipantsLoaderResType = Awaited<ReturnType<typeof inviteParticipantsLoader>>

export const inviteParticipantsLoader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const members = await client.fetchQuery(assignedUsersQuery(company.id, params.scenarioId))

	return {
		members,
		scenarioId: params.scenarioId,
	}
}
