import { type FormOptions } from '@conform-to/dom'
import { getFormProps, useForm, type FormMetadata } from '@conform-to/react'
import { type ReactNode, useEffect } from 'react'
import { Form } from 'react-router-dom'
import { WizardFooter } from './WizardFooter'
import { WizardHeader } from './WizardHeader'

export type WizardStep<Schema extends Record<string, unknown>, FormError = string[]> = {
	label: string
	value: string
	isFinished: boolean
	component: (fields: FieldsetType<Schema, FormError>) => ReactNode
}

export type FieldsetType<Schema extends Record<string, unknown>, FormError = string[]> = ReturnType<
	FormMetadata<Schema, FormError>['getFieldset']
>

type WizardProps<Schema extends Record<string, unknown>, FormValue = Schema, FormError = string[]> = {
	steps: WizardStep<Schema, FormError>[]
	title: string
	onFinish?: () => void
	onNext?: (currentStep: string) => boolean
	onPrevious?: (currentStep: string) => boolean
	onTabClick?: (value: string) => void
	onExitClick?: () => void
	exitRoute?: string
	exitTooltipText?: string
	activeStep: string
	setActiveStep: (step: string) => void
	formOptions: Omit<FormOptions<Schema, FormError, FormValue>, 'formId'>
	isLoading?: boolean
	disabled?: boolean
	finishBtnText?: string
}

export const Wizard = <Schema extends Record<string, unknown>, FormValue = Schema, FormError = string[]>({
	steps,
	title,
	onFinish,
	onNext,
	onPrevious,
	onTabClick,
	onExitClick,
	exitRoute,
	exitTooltipText,
	activeStep,
	setActiveStep,
	formOptions,
	isLoading,
	disabled,
	finishBtnText = 'Finish',
}: WizardProps<Schema, FormValue, FormError>) => {
	const currentStepIndex = Math.max(
		steps.findIndex(item => item.value === activeStep),
		0,
	)

	useEffect(() => {
		if (currentStepIndex === -1) {
			setActiveStep(steps[0].value)
		}
	}, [currentStepIndex, setActiveStep, steps])

	const [form, fields] = useForm({
		...formOptions,
	})

	const currentStepValue = steps[currentStepIndex].value

	const handleNextStep = () => {
		if (onNext) {
			onNext(steps[currentStepIndex + 1].value)
		}

		if (onNext && !onNext(currentStepValue)) return

		if (onFinish) {
			onFinish()
		}
	}

	const handlePreviousStep = () => {
		if (onPrevious && !onPrevious(currentStepValue)) return

		if (currentStepIndex > 0) {
			setActiveStep(steps[currentStepIndex - 1].value)
		}
	}

	const handleTabClick = (value: string) => {
		const stepIndex = steps.findIndex(step => step.value === value)

		if (stepIndex !== -1 && steps[stepIndex].isFinished) {
			setActiveStep(value)

			if (onTabClick) onTabClick(value)
		}

		if (!steps[currentStepIndex].isFinished) return
	}

	return (
		<div className="flex min-h-screen max-w-full flex-col bg-neutral-2-bg">
			<Form {...getFormProps(form)} method="POST" className="flex flex-grow flex-col">
				<WizardHeader
					steps={steps}
					title={title}
					activeStep={activeStep}
					onTabClick={handleTabClick}
					exitRoute={exitRoute}
					onExitClick={onExitClick}
					exitTooltipText={exitTooltipText}
					disabled={disabled}
				/>
				<div className="mx-auto w-full max-w-screen-md flex-grow px-20 py-12">
					<h3 className="mb-6 text-heading-sm text-neutral-1-fg">{steps[currentStepIndex].label}</h3>
					{steps[currentStepIndex].component(fields)}
				</div>
				<WizardFooter
					activeStep={currentStepIndex}
					totalSteps={steps.length}
					onPrevious={handlePreviousStep}
					onNext={handleNextStep}
					isLoading={isLoading}
					disabled={disabled}
					finishBtnText={finishBtnText}
				/>
				<input name="activeStep" type="hidden" value={activeStep} />
				{formOptions?.defaultValue && (
					<input type="hidden" name="fullFormData" value={JSON.stringify(formOptions.defaultValue)} />
				)}
			</Form>
		</div>
	)
}
