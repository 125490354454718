import { getFormProps, useForm } from '@conform-to/react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { useNavigate, useLoaderData, Form, Link } from 'react-router-dom'
import ContactAvatar from '#src/components/chat/avatar'
import { CheckboxField } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import useCompany from '#src/hooks/useCompany'
import { formatAvatarAbbr, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type InviteParticipantsLoaderResType } from '../invite'

export const InviteParticipantsModal = () => {
	const { members, scenarioId } = useLoaderData() as InviteParticipantsLoaderResType
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const areAllMembersAssigned = members.every(item => item.assigned)

	const method = 'PUT'
	const formId = 'invite-participant'

	const [form] = useForm({
		id: formId,
	})

	const action = routes.enable.roleplay.scenario.invite({
		companyId,
		scenarioId,
	})

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<DialogRoot defaultOpen={true} onOpenChange={isOpen => !isOpen && navigate(-1)}>
			<DialogContent dialogHeading="Invite Participants" className="w-full max-w-lg">
				{areAllMembersAssigned && (
					<Surface className="flex min-h-[160px] w-full max-w-[920px] flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg">
						<h1 className="text-center text-body-lg text-neutral-2-fg">You have no participants to invite</h1>
					</Surface>
				)}
				<Form method={method} {...getFormProps(form)} action={action}>
					<ScrollArea.Root className="mb-5 w-full">
						<ScrollArea.Viewport className="max-h-[400px] overflow-hidden pe-3">
							{members.map(
								item =>
									!item.assigned && (
										<div key={item.username} className="rounded p-2 hover:bg-neutral-1-bg-hover">
											<label htmlFor={item.username} className="cursor-pointer">
												<CheckboxField
													key={item.username}
													labelProps={{
														htmlFor: item.username,
														children: (
															<div className="flex cursor-pointer items-center gap-2 text-body-sm">
																<ContactAvatar
																	className="inline-flex shrink-0 items-center justify-center rounded-full"
																	variant="gray"
																	size="sm"
																	initial={formatAvatarAbbr(item.username)}
																/>
																<span className="text-body-sm text-neutral-1-fg">{item.username}</span>
															</div>
														),
													}}
													buttonProps={{
														id: item.username,
														name: 'members',
														value: item.id.toString(),
														form: formId,
													}}
												/>
											</label>
										</div>
									),
							)}
						</ScrollArea.Viewport>
						<ScrollArea.Scrollbar orientation="vertical" className="w-1.5">
							<ScrollArea.Thumb className="rounded-full bg-neutral-2-bd" />
						</ScrollArea.Scrollbar>
						<ScrollArea.Corner />
					</ScrollArea.Root>
					<div className="flex justify-end gap-2">
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.enable.roleplay.scenario.index({
									companyId,
									scenarioId,
								})}
							>
								Cancel
							</Link>
						</Button>
						{!areAllMembersAssigned && (
							<StatusButton
								status={isPending ? 'pending' : 'idle'}
								size="sm"
								type="submit"
								name="intent"
								value="persona"
								form={formId}
								disabled={isPending}
							>
								Invite
							</StatusButton>
						)}
					</div>
				</Form>
			</DialogContent>
		</DialogRoot>
	)
}
