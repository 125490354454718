import { client } from '#src/main'
import { companiesQuery } from '#src/routes/company/queries'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async () => {
	const companies = await client.fetchQuery(companiesQuery())

	return {
		data: {
			companies,
		},
	}
}
