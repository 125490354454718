import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id))

	if (!params.verticalId) {
		const firstVertical = ecosystems.find(eco => eco.verticals.length)?.verticals[0]
		if (firstVertical) {
			throw redirect(
				routes.enable.personas.index({
					companyId,
					verticalId: String(firstVertical.id),
				}),
			)
		}
	}

	return null
}
