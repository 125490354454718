import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { useParams, useRouteError } from 'react-router-dom'
import ErrorBoundary from '#src/components/error'
import { isRouteErrorResponse } from '#src/utils/misc'

function useRouteCaptureException() {
	const params = useParams()
	const error = useRouteError()

	useEffect(() => {
		if (error) {
			if (isRouteErrorResponse(error)) {
				captureException(new Error(error.data ? JSON.stringify(error.data) : error.statusText), scope => {
					if ([401, 403, 404, 429].includes(error.status)) {
						scope.setLevel('warning')
						scope.setFingerprint([error.status.toString(), error.data ? JSON.stringify(error.data) : error.statusText])
					} else if (error.status >= 400 && error.status < 500) {
						scope.setLevel('error')
					} else {
						scope.setLevel('fatal')
					}

					scope.setContext('Message', {
						messages: error.data ? JSON.stringify(error.data) : error.statusText ? error.statusText : 'No message',
					})

					return scope
				})
			}
		}
	}, [error, params])

	return null
}

export default function RouteBoundary() {
	const error = useRouteError()

	if (!import.meta.env.PROD) {
		console.error(error)
	}

	useRouteCaptureException()

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return (
				<ErrorBoundary
					title="Page Not Found"
					message="This page doesn't exist!"
					description="There's only one way back, and it's home."
					className="h-screen w-full"
				/>
			)
		} else if (error.status >= 400 && error.status !== 401 && error.status !== 403) {
			return (
				<ErrorBoundary
					title="Ouch!"
					message="Bad request!"
					description="We tried our best, but something went wrong. We will try to fix it ASAP!"
					className="h-screen w-full"
				/>
			)
		} else if (error.status >= 500) {
			return (
				<ErrorBoundary
					title="Ouch!"
					message="Looks like a problem on our end!"
					description="Our team is aware and working to fix the issue — apologies for any inconvenience."
					className="h-screen w-full"
				/>
			)
		}
	}

	throw error
}
