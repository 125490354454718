import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { buildQueryParams } from '#src/utils/misc'
import { type ValuePropositionItemAPISchema, type ValuePropositionListQuerySchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/value-proposition`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema>['id'],
		queryParams?: z.infer<typeof ValuePropositionListQuerySchema>,
	) => {
		const query = buildQueryParams(queryParams)
		return `${base(companyId)}${query}`
	},
	create: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof ValuePropositionItemAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	update: (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof ValuePropositionItemAPISchema>['id'],
	) => `${base(companyId)}/${itemId}`,
	delete: (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof ValuePropositionItemAPISchema>['id'],
	) => `${base(companyId)}/${itemId}`,
	publish: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/publish`,
}

export { endpoints as valuePropositionEndpoints }
