import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'

type DialogFooterWarningProps = {
	count: number
	entityName: string
	tooltipLabel: string
	message: string
}

export const DialogFooterWarning = ({ count, entityName, tooltipLabel, message }: DialogFooterWarningProps) => (
	<span className="flex items-center gap-1.5 text-body-sm text-neutral-3-fg">
		<Icon name="warning-alt" size="sm" className="text-status-warning-fg" />
		<span>
			<span className="font-semibold">{`${count} ${count === 1 ? entityName : `${entityName}s`}`}</span>
			{` ${message}`}
			<CustomTooltip label={tooltipLabel} side="bottom">
				<Icon name="information" size="sm" className="ml-1" />
			</CustomTooltip>
		</span>
	</span>
)
