import { type z } from 'zod'
import { client as queryClient } from '#src/main'
import { type FILTERS } from '#src/routes/prioritize/constants'
import { signalFilterKeys, sortKey } from '#src/routes/prioritize/queries'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import {
	AssignedSignalsAPISchema,
	type AssignedSignalsUpdateSchema,
	type HackSchema,
	type IntentSourceSchema,
	type IntentTypeSchema,
	type MappedPersonasSchema,
	type SignalConfigFormSchema,
	SignalTemplateAPISchema,
	SignalUsageExampleAPISchema,
	type SortSchema,
	type WeightSchema,
} from './schema'

export const updateSignal = async (companyId: string, signalId: string, data: z.infer<typeof SignalConfigFormSchema>) =>
	await safeFetch(SignalTemplateAPISchema, api.signal.config(companyId, signalId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})

export const updateAssignedSignals = async (
	companyId: string,
	signalId: string,
	data: z.infer<typeof AssignedSignalsUpdateSchema>,
) =>
	await safeFetch(AssignedSignalsAPISchema, api.signal.assign(companyId, signalId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})

export const signalUsageExampleMutation = async (companyId: string, personaId: string, signalId: string) =>
	await safeFetch(SignalUsageExampleAPISchema, api.signal.example(companyId, signalId, personaId), {
		method: 'PUT',
	})

export function filterMutation(
	key: FILTERS,
	data:
		| z.infer<typeof IntentTypeSchema>
		| z.infer<typeof IntentSourceSchema>
		| z.infer<typeof HackSchema>
		| z.infer<typeof WeightSchema>
		| z.infer<typeof MappedPersonasSchema>
		| null,
): unknown {
	return queryClient.setQueryData(signalFilterKeys.detail(key), data)
}

export function filterMutationIntentType(filter: FILTERS.TYPE, value: z.infer<typeof IntentTypeSchema> | null) {
	return filterMutation(filter, value)
}

export function filterMutationIntentSource(filter: FILTERS.SOURCE, value: z.infer<typeof IntentSourceSchema> | null) {
	return filterMutation(filter, value)
}

export function filterMutationHack(filter: FILTERS.HACK, value: z.infer<typeof HackSchema> | null) {
	return filterMutation(filter, value)
}

export function filterMutationWeight(filter: FILTERS.WEIGHT, value: z.infer<typeof WeightSchema> | null) {
	return filterMutation(filter, value)
}

export function filterMutationMappedPersonas(
	filter: FILTERS.MAPPED_PERSONAS,
	value: z.infer<typeof MappedPersonasSchema> | null,
) {
	return filterMutation(filter, value)
}

export function sortMutation(data: z.infer<typeof SortSchema> | null): unknown {
	return queryClient.setQueryData(sortKey.all, data)
}
