import { UserByTokenAPISchema, UserSchema } from '#src/routes/auth/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'

export const getUserByToken = async (token: string) => {
	return await safeFetch(UserByTokenAPISchema, api.user.token(token), {
		method: 'GET',
	})
}

export const userKeys = {
	all: ['user'] as const,
}

export const userQuery = () => ({
	queryKey: userKeys.all,
	queryFn: async () =>
		await safeFetch(UserSchema, api.user.me, {
			method: 'GET',
		}),
})
