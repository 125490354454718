import { z } from 'zod'
import { zString } from '#src/utils/validation'

export const ValuePropositionItemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string(),
	status: z.enum(['draft', 'published']),
	createdAt: z.string(),
})

export const ValuePropositionListAPISchema = z.object({
	publishedAt: z.string().nullable(),
	isDraft: z.boolean(),
	valuePropositions: z.array(ValuePropositionItemAPISchema),
})

export const NewValuePropositionFormSchema = z.object({
	name: zString(),
	description: zString(),
})

export const ValuePropositionListQuerySchema = z.object({
	status: ValuePropositionItemAPISchema.shape.status.optional(),
})
