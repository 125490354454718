import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import { SalesCoachAPISchema, type SalesCoachAdjustmentsFormSchema } from './schema'

export const recalculateSalesCoach = async (companyId: string, personaId: string, salescoachId: string) => {
	try {
		return await fetch(api.salescoach.recalculate(companyId, personaId, salescoachId), {
			method: 'PUT',
		})
	} catch (error) {
		if (error instanceof Response && error.status === 429) {
			return Promise.resolve(new Response(null, { status: 200 }))
		}

		throw error
	}
}

export const createSalesCoach = async (
	companyId: string,
	personaId: string,
	data: z.infer<typeof SalesCoachAdjustmentsFormSchema>,
) => {
	return await safeFetch(SalesCoachAPISchema, api.salescoach.create(companyId, personaId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const updateSalesCoach = async (
	companyId: string,
	personaId: string,
	salescoachId: number,
	data: z.infer<typeof SalesCoachAdjustmentsFormSchema>,
) => {
	return await safeFetch(SalesCoachAPISchema, api.salescoach.details(companyId, personaId, salescoachId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}
