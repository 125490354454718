import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import {
	LeadResearchAgentItemAPISchema,
	LeadResearchAgentListItemAPISchema,
	LeadResearchAgentStatisticsAPISchema,
} from '#src/routes/lead-research-agent/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { api } from '#src/utils/endpoints'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'

export const leadResearchAgentJobsKeys = {
	all: ['lead-research-agent-jobs'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id'], page: number, sort: string[]) =>
		[...leadResearchAgentJobsKeys.all, 'list', companyId, page, sort] as const,
	statistics: (companyId: z.infer<typeof CompanySchema>['id']) =>
		[...leadResearchAgentJobsKeys.all, 'statistics', companyId] as const,
	item: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) =>
		[...leadResearchAgentJobsKeys.all, 'item', jobId] as const,
}

export const statisticsQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: leadResearchAgentJobsKeys.statistics(companyId),
	queryFn: async () => {
		return await safeFetch(LeadResearchAgentStatisticsAPISchema, api.leadResearchAgent.jobs.statistics(companyId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

export const listQuery = (companyId: z.infer<typeof CompanySchema>['id'], page: number, sort: string[]) => ({
	queryKey: leadResearchAgentJobsKeys.list(companyId, page, sort),
	queryFn: async () => {
		return await safeFetchMeta(
			PaginatedAPIResSchema(LeadResearchAgentListItemAPISchema),
			api.leadResearchAgent.jobs.list(companyId, page, sort),
			{ method: 'GET' },
		)
	},
	staleTime: 30000,
})

export const itemQuery = (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => ({
	queryKey: leadResearchAgentJobsKeys.item(jobId),
	queryFn: async () => {
		return await safeFetch(LeadResearchAgentItemAPISchema, api.leadResearchAgent.jobs.item(jobId), { method: 'GET' })
	},
	staleTime: 30000,
})
