import { getFormProps, getInputProps, getSelectProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { InviteFormSchema } from '#src/api/icp/company/user/schemas'
import { ErrorList, Field, Select } from '#src/components/forms'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes as SettingsTeamLoaderRes } from '#src/routes/settings/team/loaders'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../../actions/invite'

export function InviteMemberForm() {
	const {
		data: { roles },
	} = useLoaderData() as SettingsTeamLoaderRes
	const { companyId } = useCompany()

	const fetcher = useFetcher<ActionRes>()

	const formId = fetcher?.data?.token ?? 'invite-member-form'
	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(InviteFormSchema),
		defaultValue: {
			email: null,
			role: Object.keys(roles)[0],
		},
		lastResult: !(fetcher.data instanceof Response) ? fetcher.data?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: InviteFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<>
			<fetcher.Form
				{...getFormProps(form)}
				action={routes.settings.team.invite({
					companyId,
				})}
				method="POST"
				className="flex w-2/3 flex-col gap-2"
			>
				<section className="grid grid-cols-[1fr,max-content,max-content] grid-rows-1 items-start gap-2">
					<Field
						labelProps={{ children: 'Invite your team' }}
						inputProps={{
							...getInputProps(fields.email, { type: 'text' }),
							placeholder: 'Email',
						}}
						errors={fields.email.errors}
					/>
					<Select
						className="w-[176px]"
						labelProps={{
							children: 'Select Role',
						}}
						inputProps={{
							...getSelectProps(fields.role),
							defaultValue: fields.role.initialValue,
							value: fields.role.value,
							placeholder: '-',
						}}
						options={Object.entries(roles).map(([role, value]) => ({
							value,
							label: role,
						}))}
						errors={fields.role.errors}
					/>

					<StatusButton
						className="mt-5 min-w-20" // NOTE: compensate for items-start alignment because field errors
						type="submit"
						status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
						disabled={fetcher.state !== 'idle'}
					>
						Invite
					</StatusButton>
				</section>

				<ErrorList errors={form.errors} id={form.errorId} />
			</fetcher.Form>
			<Toast shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="checkmark-filled" size="md" className="text-green-70" />
					Invitation was sent to {fetcher.data?.email}
				</ToastDescription>
			</Toast>
			<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok && !!fetcher.data?.result} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="error-filled" size="md" className="text-red-60" />
					Failed to send invitation to {fetcher.data?.email}
				</ToastDescription>
			</Toast>
			<div className="absolute">
				<ToastViewport />
			</div>
		</>
	)
}
