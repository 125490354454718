import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import {
	type CriterionAllAssignedVerticalsAnswersPayload,
	type CriterionAssignPayloadSchema,
	type CriterionNewPayloadSchema,
	CriterionQuestionAPISchema,
} from './schema'

export const createCriterion = async (companyId: string, data: z.infer<typeof CriterionNewPayloadSchema>) => {
	return await safeFetch(CriterionQuestionAPISchema, api.criterion.create(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const updateCriterion = async (
	companyId: string,
	criterionId: string,
	data: z.infer<typeof CriterionNewPayloadSchema>,
) => {
	return await safeFetch(CriterionQuestionAPISchema, api.criterion.update.details(companyId, criterionId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}

export const assignCriterion = async (companyId: string, data: z.infer<typeof CriterionAssignPayloadSchema>) => {
	return await fetch(api.criterion.assign(companyId), {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: { 'content-type': 'application/json' },
	})
}

export const unassignCriterion = async (companyId: string, ecosystemId: string, criterionId: string) => {
	return await fetch(api.criterion.unassign(companyId), {
		method: 'PATCH',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			ecosystemId,
			criterionId,
		}),
	})
}

export const updateAllVerticalsAnswers = async (
	companyId: string,
	ecosystemId: string,
	data: z.infer<typeof CriterionAllAssignedVerticalsAnswersPayload>,
) => {
	return await fetch(api.criterion.update.allVerticalsAnswers(companyId, ecosystemId), {
		method: 'PATCH',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data),
	})
}

export const updateAssignedAnswers = async (
	companyId: string,
	criterionId: string,
	data: z.infer<typeof CriterionAllAssignedVerticalsAnswersPayload>,
) => {
	return await fetch(api.criterion.update.assignedAnswers(companyId, criterionId), {
		method: 'PATCH',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data),
	})
}
