import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { type ReactNode } from 'react'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { FILTER_VALUE_MAP, FILTERS, type SORT } from '#src/routes/prioritize/constants'
import { type PrioritizeListLoaderResType } from '#src/routes/prioritize/routes/list'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const Filters = () => {
	const { filters } = useLoaderData() as PrioritizeListLoaderResType

	return (
		<section className="flex items-center gap-4">
			<p className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
				<Icon name="filter" size="sm" /> Filter by:
			</p>
			<div className="flex items-center gap-2">
				<Filter filter={FILTERS.TYPE} values={filters?.[FILTERS.TYPE]} />
				<Filter filter={FILTERS.SOURCE} values={filters?.[FILTERS.SOURCE]} />
				<Filter filter={FILTERS.HACK} values={filters?.[FILTERS.HACK]} />
				<Filter filter={FILTERS.WEIGHT} values={filters?.[FILTERS.WEIGHT]} />
				<Filter filter={FILTERS.MAPPED_PERSONAS} values={filters?.[FILTERS.MAPPED_PERSONAS]} />
				<ClearAllFilters />
			</div>
		</section>
	)
}

const ClearAllFilters = () => {
	const { filters } = useLoaderData() as PrioritizeListLoaderResType
	const hasFilters = Object.values(filters).flat().filter(Boolean)?.length > 0
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId,
	})

	return (
		<fetcher.Form action={action} method="POST">
			<button
				type="submit"
				name="intent"
				value="clear-all"
				disabled={!hasFilters}
				className={cn(
					'text-button-sm',
					hasFilters ? 'text-link hover:text-link-hover active:text-link-pressed' : 'text-neutral-1-fg-disabled',
				)}
			>
				Clear all
			</button>
		</fetcher.Form>
	)
}

const Filter = ({ filter, values }: { filter: FILTERS; values: string[] | null }) => {
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId,
	})

	return (
		<DropdownPrimitive.Root>
			<DropdownPrimitive.Trigger
				className={cn(
					'group flex items-center gap-1 rounded border bg-transparent py-2 pl-3 pr-2 text-body-md text-button-sm outline-none transition-colors radix-state-open:border-brand-1-bd',
					values?.length ? 'text-brand-1-fg' : 'text-neutral-1-fg',
					values?.length ? 'border-brand-1-bd' : 'border-neutral-1-bd',
				)}
			>
				{filter}
				{values?.length ? (
					<span className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-brand-3-bg text-label-sm text-white animate-in fade-in">
						{values.length}
					</span>
				) : null}
				<Icon name="carret-down" size="sm" className="group-radix-state-open:rotate-180" />
			</DropdownPrimitive.Trigger>

			<DropdownPrimitive.Content
				side="bottom"
				align="start"
				sideOffset={4}
				className={cn(
					'z-50 flex max-h-[20rem] min-w-[12rem] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 shadow',
				)}
			>
				<DropdownPrimitive.Item
					disabled={!values?.length}
					className={cn(
						'group flex items-center gap-2 rounded-sm px-2 py-1.5 text-button-sm font-normal outline-none transition-colors',
						'cursor-pointer text-link hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover radix-disabled:cursor-default radix-disabled:text-neutral-1-fg-disabled radix-disabled:hover:bg-transparent',
					)}
					onSelect={e => {
						e.preventDefault()

						fetcher.submit({ intent: 'clear', filter }, { action, method: 'POST' })
					}}
				>
					Clear all
				</DropdownPrimitive.Item>
				{FILTER_VALUE_MAP[filter].map((label, i) => (
					<DropdownPrimitive.CheckboxItem
						key={i}
						className={cn(
							'group flex cursor-pointer items-center gap-2 rounded-sm px-2 py-1.5 text-body-md font-normal text-neutral-2-fg outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
						)}
						onSelect={e => e.preventDefault()}
						checked={values?.includes(label) ?? false}
						onCheckedChange={() => {
							fetcher.submit({ intent: 'toggle', filter, value: label }, { action, method: 'POST' })
						}}
					>
						<Icon
							name="checkbox-unchecked"
							size="sm"
							className="inline-flex animate-in fade-in group-radix-state-checked:hidden group-radix-state-checked:animate-out group-radix-state-checked:fade-out"
						/>
						<Icon
							name="checkbox-checked-filled"
							size="sm"
							className="hidden text-brand-1-fg animate-out fade-out group-radix-state-checked:inline-flex group-radix-state-checked:animate-in group-radix-state-checked:fade-in"
						/>
						{label}
					</DropdownPrimitive.CheckboxItem>
				))}
			</DropdownPrimitive.Content>
		</DropdownPrimitive.Root>
	)
}

export const Sort = ({ sortKey, children }: { sortKey: SORT; children: ReactNode }) => {
	const { sortBy } = useLoaderData() as PrioritizeListLoaderResType
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId,
	})

	return (
		<fetcher.Form action={action} method="POST">
			<input type="hidden" name="sort" value={sortKey} />
			<button
				type="submit"
				name="intent"
				value="sort"
				className={cn('flex w-max items-center justify-center gap-0.5', sortKey === sortBy?.key ? 'font-semibold' : '')}
			>
				{children}

				<div className="relative inline-flex h-4 w-4 items-center justify-center">
					{!sortBy || sortBy.key !== sortKey ? (
						<Icon name="chevron-sort" size="sm" />
					) : (
						<>
							<Icon
								name="chevron-sort-asc"
								size="sm"
								className={cn('absolute left-0 top-0', sortBy.direction === 'asc' ? 'opacity-100' : 'opacity-50')}
							/>
							<Icon
								name="chevron-sort-desc"
								size="sm"
								className={cn('absolute left-0 top-0', sortBy.direction === 'desc' ? 'opacity-100' : 'opacity-50')}
							/>
						</>
					)}
				</div>
			</button>
		</fetcher.Form>
	)
}
