import { useQuery } from '@tanstack/react-query'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { companyQuery } from '#src/routes/company/queries'
import { type CompanySchema } from '#src/routes/company/schema'

type Props = {
	companyId?: z.infer<typeof CompanySchema>['id']
}

const useCompany = (props: Props = {}) => {
	const { company: initialCompany } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const { companyId } = useParams<{ companyId?: string }>()
	const finalId = props.companyId ?? companyId ?? initialCompany.id
	const { data: company } = useQuery({
		...companyQuery(finalId),
		placeholderData: initialCompany,
	})

	return {
		company: company!,
		/** @note use companyId when you need to pass it to routes, and company.id when you realy need it (f.e. queries) */
		companyId: props.companyId ?? companyId ?? null,
	}
}

export default useCompany
