import { parseWithZod } from '@conform-to/zod'
import { redirect, type ActionFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { createGlossaryTerm, updateGlossaryTerm } from './mutations'
import { glossaryKeys } from './queries'
import { EditTermFormSchema } from './schema'

export type SaveGlossaryTermActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	const submission = parseWithZod(formData, { schema: EditTermFormSchema })

	if (submission.status !== 'success') {
		throw new Response('Invalid FormData', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

	if (params.termId) {
		await updateGlossaryTerm(company.id, params.termId, submission.value)
	} else {
		await createGlossaryTerm(company.id, submission.value)
	}

	await client.invalidateQueries({
		queryKey: glossaryKeys.all,
	})

	return redirect(routes.enable.glossary.index({ companyId }))
}
