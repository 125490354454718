import { clsx } from 'clsx'
import { type ReactNode, useCallback } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import {
	JobStatusName,
	LRA_JOB_STATUS as JobStatus,
	type LRA_JOB_STATUS,
} from '#src/routes/lead-research-agent/constants'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

type Props = {
	status: LRA_JOB_STATUS
	progress?: {
		current: number
		total: number
	}
}

const LABEL_BG: Record<LRA_JOB_STATUS, ClassName> = {
	[JobStatus.Launching]: 'bg-brand-1-bg',
	[JobStatus.InProgress]: 'bg-brand-1-bg',
	[JobStatus.Completed]: 'bg-green-10',
	[JobStatus.CompletedWithQuestions]: 'bg-green-10',
	[JobStatus.Cancelled]: 'bg-red-10',
	[JobStatus.Error]: 'bg-red-10',
} as const

const ICON: Record<LRA_JOB_STATUS, IconName> = {
	[JobStatus.Launching]: 'loading-sm',
	[JobStatus.InProgress]: 'loading-sm',
	[JobStatus.Completed]: 'checkmark-filled',
	[JobStatus.CompletedWithQuestions]: 'checkmark-filled-warning',
	[JobStatus.Cancelled]: 'stop-sign-filled',
	[JobStatus.Error]: 'stop-sign-filled',
} as const

const COLOR: Record<LRA_JOB_STATUS, ClassName> = {
	[JobStatus.Launching]: 'text-brand-1-fg',
	[JobStatus.InProgress]: 'text-brand-1-fg',
	[JobStatus.Completed]: 'text-green-80',
	[JobStatus.CompletedWithQuestions]: 'text-green-80',
	[JobStatus.Cancelled]: 'text-red-60',
	[JobStatus.Error]: 'text-red-60',
} as const

const PROGRESS_BG: Record<LRA_JOB_STATUS, ClassName> = {
	[JobStatus.Launching]: 'bg-brand-3-bg',
	[JobStatus.InProgress]: 'bg-brand-3-bg',
	[JobStatus.Completed]: 'bg-green-80',
	[JobStatus.CompletedWithQuestions]: 'bg-green-80',
	[JobStatus.Cancelled]: 'bg-red-60',
	[JobStatus.Error]: 'bg-red-60',
} as const

const PROGRESS_COLOR: Record<LRA_JOB_STATUS, ClassName> = {
	[JobStatus.Launching]: 'text-brand-3-fg',
	[JobStatus.InProgress]: 'text-brand-3-fg',
	[JobStatus.Completed]: 'text-green-10',
	[JobStatus.CompletedWithQuestions]: 'text-green-10',
	[JobStatus.Cancelled]: 'text-red-10',
	[JobStatus.Error]: 'text-red-10',
} as const

export const StatusLabel = (props: Props) => {
	const { status, progress } = props

	const tooltipWrapper = useCallback(
		(children: ReactNode) => {
			if (status === JobStatus.CompletedWithQuestions) {
				return (
					<CustomTooltip label="Completed with questions" noChildrenWrapper>
						{children}
					</CustomTooltip>
				)
			}
			return children
		},
		[status],
	)

	return tooltipWrapper(
		<div className={clsx('inline-flex flex-row items-center gap-1 rounded-xl p-1', LABEL_BG[status], COLOR[status])}>
			<Icon name={ICON[status]} className={cn('h-4 w-4', ICON[status].includes('loading') && 'animate-spin')} />
			<div className={cn('text-body-sm', !progress && 'mr-1')}>{JobStatusName[status]}</div>
			{!!progress && (
				<div
					className={clsx(
						'rounded-xl px-2 py-0.5 text-label-sm text-brand-3-fg',
						PROGRESS_BG[status],
						PROGRESS_COLOR[status],
					)}
				>
					{progress.current}/{progress.total}
				</div>
			)}
		</div>,
	)
}
