import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { resetPassword } from '#src/routes/auth/mutations'
import { ResetPasswordFormSchema } from '#src/routes/auth/schema'
import { validateRouteParams } from '#src/utils/misc'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['token'])

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: ResetPasswordFormSchema,
	})

	if (submission.status !== 'success') {
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Failed to submit form. Make sure all fields are filled out correctly.'],
			}),
		}
	}

	try {
		await resetPassword({
			token: params.token,
			password: submission.value.password,
		})

		return redirect('/login')
	} catch (error) {
		captureException(error)

		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Something went wrong. Try again later.'],
			}),
		}
	}
}
