import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/user`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/list`,
	invite: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/invite`,
	roles: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/roles`,
	allRoles: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/roles/all`,
}

export { endpoints as userEndpoints }
