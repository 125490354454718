import { useState, useMemo, useCallback } from 'react'
import { Chip } from '#src/components/chip'
import { Divider } from '#src/components/ui/Divider'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import { PopoverRoot, PopoverTrigger, PopoverContent } from '#src/components/ui/popover'
import { inputCommonClasses } from '#src/theme'
import { getAvailableCountriesAndRegions } from '#src/utils/fieldOptions'
import { cn } from '#src/utils/misc'

export type RegionSelectValue = { included: string[]; excluded: string[] } | undefined

export type RegionsSelectProps = {
	options?: MultiSelectOption[]
	value?: RegionSelectValue
	onChange?: (value: RegionSelectValue) => void
	placeholder?: string
	disabled?: boolean
	className?: string
}

export const RegionsSelect = ({
	options = getAvailableCountriesAndRegions(),
	value = { included: [], excluded: [] },
	onChange = () => null,
	placeholder = 'Select...',
	disabled = false,
	className,
	...props
}: RegionsSelectProps) => {
	const [search, setSearch] = useState<string>('')

	const filteredOptions = useMemo(() => {
		return search
			? options.filter(option => option.label?.toString().toLowerCase().includes(search.toLowerCase()))
			: options
	}, [search, options])

	const handleToggle = useCallback(
		(regionValue: string, included: boolean) => {
			const updatedValue = {
				included: value.included || [],
				excluded: value.excluded || [],
			}

			if (included) {
				updatedValue.included = [...updatedValue.included.filter(v => v !== regionValue), regionValue]
				updatedValue.excluded = updatedValue.excluded.filter(v => v !== regionValue)
			} else {
				updatedValue.excluded = [...updatedValue.excluded.filter(v => v !== regionValue), regionValue]
				updatedValue.included = updatedValue.included.filter(v => v !== regionValue)
			}

			onChange(updatedValue)
		},
		[value, onChange],
	)

	const handleChipRemove = useCallback(
		(regionValue: string, included: boolean) => {
			const updatedValue = { ...value }
			if (included) {
				updatedValue.included = value.included?.filter(v => v !== regionValue)
			} else {
				updatedValue.excluded = value.excluded?.filter(v => v !== regionValue)
			}
			onChange(updatedValue)
		},
		[value, onChange],
	)

	const renderChips = () => (
		<div className="flex flex-wrap gap-1">
			{value.included?.map(regionValue => {
				const option = options.find(o => o.value === regionValue)
				return (
					<Chip key={regionValue} variant="green" className="flex items-center gap-x-1.5 hover:bg-neutral-2-bg-hover">
						{option?.label}
						<button
							onClick={e => {
								e.stopPropagation()
								handleChipRemove(regionValue, true)
							}}
							className="p-0"
						>
							<Icon name="close-filled" className="hover:text-neutral-3-fg" />
						</button>
					</Chip>
				)
			})}
			{value.excluded?.map(regionValue => {
				const option = options.find(o => o.value === regionValue)
				return (
					<Chip key={regionValue} variant="red" className="flex items-center gap-x-1.5 hover:bg-neutral-2-bg-hover">
						{option?.label}
						<button
							onClick={e => {
								e.stopPropagation()
								handleChipRemove(regionValue, false)
							}}
							className="p-0"
						>
							<Icon name="close-filled" className="hover:text-neutral-3-fg" />
						</button>
					</Chip>
				)
			})}
		</div>
	)

	const renderOptions = useMemo(() => {
		return filteredOptions.map(option => {
			const isIncluded = value.included?.includes(option.value)
			const isExcluded = value.excluded?.includes(option.value)

			return (
				<label
					key={option.value}
					className="flex cursor-pointer items-center justify-between rounded px-2 py-1.5 hover:bg-neutral-1-bg-hover"
				>
					<span
						className={cn(
							'text-neutral-1-fg',
							isIncluded && 'text-status-success-fg',
							isExcluded && 'text-status-danger-fg line-through',
						)}
					>
						{option.label}
					</span>
					<div className="flex gap-0.5">
						<Icon
							onClick={() => handleToggle(option.value, true)}
							name={!isIncluded ? 'add-alt' : 'add-filled'}
							className={cn('h-4.5 w-4.5 hover:text-status-success-fg', isIncluded && 'text-status-success-fg')}
						/>
						<Icon
							onClick={() => handleToggle(option.value, false)}
							name={!isExcluded ? 'subtract-alt' : 'subtract-filled'}
							className={cn('h-4.5 w-4.5 hover:text-status-danger-fg', isExcluded && 'text-status-danger-fg')}
						/>
					</div>
				</label>
			)
		})
	}, [filteredOptions, handleToggle, value])

	return (
		<PopoverRoot open={disabled ? false : undefined}>
			<PopoverTrigger asChild tabIndex={-1}>
				<button
					className={cn(
						inputCommonClasses,
						'relative flex w-full items-center',
						(value.included?.length || value.excluded?.length) && 'h-auto',
						disabled && 'text-neutral-inverse-fg-disabled opacity-50',
						className,
					)}
					style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
					{...props}
				>
					<div className="flex flex-1 flex-wrap items-center gap-1">
						{(value.included?.length || value.excluded?.length) && !value.included?.includes('-') ? (
							renderChips()
						) : (
							<span className="text-neutral-3-fg">{placeholder}</span>
						)}
					</div>
					{!!(value.included?.length || value.excluded?.length) && !disabled && (
						<Icon
							onClick={e => {
								e.stopPropagation()
								onChange(undefined)
							}}
							name="close-filled"
							className="me-1 text-neutral-3-fg hover:text-neutral-4-fg"
						/>
					)}
					<Icon name="chevron-down" className="text-neutral-4-fg" />
				</button>
			</PopoverTrigger>
			<PopoverContent
				className="z-50 max-h-[20rem] w-full rounded border border-neutral-1-bd bg-neutral-1-bg p-0 text-body-md shadow-sm"
				sideOffset={5}
				align="start"
			>
				<section className="sticky top-0 flex flex-col gap-2 bg-white px-2 pt-3">
					<div className="mb-1 px-2">
						<Input value={search} onChange={e => setSearch(e.target.value)} iconLeft="search" clearable />
					</div>
					<Divider marginY="none" />
				</section>
				<div className="flex flex-col overflow-auto px-2 pb-2">{renderOptions}</div>
			</PopoverContent>
		</PopoverRoot>
	)
}
