import { type LoaderFunctionArgs } from 'react-router-dom'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	const { company } = await getCompany(params)

	return {
		data: {
			dashboard: company.dashboard,
		},
	}
}
