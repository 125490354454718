import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { StatsAPISchema, StatsValuePropositionSchema } from '#src/api/icp/company/stats/schemas'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'

export const LoaderSchema = z.object({
	values: z.object({}),
	data: z.object({
		statistics: StatsValuePropositionSchema,
		statisticsDashboard: StatsAPISchema,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	() =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId'])
		const [statistics, statisticsDashboard] = await Promise.all([
			statsQueries.valueProposition(params.companyId),
			client.fetchQuery(statsQueries.dashboard(params.companyId)),
		])

		return {
			data: {
				statistics,
				statisticsDashboard,
			},
		}
	}
