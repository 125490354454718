import { type ReactNode } from 'react'
import Paginator, { type PaginatorProps } from './Paginator'

type LoaderOverlayProps = {
	isLoading?: boolean
	children: ReactNode
	paginatorPosition?: 'left' | 'center' | 'right'
}

type PagePaginatorProps = PaginatorProps & LoaderOverlayProps

export const PagePaginator = (props: PagePaginatorProps) => {
	const { isLoading, children, currentPage, setCurrentPage, totalPages, paginatorPosition = 'right' } = props

	return (
		<div className="relative">
			{isLoading && (
				<div className="z-100 absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
					<div className="loader h-8 w-8 animate-spin rounded-full border-4 border-neutral-2-bd border-t-transparent"></div>
				</div>
			)}
			{children}
			<Paginator
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				totalPages={totalPages}
				paginatorPosition={paginatorPosition}
			/>
		</div>
	)
}
