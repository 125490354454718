import * as Sentry from '@sentry/react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

export const loader = () => {
	Sentry.setUser(null)
	return null
}

export default function Root() {
	return (
		<>
			<Outlet />
			<ScrollRestoration getKey={({ pathname }) => pathname} />
		</>
	)
}
