import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { assignCriterion } from '#src/routes/calibrate/verticals/criteria/mutations'
import { criteriaKeys } from '#src/routes/calibrate/verticals/criteria/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type CriteriaEditAnswersSchema } from '../routes/editAnswers'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient, type: z.infer<typeof CriteriaEditAnswersSchema>['values']['type']) =>
	async ({ params }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId', 'criterionId'])

		try {
			await assignCriterion(params.companyId, {
				criterionId: params.criterionId,
				ecosystemId: params.ecosystemId,
			})

			showToast({
				message: 'Criterion successfully created and assigned',
				type: 'success',
				bottom: '60px',
			})

			await queryClient.invalidateQueries({ queryKey: criteriaKeys.all })
		} catch (err) {
			captureException(err)
			console.error(err)
			showToast({
				message: 'Unexpected error while assigning criterion',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.criteria.editAnswers({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				criterionType: type,
			}),
		)
	}
