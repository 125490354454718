import { type Submission } from '@conform-to/dom'
import { parseWithZod } from '@conform-to/zod'
import { z, type AnyZodObject, type z as zType } from 'zod'

export const objToFormData = (data: z.infer<AnyZodObject>) => {
	const res = new FormData()
	Object.entries(data).forEach(([key, value]) => {
		if (value === null || value === undefined) {
			return
		}
		if (Array.isArray(value)) {
			value.forEach(val => {
				res.append(`${key}[]`, val as string | Blob)
			})
		} else {
			res.append(key, value as string | Blob)
		}
	})

	return res
}

/** @description: this one looks more valid, i took it from stackoverflow
 * @ref: https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data  */
export const objectToFormData = (
	obj: unknown,
	rootName: string | undefined = undefined,
	// ignoreList,
) => {
	const formData = new FormData()

	const appendFormData = (data: unknown, root: string | undefined) => {
		// if (!ignore(root)) {
		root = root || ''
		if (data instanceof File) {
			formData.append(root, data)
		} else if (Array.isArray(data)) {
			data.forEach((val, i) => {
				appendFormData(val, root + '[' + i + ']')
			})
		} else if (typeof data === 'object' && data) {
			Object.entries(data).forEach(([key, value]) => {
				if (Object.hasOwn(data, key)) {
					if (root === '') {
						appendFormData(value, key)
					} else {
						appendFormData(value, root + '.' + key)
					}
				}
			})
		} else {
			const formattedData = data === null || data === undefined ? '' : data
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			formData.append(root, formattedData)
			// todo: old check, need to remove if nothing got broken
			// if (data !== null && typeof data !== 'undefined') {
			// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// 	// @ts-expect-error
			// 	formData.append(root, data)
			// }
		}
		// }
	}

	// const ignore = (root) => {
	// 	return Array.isArray(ignoreList)
	// 		&& ignoreList.some(function(x) { return x === root; });
	// }

	appendFormData(obj, rootName)

	return formData
}

export const unwrapSchema = (schema: z.ZodTypeAny): z.ZodObject<z.ZodRawShape> => {
	if (schema instanceof z.ZodEffects) {
		const innerSchema = schema._def.schema as z.ZodTypeAny

		return unwrapSchema(innerSchema)
	}
	if (schema instanceof z.ZodObject) {
		return schema as z.ZodObject<z.ZodRawShape>
	}

	throw new Error('Provided schema is not a ZodObject or ZodEffects.')
}

const transformBooleanFieldsToStrings = <T extends z.ZodTypeAny>(schema: T): T => {
	const replaceBooleansSchemasWithStrings = (shape: z.ZodRawShape) => {
		const booleanKeys = Object.entries(shape)
			.filter(([, value]) => {
				while (value instanceof z.ZodOptional || value instanceof z.ZodNullable) {
					value = value._def.innerType as z.ZodTypeAny
				}
				return value instanceof z.ZodBoolean
			})
			.map(([key]) => key)

		if (!booleanKeys.length) return {}

		return Object.fromEntries(booleanKeys.map(key => [key, z.string().optional()])) as z.ZodRawShape
	}

	if (schema instanceof z.ZodObject) {
		const shape = schema.shape as z.ZodRawShape
		const transformedShape = replaceBooleansSchemasWithStrings(shape)

		if (!Object.keys(transformedShape).length) {
			return schema
		}

		return schema.extend(transformedShape) as unknown as T
	}

	// Applied when transformation is done to the schema
	if (schema instanceof z.ZodEffects) {
		const def = schema._def as z.ZodEffectsDef
		const innerSchema = def.schema

		if (innerSchema && innerSchema instanceof z.ZodObject) {
			const transformedShape = replaceBooleansSchemasWithStrings(innerSchema.shape as Record<string, z.ZodTypeAny>)

			if (!Object.keys(transformedShape).length) {
				return schema
			}

			const extendedInnerSchema = innerSchema.extend(transformedShape)

			return new z.ZodEffects({
				...def,
				schema: extendedInnerSchema,
			}) as unknown as T
		}
	}

	// Add more schema instances if needed

	throw new Error('Provided schema does not support booleans')
}

/**
 * Parses `FormData` using a Zod schema and processes the resulting data.
 *
 * This utility performs the following operations:
 * - Parses the `FormData` against the provided Zod schema.
 * - Handles boolean fields by converting them to string representations for parsing,
 *   then back to their original boolean form.
 * - Logs errors to the console and invokes an optional error callback when parsing fails.
 * - Ensures nullable fields are included in the result if they have a `z.nullable` schema definition.
 * - Excludes fields considered disabled (not present in the payload) from nullable values.
 */
export const parseFormData = <T extends zType.ZodTypeAny>(
	formData: FormData,
	schema: T,
	onError?: (error: Submission<unknown>) => void,
) => {
	const extendedSchema: T = transformBooleanFieldsToStrings(schema)
	const submission = parseWithZod(formData, { schema: extendedSchema })

	if (submission.status !== 'success') {
		if (onError) {
			onError(submission)
		}

		console.error('Parsing errors:', submission.error)
		throw new Error('Failed to parse form data')
	}

	// After successful parsing, convert boolean strings back to actual booleans for the payload.
	Object.entries(submission.value).forEach(([key, value]) => {
		if (value === 'true') {
			submission.value[key] = true
		} else if (value === 'false') {
			submission.value[key] = false
		}
	})

	return {
		...submission,
		value: {
			...submission.value,
		},
	}
}
