import { clsx } from 'clsx'
import { forwardRef, type ReactNode, useImperativeHandle, useState } from 'react'
import { type Accept, type FileWithPath, useDropzone } from 'react-dropzone'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { selectionControlDisabledVariants } from '#src/theme'
import { cn } from '#src/utils/misc'

export type FileInputProps = {
	maxFiles?: number
	accept?: Accept
	// todo: initial value is not handled anywhere
	value?: FileWithPath | FileWithPath[] | undefined
	onChange?: (e: FileWithPath[] | FileWithPath | undefined) => void
	infoContent?: ReactNode
	disabled?: boolean
}

export type FileInputForwardProps = {
	open: () => void
}

export const FileInput = forwardRef<FileInputForwardProps, FileInputProps>((props: FileInputProps, ref) => {
	const { maxFiles, accept, onChange, infoContent, value, disabled } = props
	const multiple = !!(maxFiles && maxFiles > 1)
	const [files, setFiles] = useState<FileWithPath[]>(value ? (Array.isArray(value) ? value : [value]) : [])
	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		disabled,
		maxFiles: maxFiles ?? 1,
		accept,
		multiple,
		onDropAccepted: files => {
			setFiles(files)
			onChange?.(multiple ? files : (files[0] ?? undefined))
		},
	})

	useImperativeHandle(ref, () => ({ open }), [open])

	const onDeleteFile = (file: File) => {
		const newFiles = [...files]
		newFiles.splice(newFiles.indexOf(file), 1)
		setFiles(newFiles)
		onChange?.(multiple ? newFiles : (newFiles[0] ?? undefined))
	}

	return (
		<div className={cn('flex', disabled && selectionControlDisabledVariants.true)}>
			<div
				{...getRootProps()}
				className={clsx(
					'flex min-h-[330px] w-full flex-1 flex-col gap-1 rounded border p-6',
					isDragActive ? 'border-brand-1-bd-hover' : 'border-neutral-2-bd',
					files.length ? 'hidden' : 'items-center justify-center border-dashed bg-neutral-2-bg',
				)}
			>
				<input {...getInputProps()} />
				{infoContent ?? (
					<>
						<div className="flex flex-row items-center justify-center gap-2">
							<Icon name="upload" className="block h-4 w-4" />
							<p className="text-body-md">Drag your file here</p>
						</div>
						<Button
							variant="ghost"
							size="sm"
							type="button"
							onClick={e => {
								e.preventDefault()
								e.stopPropagation()
								open()
							}}
						>
							Upload from computer
						</Button>
					</>
				)}
			</div>
			{!!files.length && (
				<div className="flex w-full flex-col gap-2">
					{files.map(file => (
						<div
							onClick={e => e.stopPropagation()}
							key={`file-${file.path}`}
							className="flex w-full items-center gap-1 rounded border border-neutral-2-bd p-4"
						>
							<Icon name="document" size="md" className="block" />
							<p className="flex-1 truncate text-body-md">{file.name}</p>
							<Button
								variant="icon"
								size="sm"
								type="button"
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									onDeleteFile(file)
								}}
							>
								<Icon name="trash" className="block text-status-danger-fg" />
							</Button>
						</div>
					))}
				</div>
			)}
		</div>
	)
})

FileInput.displayName = 'FileInput'
