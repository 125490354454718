import { z } from 'zod'
import { zString } from '#src/utils/validation'

export const PlaysItemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	instructions: z.string(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	createdAt: z.string(),
	personaTypes: z.array(z.string()),
	expertise: z.array(z.string()),
})

export const PlaysListItemAPISchema = PlaysItemAPISchema.array()

export const PlaysFormSchema = z.object({
	name: zString(),
	instructions: zString(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	personaTypes: z.array(z.string()).nullable(),
	expertise: z.array(z.string()).nullable(),
})

export const PlaysFormParserSchema = z.object({
	name: zString(),
	instructions: zString(),
	inCopilot: z.enum(['true', 'false']).transform(val => val === 'true'),
	inCrm: z.enum(['true', 'false']).transform(val => val === 'true'),
	personaTypes: z.array(z.string()).nullable(),
	expertise: z.array(z.string()).nullable(),
})
