import { type ButtonHTMLAttributes, type ReactNode, forwardRef } from 'react'
import { useFetcher, redirect, useNavigate, type LoaderFunctionArgs } from 'react-router-dom'
import DeleteConfirm, { DeleteConfirmContent, DeleteConfirmFooter } from '#src/components/delete-confirm'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { cn, useIsPending, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deleteConversation } from './mutations'
import { enableKeys } from './queries'

export const action = async ({ params }: LoaderFunctionArgs) => {
	const { companyId, company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])
	const { readOnlySession } = await getUser()

	if (readOnlySession)
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})

	await deleteConversation(company.id, params.conversationId)

	void client.invalidateQueries({
		queryKey: enableKeys.chats(company.id),
	})
	return redirect(
		routes.enable.copilot.index({
			companyId,
			conversationId: null,
		}),
	)
}

export default function DeleteChatForm() {
	const { companyId } = useCompany()
	const params = useParsedRouteParams(['conversationId'])
	const navigate = useNavigate()
	const fetcher = useFetcher()

	const action = routes.enable.copilot.delete({
		companyId,
		conversationId: params.conversationId,
	})
	const isPending = useIsPending({
		formAction: action,
		formMethod: 'DELETE',
	})

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen => {
				if (!isOpen && !isPending) {
					navigate(-1)
				}
			}}
		>
			<DialogContent className="w-full max-w-lg" closeClassName={isPending ? 'opacity-50 cursor-default' : ''}>
				<DeleteConfirmContent
					title="Delete chat?"
					description="This action cannot be undone. This will permanently delete the chat and remove any associated data such as messages."
				>
					<DeleteConfirmFooter action={action} fetcher={fetcher} />
				</DeleteConfirmContent>
			</DialogContent>
		</DialogRoot>
	)
}

interface DeleteChatAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	conversationId: string
	children: ReactNode
}

export const DeleteChatAsyncForm = forwardRef<HTMLButtonElement, DeleteChatAsyncFormProps>(
	({ conversationId, children, className }, ref) => {
		const { companyId } = useCompany()
		const action = routes.enable.copilot.delete({
			companyId,
			conversationId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button type="button" className={cn('w-full', className)} ref={ref}>
						{children}
					</button>
				}
				action={action}
				title="Delete chat?"
				description="This action cannot be undone. This will permanently delete the chat and remove any associated data such as messages."
			/>
		)
	},
)
DeleteChatAsyncForm.displayName = 'DeleteChatAsyncForm'
