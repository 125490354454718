export const getObjectValueByKey = (obj: Record<string, unknown>, key: string) => {
	const keys = key.split('.')
	let value: unknown = obj
	keys.forEach(item => {
		if (typeof value === 'object' && value !== null && item in value) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			value = value[item]
		} else {
			throw new Error(`getObjectValueByKey:: Unknown key "${key}" in ${key}: ${item}`)
		}
	})
	return value as string | number
}
