import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { validateRouteParams } from '#src/utils/misc'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
		ecosystemId: z.string(),
		type: CRITERION_TYPE_ENUM,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient, type: z.infer<typeof LoaderSchema>['values']['type']) =>
	({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])

		return {
			values: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				type,
			},
		}
	}
