import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import { EcosystemAPISchema, type EcosystemFormSchema, type EcosystemTemplateFormSchema } from './schema'

export const createEcosystem = async (companyId: string, data: z.infer<typeof EcosystemFormSchema>) => {
	return await safeFetch(EcosystemAPISchema, api.ecosystem.create(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const createEcosystemFromTemplate = async (
	companyId: string,
	data: z.infer<typeof EcosystemTemplateFormSchema>,
) => {
	return await safeFetch(EcosystemAPISchema, api.ecosystem.templates(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const updateEcosystem = async (
	companyId: string,
	ecosystemId: number,
	data: z.infer<typeof EcosystemFormSchema>,
) =>
	await safeFetch(EcosystemAPISchema, api.ecosystem.details(companyId, ecosystemId.toString()), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})

export const deleteEcosystem = async (companyId: string, ecosystemId: string) => {
	return await fetch(api.ecosystem.details(companyId, ecosystemId), {
		method: 'DELETE',
	})
}
