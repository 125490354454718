import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useActionData, useFetcher, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { type z } from 'zod'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { FILE_TYPES, FORM_STEPS } from '#src/routes/lead-research-agent/constants'
import { type LRADownloadTemplateActionRes } from '#src/routes/lead-research-agent/routes/downloadTemplate'
import { LeadResearchAgentJobFormSchema } from '#src/routes/lead-research-agent/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type LRACreateActionRes, type LRACreateLoaderRes } from '../routes/create'

const FORM_NAME = 'lra-job-create'

export const View = () => {
	const actionData = useActionData() as LRACreateActionRes
	const {
		data: { ecosystems },
	} = useLoaderData() as LRACreateLoaderRes
	const { company, companyId } = useCompany()

	const navigate = useNavigate()
	const isPending = useIsPending()

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.File)

	const form = useForm<z.infer<typeof LeadResearchAgentJobFormSchema>>({
		resolver: zodResolver(LeadResearchAgentJobFormSchema),
		defaultValues: {
			companyId: company.id,
			contactFinderEnabled: false,
			contactWaterfallingEnabled: false,
			type: FILE_TYPES.Accounts,
			verticals: ecosystems.map(ecosystem => ecosystem.verticals.map(vertical => String(vertical.id))).flat(),
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(
					// todo: need better way to get form field names
					fieldName as
						| 'type'
						| 'title'
						| 'contactFinderEnabled'
						| 'contactWaterfallingEnabled'
						| 'comment'
						| 'verticals'
						| 'companyId'
						| 'file',
					{
						type: 'server',
						message: errorMessages.join(', '),
					},
				)
			})
		}
	}, [actionData, form])

	const typeWatch = form.watch('type')
	const contactFinderEnabledWatch = form.watch('contactFinderEnabled')

	useEffect(() => {
		form.setValue('contactWaterfallingEnabled', typeWatch === FILE_TYPES.AccountsAndContacts)
		form.setValue('contactFinderEnabled', false)
	}, [form, typeWatch])

	useEffect(() => {
		form.setValue('contactWaterfallingEnabled', contactFinderEnabledWatch)
	}, [form, contactFinderEnabledWatch])

	const handleContinue = async () => {
		switch (step) {
			case FORM_STEPS.File: {
				await form.trigger('type')
				await form.trigger('file')
				if (!('type' in form.formState.errors) && !('file' in form.formState.errors)) {
					setStep(FORM_STEPS.Verticals)
				}
				break
			}
			case FORM_STEPS.Verticals: {
				await form.trigger('verticals')
				if (!('verticals' in form.formState.errors)) {
					setStep(FORM_STEPS.Settings)
				}
				break
			}
			case FORM_STEPS.Settings: {
				setStep(FORM_STEPS.AdditionalInfo)
				break
			}
			case FORM_STEPS.AdditionalInfo: {
				await form.trigger('title')
				await form.trigger('comment')
				if (!('title' in form.formState.errors) && !('comment' in form.formState.errors)) {
					const formEl = document.getElementById(FORM_NAME) as HTMLFormElement
					formEl.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.File:
				navigate({
					pathname: routes.leadResearchAgent.index({ companyId }),
					search: searchParams.toString(),
				})
				break
			case FORM_STEPS.Verticals:
				setStep(FORM_STEPS.File)
				break
			case FORM_STEPS.Settings:
				setStep(FORM_STEPS.Verticals)
				break
			case FORM_STEPS.AdditionalInfo:
				setStep(FORM_STEPS.Settings)
				break
		}
	}

	const downloadFetcher = useFetcher<LRADownloadTemplateActionRes>()
	const handleDownload = (type: FILE_TYPES) => {
		downloadFetcher.submit(
			{ type },
			{
				method: 'POST',
				action: routes.leadResearchAgent.downloadTemplate({ companyId }),
			},
		)
	}

	const [searchParams] = useSearchParams()

	return (
		<Dialog
			size="lg"
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				navigate({
					pathname: routes.leadResearchAgent.index({ companyId }),
					search: searchParams.toString(),
				})
			}
			dialogHeading="Create Lead Research Agent Job"
			{...(step === FORM_STEPS.Verticals && {
				footerInfo: (
					<>
						<b>{form.watch('verticals')?.length ?? 0}</b> verticals selected
					</>
				),
			})}
			actions={[
				<Button onClick={handleCancel} variant="outline" size="sm" key="lra-create-back">
					Back
				</Button>,
				<StatusButton
					status={isPending ? 'pending' : 'idle'}
					disabled={isPending}
					size="sm"
					onClick={() => {
						handleContinue()
							.then()
							.catch(() => {})
					}}
					type="button"
					form={FORM_NAME}
					key="lra-create-submit"
				>
					Continue
				</StatusButton>,
			]}
		>
			<FormWrapper
				formId={FORM_NAME}
				formProps={form}
				method="POST"
				encType="multipart/form-data"
				className="flex flex-col gap-6"
			>
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.File, label: 'Import file' },
						{ name: FORM_STEPS.Verticals, label: 'Select ecosystems' },
						{ name: FORM_STEPS.Settings, label: 'Job settings' },
						{ name: FORM_STEPS.AdditionalInfo, label: 'Additional info' },
					]}
				/>
				{step === FORM_STEPS.File && (
					<>
						<FormField
							fieldType="radioCards"
							disableLabel
							name="type"
							options={[
								{
									value: FILE_TYPES.Accounts,
									label: 'Accounts',
									icon: 'portfolio',
								},
								{
									value: FILE_TYPES.AccountsAndContacts,
									label: 'Accounts + Contacts',
									icon: 'user-multiple',
								},
							]}
						/>
						<FormField
							fieldType="file"
							name="file"
							accept={{ 'text/csv': ['.csv'] }}
							label="Import CSV"
							infoContent={
								<div className="flex flex-col items-center gap-8">
									<div className="flex flex-col gap-4">
										<Icon name="upload-thin" className="block h-10 w-10 text-brand-2-fg" />
										<div className="flex flex-col gap-1">
											<p className="text-center text-title-sm text-neutral-1-fg">Drag your CSV file here</p>
											<p className="text-center text-body-md text-neutral-2-fg">File must-have columns:</p>
										</div>
									</div>
									<div className="flex flex-row items-center justify-center gap-1">
										{form.watch('type') === FILE_TYPES.Accounts ? (
											<>
												<Chip variant="blue">Account name</Chip>
												<Icon name="add" className="text-neutral-4-fg" />
												<Chip variant="blue">Domain</Chip>
											</>
										) : (
											<>
												<Chip variant="blue">Account name</Chip>
												<Icon name="add" className="text-neutral-4-fg" />
												<Chip variant="blue">Domain</Chip>
												<Icon name="or-operation" className="size-6 text-neutral-4-fg" />
												<div className="flex flex-col gap-2">
													<Chip variant="orange">Contact LinkedIn URL</Chip>
													<div className="flex flex-row items-center justify-center gap-1">
														<Chip variant="indigo">First name</Chip>
														<Icon name="add" className="text-neutral-4-fg" />
														<Chip variant="indigo">Last name</Chip>
													</div>
												</div>
											</>
										)}
									</div>
									<div className="flex flex-col gap-2">
										<Button type="button">Upload</Button>
										<Button
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
												handleDownload(form.getValues('type'))
											}}
											type="button"
											variant="ghost"
											size="sm"
											disabled={downloadFetcher.state !== 'idle'}
										>
											Download template
										</Button>
									</div>
								</div>
							}
						/>
					</>
				)}
				{step === FORM_STEPS.Verticals && (
					<>
						<FormField
							name="verticals"
							fieldType="checkboxTree"
							searchPlaceholder="Find verticals"
							options={ecosystems.map(ecosystem => ({
								id: String(ecosystem.id),
								label: ecosystem.name,
								children: ecosystem.verticals.map(vertical => ({
									id: String(vertical.id),
									label: vertical.name,
								})),
							}))}
						/>
					</>
				)}
				{step === FORM_STEPS.Settings && (
					<>
						{form.watch('type') === FILE_TYPES.Accounts && (
							<FormField
								name="contactFinderEnabled"
								fieldType="cardSwitch"
								size="sm"
								disableLabel
								heading="Contact finder"
								description="Finds contacts for accounts according to your IBP mapping"
							/>
						)}
						<FormField
							size="sm"
							name="contactWaterfallingEnabled"
							disableLabel
							fieldType="cardSwitch"
							heading="Contact waterfalling"
							description="Combines multiple vendors to find email and phone number"
							disableField={form.watch('type') === FILE_TYPES.Accounts}
							{...(form.watch('type') === FILE_TYPES.Accounts && {
								tooltip: 'Contact Finder requires Contact Waterfalling to be enabled',
							})}
						/>
					</>
				)}
				{step === FORM_STEPS.AdditionalInfo && (
					<>
						<FormField name="title" fieldType="text" label="Name" placeholder="Enter job name" />
						<FormField
							name="comment"
							fieldType="textarea"
							label="Note"
							placeholder="You can type extra notes about your job"
							rows={10}
						/>
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}
