import { z } from 'zod'

export const CompanyUserAPISchema = z.object({
	email: z.string(),
	roles: z.string().array(),
	accepted: z.boolean().optional(),
})

export const CompanyUserListAPISchema = CompanyUserAPISchema.array()

export const RolesAPISchema = z.record(z.string(), z.string())

export const InviteFormSchema = z.object({
	email: z.string().email(),
	role: z.enum(['user', 'read-only', 'enable-only']),
})

// export const InviteAPISchema = z.object({
//     token: z.string(),
// })
