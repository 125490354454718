import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { settingsCommandsEndpoints } from './endpoints'
import { SettingsCommandsListItemAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'settings', 'commands'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'list', companyId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.list(companyId),
	queryFn: async () =>
		await safeFetch(SettingsCommandsListItemAPISchema, settingsCommandsEndpoints.list(companyId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
}

export { queries as settingsCommandsQueries, keys as settingsCommandsQueriesKeys }
