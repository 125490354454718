import * as DialogPrimitive from '@radix-ui/react-dialog'
import { useEffect } from 'react'
import { useFetcher } from 'react-router-dom'
import Markdown from '#src/components/markdown'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { type PrioritizeItemExampleActionRes } from '#src/routes/prioritize/routes/example'
import { routes } from '#src/utils/routes'

export const SignalUsageExample = ({
	companyId,
	signalId,
	personaId,
	personaJobTitle,
}: {
	companyId: string
	signalId: string
	personaId: string
	personaJobTitle: string
}) => (
	<Dialog
		trigger={
			<button
				type="button"
				className="inline-flex items-center gap-1 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
			>
				<Icon name="magic-wand" size="sm" />
				Generate
			</button>
		}
		dialogHeading="Signal Usage Example"
		dialogDescription={personaJobTitle}
		contentProps={{
			className: 'min-w-full md:min-w-[42rem] lg:min-w-[48rem] max-w-[48rem]',
		}}
	>
		<SignalUsageExampleContent companyId={companyId} signalId={signalId} personaId={personaId} />
	</Dialog>
)

const SignalUsageExampleContent = ({
	companyId,
	signalId,
	personaId,
}: {
	companyId: string
	signalId: string
	personaId: string
}) => {
	const fetcher = useFetcher<PrioritizeItemExampleActionRes>()

	useEffect(() => {
		fetcher.submit(null, {
			method: 'PUT',
			action: routes.prioritize.signal.example({
				companyId: companyId,
				signalId: signalId,
				personaId: personaId,
			}),
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (fetcher.data && 'preview' in fetcher.data && fetcher.state === 'idle')
		return (
			<>
				<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6 pb-10">
					<Markdown className="text-body-lg text-neutral-1-fg">{fetcher.data.preview}</Markdown>
				</section>
				<section className="flex items-center justify-center px-10 py-4">
					<Button
						type="button"
						variant="outline"
						className="min-w-32 gap-1"
						onClick={() => {
							fetcher.submit(null, {
								method: 'PUT',
								action: routes.prioritize.signal.example({
									companyId: companyId,
									signalId: signalId,
									personaId: personaId,
								}),
							})
						}}
					>
						<Icon name="restart" size="sm" />
						Regenerate
					</Button>
				</section>
			</>
		)

	if (fetcher.state === 'submitting')
		return (
			<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6">
				<div className="flex flex-col items-center justify-center gap-3">
					<span className="text-[5.5rem] leading-none">
						<Icon name="loading-lg" size="font" className="animate-spin text-neutral-1-fg" />
					</span>
					<h1 className="text-body-lg font-medium text-neutral-1-fg">Generating...</h1>
				</div>
				<div>
					<DialogPrimitive.Close asChild>
						<Button type="button" variant="outline" className="min-w-32">
							Cancel
						</Button>
					</DialogPrimitive.Close>
				</div>
			</section>
		)

	return (
		<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6">
			<div className="flex flex-col items-center justify-center gap-3">
				<Icon name="warning-alt" size="xl" className="text-neutral-1-fg" />
				<h1 className="text-title-md text-neutral-1-fg">Ouch! Something went wrong.</h1>
			</div>
			<div>
				<Button
					type="button"
					className="min-w-32"
					onClick={() => {
						fetcher.submit(null, {
							method: 'PUT',
							action: routes.prioritize.signal.example({
								companyId: companyId,
								signalId: signalId,
								personaId: personaId,
							}),
						})
					}}
				>
					Try again
				</Button>
			</div>
		</section>
	)
}
