import { type LoaderFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { statisticsQuery } from '#src/routes/lead-research-agent/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	let lraStats = {
		launched: 0,
		completed: 0,
	}

	try {
		const req = await client.fetchQuery(statisticsQuery(company.id))
		lraStats = {
			launched: req.inProgressCount + req.inQueueCount,
			completed: req.completedCount,
		}
	} catch (e) {
		showToast({
			message: `LRA stats failed to fetch`,
			type: 'error',
		})
	}

	return {
		data: {
			lra: lraStats,
		},
	}
}
