import { type LoaderFunctionArgs } from 'react-router-dom'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

const paramsToObject = (params: URLSearchParams) => {
	const result: Record<string, string | string[]> = {}
	for (const [key, value] of params.entries()) {
		if (key in result) {
			if (Array.isArray(result[key])) {
				result[key] = [...result[key], value]
			} else {
				result[key] = [result[key], value]
			}
		} else {
			result[key] = value
		}
	}
	return result
}

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const filtersSelected = paramsToObject(new URL(request.url).searchParams)
	const plays = await client.fetchQuery(playsQueries.list(company.id))
	const personaType = [...new Set(plays.map(play => play.personaTypes).flat(1))]
	const expertise = [...new Set(plays.map(play => play.expertise).flat(1))]

	const filteredPlays = plays
		.filter(play => {
			// personaType filters
			if (!('personaType' in filtersSelected)) {
				return true
			}
			const personaTypes = Array.isArray(filtersSelected.personaType)
				? filtersSelected.personaType
				: [filtersSelected.personaType]
			return play.personaTypes.filter(item => personaTypes.includes(item)).length > 0
		})
		.filter(play => {
			// expertises filters
			if (!('expertise' in filtersSelected)) {
				return true
			}
			const expertises = Array.isArray(filtersSelected.expertise)
				? filtersSelected.expertise
				: [filtersSelected.expertise]
			return play.expertise.filter(item => expertises.includes(item)).length > 0
		})

	return {
		filters: {
			values: {
				personaType,
				expertise,
			},
			selected: filtersSelected,
		},
		data: {
			plays: filteredPlays,
		},
	}
}
