import { type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { signalsTemplatesQuery } from '#src/routes/prioritize/queries'
import { getCompany } from '#src/utils/server/company'

export type PrioritizeModuleLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const signals = await client.fetchQuery(signalsTemplatesQuery(company.id))

	return {
		signals,
	}
}
