import { type FieldMetadata, getInputProps, getSelectProps } from '@conform-to/react'
import { useMemo } from 'react'
import { type z } from 'zod'
import { Field, Select } from '#src/components/forms'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { usePersona } from '#src/hooks/usePersona'
import { useVertical } from '#src/hooks/useVertical'
import { CreateSessionFormSchema } from '#src/routes/enable/roleplay/session/schema'
import { useIsPending } from '#src/utils/misc'

const PickedSchema = CreateSessionFormSchema.pick({
	ecosystemId: true,
	verticalId: true,
	personaId: true,
	linkedinUrl: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

type PersonaMappingFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const PersonaMappingForm = ({ fields }: PersonaMappingFormProps) => {
	const { ecosystemsOptions } = useEcosystem()
	const { getVerticalsOptions } = useVertical()
	const { getPersonasOptionsById } = usePersona()
	const isPending = useIsPending()

	const verticalsOptions = useMemo(
		() => getVerticalsOptions(fields.ecosystemId.value as string),
		[fields.ecosystemId.value, getVerticalsOptions],
	)

	const personasOptions = useMemo(
		() => getPersonasOptionsById(fields.ecosystemId.value as string, fields.verticalId.value as string),
		[fields.ecosystemId.value, fields.verticalId.value, getPersonasOptionsById],
	)

	return (
		<>
			<Select
				labelProps={{
					children: 'Select Ecosystem',
				}}
				inputProps={{
					...getSelectProps(fields.ecosystemId),
					defaultValue: fields.ecosystemId.initialValue,
					value: fields.ecosystemId.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={ecosystemsOptions}
				errors={fields.ecosystemId.errors}
			/>
			<Select
				labelProps={{
					children: `Select Vertical`,
				}}
				inputProps={{
					...getSelectProps(fields.verticalId),
					defaultValue: fields.verticalId.initialValue,
					value: fields.verticalId.value,
					placeholder: '-',
					disabled: !verticalsOptions.length,
					className: 'bg-white',
				}}
				options={verticalsOptions}
				errors={fields.verticalId.errors}
			/>
			<Select
				labelProps={{
					children: 'Assign Persona',
				}}
				inputProps={{
					...getSelectProps(fields.personaId),
					contentProps: {
						position: 'popper',
						className: 'overflow-y-auto max-h-[20rem]',
					},
					className: 'h-12 bg-white',
					defaultValue: fields.personaId.initialValue,
					value: fields.personaId.value,
					placeholder: '-',
				}}
				options={personasOptions}
				errors={fields.personaId.errors}
			/>
			<Field
				labelProps={{
					children: 'LinkedIn URL',
				}}
				inputProps={{
					...getInputProps(fields.linkedinUrl as FieldMetadata<string>, {
						type: 'text',
					}),
					placeholder: 'Enter LinkedIn URL',
					readOnly: isPending,
				}}
				errors={fields.linkedinUrl?.errors}
				color="white"
				isLoading={isPending}
			/>
		</>
	)
}
