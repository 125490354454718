import { z } from 'zod'

export enum MessageStatus {
	Sending = 'sending',
	Error = 'error',
	Generating = 'generating',
}

export enum PersonalityTrait {
	Analytical = 'analytical',
	Amiable = 'amiable',
	Expressive = 'expressive',
	Driver = 'driver',
}
export enum EmotionalState {
	Skeptical = 'skeptical',
	Supportive = 'supportive',
	Busy = 'busy',
	Indecisive = 'indecisive',
}
export enum CommunicationStyle {
	Direct = 'direct',
	Indirect = 'indirect',
	Formal = 'formal',
	Informal = 'informal',
}
export enum DecisionMakingProcess {
	Collaborative = 'collaborative',
	Autonomous = 'autonomous',
	Hierarchical = 'hierarchical',
}

export const PersonalitySchema = z.object({
	personalityTrait: z.enum([
		PersonalityTrait.Analytical,
		PersonalityTrait.Amiable,
		PersonalityTrait.Expressive,
		PersonalityTrait.Driver,
	]),
	emotionalState: z.enum([
		EmotionalState.Skeptical,
		EmotionalState.Supportive,
		EmotionalState.Busy,
		EmotionalState.Indecisive,
	]),
	communicationStyle: z.enum([
		CommunicationStyle.Direct,
		CommunicationStyle.Indirect,
		CommunicationStyle.Formal,
		CommunicationStyle.Informal,
	]),
	decisionMakingProcess: z.enum([
		DecisionMakingProcess.Collaborative,
		DecisionMakingProcess.Autonomous,
		DecisionMakingProcess.Hierarchical,
	]),
	voice: z.string(),
})

export enum Status {
	WaitingForContact = 'waiting_for_contact',
	Closed = 'closed',
	Ready = 'ready',
	Initializing = 'initializing',
	Crashed = 'crashed',
}

export const StatusSchema = z.enum([
	Status.WaitingForContact,
	Status.Closed,
	Status.Ready,
	Status.Initializing,
	Status.Crashed,
])

export const ScenarioTemplateSchema = z.object({
	id: z.number(),
	name: z.string(),
	scenario: z.string().optional().nullable(),
	evaluation: z.string().optional(),
})

export const ScenarioTemplatesSchema = ScenarioTemplateSchema.array()

export const CreateScenarioTestSessionSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})
