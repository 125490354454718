import { type BaseSyntheticEvent, type FormEvent, type ReactNode } from 'react'
import { type FieldValues, FormProvider, type UseFormReturn } from 'react-hook-form'
import { type FormEncType, useSubmit } from 'react-router-dom'
import { objectToFormData } from '#src/utils/forms'

type FormWrapperProps<T extends FieldValues> = {
	children: ReactNode
	formProps: UseFormReturn<T>
	action?: string
	onSubmit?: (formData: FormData, data: T) => void
	method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE'
	className?: string
	formId?: string
	encType?: FormEncType
}

export function FormWrapper<T extends FieldValues>({
	children,
	formProps,
	method = 'POST',
	action,
	onSubmit,
	className,
	formId,
	encType,
}: FormWrapperProps<T>) {
	const submit = useSubmit()

	const handleFormSubmit = (data: T, e?: BaseSyntheticEvent) => {
		const submitter = (e?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement
		const btnValue = submitter?.name ? { [submitter.name]: submitter.value } : {}

		const submitData = { ...btnValue, ...data }
		const submitFormData = objectToFormData(submitData)

		if (onSubmit) {
			onSubmit(submitFormData, submitData)
			return
		}

		submit(submitFormData, {
			method,
			action,
			encType,
		})
	}

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		void formProps.handleSubmit(handleFormSubmit)(e)
	}

	return (
		<FormProvider {...formProps}>
			<form onSubmit={handleSubmit} className={className} id={formId}>
				{children}
			</form>
		</FormProvider>
	)
}
