import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import RouteBoundary from '#src/routes/route-boundary'
import { ReportDashboardView } from './routes/dashboard'

export default {
	element: <DefaultSidebarLayout />,
	path: 'report',
	children: [
		{
			index: true,
			element: <ReportDashboardView />,
			errorElement: <RouteBoundary />,
		},
	],
} satisfies RouteObject
