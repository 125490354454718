import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { valuePropositionEndpoints } from './endpoints'
import {
	ValuePropositionListAPISchema,
	ValuePropositionItemAPISchema,
	type ValuePropositionListQuerySchema,
} from './schemas'

const keys = {
	all: ['icp', 'company', 'valueProposition'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id'], query?: z.infer<typeof ValuePropositionListQuerySchema>) =>
		[...keys.all, 'list', companyId, query] as const,
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof ValuePropositionItemAPISchema>['id']) =>
		[...keys.all, 'item', companyId, itemId] as const,
}

const listQuery = (
	companyId: z.infer<typeof CompanySchema>['id'],
	query?: z.infer<typeof ValuePropositionListQuerySchema>,
) => ({
	queryKey: keys.list(companyId, query),
	queryFn: async () =>
		await safeFetch(ValuePropositionListAPISchema, valuePropositionEndpoints.list(companyId, query), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema>['id'],
	itemId: z.infer<typeof ValuePropositionItemAPISchema>['id'],
) => ({
	queryKey: keys.item(companyId, itemId),
	queryFn: async () =>
		await safeFetch(ValuePropositionItemAPISchema, valuePropositionEndpoints.item(companyId, itemId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as valuePropositionQueries, keys as valuePropositionQueriesKeys }
