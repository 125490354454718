import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { userEndpoints } from './endpoints'
import { CompanyUserListAPISchema, RolesAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'user'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'list', companyId] as const,
	roles: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'roles', companyId] as const,
	allRoles: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'all-roles', companyId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.list(companyId),
	queryFn: async () =>
		await safeFetch(CompanyUserListAPISchema, userEndpoints.list(companyId), {
			method: 'GET',
		}),
})

const rolesQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.roles(companyId),
	staleTime: Infinity,
	queryFn: async () =>
		await safeFetch(RolesAPISchema, userEndpoints.roles(companyId), {
			method: 'GET',
		}),
})

const allRolesQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.allRoles(companyId),
	staleTime: Infinity,
	queryFn: async () =>
		await safeFetch(RolesAPISchema, userEndpoints.allRoles(companyId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	roles: rolesQuery,
	allRoles: allRolesQuery,
}

export { queries as userQueries, keys as userQueriesKeys }
