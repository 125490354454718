import * as ToastPrimitive from '@radix-ui/react-toast'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, useEffect, useState, useRef } from 'react'
import { cn } from '#src/utils/misc'

export const Toast = forwardRef<
	ElementRef<typeof ToastPrimitive.Root>,
	ComponentPropsWithoutRef<typeof ToastPrimitive.Root> & {
		shouldOpen: boolean
	}
>(({ className, children, shouldOpen, ...props }, ref) => {
	const [count, setCount] = useState(0)
	const timerRef = useRef(0)
	const timer = timerRef.current

	useEffect(() => {}, [])

	useEffect(() => {
		if (shouldOpen) {
			window.clearTimeout(timerRef.current)
			timerRef.current = window.setTimeout(() => {
				setCount(s => s + 1)
			}, 100)
		}

		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldOpen])

	return (
		<>
			{Array.from({ length: count }).map((_, index) => (
				<ToastPrimitive.Root
					key={index}
					ref={ref}
					className={cn(
						'rounded bg-[#393939] p-4 text-body-md text-neutral-inverse-fg shadow',
						'radix-state-open:animate-in radix-state-open:slide-in-from-right',
						'radix-state-closed:animate-out radix-state-closed:slide-out-to-right',
						'radix-swipe-end:animate-out radix-swipe-end:slide-out-to-right',
						'radix-swipe-move:translate-x-[var(--radix-toast-swipe-move-x)]',
						'radix-swipe-cancel:translate-x-0',
						className,
					)}
					{...props}
				>
					{children}
				</ToastPrimitive.Root>
			))}
		</>
	)
})
Toast.displayName = ToastPrimitive.Root.displayName

export const ToastDescription = ToastPrimitive.Description

export const ToastViewport = forwardRef<
	ElementRef<typeof ToastPrimitive.Viewport>,
	ComponentPropsWithoutRef<typeof ToastPrimitive.Viewport>
>(({ className, children, ...props }, ref) => (
	<ToastPrimitive.Viewport
		ref={ref}
		{...props}
		className={cn('fixed bottom-0 right-0 z-50 m-0 flex w-max list-none flex-col gap-2.5 p-6', className)}
	>
		{children}
	</ToastPrimitive.Viewport>
))
ToastViewport.displayName = ToastPrimitive.Viewport.displayName
