import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { settingsCommandsMutations } from '#src/api/icp/company/settings/commands/mutations'
import { SettingsCommandsFormParserSchema } from '#src/api/icp/company/settings/commands/schemas'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['commandId'])

	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: SettingsCommandsFormParserSchema,
	})

	if (submission.status !== 'success') {
		showToast({
			message: 'Failed to parse form data',
			type: 'error',
		})
		return {
			ok: false,
		}
	}

	try {
		await settingsCommandsMutations.update(company.id, Number(params.commandId), submission.value)
		showToast({
			message: 'Default command edited',
			type: 'success',
		})
		return {
			ok: true,
		}
	} catch (e) {
		if (e instanceof Response) {
			const response = (await e.json()) as { message: string; errors: Record<string, string[]> }
			showToast({
				message: response.message,
				type: 'error',
			})
			return {
				ok: false,
			}
		}
		showToast({
			message: 'Failed api response. Please contact support',
			type: 'error',
		})
		return {
			ok: false,
		}
	}
}
