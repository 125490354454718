import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { FORM_STEPS } from '#src/routes/lead-research-agent/constants'
import { createJob } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'
import {
	LeadResearchAgentJobFormSchema,
	LeadResearchAgentJobFileFormSchema,
	LeadResearchAgentJobAdditionalInfoFormSchema,
	LeadResearchAgentJobVerticalsFormSchema,
	LeadResearchAgentJobSettingsFormSchema,
} from '#src/routes/lead-research-agent/schema'
import { parseFormData } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { companyId } = await getCompany(params)
	const formData = await request.formData()

	const submission = parseFormData(formData, LeadResearchAgentJobFormSchema)

	try {
		await createJob(submission.value)
		await client.invalidateQueries({
			queryKey: leadResearchAgentJobsKeys.all,
		})

		showToast({
			message: 'Job created successfully',
			type: 'success',
		})

		return redirect(routes.leadResearchAgent.index({ companyId }))
	} catch (e) {
		try {
			const res = (e as Response).clone()
			const body = (await res.json()) as {
				success: boolean
				message: string
				errors?: Record<string, string[]>
			}
			showToast({
				message: body.message,
				type: 'error',
			})

			const failedStep = getEarliestFailedStepFromResErrors(body.errors, [
				{
					step: FORM_STEPS.File,
					schema: LeadResearchAgentJobFileFormSchema,
				},
				{
					step: FORM_STEPS.Verticals,
					schema: LeadResearchAgentJobVerticalsFormSchema,
				},
				{
					step: FORM_STEPS.Settings,
					schema: LeadResearchAgentJobSettingsFormSchema,
				},
				{
					step: FORM_STEPS.AdditionalInfo,
					schema: LeadResearchAgentJobAdditionalInfoFormSchema,
				},
			])

			return {
				ok: false,
				errors: body.errors,
				failedStep,
			}
		} catch (err) {
			showToast({
				message: 'Unexpected error while creating Lead Research Agent job',
				type: 'error',
			})
			return {
				ok: false,
			}
		}
	}
}

const getEarliestFailedStepFromResErrors = <T>(
	errors: Record<string, string[]> | undefined,
	steps: { step: T; schema: z.SomeZodObject }[],
): T | undefined => {
	let res: T | undefined = undefined
	if (!errors) {
		return res
	}
	steps.some(({ step, schema }) => {
		const shape = schema.shape
		if (res) {
			return true
		}
		Object.keys(shape).some(key => {
			if (key in errors) {
				res = step
				return true
			}
		})
	})
	return res
}
