import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { adminApi } from '#src/utils/endpoints'

const base = `${adminApi}/company`

const endpoints = {
	create: () => base,
	delete: (companyId: z.infer<typeof CompanySchema>['id']) => `${base}/${companyId}`,
	updateDashboard: (companyId: z.infer<typeof CompanySchema>['id']) => `${base}/${companyId}/dashboard`,
}

export { endpoints as adminCompanyEndpoints }
