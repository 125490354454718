import { forwardRef, type TextareaHTMLAttributes, useEffect, useImperativeHandle, useRef } from 'react'
import { fileInputClasses, inputCommonClasses } from '#src/theme'
import { cn } from '#src/utils/misc'

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	autoExpand?: boolean
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, autoExpand, ...props }, ref) => {
	const inputRef = useRef<HTMLTextAreaElement>(null)
	useImperativeHandle(ref, () => inputRef.current!, [inputRef])

	useEffect(() => {
		if (autoExpand && inputRef.current) {
			const textarea = inputRef.current
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}, [props.value, autoExpand])

	return (
		<textarea
			className={cn(
				inputCommonClasses,
				fileInputClasses,
				'flex h-full w-full rounded px-3 py-2.5 transition-none',
				className,
			)}
			rows={props.rows ?? 3}
			ref={inputRef}
			{...props}
		/>
	)
})
Textarea.displayName = 'Textarea'

export { Textarea }
