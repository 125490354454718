import { type Config } from 'tailwindcss'

export const extendedTheme = {
	screens: {
		'3xl': '1800px',
	},
	keyframes: {
		'acc-slide-down': {
			from: {
				height: '0',
				opacity: '0',
			},
			to: {
				height: 'var(--radix-accordion-content-height)',
				opacity: '1',
			},
		},
		'acc-slide-up': {
			from: {
				height: 'var(--radix-accordion-content-height)',
				opacity: '1',
			},
			to: {
				height: '0',
				opacity: '0',
			},
		},
		loading: {
			'0%': { left: '0', right: '100%' },
			'50%': { left: '0', right: '0' },
			'100%': { left: '100%', right: '0' },
		},
		'pulsing-rings': {
			'0%': { opacity: '0', transform: 'scale(0.2,0.2)' },
			'50%': { opacity: '1' },
			'100%': { opacity: '0', transform: 'scale(1.2,1.2)' },
		},
	},
	animation: {
		loading: 'loading 1.2s ease infinite',
		'pulsing-rings-1': 'pulsing-rings 2s ease-in-out infinite both 400ms',
		'pulsing-rings-2': 'pulsing-rings 2s ease-in-out infinite both 300ms',
		'pulsing-rings-3': 'pulsing-rings 2s ease-in-out infinite both',
		'acc-slide-down': 'acc-slide-down 200ms ease-out forwards',
		'acc-slide-up': 'acc-slide-up 200ms ease-out forwards',
	},
	backgroundImage: {
		'loading-gradient': 'linear-gradient(to right, #F3F4F6, #1237E0)',
	},
	backgroundColor: {
		'loading-mask': '#F3F4F6',
	},
	borderColor: {
		DEFAULT: 'var(--eg-border-elements)',
	},
	colors: {
		neutral: {
			'1': {
				bg: {
					DEFAULT: 'hsl(var(--c-white) / 100%)',
					disabled: 'hsl(var(--c-gray-10) / 100%)',
					hover: 'hsl(var(--c-gray-50) / 12%)',
					pressed: 'hsl(var(--c-gray-30) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-gray-50) / 20%)',
						hover: 'hsl(var(--c-gray-50) / 25%)',
					},
				},
				fg: {
					DEFAULT: 'hsl(var(--c-gray-100) / 100%)',
					disabled: 'hsl(var(--c-gray-100) / 25%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-coolgray-20) / 60%)',
				},
			},
			'2': {
				bg: {
					DEFAULT: 'hsl(var(--c-gray-10) / 100%)',
					disabled: 'hsl(var(--c-gray-10) / 100%)',
					hover: 'hsl(var(--c-gray-50) / 12%)',
					pressed: 'hsl(var(--c-gray-30) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-gray-50) / 20%)',
						hover: 'hsl(var(--c-gray-50) / 25%)',
					},
				},
				fg: {
					DEFAULT: 'hsl(var(--c-gray-70) / 100%)',
					selected: 'hsl(var(--c-gray-100) / 100%)',
					disabled: 'hsl(var(--c-gray-100) / 25%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-coolgray-20) / 100%)',
					disabled: 'hsl(var(--c-coolgray-10) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-coolgray-30) / 100%)',
					},
				},
			},
			'3': {
				bg: {
					DEFAULT: 'hsl(var(--c-gray-10) / 100%)',
					disabled: 'hsl(var(--c-gray-10) / 100%)',
					hover: 'hsl(var(--c-gray-50) / 25%)',
					pressed: 'hsl(var(--c-gray-30) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-gray-20) / 100%)',
						hover: 'hsl(var(--c-gray-40) / 100%)',
					},
				},
				fg: {
					DEFAULT: 'hsl(var(--c-gray-50) / 100%)',
					disabled: 'hsl(var(--c-gray-100) / 25%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-coolgray-30) / 100%)',
				},
			},
			'4': {
				fg: {
					DEFAULT: 'hsl(var(--c-gray-40) / 100%)',
					disabled: 'hsl(var(--c-gray-100) / 25%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-coolgray-50) / 100%)',
				},
			},
			'5': {
				bg: {
					DEFAULT: 'hsl(var(--c-coolgray-100) / 100%)',
				},
			},
			inverse: {
				bg: {
					DEFAULT: 'hsl(var(--c-gray-80) / 100%)',
					hover: 'hsl(var(--c-gray-70) / 100%)',
				},
				fg: {
					DEFAULT: 'hsl(var(--c-white) / 100%)',
					disabled: 'hsl(var(--c-gray-70) / 100%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-coolgray-100) / 100%)',
				},
			},
		},
		brand: {
			'1': {
				bg: {
					DEFAULT: 'hsl(var(--c-blue-10) / 100%)',
					disabled: 'hsl(var(--c-blue-10) / 35%)',
					hover: 'hsl(var(--c-blue-20) / 100%)',
				},
				fg: {
					DEFAULT: 'hsl(var(--c-blue-60) / 100%)',
					disabled: 'hsl(var(--c-blue-20) / 100%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-blue-60) / 100%)',
					hover: 'hsl(var(--c-blue-70) / 100%)',
					pressed: 'hsl(var(--c-blue-90) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-blue-80) / 100%)',
					},
				},
			},
			'2': {
				fg: {
					DEFAULT: 'hsl(var(--c-blue-40) / 100%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-blue-40) / 100%)',
				},
			},
			'3': {
				bg: {
					DEFAULT: 'hsl(var(--c-blue-60) / 100%)',
					hover: 'hsl(var(--c-blue-70) / 100%)',
					pressed: 'hsl(var(--c-blue-90) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-blue-80) / 100%)',
					},
				},
				fg: {
					DEFAULT: 'hsl(var(--c-blue-10) / 100%)',
				},
			},
			inverse: {
				bg: {
					DEFAULT: 'hsl(var(--c-blue-100) / 100%)',
				},
				fg: {
					DEFAULT: 'hsl(var(--c-blue-40) / 100%)',
				},
			},
		},
		link: {
			DEFAULT: 'hsl(var(--c-blue-60) / 100%)',
			hover: 'hsl(var(--c-blue-70) / 100%)',
			pressed: 'hsl(var(--c-purple-60) / 100%)',
			inverse: 'hsl(var(--c-blue-40) / 100%)',
		},
		status: {
			danger: {
				bg: {
					DEFAULT: 'hsl(var(--c-red-60) / 100%)',
					hover: 'hsl(var(--c-red-70) / 100%)',
					pressed: 'hsl(var(--c-red-90) / 100%)',
					selected: {
						DEFAULT: 'hsl(var(--c-red-80) / 100%)',
					},
				},
				fg: {
					DEFAULT: 'hsl(var(--c-red-60) / 100%)',
				},
				bd: {
					DEFAULT: 'hsl(var(--c-red-60) / 100%)',
				},
			},
			success: {
				bg: {
					DEFAULT: 'hsl(var(--c-green-70) / 100%)',
				},
				fg: {
					DEFAULT: 'hsl(var(--c-green-70) / 100%)',
				},
			},
			warning: {
				bg: {
					DEFAULT: 'hsl(var(--c-orange-60) / 100%)',
				},
				fg: {
					DEFAULT: 'hsl(var(--c-orange-60) / 100%)',
				},
			},
		},
		overlay: {
			DEFAULT: 'hsl(var(--c-gray-100) / 50%)',
		},
		green: {
			10: 'hsl(var(--c-green-10) / 100%)',
			20: 'hsl(var(--c-green-20) / 100%)',
			30: 'hsl(var(--c-green-30) / 100%)',
			40: 'hsl(var(--c-green-40) / 100%)',
			70: 'hsl(var(--c-green-70) / 100%)',
			80: 'hsl(var(--c-green-80) / 100%)',
			90: 'hsl(var(--c-green-90) / 100%)',
			100: 'hsl(var(--c-green-100) / 100%)',
		},
		blue: {
			10: 'hsl(var(--c-blue-10) / 100%)',
			20: 'hsl(var(--c-blue-20) / 100%)',
			60: 'hsl(var(--c-blue-60) / 100%)',
			70: 'hsl(var(--c-blue-70) / 100%)',
		},
		purple: {
			10: 'hsl(var(--c-purple-10) / 100%)',
			20: 'hsl(var(--c-purple-20) / 100%)',
			70: 'hsl(var(--c-purple-70) / 100%)',
		},
		red: {
			10: 'hsl(var(--c-red-10) / 100%)',
			20: 'hsl(var(--c-red-20) / 100%)',
			60: 'hsl(var(--c-red-60) / 100%)',
		},
		orange: {
			10: 'hsl(var(--c-orange-10) / 100%)',
			20: 'hsl(var(--c-orange-20) / 100%)',
			70: 'hsl(var(--c-orange-70) / 100%)',
		},
		yellow: {
			10: 'hsl(var(--c-yellow-10) / 100%)',
			20: 'hsl(var(--c-yellow-20) / 100%)',
			70: 'hsl(var(--c-yellow-70) / 100%)',
		},
		gray: {
			10: 'hsl(var(--c-gray-10) / 100%)',
			20: 'hsl(var(--c-gray-20) / 100%)',
			30: 'hsl(var(--c-gray-30) / 100%)',
			40: 'hsl(var(--c-gray-40) / 100%)',
			50: 'hsl(var(--c-gray-50) / 100%)',
			60: 'hsl(var(--c-gray-60) / 100%)',
			70: 'hsl(var(--c-gray-70) / 100%)',
			80: 'hsl(var(--c-gray-80) / 100%)',
			90: 'hsl(var(--c-gray-90) / 100%)',
			100: 'hsl(var(--c-gray-100) / 100%)',
		},
		coolgray: {
			60: 'hsl(var(--c-coolgray-60) / 100%)',
		},
		indigo: {
			10: 'hsl(var(--c-indigo-10) / 100%)',
			20: 'hsl(var(--c-indigo-20) / 100%)',
			30: 'hsl(var(--c-indigo-30) / 100%)',
			40: 'hsl(var(--c-indigo-40) / 100%)',
			50: 'hsl(var(--c-indigo-50) / 100%)',
			60: 'hsl(var(--c-indigo-60) / 100%)',
			70: 'hsl(var(--c-indigo-70) / 100%)',
			80: 'hsl(var(--c-indigo-80) / 100%)',
			90: 'hsl(var(--c-indigo-90) / 100%)',
			100: 'hsl(var(--c-indigo-100) / 100%)',
		},
	},
} satisfies Config['theme']
