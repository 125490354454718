import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { type z } from 'zod'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { CopyValidatePersonaAPIResSchema } from '#src/routes/calibrate/personas/schema'

export type CopyPersonasLoaderLoaderType = Awaited<ReturnType<ReturnType<typeof copyPersonasLoader>>>

export const copyPersonasLoader =
	(queryClient: QueryClient) =>
	({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const res = queryClient.getQueryData<{
			data: z.infer<typeof CopyValidatePersonaAPIResSchema>
			personaIdsArray: string[]
		}>(personaKeys.copyValidate(params.companyId))

		const emptyReturn = {
			ecosystemsWithValidatedPersonas: [],
			selectedPersonasIds: [],
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
		}

		try {
			if (!res?.data) {
				return emptyReturn
			}

			const { data, personaIdsArray } = res

			const parsedData = CopyValidatePersonaAPIResSchema.parse(data)

			return {
				ecosystemsWithValidatedPersonas: parsedData,
				selectedPersonasIds: personaIdsArray,
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}
		} catch (error) {
			return emptyReturn
		}
	}
