import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { CompaniesSchema, CompanySchema } from './schema'

export const companyKeys = {
	all: ['company'] as const,
	list: () => [...companyKeys.all, 'list'] as const,
	detail: (id: string) => [...companyKeys.all, 'details', id] as const,
}

export const companyQuery = (id: string) => ({
	queryKey: companyKeys.detail(id),
	queryFn: async () =>
		await safeFetch(CompanySchema, api.company.details(id), {
			method: 'GET',
		}),
})

export const companiesQuery = () => ({
	queryKey: companyKeys.list(),
	queryFn: async () =>
		await safeFetch(CompaniesSchema, api.company.list, {
			method: 'GET',
		}),
})
