import { captureException } from '@sentry/react'
import { redirect } from 'react-router-dom'
import {
	AUTH_REDIRECT_KEY,
	authorizeSession,
	checkIsAuth0Callback,
	checkSession,
	isAuth0Error,
	refreshSession,
} from '#src/routes/auth/utils'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async () => {
	try {
		if (checkIsAuth0Callback()) {
			await authorizeSession()
		} else {
			await refreshSession()
		}
	} catch (error) {
		if (isAuth0Error(error)) {
			captureException(error)

			return {
				ok: false,
				result: ['An error occurred while trying to authenticate'],
			}
		}

		throw error
	}

	if (checkSession()) {
		const redirectUrl = localStorage.getItem(AUTH_REDIRECT_KEY)
		if (redirectUrl) {
			localStorage.removeItem(AUTH_REDIRECT_KEY)

			throw redirect(redirectUrl)
		}

		throw redirect('/')
	} else {
		return null
	}
}
