import { memo } from 'react'
import { NextButton, PageButton, Pagination, PrevButton } from 'react-headless-pagination'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

export type PaginatorProps = {
	className?: string
	currentPage: number
	setCurrentPage: (page: number) => void
	totalPages: number
	paginatorPosition?: 'left' | 'center' | 'right'
}

/**
 * Paginator component to render pagination controls.
 * Integrates with the `usePaginator` hook.
 */
const Paginator = memo(
	({ currentPage, setCurrentPage, totalPages, paginatorPosition = 'right', className }: PaginatorProps) => {
		const isFirstPage = currentPage === 1
		const isLastPage = currentPage === totalPages

		if (totalPages === 1) return null

		const paginatorPositionClasses = cn('flex', {
			'justify-start': paginatorPosition === 'left',
			'justify-center': paginatorPosition === 'center',
			'justify-end': paginatorPosition === 'right',
		})

		return (
			<Pagination
				className={cn('mb-3 mt-6 flex h-10 w-full select-none list-none items-center', className)}
				currentPage={currentPage - 1}
				edgePageCount={1}
				middlePagesSiblingCount={1}
				setCurrentPage={setCurrentPage}
				totalPages={totalPages}
				truncableClassName="w-10 px-0.5 text-center"
				truncableText="..."
			>
				<div className={cn('flex flex-grow items-center text-body-md', paginatorPositionClasses)}>
					<PrevButton
						className={cn(
							'flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm hover:bg-brand-1-bg-disabled',
							isFirstPage && 'mr-0.5 text-neutral-3-bd',
						)}
					>
						<Icon name="chevron-down" size="sm" className="rotate-90" />
					</PrevButton>

					<PageButton
						activeClassName="bg-brand-1-bg text-brand-1-fg"
						className="mx-[1px] flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm"
						inactiveClassName="text-neutral-2-fg hover:bg-brand-1-bg-disabled"
					/>
					<NextButton
						className={cn(
							'flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm hover:bg-brand-1-bg-disabled',
							isLastPage && 'ml-0.5 text-neutral-3-bd',
						)}
					>
						<Icon name="chevron-down" size="sm" className="-rotate-90" />
					</NextButton>
				</div>
			</Pagination>
		)
	},
)

Paginator.displayName = 'Paginator'

export default Paginator
