import * as Sentry from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'
import Root, { loader as rootLoader } from '#src/routes/_layout/root'
import mainRouter from '#src/routes/_router/main'
import authRouter from '#src/routes/auth/router'
import copilotRouter from '#src/routes/copilot/router'
import RootBoundary from '#src/routes/root-boundary'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const createAppRouter = (client: QueryClient) =>
	sentryCreateBrowserRouter([
		{
			element: <Root />,
			errorElement: <RootBoundary />,
			loader: rootLoader,
			children: [authRouter, copilotRouter, mainRouter(client)],
		},
	])
