import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { updateSignal } from '../mutations'
import { signalsKeys } from '../queries'
import { SignalConfigFormSchema } from '../schema'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['signalId'])

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: SignalConfigFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	await updateSignal(company.id, params.signalId, submission.value)

	void client.invalidateQueries({
		queryKey: signalsKeys.all,
	})

	return { result: submission.reply() }
}
