// global.d.ts
import { z, type ZodType, type ZodTypeAny, type ZodTypeDef } from 'zod'
import { type FormFieldType } from '#src/components/forms/v2/FormField'
import { nonEmptyArray, nonEmptyRegions, RegionsSelectFieldSchema, zString } from '#src/utils/validation'

export const MetaSchema = z.object({
	current_page: z.number(),
	from: z.number().nullable(),
	last_page: z.number(),
	per_page: z.number(),
	to: z.number().nullable(),
	total: z.number(),
	path: z.string(),
	links: z
		.array(
			z.object({
				url: z.string().nullable(),
				label: z.string(),
				active: z.boolean(),
			}),
		)
		.optional(),
})

export const LinksSchema = z.object({
	first: z.string().nullable(),
	last: z.string().nullable(),
	next: z.string().nullable(),
	prev: z.string().nullable(),
})

export const PaginatedAPIResSchema = <T extends z.ZodTypeAny>(dataSchema: T) => {
	return z.object({
		data: z.array(dataSchema),
		meta: MetaSchema,
		links: LinksSchema.optional(),
	})
}

export const API_TO_FIELD_TYPE_SCHEMA_MAP: Record<APIFieldType, ZodTypeAny> = {
	select: zString(),
	text: zString(),
	number: zString(),

	// Range can be rendered inside Select
	range: z.array(zString()).or(zString()),
	regions: nonEmptyRegions(),
	multiselect: nonEmptyArray(),
	radio: zString(),

	// Radio
	boolean: zString(),
}

export const getFieldSchemaByType = ({
	fieldType,
	isOptional,
	isNullable,
}: {
	fieldType: APIFieldType
	isOptional: boolean
	isNullable: boolean
}): ZodType<unknown, ZodTypeDef, unknown> => {
	const baseSchemas: Record<APIFieldType, ZodType<unknown, ZodTypeDef, unknown>> = {
		multiselect: z.array(z.string()),
		regions: RegionsSelectFieldSchema,
		range: z.array(z.string()).or(z.string()),
		select: z.string(),
		text: z.string(),
		number: z.string(),
		radio: z.string(),
		boolean: z.string(),
	}

	const base = baseSchemas[fieldType]
	const required = API_TO_FIELD_TYPE_SCHEMA_MAP[fieldType] as ZodType<unknown, ZodTypeDef, unknown>

	const applyModifiers = (schema: ZodType<unknown, ZodTypeDef, unknown>) => {
		let modified = schema

		if (isNullable) modified = modified.nullable()
		if (isOptional) modified = modified.optional()
		if (isOptional && isNullable) modified = modified.default(null)

		return modified
	}

	if (isOptional) {
		return applyModifiers(base)
	}

	return applyModifiers(required)
}

export type APIFieldType =
	| Extract<FormFieldType, 'text' | 'number' | 'select' | 'multiselect' | 'regions' | 'range' | 'radio'>
	| 'boolean'
export const API_FIELD_TYPE_ENUM = Object.keys(API_TO_FIELD_TYPE_SCHEMA_MAP) as [APIFieldType, ...APIFieldType[]]
