import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type SettingsCommandsItemAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/settings/commands`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	update: (
		companyId: z.infer<typeof CompanySchema>['id'],
		commandId: z.infer<typeof SettingsCommandsItemAPISchema>['id'],
	) => `${base(companyId)}/${commandId}`,
}

export { endpoints as settingsCommandsEndpoints }
