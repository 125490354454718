import { Fragment, memo, type ReactNode, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type CriteriaSaveLoaderType } from '../../routes/save'
import { type CriterionAnswerAPISchema, type CriterionQuestionAPISchema } from '../../schema'
import { generateCriterionAnswerPerVertical } from '../../utils'
import { type FormData } from '../save'

type FormVerticalsSectionProps = {
	criterion?: z.infer<typeof CriterionQuestionAPISchema>
	isInitialized: boolean
}

export const FormVerticalsSection = memo(({ criterion, isInitialized }: FormVerticalsSectionProps) => {
	const {
		data: { verticals },
	} = useLoaderData() as CriteriaSaveLoaderType
	const { watch } = useFormContext<FormData>()
	const formValues = watch()
	const previousSectionsRef = useRef<ReactNode | null>(null)

	const updatedCriterion = useMemo(() => {
		if (!formValues || !isInitialized) return null

		return {
			id: criterion?.id,
			name: formValues.name,
			type: formValues.type,
			unit:
				!!formValues.checkboxUnit?.length &&
				(formValues.unit === 'other' && formValues.otherUnit?.trim() ? formValues.otherUnit : formValues.unit),
			options:
				formValues.type === 'boolean'
					? ['Yes', 'No']
					: formValues.options
							?.map(item => {
								if (formValues.type === 'range' && Array.isArray(item)) {
									return item.filter(Boolean).join(',')
								}
								return item
							})
							.filter((value): value is string => Boolean(value)),
		}
	}, [formValues, isInitialized, criterion?.id])

	const verticalSections = useMemo(() => {
		if (!updatedCriterion || !verticals.length) return null

		const sections = verticals.map(item => (
			<Fragment key={item.id}>
				{generateCriterionAnswerPerVertical(
					updatedCriterion as z.infer<typeof CriterionQuestionAPISchema>,
					{ vertical: item.name, verticalId: item.id } as z.infer<typeof CriterionAnswerAPISchema>,
				)}
			</Fragment>
		))
		previousSectionsRef.current = <>{sections}</>

		return sections
	}, [updatedCriterion, verticals])

	return isInitialized ? <>{verticalSections}</> : previousSectionsRef.current
})

FormVerticalsSection.displayName = 'FormVerticalsSection'
