import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { AdminCompanyDashboardFormSchema } from '#src/api/icp/admin/company/schemas'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])

	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: AdminCompanyDashboardFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	try {
		await adminCompanyMutations.updateDashboard(params.companyId, submission.value)

		return redirect(routes.admin.index)
	} catch (error) {
		let errorMessage = 'Something went wrong with updating company dashbaord details. Try again later.'

		if (error instanceof Response) {
			const errorBody = (await error.json()) as { message?: string }

			if (errorBody?.message) {
				errorMessage = errorBody.message
			}
		}

		return {
			ok: false,
			result: submission.reply({
				formErrors: [errorMessage],
			}),
		}
	}
}
