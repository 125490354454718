import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { type z } from 'zod'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import PlayFormFields from './components/PlayFormFields'

const FORM_ID = 'create-play-form'

export const View = () => {
	const { companyId } = useCompany()
	const navigate = useNavigate()

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: '',
			instructions: '',
			inCopilot: true,
			inCrm: false,
			personaTypes: [],
			expertise: [],
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && navigate(routes.enable.plays.index({ companyId }))}
			dialogHeading="Create new Play"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Create' },
			]}
		>
			<FormWrapper formId={FORM_ID} formProps={form} className="flex flex-col gap-6">
				<PlayFormFields />
			</FormWrapper>
		</Dialog>
	)
}
