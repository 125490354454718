import { type ReactNode } from 'react'
import { Surface } from '#src/components/ui/surface'

type Props = {
	title: string
	description?: ReactNode
	children: ReactNode
}

const GuestPageLayout = (props: Props) => {
	const { title, description, children } = props

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center">
			<Surface className="flex w-full max-w-md flex-col items-center gap-8 p-8">
				<div className="flex flex-col gap-2 text-center">
					<h1 className="text-title-md text-neutral-1-fg">{title}</h1>
					{!!description && <p className="text-body-md text-neutral-2-fg">{description}</p>}
				</div>
				{children}
			</Surface>
		</main>
	)
}

export default GuestPageLayout
