import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { client } from '#src/main'
import { CRITERION_TYPE_ENUM } from '#src/routes/calibrate/verticals/criteria/constants'
import { criterionQuery } from '#src/routes/calibrate/verticals/criteria/queries'
import { generateSaveCriterionContext } from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalsQuery } from '#src/routes/calibrate/verticals/queries'
import { VerticalListAPISchema } from '#src/routes/calibrate/verticals/schema'
import { validateRouteParams } from '#src/utils/misc'
import { CriterionQuestionAPISchema } from '../schema'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
		ecosystemId: z.string(),
		type: CRITERION_TYPE_ENUM,
		defaultValues: z.record(z.union([z.string(), z.number(), z.boolean(), z.array(z.string())])),
	}),
	data: z.object({
		criterion: CriterionQuestionAPISchema.optional(),
		verticals: VerticalListAPISchema,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient, type: z.infer<typeof LoaderSchema>['values']['type']) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])
		let criterion
		let defaultValues = {}

		const verticals = await client.fetchQuery(verticalsQuery(params.companyId, Number(params.ecosystemId)))

		if (params.criterionId) {
			criterion = await queryClient.fetchQuery(criterionQuery(params.companyId, params.criterionId))

			if (criterion) {
				const { defaultValues: verticalsDefaults } = generateSaveCriterionContext(
					verticals,
					criterion.type ?? 'text',
					type,
					criterion,
				)
				defaultValues = verticalsDefaults
			}
		}

		return {
			values: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				type,
				defaultValues,
			},
			data: {
				verticals,
				criterion,
			},
		}
	}
