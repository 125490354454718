import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import {
	type CopyPersonaFormSchema,
	CopyValidatePersonaAPIResSchema,
	type CopyValidatePersonaPayloadSchema,
	PersonaAPISchema,
	type PersonaEditFormSchema,
	PersonaListAPISchema,
	type PersonaUpdateFormSchema,
} from './schema'

export const createPersonas = async (companyId: string, data: z.infer<typeof PersonaEditFormSchema>) => {
	return await safeFetch(PersonaListAPISchema, api.persona.create(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const updatePersona = async (
	companyId: string,
	personaId: string,
	data: z.infer<typeof PersonaUpdateFormSchema>,
) => {
	return await safeFetch(PersonaAPISchema, api.persona.details(companyId, personaId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}

export const deletePersona = async (companyId: string, personaId: string) => {
	return await fetch(api.persona.details(companyId, personaId), {
		method: 'DELETE',
	})
}

export const recalculatePersonas = async (companyId: string, verticalId: string) => {
	try {
		return await fetch(api.vertical.recalculate(companyId, verticalId), {
			method: 'PUT',
		})
	} catch (error) {
		if (error instanceof Response && error.status === 429) {
			return Promise.resolve(new Response(null, { status: 200 }))
		}

		throw error
	}
}

export const recalculatePersona = async (companyId: string, personaId: string) => {
	try {
		return await fetch(api.persona.recalculate(companyId, personaId), {
			method: 'PUT',
		})
	} catch (error) {
		if (error instanceof Response && error.status === 429) {
			return Promise.resolve(new Response(null, { status: 200 }))
		}

		throw error
	}
}

export const copyValidatePersonas = async (
	companyId: string,
	data: z.infer<typeof CopyValidatePersonaPayloadSchema>,
) => {
	return await safeFetch(CopyValidatePersonaAPIResSchema, api.persona.copyValidate(companyId), {
		method: 'PUT',
		body: JSON.stringify(data),
	})
}

export const copyPersonas = async (companyId: string, data: z.infer<typeof CopyPersonaFormSchema>) => {
	return await fetch(api.persona.copy(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { 'content-type': 'application/json' },
	})
}

export const deletePersonas = async (companyId: string, data: z.infer<typeof CopyValidatePersonaPayloadSchema>) => {
	return await fetch(api.persona.batchDelete(companyId), {
		method: 'DELETE',
		body: JSON.stringify(data),
		headers: { 'content-type': 'application/json' },
	})
}
