import { useMemo, type MouseEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { type z } from 'zod'
import { PersonaDetailsCard } from '#src/components/shared/personas'
import { Button } from '#src/components/ui/button'
import { Surface } from '#src/components/ui/surface'
import useCompany from '#src/hooks/useCompany'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { type PersonasSchema } from '#src/routes/enable/personas/schema'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type PersonaCardType = z.infer<typeof PersonasSchema>

export const PersonaCard = ({ persona }: { persona: PersonaCardType }) => {
	const { companyId } = useCompany()
	const { verticalId } = useParsedRouteParams(['verticalId'])
	const { findEcosystemByVerticalId } = useEcosystem()
	const navigate = useNavigate()

	const ecosystem = useMemo(() => findEcosystemByVerticalId(verticalId), [findEcosystemByVerticalId, verticalId])

	const handleRoleplayClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		e.stopPropagation()
		navigate(routes.enable.roleplay.new.session({ companyId }), {
			state: {
				ecosystemId: ecosystem?.id,
				verticalId: Number(verticalId),
				personaId: persona.id,
			},
		})
	}

	return (
		<Link
			to={routes.enable.personas.details({
				companyId,
				verticalId,
				personaId: String(persona.id),
			})}
		>
			<Surface className="p-6 hover:border-neutral-2-bd">
				<section className="flex flex-col gap-4.5">
					<PersonaDetailsCard persona={persona} showJobSeniority />
					<hr className="w-full border-t border-neutral-1-bd" />
					<div className="flex flex-col gap-1.5">
						<p className="text-body-sm">
							<span className="font-semibold">Ecosystem:</span> {persona.ecosystemName}
						</p>
						<p className="text-body-sm">
							<span className="font-semibold">Vertical:</span> {persona.verticalName}
						</p>
					</div>
					{ecosystem && (
						<Button type="button" variant="outline" size="sm" className="w-full" onClick={handleRoleplayClick}>
							Roleplay
						</Button>
					)}
				</section>
			</Surface>
		</Link>
	)
}
