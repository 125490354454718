import { client } from '#src/main'
import { userQuery } from '#src/routes/auth/queries'
import {
	checkIsAdminSession,
	checkIsEnableOnlySession,
	checkIsReadOnlySession,
	checkIsUserSession,
} from '#src/utils/misc'

export const getUser = async () => {
	const user = await client.fetchQuery(userQuery())

	return {
		user,
		enableOnlySession: checkIsEnableOnlySession(user.roles),
		adminSession: checkIsAdminSession(user.roles),
		userSession: checkIsUserSession(user.roles),
		readOnlySession: checkIsReadOnlySession(user.roles),
	}
}
