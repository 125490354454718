import { type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { findEcosystemByVerticalId, validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['verticalId', 'personaId'])

	const [persona, ecosystems] = await Promise.all([
		client.fetchQuery(personaQuery(company.id, params.personaId)),
		client.fetchQuery(ecosystemsQuery(company.id)),
	])

	const ecosystem = findEcosystemByVerticalId(params.verticalId, ecosystems)

	if (!ecosystem) {
		throw new Response('Ecosystem not found', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

	return {
		persona,
		ecosystems,
		ecosystemId: ecosystem.id,
		verticalId: params.verticalId,
	}
}
