import { z } from 'zod'

export const SettingsCommandsItemAPISchema = z.object({
	id: z.number(),
	title: z.string(),
	value: z.string(),
	enabled: z.boolean(),
})

export const SettingsCommandsListItemAPISchema = SettingsCommandsItemAPISchema.array()

export const SettingsCommandsFormSchema = z.object({
	enabled: z.boolean(),
})

export const SettingsCommandsFormParserSchema = z.object({
	enabled: z.enum(['true', 'false']).transform(val => val === 'true'),
})
