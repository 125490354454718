import { useLoaderData } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { AccordionRoot } from '#src/components/ui/accordion'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import { type PlaysListLoaderRes } from '#src/routes/enable/plays/routes/list'
import PlayAccordion from '#src/routes/enable/plays/views/components/PlayAccordion'
import PlaysFilters from '#src/routes/enable/plays/views/components/PlaysFilters'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		data: { plays },
	} = useLoaderData() as PlaysListLoaderRes
	const { companyId } = useCompany()

	return (
		<PageLayout
			title="Plays"
			actions={[
				{
					to: routes.enable.plays.create({ companyId }),
					icon: 'add',
					title: 'Add new',
					variant: 'default',
				},
			]}
		>
			{plays.length ? (
				<>
					<PlaysFilters />
					<AccordionRoot type="multiple" className="flex flex-col gap-4">
						{plays.map(item => (
							<PlayAccordion key={item.id} play={item} />
						))}
					</AccordionRoot>
				</>
			) : (
				<EmptyStateCard
					icon="chat"
					title="You don't have any plays"
					actions={[
						{
							to: routes.enable.plays.create({ companyId }),
							title: 'Add new',
						},
					]}
				/>
			)}
		</PageLayout>
	)
}
