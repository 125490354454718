import { z } from 'zod'

export const Auth0AuthAPISchema = z
	.object({
		access_token: z.string(),
		refresh_token: z.string(),
	})
	.transform(value => ({
		accessToken: value.access_token,
		refreshToken: value.refresh_token,
	}))

export const ResetPasswordAPISchema = z.object({
	token: z.string(),
	password: z.string(),
})

export const ResetPasswordFormSchema = z
	.object({
		password: z.string().superRefine((val, ctx) => {
			if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(val)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message:
						'Make sure password is at least 8 characters, contains at least 1 lower and 1 upper case letter, and 1 number',
					fatal: true,
				})
			}
		}),
		confirm: z.string(),
	})
	.superRefine((val, ctx) => {
		if (val.password !== val.confirm) {
			ctx.addIssue({
				path: ['confirm'],
				code: z.ZodIssueCode.custom,
				message: 'Passwords do not match',
				fatal: true,
			})

			return z.NEVER
		}
	})

export const UserByTokenAPISchema = z.object({
	invitedBy: z.string(),
	email: z.string(),
	accepted: z.boolean(),
})

export const LoginFormSchema = z.object({
	username: z.string(),
	password: z.string(),
})

export const RoleType = z.array(
	z.union([
		z.literal('admin'),
		z.literal('user'),
		z.literal('enable-only'),
		z.literal('data-trainer'),
		z.literal('read-only'),
	]),
)

export const UserSchema = z.object({
	userId: z.string(),
	roles: RoleType,
	email: z.string(),
	companyId: z.string(),
	company: z.string().nullable().optional(),
})

export type UserDataType = z.infer<typeof UserSchema>
export type RoleDataType = z.infer<typeof RoleType>
