import './tailwind.css'

import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { createRoutesFromChildren, RouterProvider, useLocation, useNavigationType, matchRoutes } from 'react-router-dom'

import { SplashScreenProvider } from '#src/context/SplashScreenContext'
import { ToastProvider } from '#src/context/ToastContext'
import { createAppRouter } from '#src/router'
import { createClientStore } from './store'

if (import.meta.env.PROD) {
	const appEnv = import.meta.env.VITE_APP_ENVIRONMENT

	Sentry.init({
		...(appEnv ? { environment: appEnv } : {}),
		dsn: 'https://9ba42fa9551b748297c9e421c872f798@o4506613836677120.ingest.sentry.io/4506823228915712',
		integrations: [
			Sentry.metrics.metricsAggregatorIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracePropagationTargets: ['localhost', /^https:\/\/evergrowth\.com/],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
	})
}

if (import.meta.env.DEV) {
	const { worker } = await import('../mocks/browser')

	await worker.start()
}

// todo: move client to createClientStore() file. just create it as a const and export it.
export const client = createClientStore()
export const router = createAppRouter(client)

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<SplashScreenProvider>
			<QueryClientProvider client={client}>
				<ToastProvider>
					<RouterProvider router={router} />
				</ToastProvider>
				<ReactQueryDevtools buttonPosition="bottom-right" />
			</QueryClientProvider>
		</SplashScreenProvider>
	</StrictMode>,
)
