import { Link, useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import DeleteConfirm from '#src/components/delete-confirm'
import Role from '#src/components/Role'
import { Button } from '#src/components/ui/button'
import { Checkbox } from '#src/components/ui/checkbox'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useAuth from '#src/hooks/useAuth'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { usePersonasCheckboxGroup } from '#src/routes/calibrate/verticals/context/PersonasCheckboxGroupContext'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import { routes } from '#src/utils/routes'

type VerticalItemProps = {
	vertical: z.infer<typeof VerticalAPISchema>
	ecosystem?: z.infer<typeof EcosystemAPISchema>
	companyId: string
	ecosystemId: string
	readOnlySession: boolean
}

const MasterCheckbox = ({ verticalId }: { verticalId: string }) => {
	const {
		isAllSelected,
		isIndeterminate,
		handleSelectAll,
		getPersonasPerVerticalCount,
		getCheckedPersonasPerVerticalCount,
	} = usePersonasCheckboxGroup()
	const totalPersonas = getPersonasPerVerticalCount(verticalId)
	const checkedPersonasCount = getCheckedPersonasPerVerticalCount(verticalId)
	const isChecked = checkedPersonasCount > 0

	return (
		<div className="flex items-center gap-2">
			<Checkbox
				checked={isIndeterminate(verticalId) ? 'indeterminate' : isAllSelected(verticalId)}
				onCheckedChange={() => handleSelectAll(verticalId)}
			/>
			{isChecked ? (
				<p className="flex items-center gap-1 text-body-md">{`${checkedPersonasCount} selected`}</p>
			) : (
				<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
					{!isChecked ? (
						<>
							<Icon name="user" size="sm" />
							<span className="flex font-normal text-neutral-3-fg">{totalPersonas}</span>
						</>
					) : (
						`${checkedPersonasCount} selected`
					)}
				</p>
			)}
		</div>
	)
}

export const PersonaListHeader = ({ vertical, ecosystem, companyId, ecosystemId }: VerticalItemProps) => {
	const { user } = useAuth()
	const fetcher = useFetcher()

	const firstPersonaId = ecosystem?.verticals.find(v => v.id === vertical.id)?.personas?.[0]?.id?.toString() ?? null

	const {
		getCheckedPersonasPerVerticalCount,
		getCheckedPersonasIdsPerVertical,
		getPersonasPerVerticalCount,
		hasMixedVerticalsSelection,
		allCheckedPersonasIds,
	} = usePersonasCheckboxGroup()

	const isPersonaChecked = !!getCheckedPersonasPerVerticalCount(String(vertical.id))

	const checkedPersonasCount = getCheckedPersonasPerVerticalCount(String(vertical.id))

	const copyAction = routes.calibrate.verticals.copyValidatePersonas({
		companyId: companyId,
		ecosystemId: ecosystemId,
	})

	const batchDeleteAction = routes.calibrate.verticals.batchDeletePersonas({
		companyId: companyId,
		ecosystemId: ecosystemId,
	})

	const personasCount = getPersonasPerVerticalCount(String(vertical.id))
	const copyPersonasTooltipLabel = !hasMixedVerticalsSelection
		? 'Copy Personas'
		: 'Please select personas from a single vertical to enable copying'

	return (
		<section className="flex w-full flex-wrap items-center justify-between gap-2">
			<div className="flex gap-2">{!!personasCount && <MasterCheckbox verticalId={String(vertical.id)} />}</div>
			<Role user={user} allow={['user']} hideIfReadOnly>
				{!isPersonaChecked ? (
					<CustomTooltip label="Add Persona">
						<Button size="icon" variant="icon" asChild>
							<Link
								to={routes.calibrate.persona.edit({
									companyId,
									ecosystemId: ecosystemId.toString(),
									verticalId: vertical.id.toString(),
									personaId: firstPersonaId,
								})}
							>
								<Icon name="add" />
							</Link>
						</Button>
					</CustomTooltip>
				) : (
					<div className="flex gap-0.5">
						<fetcher.Form action={copyAction} method="PUT">
							<CustomTooltip label={copyPersonasTooltipLabel}>
								<Button
									size="icon"
									variant="icon"
									type="submit"
									disabled={hasMixedVerticalsSelection}
									className="cursor-disabled"
								>
									<Icon name="copy" />
								</Button>
							</CustomTooltip>
							<input
								name="personas"
								value={JSON.stringify(getCheckedPersonasIdsPerVertical(String(vertical.id)))}
								type="hidden"
							/>
						</fetcher.Form>
						<DeleteConfirm
							trigger={
								<div>
									<CustomTooltip label="Delete Personas">
										<Button size="icon" variant="icon" type="button">
											<Icon name="trash" size="sm" />
										</Button>
									</CustomTooltip>
								</div>
							}
							action={batchDeleteAction}
							title={`Delete ${checkedPersonasCount} ${checkedPersonasCount === 1 ? 'persona' : 'personas'}?`}
							description={
								<>
									Are you sure you want to archive {checkedPersonasCount}{' '}
									{checkedPersonasCount === 1 ? 'persona' : 'personas'} for <b>{vertical.name}</b> vertical?
								</>
							}
							input={<input name="personas" value={JSON.stringify(allCheckedPersonasIds)} type="hidden" />}
						/>
					</div>
				)}
			</Role>
		</section>
	)
}
