import { useState, useMemo, useCallback, type ReactNode, useEffect, Fragment } from 'react'
import { Chip } from '#src/components/chip'
import { Checkbox } from '#src/components/ui/checkbox'
import { Divider } from '#src/components/ui/Divider'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import { inputCommonClasses } from '#src/theme'
import { cn } from '#src/utils/misc'
import { PopoverRoot, PopoverTrigger, PopoverContent } from './popover'

const SelectItem = ({
	children,
	checked,
	indeterminate = false,
	onCheckedChange,
}: {
	children: ReactNode
	checked: boolean
	indeterminate?: boolean
	onCheckedChange: () => void
}) => (
	<label className="flex cursor-pointer items-center rounded px-2 py-1.5 hover:bg-neutral-1-bg-hover">
		<Checkbox checked={indeterminate ? 'indeterminate' : checked} onCheckedChange={onCheckedChange} className="mr-2" />
		{children}
	</label>
)

export type MultiSelectOption = {
	value: string
	label: ReactNode
}

export type MultiSelectProps = {
	options: MultiSelectOption[]
	value?: string[]
	onChange?: (value: string[] | undefined) => void
	placeholder?: string
	className?: string
	disabled?: boolean
	enableSearch?: boolean
	chipSeparatorText?: ReactNode
}

export const MultiSelect = ({
	options,
	value = [],
	onChange = () => null,
	placeholder = 'Select...',
	className,
	disabled,
	enableSearch,
	chipSeparatorText,
	...props
}: MultiSelectProps) => {
	const [search, setSearch] = useState<string>('')

	const filteredOptions = useMemo(() => {
		return search
			? options.filter(option => option.label?.toString().toLowerCase().includes(search.toLowerCase()))
			: options
	}, [search, options])

	const toggleOption = useCallback(
		(optionValue: string) => {
			onChange(value.includes(optionValue) ? value.filter(v => v !== optionValue) : [...value, optionValue])
		},
		[value, onChange],
	)

	const handleSelectAll = useCallback(() => {
		const filteredValues = filteredOptions.map(item => item.value)
		const allSelected = filteredValues.every(item => value.includes(item))

		if (allSelected) {
			onChange(value.filter(item => !filteredValues.includes(item)))
		} else {
			onChange([...value, ...filteredValues.filter(item => !value.includes(item))])
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredOptions, value])

	const renderOptions = useMemo(() => {
		if (!filteredOptions?.length) return null

		return filteredOptions.map(option => (
			<SelectItem
				key={option.value}
				checked={value.includes(option.value)}
				onCheckedChange={() => toggleOption(option.value)}
			>
				<span className="text-body-md text-neutral-1-fg">{option.label}</span>
			</SelectItem>
		))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredOptions, value])

	const renderChips = useMemo(() => {
		if (!value.length || value.includes('-')) return []

		return value.map((item, i) => {
			const option = options.find(o => o.value === item)

			return (
				<Fragment key={item + i}>
					<Chip variant="gray" className="flex items-center gap-x-1.5 hover:bg-neutral-3-bg-hover">
						{option?.label}
						<span
							onClick={e => {
								if (disabled) return

								e.stopPropagation()
								toggleOption(item)
							}}
							className="m-0 flex items-center p-0 leading-none"
						>
							<Icon name="close-filled" className="hover:text-neutral-3-fg" />
						</span>
					</Chip>
					{chipSeparatorText && i + 1 !== value.length && (
						<span className="mx-0.5 text-label-sm font-semibold text-neutral-3-fg">{chipSeparatorText}</span>
					)}
				</Fragment>
			)
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, options])

	const isSelectAllChecked = !!filteredOptions?.length && filteredOptions.every(option => value.includes(option.value))
	const isSelectAllIndeterminate =
		!!filteredOptions?.length && !isSelectAllChecked && filteredOptions.some(option => value.includes(option.value))

	useEffect(() => {
		if (disabled) return

		if (value?.length && !options?.length) {
			onChange?.(undefined)
		}

		const validValues = value.filter(item => options.some(option => option.value === item))

		if (validValues.length !== value.length) {
			onChange?.(validValues)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, value, disabled])

	return (
		<PopoverRoot open={disabled ? false : undefined}>
			<PopoverTrigger asChild tabIndex={-1}>
				<button
					className={cn(
						inputCommonClasses,
						'relative flex w-full items-center',
						renderChips.length && 'h-auto',
						disabled && 'text-neutral-inverse-fg-disabled opacity-50',
						className,
					)}
					style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
					{...props}
				>
					<div className="flex flex-1 flex-wrap items-center gap-1">
						{renderChips.length && !disabled ? renderChips : <span className="text-neutral-3-fg">{placeholder}</span>}
					</div>
					{!!value.length && !value.includes('-') && !disabled && (
						<Icon
							onClick={e => {
								if (disabled) return
								e.stopPropagation()
								onChange([])
							}}
							name="close-filled"
							className="me-1 text-neutral-3-fg hover:text-neutral-4-fg"
						/>
					)}
					<Icon name="chevron-down" className="text-neutral-4-fg" />
				</button>
			</PopoverTrigger>
			<PopoverContent
				className={cn(
					'z-50 max-h-[20rem] w-full rounded border border-neutral-1-bd bg-neutral-1-bg p-0 text-body-md shadow-sm',
				)}
				sideOffset={5}
				align="start"
			>
				<section className="sticky top-0 flex flex-col gap-2 bg-white px-2 pt-3">
					{enableSearch && (
						<div className="mb-1 px-2">
							<Input value={search} onChange={e => setSearch(e.target.value)} iconLeft="search" clearable />
						</div>
					)}
					<SelectItem
						checked={isSelectAllChecked}
						indeterminate={isSelectAllIndeterminate}
						onCheckedChange={handleSelectAll}
					>
						Select All
					</SelectItem>
					<Divider marginY="none" />
				</section>
				<div className="flex flex-col overflow-auto px-2 pb-2">{renderOptions}</div>
			</PopoverContent>
		</PopoverRoot>
	)
}
