import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { type ComponentProps, type ReactNode, useEffect, useState } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { selectionControlDisabledVariants } from '#src/theme'
import { cn } from '#src/utils/misc'
import { generateUniqueId } from '#src/utils/randoms'

export type RadioGroupCardsOption = {
	value: string
	label: ReactNode
	disabled?: boolean
	icon?: IconName
	description?: ReactNode
}

export type RadioGroupCardsProps = {
	options: RadioGroupCardsOption[]
	value?: string
	onChange?: (value: string) => void
	disabled?: boolean
	selectFirstByDefault?: boolean
} & ComponentProps<typeof RadioGroupPrimitive.Root>

export const RadioGroupCards = ({
	options = [],
	value,
	onChange,
	disabled,
	selectFirstByDefault,
	...rest
}: RadioGroupCardsProps) => {
	useEffect(() => {
		if (selectFirstByDefault && !value && options.length) {
			if (onChange) {
				onChange(options[0].value)
			}
		}
	}, [onChange, options, selectFirstByDefault, value])

	const [id] = useState<string>(generateUniqueId())

	return (
		<RadioGroupPrimitive.Root
			className={cn(
				'flex flex-wrap gap-2',
				options?.length > 2 ? 'flex-col' : 'flex-row',
				disabled && selectionControlDisabledVariants.true,
			)}
			value={value}
			onValueChange={onChange}
			disabled={disabled}
			{...rest}
		>
			{options.map(option => (
				<label
					key={`radio-group-${option.value}`}
					className="flex flex-1 items-center gap-3 rounded border border-neutral-2-bd px-4 py-3 text-neutral-2-fg has-[input:checked]:border-brand-1-bd-hover has-[input:checked]:bg-brand-1-bg aria-disabled:opacity-50"
					htmlFor={`${id}-${option.value}`}
					aria-disabled={option.disabled}
				>
					<RadioGroupPrimitive.Item
						value={option.value}
						id={`${id}-${option.value}`}
						className="flex h-4.5 w-4.5 items-center justify-center rounded-full border-2 border-neutral-3-bd data-[state=checked]:border-brand-1-bd-hover"
						disabled={option.disabled}
					>
						<RadioGroupPrimitive.Indicator className="block h-2 w-2 rounded-full bg-brand-1-bd-hover" />
					</RadioGroupPrimitive.Item>
					<div className="flex flex-row items-center gap-2 text-body-md">
						{!!option.icon && <Icon name={option.icon} className="block h-6 w-6 text-neutral-3-fg" />}
						<div className="flex flex-1 flex-col">
							<p>{option.label}</p>
							{!!option.description && <small className="text-body-sm text-neutral-3-fg">{option.description}</small>}
						</div>
					</div>
				</label>
			))}
		</RadioGroupPrimitive.Root>
	)
}
