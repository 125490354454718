import { type z } from 'zod'
import { CreateScenarioTestSessionSchema } from '#src/routes/enable/roleplay/schema'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'
import { type EditScenarioFormSchema, ScenarioAPISchema, type ScenarioFormPayloadSchema } from './schema'

export const createRoleplayScenario = async (companyId: string, data: z.infer<typeof ScenarioFormPayloadSchema>) => {
	return await safeFetch(ScenarioAPISchema, api.roleplay.scenario.index(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const editRoleplayScenario = async (
	companyId: string,
	scenarioId: string,
	data: z.infer<typeof EditScenarioFormSchema>,
) => {
	return await safeFetch(ScenarioAPISchema, api.roleplay.scenario.update(companyId, scenarioId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}

export const assignUserToScenario = async (companyId: string, scenarioId: string, userId: string) => {
	return await fetch(api.roleplay.scenario.assignUser(companyId, scenarioId, userId), {
		method: 'PUT',
	})
}

export const createScenarioTestSession = async (companyId: string, scenarioId: string) => {
	return await safeFetch(
		CreateScenarioTestSessionSchema,
		api.roleplay.scenario.createTestSession(companyId, scenarioId),
		{
			method: 'POST',
		},
	)
}
