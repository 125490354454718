import { queryOptions } from '@tanstack/react-query'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { ChatSchema, ChatsSchema, ConversationDataSchema, ContactSchema, StatusSchema } from './schema'

export const enableKeys = {
	all: ['enable', 'crm'] as const,
	contact: (contactId: string) => [...enableKeys.all, 'map', contactId] as const,
	status: (companyId: string, contactId: string) => [...enableKeys.all, 'status', companyId, contactId] as const,
	conversation: (companyId: string, conversationId: string) =>
		[...enableKeys.all, 'chat', companyId, conversationId] as const,
	chats: (companyId: string) => [...enableKeys.all, 'chats', companyId] as const,
	chat: (companyId: string, conversationId: string) => [...enableKeys.all, 'chats', companyId, conversationId] as const,
}

export const conversationQuery = (companyId: string, conversationId: string) => ({
	queryKey: enableKeys.conversation(companyId, conversationId),
	queryFn: async () =>
		await safeFetch(ConversationDataSchema, api.chat.context(companyId, conversationId), {
			method: 'GET',
		}),
})

export const chatsQuery = (companyId: string) =>
	queryOptions({
		queryKey: enableKeys.chats(companyId),
		queryFn: async () =>
			(
				await safeFetch(ChatsSchema, api.chat.list(companyId), {
					method: 'GET',
				})
			).sort((a, b) =>
				new Date(a.conversation.lastMessage ?? a.conversation.createdAt) <
				new Date(b.conversation.lastMessage ?? b.conversation.createdAt)
					? 1
					: -1,
			),
	})

export const chatQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: enableKeys.chat(companyId, conversationId),
		queryFn: async () => {
			return await safeFetch(ChatSchema, api.chat.messages(companyId, conversationId), {
				method: 'GET',
			})
		},
	})

export const contactQuery = (contactId: string) =>
	queryOptions({
		queryKey: enableKeys.contact(contactId),
		queryFn: async () =>
			await safeFetch(ContactSchema, api.chat.contact(contactId), {
				method: 'GET',
			}),
	})

export const statusQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: enableKeys.status(companyId, conversationId),
		queryFn: async () =>
			await safeFetch(StatusSchema, api.chat.status(companyId, conversationId), {
				method: 'GET',
			}),
	})
