import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import leadResearchAgentRouter from '#src/routes/lead-research-agent/router'
import { organizeDashboardLoader, OrganizeDashboardView } from '#src/routes/organize/routes/dashboard'
import RouteBoundary from '#src/routes/route-boundary'

export default {
	path: 'organize',
	element: <DefaultSidebarLayout />,
	handle: {
		crumb: () => [
			{
				label: 'Organize',
				icon: 'data-unreal',
			},
		],
	},
	children: [
		{
			index: true,
			loader: organizeDashboardLoader,
			element: <OrganizeDashboardView />,
			errorElement: <RouteBoundary />,
		},
		leadResearchAgentRouter,
	],
} satisfies RouteObject
