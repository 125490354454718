import * as React from 'react'
import { useSpinDelay } from 'spin-delay'
import { cn } from '#src/utils/misc'
import { Button, type ButtonProps } from './button'
import { Icon } from './icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip'

export const StatusButton = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & {
		status: 'pending' | 'success' | 'error' | 'idle'
		message?: string | null
		spinDelay?: Parameters<typeof useSpinDelay>[1]
	}
>(({ message, status, className, children, spinDelay, ...props }, ref) => {
	const delayedPending = useSpinDelay(status === 'pending', {
		delay: 400,
		minDuration: 300,
		...spinDelay,
	})
	const companion = {
		pending: delayedPending ? <Icon name="update" className="animate-spin" /> : null,
		success: <Icon name="check" />,
		error: <Icon name="cross-1" className="text-status-danger-fg" />,
		idle: null,
	}[status]

	return (
		<Button
			ref={ref}
			className={cn('flex justify-center gap-2', className)}
			{...props}
			onClick={e => {
				if (status === 'pending') {
					e.preventDefault()
				}
				props.onClick?.(e)
			}}
		>
			{children}
			{message ? (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger>{companion}</TooltipTrigger>
						<TooltipContent>{message}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			) : (
				companion
			)}
		</Button>
	)
})
StatusButton.displayName = 'Button'
