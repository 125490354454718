import { forwardRef, type ChangeEvent, type InputHTMLAttributes, useMemo } from 'react'
import { cn } from '#src/utils/misc'
import { NumberInput, type NumberInputProps } from './NumberInput'

type RangeInputType = InputHTMLAttributes<HTMLInputElement>['value']
export type RangeValue = [RangeInputType, RangeInputType]

export type RangeInputProps = Omit<NumberInputProps, 'value' | 'onChange'> & {
	value?: RangeValue | string
	onChange?: (value: RangeValue | string) => void
	fromInputProps?: NumberInputProps
	toInputProps?: NumberInputProps
	disableSeparator?: boolean
	valueAsString?: boolean
}

export const RangeInput = forwardRef<HTMLDivElement, RangeInputProps>(
	(
		{
			value = [undefined, undefined],
			onChange,
			fromInputProps,
			toInputProps,
			disableSeparator,
			valueAsString,
			...props
		},
		ref,
	) => {
		const normalizedValue: RangeValue = useMemo(() => {
			if (Array.isArray(value)) {
				return [value[0] || undefined, value[1] || undefined]
			}

			const [from, to] = value.split(',').map(v => v.trim() || undefined)
			return [from, to]
		}, [value])

		const handleFromChange = (e: ChangeEvent<HTMLInputElement>) => {
			const newFrom: RangeInputType = e.target.value || undefined
			const newRange: RangeValue = [newFrom, normalizedValue[1]]
			onChange?.(valueAsString ? newRange.join(',') : newRange)
		}

		const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
			const newTo: RangeInputType = e.target.value || undefined
			const newRange: RangeValue = [normalizedValue[0], newTo]
			onChange?.(valueAsString ? newRange.join(',') : newRange)
		}

		return (
			<div className={cn('flex items-center gap-4', disableSeparator && 'gap-2')} ref={ref}>
				<NumberInput value={normalizedValue[0] ?? ''} onChange={handleFromChange} {...props} {...fromInputProps} />
				{!disableSeparator && <span className="text-body-md text-neutral-4-fg">to</span>}
				<NumberInput value={normalizedValue[1] ?? ''} onChange={handleToChange} {...props} {...toInputProps} />
			</div>
		)
	},
)

RangeInput.displayName = 'RangeInput'
