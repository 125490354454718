import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import { action as adminCompaniesDeleteAction } from './actions/delete'
import { action as adminCompaniesEditAction } from './actions/edit'
import { action as adminCompaniesNewAction } from './actions/new'
import { loader as adminCompaniesLoader } from './loaders/companies'
import { loader as adminCompaniesEditLoader } from './loaders/edit'
import { loader as rootLoader } from './loaders/root'
import Companies from './views/companies'
import EditCompanyDashboard from './views/edit'
import NewCompany from './views/new'

export default {
	path: 'admin',
	loader: rootLoader,
	element: <DefaultSidebarLayout />,
	handle: {
		crumb: () => [
			{
				label: 'Companies',
				icon: 'settings',
			},
		],
	},
	children: [
		{
			index: true,
			element: <Companies />,
			loader: adminCompaniesLoader,
		},
		{
			path: 'company',
			handle: {
				layout: {
					enableHeader: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <NewCompany />,
					action: adminCompaniesNewAction,
				},
				{
					path: ':companyId',
					children: [
						{
							path: 'delete',
							action: adminCompaniesDeleteAction,
						},
						{
							path: 'edit',
							element: <EditCompanyDashboard />,
							loader: adminCompaniesEditLoader,
							action: adminCompaniesEditAction,
						},
					],
				},
			],
		},
	],
} satisfies RouteObject
