import { useQuery } from '@tanstack/react-query'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import {
	PersonasAPIResSchema,
	PersonasExpertiseAPIResSchema,
	type PersonasExpertiseQueryPayload,
	type PersonasQueryPayload,
} from '#src/routes/enable/personas/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'

export const personasQuery = (companyId: string, queryParams: PersonasQueryPayload) => ({
	queryKey: personaKeys.list(companyId, queryParams),
	queryFn: async () =>
		await safeFetchMeta(PersonasAPIResSchema, api.persona.list(companyId, queryParams), {
			method: 'GET',
		}),
})

export const usePersonasQuery = (companyId: string, queryParams: PersonasQueryPayload) => {
	const { data: personasData, isFetching: personasLoading } = useQuery({
		queryKey: personaKeys.list(companyId, queryParams),
		queryFn: async () =>
			await safeFetchMeta(PersonasAPIResSchema, api.persona.list(companyId, queryParams), {
				method: 'GET',
			}),
		placeholderData: prev => prev,
	})

	return {
		personasData,
		personasLoading,
	}
}

export const personasExpertiseQuery = (companyId: string, queryParams: PersonasExpertiseQueryPayload) => ({
	queryKey: personaKeys.expertiseList(companyId, queryParams),
	queryFn: async () =>
		await safeFetch(PersonasExpertiseAPIResSchema, api.persona.expertiseList(companyId, queryParams), {
			method: 'GET',
		}),
})
