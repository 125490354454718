import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { type ComponentPropsWithoutRef } from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

export function TableAction({ className, ...props }: ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>) {
	return (
		<CheckboxPrimitive.Root
			{...props}
			className={cn('group flex h-4 w-4 items-center justify-center text-neutral-3-fg', className)}
		>
			<CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-brand-1-fg')}>
				<Icon
					name="checkbox-indeterminate-filled"
					size="sm"
					className="hidden group-data-[state=indeterminate]:inline-flex"
				/>
				<Icon name="checkbox-checked-filled" size="sm" className="hidden group-data-[state=checked]:inline-flex" />
			</CheckboxPrimitive.Indicator>

			<span className={cn('hidden items-center justify-center text-current group-data-[state=unchecked]:flex')}>
				<Icon name="checkbox-unchecked" size="sm" className="hidden group-data-[state=unchecked]:inline-flex" />
			</span>
		</CheckboxPrimitive.Root>
	)
}
