import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Fragment } from 'react'
import { useFetcher, useLoaderData } from 'react-router-dom'
import { AdminCompanyDashboardFormSchema } from '#src/api/icp/admin/company/schemas'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../actions/edit'
import { type LoaderRes } from '../loaders/edit'

export default function EditCompanyDashboard() {
	const {
		data: { dashboard },
	} = useLoaderData() as LoaderRes
	const { company } = useCompany()
	const fetcher = useFetcher()
	const fetcherData = fetcher.data as ActionRes

	const action = routes.admin.company.edit({ companyId: company.id })

	const [form, fields] = useForm({
		id: `company-edit-form-${company.id}`,
		constraint: getZodConstraint(AdminCompanyDashboardFormSchema),
		defaultValue: { dashboard },
		lastResult: !(fetcherData instanceof Response) ? fetcherData?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: AdminCompanyDashboardFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	const list = fields.dashboard.getFieldList()

	return (
		<Surface className="mx-auto flex max-w-screen-md flex-col items-center gap-6 px-24 py-10">
			<h1 className="text-center text-title-md">Edit Company Dashboard URLs</h1>

			<fetcher.Form method="POST" {...getFormProps(form)} action={action} className="flex w-full flex-col gap-6">
				{list.map((field, index) => {
					const input = field.getFieldset()
					const label = dashboard?.find(d => d.id.toString() === input.id.value)?.name ?? ''
					return (
						<Fragment key={input.id.value ?? index}>
							{input.id.value ? <input {...getInputProps(input.id, { type: 'hidden' })} /> : null}
							<Field
								labelProps={{ children: label }}
								inputProps={{
									...getInputProps(input.url, { type: 'text' }),
									placeholder: 'Enter company name',
								}}
								errors={input.url.errors}
							/>
						</Fragment>
					)
				})}

				<ErrorList errors={form.errors} id={form.errorId} />

				<div className="flex w-full justify-end gap-2">
					<StatusButton
						status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
						type="submit"
						disabled={fetcher.state !== 'idle'}
						size="sm"
						className="min-w-32"
					>
						Save
					</StatusButton>
				</div>
			</fetcher.Form>
		</Surface>
	)
}
