import Priority from '#src/components/priority'
import { FormFieldTypeChip } from '#src/components/shared'
import Status from '#src/components/status'
import { type APIFieldType } from '#src/schemas/global'
import { COUNTRIES, REGIONS } from '#src/utils/countries'
import {
	API_TO_FIELD_TYPE_MAP,
	CRITERION_LOGIC_OPTIONS,
	MATH_COMPARISON_LABELS,
	MATH_COMPARISON_VALUES,
	PRIORITY_OPTIONS,
	STATUS_OPTIONS,
	UNITS,
} from '#src/utils/enumerations'

const getMathComparisonUnitExplanatoryText = (key: string) => {
	switch (key) {
		case 'GREATER_THAN':
			return 'Greater Than'
		case 'GREATER_THAN_OR_EQUAL_TO':
			return 'Greater Than or Equal To'
		case 'LESS_THAN':
			return 'Less Than'
		case 'LESS_THAN_OR_EQUAL_TO':
			return 'Less Than or Equal To'
		case 'APPROXIMATELY_EQUAL_TO':
			return 'Approximately equals To'
		case 'NOT_EQUAL':
			return 'Not Equal To'
		default:
			return ''
	}
}

export const getAvailableMathComparisonUnits = (showExplanatoryText = false) =>
	Object.entries(MATH_COMPARISON_VALUES).map(([key, symbol]) => ({
		value: symbol,
		label: showExplanatoryText
			? `${!showExplanatoryText ? symbol : `(${MATH_COMPARISON_LABELS[key as keyof typeof MATH_COMPARISON_LABELS]})`} ${getMathComparisonUnitExplanatoryText(key)}`
			: symbol,
	}))

export const getAvailableFieldsChips = () =>
	Object.keys(API_TO_FIELD_TYPE_MAP)
		.filter(item => item !== 'radio')
		.map(item => ({
			value: item,
			label: <FormFieldTypeChip type={item as APIFieldType} size="sm" />,
		}))

export const getAvailableUnits = () =>
	Object.values(UNITS).map(item => ({
		value: item,
		label: item,
	}))

export const getAvailableCountriesAndRegions = () => {
	const filteredRegions = REGIONS.filter(
		region => !COUNTRIES.some(country => region.label === country.label && region.label === country.label),
	)
	const combined = [...filteredRegions, ...COUNTRIES]

	return combined.map(region => ({
		value: region.label,
		label: region.label,
	}))
}

export const getAvailableStatuses = () =>
	STATUS_OPTIONS.map(({ value, label }) => ({
		value,
		label: <Status status={label} />,
	}))

export const getAvailablePriorities = () =>
	PRIORITY_OPTIONS.map(({ value, label }) => ({
		value,
		label: <Priority priority={label} />,
	}))

export const getAvailableLogicOptions = () =>
	CRITERION_LOGIC_OPTIONS.map(({ value, label }) => ({
		value,
		label,
	}))
