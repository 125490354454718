import { useCallback, useEffect, useMemo, useRef } from 'react'
import { type BlockerFunction, Link, useBlocker, useLoaderData, useNavigate } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { Chip } from '#src/components/chip'
import Markdown from '#src/components/markdown'
import { ExpertGtpInfo } from '#src/components/shared/value-proposition'
import { Accordion } from '#src/components/ui/accordion'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import { type IndexLoaderType } from '#src/routes/calibrate/value-proposition/routes'
import { LabeledValue } from '#src/routes/lead-research-agent/views/components'
import { formatDateToReadableString } from '#src/utils/date'
import { getWordCount } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { AccordionTrigger, AccordionContent } from './components'

export const View = ({ isReadOnly }: { isReadOnly?: boolean }) => {
	const { company } = useCompany()
	const {
		data: { valueProposition },
	} = useLoaderData() as IndexLoaderType
	const navigate = useNavigate()
	const bypassBlocker = useRef(false)

	const shouldBlock = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) =>
			!bypassBlocker.current &&
			valueProposition.isDraft &&
			currentLocation.pathname !== nextLocation.pathname &&
			!nextLocation.pathname.includes('value-proposition'),
		[valueProposition.isDraft],
	)

	const blocker = useBlocker(shouldBlock)

	const sections = useMemo(
		() =>
			valueProposition.valuePropositions.map((item, i) => ({
				trigger: <AccordionTrigger key={item.id} item={item} i={i} />,
				content: <AccordionContent key={item.id} item={item} />,
			})),
		[valueProposition.valuePropositions],
	)

	const wordCount = useMemo(() => {
		if (!valueProposition?.valuePropositions) return 0

		const joined = valueProposition.valuePropositions.map(item => `${item.name} ${item.description}`).join(' ')

		return getWordCount(joined)
	}, [valueProposition])

	useEffect(() => {
		if (blocker.state === 'blocked') {
			bypassBlocker.current = true
			blocker.reset?.()

			navigate(routes.calibrate.valueProposition.publishWarn({ companyId: company.id }), {
				state: { redirectPath: blocker.location.pathname },
			})
		}
	}, [blocker, company.id, navigate])

	return (
		<PageLayout
			title="Value Proposition"
			description={wordCount ? `Total ${wordCount} words` : undefined}
			titleItems={
				<>
					<Chip variant="gray">
						{valueProposition.isDraft
							? 'Draft'
							: formatDateToReadableString(valueProposition.publishedAt ?? '', { includeTime: true })}
					</Chip>
					{!isReadOnly && valueProposition.isDraft && valueProposition.publishedAt && (
						<Button asChild size="sm" variant="ghost" className="gap-1.5">
							<Link to={routes.calibrate.valueProposition.published({ companyId: company.id })} target="_blank">
								View currently published version
								<Icon name="launch" size="sm" />
							</Link>
						</Button>
					)}
				</>
			}
			actions={
				!isReadOnly
					? [
							{
								title: valueProposition.isDraft ? 'Publish' : 'Published',
								icon: valueProposition.isDraft ? 'arrow-up' : 'check',
								to: routes.calibrate.valueProposition.publish({ companyId: company.id }),
								variant: 'outline',
								disabled: !valueProposition.isDraft || !valueProposition.valuePropositions.length,
							},
							{
								title: 'Add New Point',
								icon: 'add',
								to: routes.calibrate.valueProposition.new({ companyId: company.id }),
							},
						]
					: []
			}
		>
			{!isReadOnly && <ExpertGtpInfo />}
			{!isReadOnly ? (
				<Accordion
					data={sections}
					type="multiple"
					className="rounded-lg border border-neutral-1-bd"
					triggerProps={{ className: 'py-3.5 px-4.5', separator: true }}
				/>
			) : (
				valueProposition.valuePropositions.length &&
				valueProposition.valuePropositions.map(item => (
					<LabeledValue key={item.id} label={item.name}>
						<Markdown>{item.description}</Markdown>
					</LabeledValue>
				))
			)}
			{!valueProposition.valuePropositions.length && (
				<EmptyStateCard title="You have no value proposition yet" icon="white-paper" />
			)}
		</PageLayout>
	)
}
