import { type LoaderFunctionArgs } from 'react-router-dom'
import { settingsCommandsQueries } from '#src/api/icp/company/settings/commands/queries'
import { settingsCopilotQueries } from '#src/api/icp/company/settings/copilot/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const [general, commands] = await Promise.all([
		client.fetchQuery(settingsCopilotQueries.get(company.id)),
		client.fetchQuery(settingsCommandsQueries.list(company.id)),
	])

	return {
		data: {
			general,
			commands,
		},
	}
}
