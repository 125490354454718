import { cva, type VariantProps } from 'class-variance-authority'
import { cloneElement, type ReactElement, type ReactNode, useEffect, useMemo, useRef } from 'react'
import { useController, useFormContext, get, type FieldErrors } from 'react-hook-form'
import { FileInput, type FileInputProps } from '#src/components/forms/FileInput'
import { FieldError } from '#src/components/forms/v2/FieldError'
import { type RegionSelectValue, RegionsSelect, type RegionsSelectProps } from '#src/components/RegionsSelect'
import { CardSwitch, type CardSwitchProps } from '#src/components/ui/CardSwitch'
import { CheckboxGroup, type CheckboxGroupProps } from '#src/components/ui/CheckboxGroup'
import { type CheckboxTreeProps, CheckboxTreeViewWithSearch } from '#src/components/ui/CheckboxTreeViewWithSearch'
import { Input, type InputProps } from '#src/components/ui/input'
import { Label } from '#src/components/ui/label'
import { MultiSelect, type MultiSelectProps } from '#src/components/ui/MultiSelect'
import { NumberInput, type NumberInputProps } from '#src/components/ui/NumberInput'
import { RadioGroup, type RadioGroupProps } from '#src/components/ui/RadioGroup'
import { RadioGroupCards, type RadioGroupCardsProps } from '#src/components/ui/RadioGroupCards'
import { RangeInput, type RangeInputProps } from '#src/components/ui/RangeInput'
import { SelectField, type SelectFieldProps as SelectProps } from '#src/components/ui/select'
import { Switch, type SwitchProps } from '#src/components/ui/switch'
import { Textarea, type TextareaProps } from '#src/components/ui/textarea'
import { defaultLabelSizeVariants } from '#src/theme'
import { type ClassName } from '#src/types/styles'
import { cn, formatToSentenceCase, lowercaseFirstLetter } from '#src/utils/misc'

export const FIELDS_WITH_VALUE_AS_ARRAY = ['checkbox', 'multiselect', 'range'] as const
export const FIELDS_WITH_OPTIONS = [
	'select',
	'multiselect',
	'checkbox',
	'radio',
	'regions',
	'radioCards',
	'checkboxTree',
] as const

export const getDisabledFieldValueByType = (fieldType: FormFieldType, mode?: 'empty' | 'disabled') => {
	if (mode === 'empty') {
		if (fieldType === 'switch' || fieldType === 'cardSwitch' || fieldType === 'regions') return undefined
		if (fieldType === 'range') return ['', '']
		if (FIELDS_WITH_VALUE_AS_ARRAY.includes(fieldType as (typeof FIELDS_WITH_VALUE_AS_ARRAY)[number])) return []

		return ''
	}

	if (fieldType === 'number') return '0'
	if (fieldType === 'switch' || fieldType === 'cardSwitch') return undefined
	if (fieldType === 'regions') return { included: ['-'], excluded: [] }
	if (fieldType === 'range') return ['0', '0']
	if (FIELDS_WITH_VALUE_AS_ARRAY.includes(fieldType as (typeof FIELDS_WITH_VALUE_AS_ARRAY)[number])) return ['-']

	return '-'
}

export const labelSizeVariants = cva('', {
	variants: {
		size: defaultLabelSizeVariants,
	},
	defaultVariants: {
		size: 'sm',
	},
})

export type FormFieldType =
	| 'text'
	| 'textarea'
	| 'number'
	| 'range'
	| 'select'
	| 'radio'
	| 'checkbox'
	| 'multiselect'
	| 'regions'
	| 'switch'
	| 'cardSwitch'
	| 'radioCards'
	| 'file'
	| 'checkboxTree'

type BaseFieldProps = {
	name: string
	label?: ReactNode
	placeholder?: string
	extendedPlaceholder?: string
	wrapperClassName?: ClassName
	disableLabel?: boolean
	disableField?: boolean
	labelClassName?: ClassName
	labelSize?: VariantProps<typeof labelSizeVariants>['size']

	/**
	 * When `enableToggle` prop is provided, the schema needs to use `transformAndCleanToggleableFieldsSchema`
	 * for proper handling of toggleable fields in the final data output.
	 *
	 * @see {@link transformAndCleanToggleableFieldsSchema} for transformation logic.
	 */
	enableToggle?: boolean
	hideWhenToggledOff?: boolean
}

type FieldMap = {
	text: InputProps
	textarea: TextareaProps
	number: NumberInputProps
	range: RangeInputProps
	select: SelectProps
	radio: RadioGroupProps
	checkbox: CheckboxGroupProps
	multiselect: MultiSelectProps
	regions: RegionsSelectProps
	switch: SwitchProps
	cardSwitch: CardSwitchProps
	radioCards: RadioGroupCardsProps
	file: FileInputProps
	checkboxTree: CheckboxTreeProps
}

export type FormFieldProps<T extends keyof FieldMap> = BaseFieldProps &
	FieldMap[T] & {
		fieldType: T
	}

export function FormField(props: FormFieldProps<'text'>): ReactElement
export function FormField(props: FormFieldProps<'textarea'>): ReactElement
export function FormField(props: FormFieldProps<'number'>): ReactElement
export function FormField(props: FormFieldProps<'range'>): ReactElement
export function FormField(props: FormFieldProps<'select'>): ReactElement
export function FormField(props: FormFieldProps<'regions'>): ReactElement
export function FormField(props: FormFieldProps<'radio'>): ReactElement
export function FormField(props: FormFieldProps<'checkbox'>): ReactElement
export function FormField(props: FormFieldProps<'multiselect'>): ReactElement
export function FormField(props: FormFieldProps<'switch'>): ReactElement
export function FormField(props: FormFieldProps<'cardSwitch'>): ReactElement
export function FormField(props: FormFieldProps<'radioCards'>): ReactElement
export function FormField(props: FormFieldProps<'file'>): ReactElement
export function FormField(props: FormFieldProps<'checkboxTree'>): ReactElement
export function FormField<T extends keyof FieldMap>({
	fieldType,
	name,
	label,
	placeholder,
	extendedPlaceholder,
	wrapperClassName,
	disableLabel,
	disableField,
	enableToggle,
	hideWhenToggledOff,
	labelClassName,
	labelSize,
	...rest
}: FormFieldProps<T>): ReactElement {
	const {
		watch,
		setValue,
		control,
		clearErrors,
		formState: { errors, isSubmitting },
	} = useFormContext()

	const { field } = useController({
		name,
		control,
	})

	const errorVal = get(errors, name) as FieldErrors | FieldErrors[] | undefined
	const previousVal = useRef<unknown>('')

	const errorMsg = useMemo(() => {
		if (Array.isArray(errorVal)) {
			return errorVal
				.map(err => (err && typeof err.message === 'string' ? err.message : undefined))
				.filter(Boolean) as string[]
		}

		if (errorVal && typeof errorVal.message === 'string') {
			return errorVal.message
		}

		if (typeof errorVal === 'object') {
			return Object.values(errorVal).flatMap(err => (err && typeof err.message === 'string' ? err.message : []))
		}
	}, [errorVal])

	const errorId = errorMsg ? `${name}-error` : undefined
	const defaultPlaceholder =
		placeholder ||
		`Enter ${!extendedPlaceholder ? formatToSentenceCase(name) : lowercaseFirstLetter(extendedPlaceholder)}`
	const defaultSelectPlaceholder =
		placeholder ||
		`Select ${!extendedPlaceholder ? formatToSentenceCase(name) : lowercaseFirstLetter(extendedPlaceholder)}`
	const defaultLabel = label || formatToSentenceCase(name, true)
	const toggleFieldName = `${name}Toggle`
	const isToggleChecked = enableToggle ? Boolean(watch(toggleFieldName) ?? true) : true
	const isFieldDisabled = enableToggle && !isToggleChecked

	const inputProps = useMemo(
		() => ({
			'aria-invalid': !isFieldDisabled && errorMsg ? true : undefined,
			'aria-describedby': errorId,
			isLoading: isSubmitting,
			disabled: isFieldDisabled || disableField,
		}),
		[errorMsg, errorId, isSubmitting, isFieldDisabled, disableField],
	)

	const commonProps = useMemo(
		() => ({
			...inputProps,
			id: name,
		}),
		[inputProps, name],
	)

	useEffect(() => {
		if (enableToggle && !isToggleChecked) {
			const disabledValue = getDisabledFieldValueByType(fieldType)
			field.onChange(disabledValue)
			setValue(toggleFieldName, false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableToggle])

	const handleToggleChange = (v: boolean) => {
		setValue(toggleFieldName, v)

		if (v) {
			field.onChange(previousVal.current || getDisabledFieldValueByType(fieldType, 'empty'))

			return
		}

		previousVal.current = field.value
		const disabledValue = getDisabledFieldValueByType(fieldType)
		field.onChange(disabledValue)
		clearErrors(name)
	}

	const formFieldItem = (() => {
		switch (fieldType) {
			case 'text': {
				const props = rest as InputProps
				return (
					<Input
						{...props}
						value={(field.value as string) || ''}
						onChange={e => field.onChange(e.target.value)}
						onClear={props.clearable ? () => field.onChange('') : undefined}
						placeholder={defaultPlaceholder}
					/>
				)
			}
			case 'textarea': {
				const props = rest as TextareaProps
				return (
					<Textarea
						{...props}
						value={(field.value as string) || ''}
						onChange={e => field.onChange(e.target.value)}
						placeholder={defaultPlaceholder}
					/>
				)
			}
			case 'number': {
				const props = rest as NumberInputProps
				return (
					<NumberInput
						{...props}
						value={(field.value as string) || ''}
						onChange={e => field.onChange(e.target.value)}
						placeholder={defaultPlaceholder}
					/>
				)
			}
			case 'radio': {
				const props = rest as unknown as RadioGroupProps
				return (
					<RadioGroup
						{...props}
						options={isFieldDisabled ? [...[{ label: '-', value: '-' }], ...props.options] : props.options}
						onChange={v => field.onChange(v || undefined)}
						value={field.value as string}
					/>
				)
			}
			case 'checkbox': {
				const props = rest as unknown as CheckboxGroupProps
				return (
					<CheckboxGroup
						{...props}
						options={isFieldDisabled ? [...[{ label: '-', value: '-' }], ...props.options] : props.options}
						onChange={field.onChange}
						value={field.value as string[]}
					/>
				)
			}
			case 'select': {
				const props = rest as unknown as SelectProps
				return (
					<SelectField
						{...props}
						options={isFieldDisabled ? [...[{ label: '-', value: '-' }], ...props.options] : props.options}
						onValueChange={v => field.onChange(v)}
						value={field.value as string}
						placeholder={placeholder || defaultSelectPlaceholder}
					/>
				)
			}
			case 'multiselect': {
				const props = rest as unknown as MultiSelectProps
				return (
					<MultiSelect
						{...props}
						options={isFieldDisabled ? [...[{ label: '-', value: '-' }], ...props.options] : props.options}
						value={field.value as string[]}
						onChange={v => field.onChange(v || undefined)}
						placeholder={placeholder || defaultSelectPlaceholder}
					/>
				)
			}
			case 'regions': {
				const props = rest as RegionsSelectProps

				return (
					<RegionsSelect
						{...props}
						options={isFieldDisabled ? [{ label: '-', value: '-' }] : props.options}
						value={(field.value as RegionSelectValue) || { included: [], excluded: [] }}
						onChange={v => field.onChange(v || undefined)}
						placeholder={placeholder || defaultSelectPlaceholder}
						disabled={isFieldDisabled}
					/>
				)
			}
			case 'switch': {
				const props = rest as SwitchProps
				return <Switch {...props} checked={!!field.value} onCheckedChange={v => field.onChange(v)} type="button" />
			}
			case 'cardSwitch': {
				const props = rest as unknown as CardSwitchProps
				return <CardSwitch {...props} checked={!!field.value} onCheckedChange={v => field.onChange(v)} type="button" />
			}
			case 'range': {
				const props = rest as RangeInputProps
				const fromName = `${name}[0]`
				const toName = `${name}[1]`

				return (
					<RangeInput
						{...props}
						value={field.value as [string, string]}
						onChange={newValue => field.onChange(newValue)}
						fromInputProps={{
							...inputProps,
							id: fromName,
							...props.fromInputProps,
						}}
						toInputProps={{
							...inputProps,
							id: toName,
							...props.toInputProps,
						}}
					/>
				)
			}
			case 'radioCards': {
				const props = rest as unknown as RadioGroupCardsProps
				return (
					<RadioGroupCards
						{...props}
						options={props.options}
						onChange={v => field.onChange(v || undefined)}
						value={field.value as string}
					/>
				)
			}
			case 'file': {
				const props = rest as unknown as FileInputProps
				return (
					<FileInput
						{...props}
						value={field.value as File | undefined}
						onChange={v => field.onChange(v || undefined)}
					/>
				)
			}
			case 'checkboxTree': {
				const props = rest as unknown as CheckboxTreeProps
				return (
					<CheckboxTreeViewWithSearch
						{...props}
						value={field.value as string[] | undefined}
						onChange={v => field.onChange(v || [])}
					/>
				)
			}
			default:
				return <Input />
		}
	})()

	const renderField = cloneElement(formFieldItem, {
		...commonProps,
	})

	return (
		<div className={cn('relative flex flex-col gap-1', wrapperClassName)}>
			{isFieldDisabled && (
				<div className="absolute inset-0 z-10 cursor-pointer" onClick={() => handleToggleChange(true)} />
			)}
			{!disableLabel && (
				<Label
					htmlFor={name}
					className={cn(labelSizeVariants({ size: labelSize }), labelClassName, 'flex items-center justify-between')}
				>
					{defaultLabel}
					{enableToggle && <Switch checked={isToggleChecked} onCheckedChange={handleToggleChange} type="button" />}
				</Label>
			)}
			{!(hideWhenToggledOff && isFieldDisabled) && renderField}
			{errorMsg && !isFieldDisabled && <FieldError errorId={errorId} errorMsg={errorMsg} />}
		</div>
	)
}
