import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { updateAssignedSignals } from '../mutations'
import { assignedSignalsQuery, signalsKeys } from '../queries'
import { AssignedSignalsFormSchema } from '../schema'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['signalId'])

	const formData = await request.formData()
	const intent = formData.get('intent') as string | null
	if (!intent) {
		throw Error('Form is missing intent')
	}

	let payload: Array<{ id: number; manualInput: string | null | undefined }> | undefined

	if (intent === 'unmap-all') {
		payload = []
	} else {
		const submission = parseWithZod(formData, {
			schema: AssignedSignalsFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error(`Failed to parse form data: ${JSON.stringify(submission.error)}`)
		}

		const data = Object.entries(submission.value.personas)
			.filter(([, { selected }]) => selected === 'on')
			.map(([personaId, { manualInput }]) => ({
				id: Number(personaId),
				manualInput: manualInput ?? null,
			}))

		const assignedSignals = (await client.fetchQuery(assignedSignalsQuery(company.id))).filter(
			s => s.signalId === Number(params.signalId),
		)

		if (intent === 'map') {
			const newPersonas = data.filter(p => !assignedSignals.some(a => a.personaId === p.id))
			const oldPersonas = assignedSignals.map(signal => {
				const persona = data.find(p => p.id === signal.personaId)

				if (!persona) {
					return {
						id: signal.personaId,
						manualInput: signal.manualInput,
					}
				}

				return {
					id: persona.id,
					manualInput: persona.manualInput ?? null,
				}
			})

			payload = oldPersonas.concat(newPersonas)
		} else if (intent === 'unmap') {
			payload = assignedSignals
				.filter(p => !data.some(a => a.id === p.personaId))
				.map(p => ({
					id: p.personaId,
					manualInput: p.manualInput,
				}))
		}
	}

	if (!payload) {
		throw Error('Failed to parse form data')
	}

	try {
		await updateAssignedSignals(company.id, params.signalId, {
			personas: payload,
		})

		void client.invalidateQueries({
			queryKey: signalsKeys.all,
		})

		return {
			ok: true,
			success: true,
			action: intent,
		}
	} catch (error) {
		return {
			ok: false,
			action: intent,
		}
	}
}
