import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { deletePersonas } from '#src/routes/calibrate/personas/mutations'
import { DeletePersonasFormSchema } from '#src/routes/calibrate/personas/schema'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'

export const deletePersonasFormAction =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: DeletePersonasFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		await deletePersonas(params.companyId, {
			personas: submission.value.personas as string[],
		})

		await queryClient.invalidateQueries({
			queryKey: verticalKeys.all,
		})

		void queryClient.invalidateQueries({
			queryKey: ecosystemKeys.list(params.companyId),
		})

		showToast({
			message: 'Personas successfully deleted',
			type: 'success',
		})

		return { success: true, message: 'Personas successfully deleted' }
	}
