import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cva, type VariantProps } from 'class-variance-authority'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
	type ReactNode,
	useRef,
	useEffect,
	useImperativeHandle,
} from 'react'
import { defaultLabelSizeVariants } from '#src/theme'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'
import { Surface } from './surface'

export const AccordionRoot = AccordionPrimitive.Root
export type TriggerProps = ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
	separator?: boolean
}

export const AccordionItem = forwardRef<
	ElementRef<typeof AccordionPrimitive.Item>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> & {
		trigger: ReactNode
		children: ReactNode
		contentProps?: ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
		disableBorder?: boolean
	}
>(({ trigger, children, contentProps, disableBorder, ...props }, forwardedRef) => (
	<AccordionPrimitive.Item {...props} asChild ref={forwardedRef}>
		{disableBorder ? (
			<>
				{trigger}
				<AccordionContent {...contentProps}>{children}</AccordionContent>
			</>
		) : (
			<Surface className="flex flex-col gap-8 border border-neutral-2-bd">
				{trigger}
				<AccordionContent {...contentProps}>{children}</AccordionContent>
			</Surface>
		)}
	</AccordionPrimitive.Item>
))
AccordionItem.displayName = 'AccordionItem'

export const AccordionTrigger = forwardRef<
	ElementRef<typeof AccordionPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> &
		TriggerProps & {
			index?: number
			dataLength?: number
		}
>(({ children, className, index, dataLength = 1, ...props }, forwardedRef) => (
	<AccordionPrimitive.Header>
		<AccordionPrimitive.Trigger
			className={cn(
				'group flex w-full items-center gap-1.5 overflow-hidden text-title-md transition-opacity radix-disabled:opacity-50',
				props?.separator && index !== dataLength - 1 && 'border-b border-neutral-1-bd radix-state-open:border-b-0',
				index === 0 && 'rounded-t-lg',
				index === dataLength && 'rounded-none',
				index !== 0 && index !== dataLength - 1 && 'rounded-none',
				'transition-[border-radius,background-color,height,opacity] duration-200 ease-out',
				props?.separator && 'radix-state-open:bg-neutral-2-bg',
				className,
			)}
			{...props}
			ref={forwardedRef}
		>
			<div>
				<Icon
					name="chevron-down"
					className="transform transition-all group-radix-disabled:opacity-50 group-radix-state-open:rotate-180"
					aria-hidden
				/>
			</div>
			{children}
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
))
AccordionTrigger.displayName = 'AccordionTrigger'

export const AccordionContent = forwardRef<
	ElementRef<typeof AccordionPrimitive.Content>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => {
	const contentRef = useRef<HTMLDivElement | null>(null)
	const innerRef = useRef<HTMLDivElement | null>(null)

	useImperativeHandle(forwardedRef, () => contentRef.current as HTMLDivElement, [])

	// Source: https://github.com/radix-ui/primitives/discussions/1481
	// Because radix is broken when forceMount is true
	useEffect(() => {
		if (!props?.forceMount) return

		const content = contentRef.current
		const inner = innerRef.current

		if (!content || !inner) return

		const resizeObserver = new ResizeObserver(() => {
			const currentHeight = inner.scrollHeight
			content.style.cssText = `--radix-accordion-content-height: ${currentHeight}px;`
		})

		resizeObserver.observe(inner)

		return () => resizeObserver.disconnect()
	}, [props?.forceMount])

	return (
		<AccordionPrimitive.Content
			className={cn(
				'overflow-hidden radix-state-closed:animate-acc-slide-up radix-state-closed:opacity-0 radix-state-open:animate-acc-slide-down',
				className,
			)}
			ref={contentRef}
			{...props}
		>
			<div ref={innerRef}>{children}</div>
		</AccordionPrimitive.Content>
	)
})
AccordionContent.displayName = 'AccordionContent'

const accordionTriggerVariants = cva('font-semibold', {
	variants: {
		size: defaultLabelSizeVariants,
	},
	defaultVariants: {
		size: 'md',
	},
})

type AccordionProps = ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> & {
	data: { trigger: ReactNode; content: ReactNode }[]
	size?: VariantProps<typeof accordionTriggerVariants>['size']
	contentProps?: ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
	triggerProps?: TriggerProps
}

export const Accordion = forwardRef<ElementRef<typeof AccordionPrimitive.Root>, AccordionProps>(
	({ data, size = 'md', contentProps, triggerProps, ...props }, ref) => {
		const { className: triggerClassName, ...restTriggerProps } = triggerProps || {}

		return (
			<AccordionPrimitive.Root {...props} ref={ref}>
				{data.map(({ trigger, content }, i) => (
					<AccordionItem
						key={i}
						value={`item-${i}`}
						disableBorder
						trigger={
							<AccordionTrigger
								index={i}
								dataLength={data.length}
								className={cn(accordionTriggerVariants({ size }), triggerClassName)}
								{...restTriggerProps}
							>
								{trigger}
							</AccordionTrigger>
						}
						contentProps={contentProps}
					>
						{content}
					</AccordionItem>
				))}
			</AccordionPrimitive.Root>
		)
	},
)

Accordion.displayName = 'Accordion'
