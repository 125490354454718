import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { settingsCopilotEndpoints } from './endpoints'
import { settingsCopilotQueriesKeys } from './queries'
import { type SettingsCopilotFormSchema } from './schemas'

const mutations = {
	update: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof SettingsCopilotFormSchema>) => {
		await fetch(settingsCopilotEndpoints.update(companyId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: settingsCopilotQueriesKeys.all,
		})
	},
}

export { mutations as settingsCopilotMutations }
