import { Outlet, useLoaderData, useMatches, useNavigation } from 'react-router-dom'
import { Dialog, type DialogProps } from '#src/components/ui/dialog'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/verticals/criteria/routes/save'
import { routes } from '#src/utils/routes'
import { type LoaderResType } from '../loaders/modalLayout'

export type ModalHandle = {
	getDialogConfig: (data: LoaderResType) => DialogProps
}

export const ModalLayout = () => {
	const {
		values: { companyId, ecosystemId, type },
	} = useLoaderData() as CriteriaSaveLoaderType
	const matches = useMatches()
	const match = matches[matches.length - 1]
	const handle = match.handle as ModalHandle
	const dialogConfig = handle.getDialogConfig(match.data as LoaderResType)
	const navigation = useNavigation()
	const isSubmitting = navigation.state === 'submitting'
	const mode = match?.params?.criterionId ? 'Edit' : 'Add'

	return (
		<Dialog
			open={true}
			dialogHeading={`${mode} ${type === 'enrichment' ? 'Enrichment' : 'Qualification'} Criterion`}
			size="lg"
			closeTo={routes.calibrate.criteria.editAnswers({
				companyId,
				ecosystemId,
				criterionType: type,
			})}
			isSubmitting={isSubmitting}
			{...dialogConfig}
		>
			<Outlet />
		</Dialog>
	)
}
