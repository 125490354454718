import { Logo } from '#src/components/ui/logo'

export const SplashScreen = () => {
	return (
		<section className="flex h-screen w-full items-center justify-center overflow-hidden">
			<div className="relative z-0 flex w-max items-center justify-center">
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[570px] w-[42.5%] min-w-[570px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[400px] w-[32.5%] min-w-[400px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[230px] w-[22.5%] min-w-[230px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
				</div>
				<div className="pointer-events-none absolute inset-0 bottom-[-500%] flex select-none flex-col items-center justify-center">
					<div className="min-h-[300px] w-[32.5%] min-w-[600px] rounded-[300px_300px_0_0] bg-[radial-gradient(circle,rgba(255,255,255,1)_0%,rgba(255,255,255,1)_10%,rgba(255,255,255,0)_75%)]" />
				</div>
				<Logo size="xl" type="symbol" />
			</div>
		</section>
	)
}
