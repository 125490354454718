import DeleteConfirm from '#src/components/delete-confirm'
import { Icon } from '#src/components/ui/icon'
import { routes } from '#src/utils/routes'

const DeleteCompanyForm = ({ companyId }: { companyId: string }) => {
	return (
		<DeleteConfirm
			trigger={
				<button className="inline-flex items-center justify-center">
					<Icon name="trash" size="font" />
				</button>
			}
			action={routes.admin.company.delete({ companyId })}
			title="Delete company?"
			description="This action cannot be undone. This will permanently delete the company and any associated data such as ecosystems, verticals, personas etc."
		/>
	)
}

export default DeleteCompanyForm
