import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { settingsCopilotEndpoints } from './endpoints'
import { SettingsCopilotAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'settings', 'copilot'] as const,
	get: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'get', companyId] as const,
}

const getQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.get(companyId),
	queryFn: async () =>
		await safeFetch(SettingsCopilotAPISchema, settingsCopilotEndpoints.get(companyId), {
			method: 'GET',
		}),
})

const queries = {
	get: getQuery,
}

export { queries as settingsCopilotQueries, keys as settingsCopilotQueriesKeys }
