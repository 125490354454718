import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cva, type VariantProps } from 'class-variance-authority'
import { type ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { PanelSectionItem } from '#src/components/panel'
import { Icon, type IconName } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

const treeNodeVariants = cva('', {
	variants: {
		variant: {
			default: '',
		},
		size: {
			sm: 'text-body-sm text-neutral-2-fg',
			md: 'text-body-md text-neutral-2-fg',
			lg: 'text-body-lg text-neutral-2-fg',
		},
		iconSize: {
			sm: 'h-3 w-3',
			md: 'h-4 w-4',
			lg: 'h-5 w-5',
		},
		childrenCountSize: {
			sm: 'text-body-xs',
			md: 'text-body-sm',
			lg: 'text-body-md',
		},
	},
	defaultVariants: {
		variant: 'default',
		size: 'sm',
	},
})

export type TreeNode = {
	id: string
	label: ReactNode | string
	to?: string
	onClick?: () => void
	icon?: IconName
	childrenCount?: number
	children?: TreeNode[]
}

type ExpandableTreeProps = VariantProps<typeof treeNodeVariants> & {
	data: TreeNode[]
	defaultExpandedItems?: string[]
	defaultSelectedLeafItemId?: string
}

/**
 * ExpandableTree component renders a tree structure with expandable nodes.
 */
export const ExpandableTree = ({
	data,
	variant,
	size,
	defaultExpandedItems = [],
	defaultSelectedLeafItemId,
}: ExpandableTreeProps) => {
	const [open, setOpen] = useState<string[]>(defaultExpandedItems)
	const [selectedLeafNodeId, setSelectedLeafNodeId] = useState<string | undefined>(defaultSelectedLeafItemId)

	const toggleOpen = (id: string) =>
		setOpen(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]))

	const renderTreeNode = (node: TreeNode) => {
		const isOpen = open.includes(node.id)

		return (
			<AccordionPrimitive.Item key={node.id} value={node.id}>
				<PanelSectionItem
					isTrigger={!!node.children?.length}
					className={cn(
						'relative mx-2 cursor-pointer select-none pe-3.5',
						!node.children?.length && 'hover:bg-neutral-3-bg',
						!node.children?.length && selectedLeafNodeId === node.id && 'bg-neutral-2-bg',
					)}
					onClick={() => {
						if (node.onClick) node.onClick()
						if (node.children?.length) toggleOpen(node.id)
						if (!node.children?.length) setSelectedLeafNodeId(node.id)
					}}
				>
					<section className="flex w-full items-center justify-between">
						<div className="flex items-center gap-1.5">
							{!!node.children?.length && (
								<AccordionPrimitive.Trigger className="group" asChild>
									<button className="group flex items-center" onClick={e => e.stopPropagation()}>
										<Icon
											name="chevron-down"
											size="sm"
											aria-hidden
											className={cn(
												'-rotate-180 transform text-neutral-1-fg transition-transform duration-75',
												isOpen && 'rotate-0',
												treeNodeVariants({ iconSize: size }),
											)}
										/>
									</button>
								</AccordionPrimitive.Trigger>
							)}
							<span
								className={cn(
									treeNodeVariants({ size, variant }),
									!node.children?.length && 'pl-2.5',
									isOpen && 'font-semibold',
								)}
							>
								{node.to ? (
									<Link to={node.to} className="flex w-[180px] items-center gap-2">
										{node.icon && (
											<Icon name={node.icon} aria-hidden className={treeNodeVariants({ iconSize: size })} />
										)}
										<span className="truncate">{node.label}</span>
									</Link>
								) : (
									<button className="flex w-[180px] items-center gap-2">
										{node.icon && (
											<Icon name={node.icon} aria-hidden className={treeNodeVariants({ iconSize: size })} />
										)}
										<span className="truncate">{node.label}</span>
									</button>
								)}
							</span>
						</div>
						<span
							className={cn(
								'absolute right-[10px] top-1/2 -translate-y-1/2 transform',
								treeNodeVariants({ childrenCountSize: size }),
							)}
						>
							{node.childrenCount}
						</span>
					</section>
				</PanelSectionItem>
				{!!node.children?.length && isOpen && (
					<AccordionPrimitive.Content className="bg pl-3">
						{node.children.map(child => renderTreeNode(child))}
					</AccordionPrimitive.Content>
				)}
			</AccordionPrimitive.Item>
		)
	}

	return (
		<AccordionPrimitive.Root type="multiple" value={open} onValueChange={setOpen}>
			{data.map(node => renderTreeNode(node))}
		</AccordionPrimitive.Root>
	)
}
