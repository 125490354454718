import { type LoaderFunctionArgs } from 'react-router-dom'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { scenarioTemplatesQuery, voicesQuery } from '#src/routes/enable/roleplay/scenario/queries'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type NewRoleplayScenarioWizardLoaderResponse = Awaited<ReturnType<typeof newRoleplayScenarioWizardLoader>>

export const newRoleplayScenarioWizardLoader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const { readOnlySession } = await getUser()

	if (readOnlySession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	const scenarioTemplates = await client.fetchQuery(scenarioTemplatesQuery(company.id))

	if (!scenarioTemplates.length)
		throw new Response('No scenario templates provided', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		companyId: params.companyId,
		conversationId: params.conversationId,
		scenarioTemplates,
		ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
		voices: await client.fetchQuery(voicesQuery(company.id)),
	}
}
