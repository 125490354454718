import { type LoaderFunctionArgs } from 'react-router-dom'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { statisticsQuery } from '#src/routes/lead-research-agent/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	let lraStats = {
		inQueueCount: 0,
		inProgressCount: 0,
		completedCount: 0,
	}
	try {
		lraStats = await client.fetchQuery(statisticsQuery(company.id))
	} catch (e) {
		showToast({
			type: 'error',
			message: 'LRA stats failed to load',
		})
	}

	return {
		data: {
			stats: await client.fetchQuery(statsQueries.dashboard(company.id)),
			lraStats,
		},
	}
}
