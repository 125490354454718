import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { PageLayout, type PageLayoutProps } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { JobStatusName, LRA_JOB_STATUS } from '#src/routes/lead-research-agent/constants'
import { itemQuery } from '#src/routes/lead-research-agent/queries'
import { canDownload, canStop } from '#src/routes/lead-research-agent/utils'
import { formatDateToReadableString } from '#src/utils/date'
import { type LRAItemLoaderRes } from '../routes/item'
import {
	BarLineStatistics,
	ItemStatisticsCard,
	type ItemStatisticsCardProps,
	JobDownloadModal,
	JobStopModal,
	LabeledValue,
} from './components'

const FIRST_CARD_ICON: Record<LRA_JOB_STATUS, IconName> = {
	[LRA_JOB_STATUS.Launching]: 'loading-sm',
	[LRA_JOB_STATUS.Completed]: 'checkmark-filled',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'checkmark-filled-warning',
	[LRA_JOB_STATUS.Cancelled]: 'stop-sign-filled',
	[LRA_JOB_STATUS.Error]: 'stop-sign-filled',
	[LRA_JOB_STATUS.InProgress]: 'loading-sm',
} as const

const FIRST_CARD_ICON_VARIANT: Record<LRA_JOB_STATUS, ItemStatisticsCardProps['iconVariant']> = {
	[LRA_JOB_STATUS.Launching]: undefined,
	[LRA_JOB_STATUS.Completed]: 'success',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'success',
	[LRA_JOB_STATUS.Cancelled]: 'danger',
	[LRA_JOB_STATUS.Error]: 'danger',
	[LRA_JOB_STATUS.InProgress]: undefined,
} as const

export const View = () => {
	const {
		values: { jobId },
		data,
	} = useLoaderData() as LRAItemLoaderRes

	const { data: job } = useQuery({
		...itemQuery(jobId),
		initialData: data.job,
		refetchInterval: ({ state }) => {
			if (!state.data) {
				return 0
			}
			if (canStop(state.data.status)) {
				return 5000
			}
			return 0
		},
	})

	const [copyIdTooltipShow, setCopyIdTooltipShow] = useState(false)
	const onClickCopyId = () => {
		navigator.clipboard
			.writeText(job.id)
			.then(() => {
				setCopyIdTooltipShow(true)
				setTimeout(() => setCopyIdTooltipShow(false), 1500)
			})
			.catch(() => {})
	}

	return (
		<PageLayout
			title={job.title}
			actions={[
				...((canStop(job.status)
					? [
							<JobStopModal jobId={jobId} key={`action-stop`}>
								<Button variant="danger-outline" className="flex flex-nowrap items-center gap-2">
									<Icon name="stop-filled-alt" size="font" className="text-status-danger-fg" />
									Stop
								</Button>
							</JobStopModal>,
						]
					: []) satisfies PageLayoutProps['actions']),
				...((canDownload(job.status)
					? [
							<JobDownloadModal key={`action-download`} jobId={jobId}>
								<Button className="flex flex-nowrap items-center gap-2">
									<Icon name="download" /> Download
								</Button>
							</JobDownloadModal>,
						]
					: []) satisfies PageLayoutProps['actions']),
			]}
		>
			<div className="grid w-full max-w-screen-2xl grid-cols-1 grid-rows-1 gap-6 lg:grid-cols-2">
				<ItemStatisticsCard
					icon={FIRST_CARD_ICON[job.status]}
					iconVariant={FIRST_CARD_ICON_VARIANT[job.status]}
					name={JobStatusName[job.status]}
				>
					<BarLineStatistics
						name="Accounts"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.accountInQueueCount,
							},
							{
								id: 'in_progress',
								icon: 'incomplete',
								name: 'In progress',
								color: 'bg-brand-1-fg',
								iconColor: 'text-brand-1-fg',
								value: job.statistic.accountInProgressCount,
							},
							{
								id: 'completed',
								icon: 'checkmark-filled',
								name: 'Completed',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.accountCompletedCount,
							},
							{
								id: 'with_errors',
								icon: 'checkmark-filled',
								name: 'With errors',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.accountWithErrorsCount,
							},
							{
								id: 'with_questions',
								icon: 'help-filled',
								name: 'With questions',
								color: 'bg-status-warning-bg',
								iconColor: 'text-status-warning-bg',
								value: job.statistic.accountWithQuestionsCount,
							},
						]}
					/>
					<BarLineStatistics
						name="Contacts"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.contactInQueueCount,
							},
							{
								id: 'in_progress',
								icon: 'incomplete',
								name: 'In progress',
								color: 'bg-brand-1-fg',
								iconColor: 'text-brand-1-fg',
								value: job.statistic.contactInProgressCount,
							},
							{
								id: 'completed',
								icon: 'checkmark-filled',
								name: 'Completed',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.contactCompletedCount,
							},
							{
								id: 'with_errors',
								icon: 'checkmark-filled',
								name: 'With errors',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.contactWithErrorsCount,
							},
							{
								id: 'with_questions',
								icon: 'help-filled',
								name: 'With questions',
								color: 'bg-status-warning-bg',
								iconColor: 'text-status-warning-bg',
								value: job.statistic.contactWithQuestionsCount,
							},
						]}
					/>
				</ItemStatisticsCard>
				<ItemStatisticsCard
					icon="user-check"
					name="ICP/IBP ratio"
					tip="This view shows the number of accounts and contacts from your uploaded list that match the Ideal Customer Profile (ICP) and Ideal Buyer Profile (IBP) criteria."
				>
					<BarLineStatistics
						name="ICP"
						items={[
							{
								id: 'unknown',
								icon: 'circle-dash',
								name: 'Unknown',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.icpNullCount,
							},
							{
								id: 'yes',
								icon: 'checkmark-outline',
								name: 'ICP Yes',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.icpTrueCount,
							},
							{
								id: 'no',
								icon: 'cross-outline',
								name: 'ICP No',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.icpFalseCount,
							},
						]}
					/>
					<div className="flex flex-col gap-4">
						<BarLineStatistics
							name="IBP"
							items={[
								{
									id: 'unknown',
									icon: 'circle-dash',
									name: 'Unknown',
									color: 'bg-brand-2-fg',
									iconColor: 'text-neutral-3-fg',
									value: job.statistic.ibpNullCount,
								},
								{
									id: 'yes',
									icon: 'checkmark-outline',
									name: 'IBP Yes',
									color: 'bg-status-success-bg',
									iconColor: 'text-status-success-bg',
									value: job.statistic.ibpTrueCount,
								},
								{
									id: 'no',
									icon: 'cross-outline',
									name: 'IBP No',
									color: 'bg-status-danger-bg',
									iconColor: 'text-status-danger-bg',
									value: job.statistic.ibpFalseCount,
								},
							]}
						/>
						<div className="w-full border-b border-neutral-1-bd" />
						<div className="flex flex-row items-center justify-between gap-1 py-1.5">
							<div className="flex flex-row items-center justify-between gap-1 text-neutral-2-fg">
								<Icon name="email" size="sm" />
								<div className="text-body-sm">Contacts with Email/Phone</div>
							</div>
							<div className="text-body-sm">{job.statistic.contactWithEmailPhoneCount}</div>
						</div>
					</div>
				</ItemStatisticsCard>
			</div>
			<Surface className="flex h-full w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Details</h2>
				<div className="flex w-full flex-row gap-8">
					<div className="flex flex-1 flex-col gap-6">
						<LabeledValue label="Name">{job.title}</LabeledValue>
						{!!job.comment && (
							<LabeledValue label="Comment">
								<div className="whitespace-pre-wrap">{job.comment}</div>
							</LabeledValue>
						)}
						<LabeledValue label="Verticals">
							<div className="flex flex-row flex-wrap gap-1">
								{job.verticals?.map((vertical, index) => (
									<div
										key={`vertical-${index}`}
										className="rounded bg-neutral-2-bg px-2 py-1 text-label-md text-neutral-2-fg"
									>
										{vertical.title}
									</div>
								))}
							</div>
						</LabeledValue>
					</div>
					<div className="flex w-full max-w-[330px] flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
						<LabeledValue label="Id">
							<span>{job.id}</span>
							<CustomTooltip
								rootProps={{
									open: copyIdTooltipShow,
								}}
								label="Copied"
							>
								<Icon
									name="copy"
									className="ml-1 size-4 cursor-pointer text-neutral-3-fg hover:text-neutral-1-fg"
									onClick={onClickCopyId}
								/>
							</CustomTooltip>
						</LabeledValue>
						<LabeledValue label="Date created">{formatDateToReadableString(job.createdAt)}</LabeledValue>
						{!!job.importFileUrl && (
							<LabeledValue label="List source file">
								<a
									href={job.importFileUrl}
									target="_blank"
									rel="noreferrer"
									className="flex w-full flex-row items-center gap-1"
								>
									<Icon name="document" size="md" className="block text-brand-1-fg" />
									<div className="flex flex-1 flex-col truncate">
										<div className="max-w-full truncate text-body-md text-brand-1-fg">Uploaded file</div>
										{/*<div className="text-body-sm text-neutral-2-fg">500 KB</div>*/}
									</div>
								</a>
							</LabeledValue>
						)}
						<LabeledValue label="Accounts/Contacts uploaded">{job.accountsContactsImported ?? '-'}</LabeledValue>
						<LabeledValue label="Contact Finder (Beta) enabled">{job.contactFinderEnabled ? 'Yes' : 'No'}</LabeledValue>
						<LabeledValue label="Contact Waterfalling enabled">
							{job.contactWaterfallingEnabled ? 'Yes' : 'No'}
						</LabeledValue>
					</div>
				</div>
			</Surface>
			<Surface className="flex h-full w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Report</h2>
				<iframe
					className="aspect-video w-full"
					src={`https://evergrowth.metabaseapp.com/public/dashboard/58453dcb-dae4-4d47-97db-91a842678709?criteria=-&icp=&job=${job.id}`}
				/>
			</Surface>
		</PageLayout>
	)
}
