import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z, ZodObject } from 'zod'
import { validateRouteParams } from '#src/utils/misc'
import { CRITERION_TYPE_ENUM } from '../constants'
import { CriteriaAPISchema } from '../schema'
import { generateEditAnswersContext } from '../utils'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
		ecosystemId: z.string(),
		type: CRITERION_TYPE_ENUM,
		schema: z.instanceof(ZodObject),
		defaultValues: z.record(z.union([z.string(), z.number(), z.boolean(), z.array(z.string())])),
	}),
	data: z.object({
		criteria: CriteriaAPISchema,
	}),
})

export type LoaderResType = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient, type: z.infer<typeof LoaderSchema>['values']['type']) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])
		const { defaultValues, updatedCriteria, schema } = await generateEditAnswersContext(
			params.companyId,
			params.ecosystemId,
			type,
		)

		return {
			values: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				type,
				schema,
				defaultValues,
			},
			data: {
				criteria: updatedCriteria,
			},
		}
	}
