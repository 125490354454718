import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, useFetcher } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { recalculateSalesCoach } from './mutations'
import { salesCoachKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.personaId || !params.salescoachId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await recalculateSalesCoach(params.companyId, params.personaId, params.salescoachId)

		await queryClient.invalidateQueries({
			queryKey: salesCoachKeys.list(params.companyId, params.personaId),
		})

		return null
	}

export interface RecalculateVerticalsAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: string
	verticalId: string
	personaId: string
	generalSalescoachId: string | undefined
	timingSalescoachId: string | undefined
	children: ReactNode
}

export const RecalculateSalesCoachAsyncForm = forwardRef<HTMLButtonElement, RecalculateVerticalsAsyncFormProps>(
	(
		{ ecosystemId, verticalId, personaId, generalSalescoachId, timingSalescoachId, children, className, ...props },
		ref,
	) => {
		const params = useParsedRouteParams(['companyId'])
		const fetcher = useFetcher()
		const action = (salescoachId: string) =>
			routes.calibrate.persona.coach.recalculate({
				companyId: params.companyId,
				ecosystemId: ecosystemId,
				verticalId: verticalId,
				personaId: personaId,
				salesCoachId: salescoachId,
			})

		return (
			<Button
				variant="outline"
				{...props}
				ref={ref}
				type="button"
				disabled={fetcher.state !== 'idle'}
				className={cn('w-full', fetcher.state !== 'idle' ? 'animate-pulse' : '', className)}
				onClick={() => {
					if (generalSalescoachId)
						fetcher.submit(null, {
							method: 'PUT',
							action: action(generalSalescoachId),
						})
					if (timingSalescoachId)
						fetcher.submit(null, {
							method: 'PUT',
							action: action(timingSalescoachId),
						})
				}}
			>
				{children}
			</Button>
		)
	},
)
RecalculateSalesCoachAsyncForm.displayName = 'RecalculateSalesCoachAsyncForm'
