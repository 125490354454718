import { type RouteObject } from 'react-router-dom'
import { playsCreateAction, PlaysCreateView } from '#src/routes/enable/plays/routes/create'
import { playsDeleteAction, PlaysDeleteView } from '#src/routes/enable/plays/routes/delete'
import { playsEditAction, PlaysEditView } from '#src/routes/enable/plays/routes/edit'
import { playsItemLoader } from '#src/routes/enable/plays/routes/item'
import { loader as playsCreateLoader } from './loaders/create'
import { playsListLoader, PlaysListView } from './routes/list'

export default {
	path: 'plays',
	loader: playsListLoader,
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Plays',
				icon: 'continue',
			},
		],
	},
	element: <PlaysListView />,
	children: [
		{
			path: 'new',
			loader: playsCreateLoader,
			action: playsCreateAction,
			element: <PlaysCreateView />,
		},
		{
			id: 'plays-item',
			path: ':playId',
			loader: playsItemLoader,
			children: [
				{
					path: 'edit',
					loader: playsCreateLoader,
					action: playsEditAction,
					element: <PlaysEditView />,
				},
				{
					path: 'delete',
					action: playsDeleteAction,
					element: <PlaysDeleteView />,
				},
			],
		},
	],
} satisfies RouteObject
