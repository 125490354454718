import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deleteEcosystem } from './mutations'
import { ecosystemKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await deleteEcosystem(params.companyId, params.ecosystemId)

		await queryClient.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		return redirect(
			routes.calibrate.index({
				companyId: params.companyId,
			}),
		)
	}

export interface DeleteEcosystemAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	children: ReactNode
	onSubmit?: () => void
}

export const DeleteEcosystemAsyncForm = forwardRef<HTMLButtonElement, DeleteEcosystemAsyncFormProps>(
	({ ecosystemId, children, className }, ref) => {
		const params = useParsedRouteParams(['companyId'])
		const action = routes.calibrate.ecosystem.delete({
			companyId: params.companyId,
			ecosystemId: ecosystemId.toString(),
		})

		return (
			<DeleteConfirm
				trigger={
					<button type="button" className={cn('w-full', className)} ref={ref}>
						{children}
					</button>
				}
				action={action}
				title="Delete ecosystem?"
				description="This action cannot be undone. This will permanently delete the ecosystem and remove any associated data such as verticals and personas."
			/>
		)
	},
)
DeleteEcosystemAsyncForm.displayName = 'DeleteEcosystemAsyncForm'
