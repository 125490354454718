import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import {
	assignCriterion,
	createCriterion,
	updateAssignedAnswers,
	updateCriterion,
} from '#src/routes/calibrate/verticals/criteria/mutations'
import { criteriaKeys, criterionQuery } from '#src/routes/calibrate/verticals/criteria/queries'
import {
	generateSaveCriterionContext,
	transformAnswersSubmissionToPayload,
} from '#src/routes/calibrate/verticals/criteria/utils'
import { verticalKeys, verticalsQuery } from '#src/routes/calibrate/verticals/queries'
import { type APIFieldType } from '#src/schemas/global'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

import { type CriteriaEditAnswersSchema } from '../routes/editAnswers'
import {
	CriterionAllAssignedVerticalsAnswersPayload,
	CriterionNewPayloadSchema,
	getCriterionNewFormSchema,
} from '../schema'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient, criterionType: z.infer<typeof CriteriaEditAnswersSchema>['values']['type']) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['companyId', 'ecosystemId'])

		const formData = await request.formData()
		const fieldType = formData.get('type') as APIFieldType
		const isEdit = !!params.criterionId

		const criterion = isEdit
			? await queryClient.fetchQuery(criterionQuery(params.companyId, params.criterionId))
			: undefined

		const formSchema = getCriterionNewFormSchema(fieldType)
		const formSubmission = parseFormData(formData, formSchema)

		const verticals = await client.fetchQuery(verticalsQuery(params.companyId, Number(params.ecosystemId)))

		const { schema: verticalsAnswersSchema } = generateSaveCriterionContext(
			verticals,
			formSubmission.value.type,
			criterionType,
			criterion,
		)

		const verticalsAnswersSubmission = parseFormData(
			formData,
			verticalsAnswersSchema.transform(transformAndCleanToggleableFieldsSchema),
		)

		const createOrUpdateCriterion = async () => {
			const formPayload = {
				...formSubmission.value,
				isEnrichment: criterionType === 'enrichment',
				inBizmodel: criterionType === 'enrichment' ? formSubmission.value.inBizmodel : undefined,
			}
			try {
				const parsedForm = CriterionNewPayloadSchema.parse(formPayload)

				return isEdit
					? await updateCriterion(params.companyId, params.criterionId, parsedForm)
					: await createCriterion(params.companyId, parsedForm)
			} catch (err) {
				captureException(err)
				console.error(err)
			}
		}

		const updateAnswers = async (criterionId: string) => {
			const verticalsAnswersPayload = transformAnswersSubmissionToPayload(
				verticalsAnswersSubmission.value,
				criterionType,
				criterionId,
			)

			try {
				const parsedForm = CriterionAllAssignedVerticalsAnswersPayload.parse(verticalsAnswersPayload)
				await updateAssignedAnswers(params.companyId, criterionId, parsedForm)
			} catch (err) {
				captureException(err)
				console.error(err)
				showToast({
					message: 'Failed to save verticals answers',
					type: 'error',
				})
			}
		}

		const assignCriterionToEcosystem = async (criterionId: string) => {
			try {
				await assignCriterion(params.companyId, {
					criterionId,
					ecosystemId: params.ecosystemId,
				})
			} catch (err) {
				captureException(err)
				console.error(err)
				showToast({
					message: 'Failed to save verticals answers',
					type: 'error',
				})
			}
		}

		try {
			const criterionResult = await createOrUpdateCriterion()

			if (criterionResult) {
				await assignCriterionToEcosystem(String(criterionResult.id))
				await updateAnswers(String(criterionResult.id))
			}

			await Promise.all([
				queryClient.invalidateQueries({ queryKey: criteriaKeys.all }),
				queryClient.invalidateQueries({ queryKey: ecosystemKeys.all }),
				queryClient.invalidateQueries({ queryKey: verticalKeys.all }),
			])

			showToast({
				message: isEdit ? 'Criterion successfully updated' : 'Criterion successfully created',
				type: 'success',
				bottom: '60px',
			})
		} catch (err) {
			captureException(err)
			console.error(err)
			showToast({
				message: 'Unexpected error while saving criterion',
				type: 'error',
			})
		}

		return redirect(
			routes.calibrate.criteria.editAnswers({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				criterionType,
			}),
		)
	}
