import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLoaderData, useLocation } from 'react-router-dom'
import { type z } from 'zod'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/verticals/criteria/routes/save'
import { generateSaveCriterionContext } from '#src/routes/calibrate/verticals/criteria/utils'
import {
	FormAnswersSection,
	getOptionsForArrayField,
} from '#src/routes/calibrate/verticals/criteria/views/components/FormAnswersSection'
import { FormDetailsSection } from '#src/routes/calibrate/verticals/criteria/views/components/FormDetailsSection'
import { FormVerticalsSection } from '#src/routes/calibrate/verticals/criteria/views/components/FormVerticalsSection'
import { UNITS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { type CriterionNewSchema, type CriterionQuestionAPISchema, getCriterionNewFormSchema } from '../schema'
import { FormSections } from './components/FormSections'

export type FormData = z.infer<typeof CriterionNewSchema>

type LocationState = {
	criterion: z.infer<typeof CriterionQuestionAPISchema> | undefined
}

export const View = () => {
	const { state } = useLocation() as { state?: LocationState }

	const {
		values: { companyId, ecosystemId, type, defaultValues },
		data: { criterion: loaderCriterion, verticals },
	} = useLoaderData() as CriteriaSaveLoaderType
	const criterion = loaderCriterion || state?.criterion
	const [isInitialized, setIsInitialized] = useState(false)

	const formId = 'saveCriterionForm'
	const formProps = useForm<FormData>({
		resolver: async (values, context, options) => {
			const { schema } = generateSaveCriterionContext(verticals, values.type, type, criterion)
			const formSchema = getCriterionNewFormSchema(values.type)
			const merged = formSchema.merge(schema)
			const refined = merged.superRefine((data, ctx) => {
				if (data.unit === 'other') {
					if (!data.otherUnit) {
						ctx.addIssue({
							code: 'custom',
							path: ['otherUnit'],
							message: 'Required',
						})
					} else {
						data.unit = data.otherUnit
						delete data.otherUnit
					}
				}
			})

			const createResolver = zodResolver(refined)

			return createResolver(values, context, options)
		},

		/*
		 * Due to React Strict Mode, the validation behavior for some fields, such as
		 * the "name" field and "options" fields, may behave inconsistently on the first
		 * submission attempt. This issue typically occurs only in local development.
		 */
		shouldUnregister: false,
		resetOptions: {
			keepDirtyValues: false,
		},
		defaultValues: {
			...defaultValues,
			...criterion,
			inBizmodel: criterion?.inBizmodel ?? false,
			otherUnit: !Object.values(UNITS).includes(criterion?.unit as UNITS) ? (criterion?.unit ?? undefined) : undefined,
			unit: criterion?.unit && !Object.values(UNITS).includes(criterion.unit as UNITS) ? 'other' : undefined,
			checkboxUnit: criterion?.unit ? ['true'] : undefined,
			options: (getOptionsForArrayField(criterion, criterion?.type) as string[]) || [''],
		},
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})
	const { watch, resetField, setValue, unregister } = formProps

	const action = !loaderCriterion?.id
		? routes.calibrate.criteria.new({ companyId, ecosystemId, criterionType: type })
		: routes.calibrate.criteria.edit({
				companyId,
				ecosystemId,
				criterionType: type,
				criterionId: String(loaderCriterion.id),
			})

	const fieldType = watch('type')
	const fieldsDisabled = criterion?.isAnsweredInEcosystem ?? false

	useEffect(() => {
		const subscription = watch((_values, { name, type }) => {
			if (name === 'type' && type === 'change') {
				setIsInitialized(false)
				unregister('options')
				setValue('options', [])
			}
		})

		return () => subscription.unsubscribe()
	}, [watch, setValue, unregister])

	useEffect(() => {
		if (!fieldType) return

		const { schema, defaultValues } = generateSaveCriterionContext(verticals, fieldType, type, criterion)
		const verticalFields = Object.keys(schema.shape) as (keyof FormData)[]
		const fieldsWithToggles = Object.keys(defaultValues) as (keyof FormData)[]

		fieldsWithToggles.forEach(fieldName => {
			if (fieldName.endsWith('Toggle')) {
				setValue(fieldName, false)
			}
		})

		verticalFields.forEach(fieldName => {
			resetField(fieldName)
		})

		setIsInitialized(true)
	}, [fieldType, verticals, type, criterion, setValue, resetField])

	const getVerticalSection = () => {
		if (!fieldType) return null

		return {
			heading: 'Select Verticals',
			content: <FormVerticalsSection key={fieldType} criterion={criterion} isInitialized={isInitialized} />,
		}
	}

	const verticalSection = useMemo(
		getVerticalSection,

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fieldType, verticals, type, criterion, resetField, isInitialized],
	)

	return (
		<FormWrapper formId={formId} formProps={formProps} action={action}>
			<FormSections
				sections={[
					{
						heading: 'Details',
						content: (
							<FormDetailsSection disableField={fieldsDisabled} type={type} ecosystemsInUse={criterion?.ecosystems} />
						),
					},
					{
						heading: 'Answers',
						content: <FormAnswersSection fieldType={fieldType} criterion={criterion} fieldsDisabled={fieldsDisabled} />,
					},
					...(verticalSection ? [verticalSection] : []),
				]}
			/>
		</FormWrapper>
	)
}
