import { cva } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'

export const avatarVariants = cva(
	'inline-flex h-8 w-8 items-center justify-center rounded-full bg-brand-3-bg font-semibold text-neutral-inverse-fg',
	{
		variants: {
			variant: {
				blue: 'bg-blue-60',
				green: 'bg-green-70',
				orange: 'bg-orange-70',
				gray: 'bg-neutral-2-bd',
			},
			size: {
				sm: 'h-8 w-8 text-body-sm',
				md: 'h-10 w-10 text-body-sm',
				xl: 'h-24 w-24 text-heading-sm',
			},
		},
		defaultVariants: {
			variant: 'blue',
			size: 'sm',
		},
	},
)

export default function ContactAvatar({
	initial,
	variant,
	size,
	className,
	badge,
}: {
	initial: string
	variant: 'blue' | 'green' | 'orange' | 'gray'
	size: 'sm' | 'md' | 'xl'
	className?: string
	badge?: ReactNode
}) {
	return (
		<div className={cn('relative', avatarVariants({ variant, size, className }))}>
			{initial?.slice(0, 1) ?? 'P'}
			{badge && badge}
		</div>
	)
}

export const avatarVariantByPersonaType = (personaType: string | undefined) => {
	switch (personaType) {
		case PERSONA_TYPES.decisionmaker.toLowerCase():
			return 'blue'
		case PERSONA_TYPES.influencer.toLowerCase():
			return 'green'
		case PERSONA_TYPES.champion.toLowerCase():
			return 'orange'
		default:
			return 'blue'
	}
}
