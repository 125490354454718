import { Fragment, memo, useCallback, useMemo } from 'react'
import { Link, useFetcher, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { Accordion } from '#src/components/ui/accordion'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { type CriteriaEditAnswersLoaderType } from '#src/routes/calibrate/verticals/criteria/routes/editAnswers'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type CriterionAPISchema } from '../../schema'
import { generateCriterionAnswerPerVertical } from '../../utils'

type CriterionAnswersCard = {
	criterion: z.infer<typeof CriterionAPISchema>['criterion']
	answers: z.infer<typeof CriterionAPISchema>['answers']
}

export const CriterionAnswersCard = memo(({ criterion, answers }: CriterionAnswersCard) => {
	const {
		values: { companyId, ecosystemId, type },
	} = useLoaderData() as CriteriaEditAnswersLoaderType
	const fetcher = useFetcher()

	const handleUnassign = useCallback(() => {
		fetcher.submit(null, {
			method: 'PATCH',
			action: routes.calibrate.criteria.unassign({
				companyId,
				ecosystemId,
				criterionType: type,
				criterionId: String(criterion.id),
			}),
		})
	}, [fetcher, companyId, ecosystemId, type, criterion.id])

	const data = useMemo(
		() =>
			answers.length
				? [
						{
							trigger: (
								<div className="flex w-full items-center justify-between text-left">
									<span>{criterion.name}</span>
									<div className="flex">
										<div onClick={e => e.stopPropagation()}>
											<CustomTooltip label="Edit criterion">
												<Button variant="icon" size="icon" asChild>
													<Link
														to={routes.calibrate.criteria.edit({
															companyId: companyId,
															ecosystemId: ecosystemId,
															criterionType: type,
															criterionId: String(criterion.id),
														})}
													>
														<Icon name="edit" size="sm" />
													</Link>
												</Button>
											</CustomTooltip>
										</div>
										<div onClick={e => e.stopPropagation()}>
											<CustomTooltip label="Remove criterion">
												<Button
													variant="icon"
													size="icon"
													type="button"
													className="text-status-danger-fg"
													onClick={handleUnassign}
												>
													<Icon name="trash" size="sm" />
												</Button>
											</CustomTooltip>
										</div>
									</div>
								</div>
							),
							content: (
								<div className="flex flex-col gap-4.5 px-3.5 pb-4 pt-5">
									{answers.map(item => (
										<Fragment key={item.verticalId + item.criterionId}>
											{generateCriterionAnswerPerVertical(criterion, item)}
										</Fragment>
									))}
								</div>
							),
						},
					]
				: [],
		[answers, companyId, criterion, ecosystemId, handleUnassign, type],
	)

	const isPending = fetcher.state === 'submitting' || fetcher.state === 'loading'

	return (
		!!data.length && (
			<Accordion
				data={data}
				type="multiple"
				defaultValue={['item-0']}
				contentProps={{ forceMount: true }}
				className={cn('rounded border border-neutral-1-bd', isPending && 'pointer-events-none opacity-50')}
				triggerProps={{
					className:
						'w-full flex items-center text-neutral-1-fg bg-neutral-2-bg px-3.5 py-2 rounded-t radix-state-closed:rounded-b overflow-hidden',
				}}
			/>
		)
	)
})

CriterionAnswersCard.displayName = 'CriterionAnswersCard'
