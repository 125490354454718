import { useState, useEffect, type ReactNode } from 'react'
import { SplashScreen } from '#src/components/SplashScreen'
import { router } from '#src/main'

export const SplashScreenProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState(router.state.navigation.state !== 'idle')

	useEffect(() => {
		if (router.state.navigation.state === 'idle') return

		const unsubscribe = router.subscribe(({ navigation }) => {
			if (navigation.state === 'idle') {
				setIsLoading(false)
				unsubscribe()
			}
		})

		return () => unsubscribe()
	}, [])

	if (isLoading) {
		return <SplashScreen />
	}

	return children
}
