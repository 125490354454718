import { Chip } from '#src/components/chip'
import ProductTip from '#src/components/product-tip'

import { IntentType } from '#src/routes/prioritize/constants'

export function SignalType({ type }: { type: IntentType }) {
	if (type === IntentType.COLD_OUTBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="blue">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Cold Outbound Signal: Indicator suggesting potential customer
								interest, used to guide outreach efforts for engagement."
				/>
			</div>
		)
	if (type === IntentType.STATIC_HOOK)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="green">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Static hook: Persistent data within a target company’s website,
								linkedIn profile etc..."
				/>
			</div>
		)
	if (type === IntentType.INBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="red">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Inbound Signal: Indicator of potential customer interest based
								on actions taken towards your company, such as website visits or
								content downloads."
				/>
			</div>
		)
	if (type === IntentType.WARM_OUTBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="orange">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Warm Outbound Signal: Indicator suggesting a higher likelihood
								of interest, used to guide targeted outreach efforts to engage
								with familiar prospects."
				/>
			</div>
		)
}
