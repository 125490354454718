import { type FieldMetadata, getSelectProps } from '@conform-to/react'
import { type z } from 'zod'
import { Select } from '#src/components/forms'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import { useVoice } from '#src/hooks/useVoice'
import { CreateScenarioFormSchema } from '#src/routes/enable/roleplay/scenario/schema'

const PickedSchema = CreateScenarioFormSchema.pick({
	personality: true,
})

type PickedSchemaType = z.infer<typeof PickedSchema>

type PersonalityFormProps = {
	fields: {
		[K in keyof PickedSchemaType]: FieldMetadata<PickedSchemaType[K]>
	}
}

export const PersonalityForm = ({ fields }: PersonalityFormProps) => {
	const personalityFields = fields.personality.getFieldset()
	const { voiceOptions } = useVoice()

	return (
		<>
			<Select
				labelProps={{ children: 'Personality traits' }}
				inputProps={{
					...getSelectProps(personalityFields.personalityTrait),
					defaultValue: personalityFields.personalityTrait.initialValue,
					value: personalityFields.personalityTrait.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={PERSONALITY_TRAIT_OPTIONS}
				errors={personalityFields.personalityTrait.errors}
			/>
			<Select
				labelProps={{ children: 'Emotional states' }}
				inputProps={{
					...getSelectProps(personalityFields.emotionalState),
					defaultValue: personalityFields.emotionalState.initialValue,
					value: personalityFields.emotionalState.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={EMOTIONAL_STATE_OPTIONS}
				errors={personalityFields.emotionalState.errors}
			/>
			<Select
				labelProps={{ children: 'Communication styles' }}
				inputProps={{
					...getSelectProps(personalityFields.communicationStyle),
					defaultValue: personalityFields.communicationStyle.initialValue,
					value: personalityFields.communicationStyle.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={COMMUNICATION_STYLE_OPTIONS}
				errors={personalityFields.communicationStyle.errors}
			/>
			<Select
				labelProps={{ children: 'Decision making process' }}
				inputProps={{
					...getSelectProps(personalityFields.decisionMakingProcess),
					defaultValue: personalityFields.decisionMakingProcess.initialValue,
					value: personalityFields.decisionMakingProcess.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={DECISION_MAKING_PROCESS_OPTIONS}
				errors={personalityFields.decisionMakingProcess.errors}
			/>
			<Select
				labelProps={{ children: 'Voice character' }}
				inputProps={{
					...getSelectProps(personalityFields.voice),
					defaultValue: personalityFields.voice.initialValue,
					value: personalityFields.voice.value,
					placeholder: '-',
					className: 'bg-white',
				}}
				options={voiceOptions}
				errors={personalityFields.voice.errors}
			/>
		</>
	)
}
