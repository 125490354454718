import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { loader as mainLoader } from '#src/routes/_layout/main'
import adminRouter from '../admin/router'
import companyRouter from '../company/router'
import developersRouter from '../developers/router'
import enableRouter from '../enable/router'
import organizeRouter from '../organize/router'
import prioritizeRouter from '../prioritize/router'
import reportRouter from '../report/router'
import settingsRouter from '../settings/router'
import calibrateRouter from './calibrate'

export default (client: QueryClient) =>
	({
		id: 'main-loader',
		path: '/:companyId?',
		loader: mainLoader,
		children: [
			companyRouter,
			calibrateRouter(client),
			organizeRouter,
			prioritizeRouter,
			enableRouter,
			settingsRouter,
			adminRouter,
			reportRouter,
			...(import.meta.env.DEV ? [developersRouter] : []),
		],
	}) satisfies RouteObject
