import { useEffect, useRef } from 'react'

export default function AudioVisualizer({ analyzer }: { analyzer: AnalyserNode | null }) {
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const requestRef = useRef<number>(0)

	useEffect(() => {
		const canvas = canvasRef.current

		const draw = () => {
			if (!analyzer || !canvas) {
				if (canvas) {
					// Clear the canvas if there's no live audio
					const ctx = canvas.getContext('2d')

					if (ctx) {
						ctx.clearRect(0, 0, canvas.width, canvas.height)
					}
				}
				return
			}

			const ctx = canvas.getContext('2d')
			const { height } = canvas

			if (ctx) {
				ctx.clearRect(0, 0, canvas.width, canvas.height)

				const bufferLength = analyzer.frequencyBinCount
				const dataArray = new Uint8Array(bufferLength)
				analyzer.getByteFrequencyData(dataArray)

				const minbarHeight = 1
				const maxBarHeight = 12.8
				const barWidth = 4.8
				const barSpacing = 9.6
				// Used to amplify the bar height for better visualization for normal speech audio levels
				// This is a magic number that works well for the current bar height and width
				// It may need to be adjusted if the bar height or width changes
				// This is making an assumption that the average value of the frequency data is around 64 for normal speech
				const SCALE_FACTOR = 6

				const averageValue = dataArray.reduce((total, v) => total + v, 0) / bufferLength
				const normalizedHeight = Math.max(
					minbarHeight,
					// 0 to 255 is the range of an 8-bit unsigned integer of the frequency data
					Math.min(maxBarHeight, (averageValue / 255) * maxBarHeight * SCALE_FACTOR),
				)

				const middleBarHeight = normalizedHeight
				const sideBarHeight = Math.max(minbarHeight, middleBarHeight * 0.5)

				ctx.strokeStyle = 'rgba(57, 89, 255, 1)'
				ctx.lineWidth = barWidth
				ctx.lineCap = 'round'

				for (let i = 0; i < 3; i++) {
					const x = i * (barWidth + barSpacing) + barWidth / 2
					const barHeight = i === 1 ? middleBarHeight : sideBarHeight
					const startY = (height - barHeight) / 2

					ctx.beginPath()
					ctx.moveTo(x, startY)
					ctx.lineTo(x, startY + barHeight)
					ctx.stroke()
				}
			}

			requestRef.current = requestAnimationFrame(draw)
		}

		/**
		 * Start the animation loop
		 */
		requestRef.current = requestAnimationFrame(draw)

		return () => {
			if (requestRef.current) {
				cancelAnimationFrame(requestRef.current)
			}

			if (canvas) {
				// Clear the canvas if there's no live audio
				const ctx = canvas.getContext('2d')

				if (ctx) {
					ctx.clearRect(0, 0, canvas.width, canvas.height)
				}
			}
		}
	}, [analyzer])

	return <canvas ref={canvasRef} className="pointer-events-none" width="34" height="22" />
}
