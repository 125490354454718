export const EMPTY_PAGINATED_RESPONSE = {
	data: [],
	meta: {
		current_page: 1,
		last_page: 1,
		total: 0,
		per_page: 0,
		from: null,
		to: null,
		path: '',
		links: [],
	},
	links: {
		first: null,
		last: null,
		next: null,
		prev: null,
	},
}
